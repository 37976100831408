import { useQueryClient } from "@tanstack/react-query";
import {
    GetRequirementDocument,
    GetRequirementQuery,
    Proof,
    UpdateDocumentV2Document,
} from "../../../../../generated/client/graphql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../../QueryClientWithHeaders";

export const useUpdateProofDocumentOwner = (requirementId: string) => {
    const client = useQueryClient();

    const updateDocument = useGraphQLMutation(
        UpdateDocumentV2Document,
        {},
        true
    );
    const updateOwner = (proof: Proof, ownerId: string | null) => {
        if (!proof.document) {
            return;
        }
        client.setQueryData<GetRequirementQuery>(
            useGraphQL.getKey(GetRequirementDocument, { id: requirementId! }),
            (data) => {
                const dataCopy = structuredClone(data);
                if (dataCopy?.requirement.proofs) {
                    dataCopy.requirement.proofs =
                        dataCopy.requirement.proofs.map((p) => {
                            if (p.id === proof.id) {
                                return {
                                    ...p,
                                    document: {
                                        ...p.document!,
                                        assignedToId: ownerId,
                                    },
                                };
                            } else {
                                return p;
                            }
                        });
                }
                return dataCopy;
            }
        );
        updateDocument.mutate({
            documentId: proof.document.id,
            patch: { assignedToId: ownerId },
        });
    };

    return { updateOwner };
};
