import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text, TextProps } from "@design-system/Typography/Text";
import { ClassValue, cn } from "@design-system/Utilities";
import { type FC, type PropsWithChildren } from "react";
import { coverHeightCn, coverWidthCn, covers, type Cover } from "./utils";
import { Skeleton } from "@design-system/DataDisplay/Skeleton";

const LeftColumn: FC<PropsWithChildren<{ className?: ClassValue }>> = ({
    className,
    children,
}) => (
    <FlexCol className={cn(className)} justifyContent="center" gap="2" pl="7">
        {children}
    </FlexCol>
);

const RightColumn: FC<
    PropsWithChildren<{ className?: ClassValue; cover?: Cover }>
> = ({ children, className, cover }) => {
    const coverUrl = covers[cover ?? "default"];
    const coverStyle = {
        backgroundImage: `url(${coverUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    };
    return (
        <Box
            className={cn(coverHeightCn, coverWidthCn, className)}
            style={coverStyle}
        >
            {children}
        </Box>
    );
};

const Title: FC<PropsWithChildren<TextProps>> = (props) => (
    <Text color="white" variant="hero6" {...props} />
);

const Description: FC<PropsWithChildren<TextProps>> = (props) => (
    <Text color="white" variant="body" truncate {...props} />
);

type HeaderProps = {
    bgColor: string;
    className?: ClassValue;
};

type HeaderType = FC<PropsWithChildren<HeaderProps>> & {
    LeftColumn: typeof LeftColumn;
    RightColumn: typeof RightColumn;
    Title: typeof Title;
    Description: typeof Description;
};

export const Header: HeaderType = ({ bgColor, children, className }) => (
    <FlexRow
        justifyContent="between"
        as="header"
        className={cn("box-border", "coverHeightCn", className)}
        style={{ backgroundColor: bgColor }}
    >
        {children}
    </FlexRow>
);

export const HeaderFullSkeleton: FC<{ className?: string }> = ({
    className,
}) => (
    <Header bgColor="#EEE" className={className}>
        <FlexRow justifyContent="between" h="full" w="full">
            <LeftColumn>
                <Skeleton h="6" className="w-20" />
                <Skeleton h="3" className="w-40" />
            </LeftColumn>
            <RightColumn />
        </FlexRow>
    </Header>
);

Header.LeftColumn = LeftColumn;
Header.RightColumn = RightColumn;
Header.Title = Title;
Header.Description = Description;
