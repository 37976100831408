import { Row } from "@tanstack/react-table";
import Checkbox from "../../Checkbox";

export default function CheckboxCell<T>({
    row,
    text,
}: {
    row: Row<T>;
    text?: string;
}) {
    return (
        <div className="flex flex-row space-x-2">
            <Checkbox
                checked={row.getIsSelected()}
                onClick={(e) => {
                    e.stopPropagation();
                    row.toggleSelected(!row.getIsSelected());
                }}
                aria-label="Select row"
            />
            {text && (
                <div className="text-sm font-regular text-neutral-700">
                    {text}
                </div>
            )}
        </div>
    );
}
