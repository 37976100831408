import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "ReportingReminderModal", {
    title: "Send reminder",
    subtitle:
        "An email reminder will be sent to each contributor that has at least one outstanding data request for the selected indicators.",
    subject: "Subject",
    default_subject: "New CSR data collection campaign",
    alert_title_one: "One person will be notified",
    alert_title_other: "{{count}} persons will be notified",
    alert_subtitle:
        "Double check the recipients, and test your email by inserting your email below before confirming.",
    test_user: "Test user",
    test_before_send:
        "You can test the email before sending it. By sending a test email, the emails will be sent to your email address instead of the contributors.",
    send_test: "Send test",
    send_test_success: "Test email sent successfully",
    send_test_error: "Error sending test email",
    send_reminder: "Send reminder",
    send_reminder_success: "Email sent successfully to {{count}} contributors",
    send_reminder_error: "Error sending emails",
});

i18next.addResourceBundle("fr", "ReportingReminderModal", {
    title: "Envoyer un rappel",
    subtitle:
        "Un rappel sera envoyé par email à chaque contributeur qui a au moins une demande de données en attente pour les indicateurs sélectionnés.",
    subject: "Sujet",
    default_subject: "Nouvelle campagne de collecte de données RSE",
    alert_title_one: "Une personne sera notifiée",
    alert_title_other: "{{count}} personnes seront notifiées",
    alert_subtitle:
        "Vérifiez les destinataires et testez votre e-mail en insérant votre e-mail ci-dessous avant de confirmer.",
    test_user: "Utilisateur test",
    test_before_send:
        "Vous pouvez tester l'e-mail avant de l'envoyer. En envoyant un e-mail de test, les mails seront envoyés à votre adresse e-mail plutot qu'aux contributeurs.",
    send_test: "Envoyer le test",
    send_test_success: "E-mail de test envoyé avec succès",
    send_test_error: "Erreur lors de l'envoi de l'e-mail de test",
    send_reminder: "Envoyer un rappel",
    send_reminder_success_one: "Email envoyé avec succès ",
    send_reminder_success_other:
        "Email envoyé avec succès à {{count}} contributeurs",
    send_reminder_error: "Erreur lors de l'envoi des emails",
});
