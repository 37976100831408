import { AccessHeadersHolder } from "@app/repositories/AccessHeadersHolder";
import { CsrdRepository } from "@app/repositories/CsrdRepository";
import { graphqlClientKoyeb } from "@app/repositories/clients";
import {
    CsrdDatapointsFilter,
    GetCsrdDatapointsDocument,
    GetCsrdDatapointsQueryVariables,
    GetCsrdPillarDataDocument,
    GetCsrdPillarsDocument,
    GetCsrdSectionsAndStatsDocument,
    GetCsrdSectionsAndStatsQuery,
    GetCsrdStatsDocument,
    GetCsrdStatsQuery,
} from "@generated/client/graphql";

export class GraphQLCsrdRepository implements CsrdRepository {
    getGlobalStats = (): Promise<GetCsrdStatsQuery> => {
        return graphqlClientKoyeb.request(
            GetCsrdStatsDocument,
            undefined,
            AccessHeadersHolder.getHeaders(),
        );
    };

    getSectionsAndStats = (): Promise<GetCsrdSectionsAndStatsQuery> => {
        return graphqlClientKoyeb.request(
            GetCsrdSectionsAndStatsDocument,
            undefined,
            AccessHeadersHolder.getHeaders(),
        );
    };

    getPillar = (id: string, filter?: CsrdDatapointsFilter) => {
        return graphqlClientKoyeb.request(
            GetCsrdPillarDataDocument,
            { pillarCmsId: id, filter: filter },
            AccessHeadersHolder.getHeaders(),
        );
    };

    getPillars = () => {
        return graphqlClientKoyeb.request(
            GetCsrdPillarsDocument,
            undefined,
            AccessHeadersHolder.getHeaders(),
        );
    };

    getDatapoints = (input: GetCsrdDatapointsQueryVariables) => {
        return graphqlClientKoyeb.request(
            GetCsrdDatapointsDocument,
            input,
            AccessHeadersHolder.getHeaders(),
        );
    };
}
