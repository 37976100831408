import { ReactNode } from "react";
import { Tag } from "../../../../generated/client/graphql";
import { Variant } from "../StatusTag";
import TinyStatusTag from "../StatusTag/Tiny";

const TagList = ({ tags, extra }: { tags: Tag[]; extra?: ReactNode }) => {
    return (
        <div className="flex flex-wrap -m-0.5">
            {tags.map((tag) => (
                <div className="p-0.5">
                    <TinyStatusTag variant={Variant.Base}>
                        {tag.name}
                    </TinyStatusTag>
                </div>
            ))}
            <div className="p-0.5">{extra}</div>
        </div>
    );
};
export default TagList;
