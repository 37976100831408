import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { useState } from "react";
import { Theme } from "../../../../../generated/client/graphql";
import CheckboxItem from "../../components/dropdowns/items/CheckboxItem";
import DropdownItemSearch from "../../components/dropdowns/items/Search";
import DropdownContentLayout from "../../components/dropdowns/layout";

const ThemeDropdown = ({
    themeIds,
    onThemeIdsChange,
    themes,
    hidePlus,
    ...props
}: {
    themeIds?: string[] | undefined;
    onThemeIdsChange: (themeIds: string[]) => void;
    themes: Theme[] | undefined;
    hidePlus?: boolean;
} & DropdownPrimitive.DropdownMenuContentProps) => {
    const [searchFilterString, setSearchFilterString] = useState("");

    const onToggle = (id: string) => {
        if (themeIds?.includes(id)) {
            const newValues = [...themeIds];
            newValues.splice(themeIds.indexOf(id), 1);
            onThemeIdsChange(newValues);
        } else {
            onThemeIdsChange([...(themeIds || []), id]);
        }
    };
    return (
        <DropdownContentLayout {...props}>
            <DropdownItemSearch
                searchFilterString={searchFilterString}
                setSearchFilterString={setSearchFilterString}
            />

            {themes
                ?.filter(
                    ({ name }) =>
                        !searchFilterString ||
                        (name &&
                            name
                                .toLowerCase()
                                .includes(searchFilterString.toLowerCase())),
                )
                .map(({ id, name }) => (
                    <CheckboxItem
                        key={id}
                        onSelect={(e) => {
                            e.preventDefault();
                        }}
                        checked={id ? themeIds?.includes(id) : false}
                        onCheckedChange={() => id && onToggle(id)}
                    >
                        {name}
                    </CheckboxItem>
                ))}
        </DropdownContentLayout>
    );
};

export default ThemeDropdown;
