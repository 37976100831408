import { atom } from "jotai";

export const breadcrumbListsAtom = atom<
    Record<string, { param: string; name?: string }[]>
>({});
export const breadcrumbParamsAtom = atom<Record<string, string>>({});
export const breadcrumbAtom = atom<
    Record<
        string,
        {
            name: string;
            list?: { name: string; param: string }[];
        }
    >
>({});
