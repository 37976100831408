import {
    DataRequestStatus,
    RequestBlockFieldsFragment,
} from "../../../../generated/client/graphql";

export const useRequestStats = (
    dataRequests: readonly RequestBlockFieldsFragment[]
) => {
    const total = dataRequests.length;
    const answered = dataRequests.filter(
        (r) => r.status === DataRequestStatus.Answered
    ).length;
    const transfered = dataRequests.filter(
        (dr) => dr.status === DataRequestStatus.Transfered && dr.reassignToStr
    ).length;
    const submited = dataRequests.filter(
        (dr) => dr.status === DataRequestStatus.Submitted
    ).length;
    return { answered, total, transfered, submited };
};
