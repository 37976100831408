import {
    isOnboardingSectionCollapsedAtom,
    onboardingProgressAtom,
    setOnboardingProgressAtom,
    toggleIsOnboardingSectionCollapsedAtom,
} from "@app/store/onboardingStore";
import { currentUserAtom } from "@app/store/userStore";
import { useAtomValue, useSetAtom } from "jotai";
import { useMemo, useRef, useState, type MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import {
    beavrAdminConfigurationCards,
    userConfigurationCards,
} from "../../utils";
import "../../i18n";
import { UserRole } from "@generated/client/graphql";
import { useNavigate } from "react-router-dom";

export function useConfigurationSection() {
    const isOnboardingSectionCollapsed = useAtomValue(
        isOnboardingSectionCollapsedAtom,
    );
    const {
        progress,
        stats: { completedSteps, totalSteps, percentage },
    } = useAtomValue(onboardingProgressAtom);
    const onboardingProgressMutate = useSetAtom(setOnboardingProgressAtom);
    const toggleIsOnboardingSectionCollapsed = useSetAtom(
        toggleIsOnboardingSectionCollapsedAtom,
    );

    const [showDiscoveryCards, setShowDiscoveryCards] = useState(
        !isOnboardingSectionCollapsed,
    );
    const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
    const { t } = useTranslation("NewDashboard");
    const { data: { currentUser: user } = { currentUser: undefined } } =
        useAtomValue(currentUserAtom);
    const isBeavrAdmin = user?.permission?.role === UserRole.BeavrAdmin;
    const lang = user?.locale === "fr" ? "fr" : "en";

    const configurationCards = useMemo(
        () =>
            isBeavrAdmin
                ? beavrAdminConfigurationCards
                : userConfigurationCards,
        [isBeavrAdmin],
    );

    const navigate = useNavigate();

    const handleLinkClick = () => {
        clearTimeout(timeoutRef.current);
        if (isOnboardingSectionCollapsed) {
            setShowDiscoveryCards(true);
            timeoutRef.current = setTimeout(
                toggleIsOnboardingSectionCollapsed,
                1,
            );
            return;
        }

        toggleIsOnboardingSectionCollapsed();
        timeoutRef.current = setTimeout(() => {
            setShowDiscoveryCards(false);
        }, 300);
    };

    const cards = useMemo(() => {
        return configurationCards.map(({ path, id, learnMoreCb, ...card }) => {
            const updateOnboardingProgress = () => {
                onboardingProgressMutate({
                    ...progress,
                    [id]: true,
                });
            };

            return {
                ...card,
                id,
                handleLearnMoreClick: (e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    learnMoreCb[lang]();
                    updateOnboardingProgress();
                },
                handleCardClick: path
                    ? () => {
                          navigate(path);
                          updateOnboardingProgress();
                      }
                    : undefined,
            };
        });
    }, [configurationCards, lang, navigate, progress, onboardingProgressMutate]);

    return {
        cards,
        completedSteps,
        handleLinkClick,
        isOnboardingSectionCollapsed,
        percentage,
        showDiscoveryCards,
        t,
        totalSteps,
        user,
    };
}
