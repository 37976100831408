import { Table, usePagination, useSorting } from "@design-system/Table";
import { useState, type FC } from "react";
import SearchFilter from "../../../components/Filters/Search";
import { useOrganizations } from "../data";
import { useColumns } from "./columns";
import "./i18n";

export const UserPermissionTable: FC = () => {
    const [searchString, setSearchString] = useState<string | undefined>("");
    const { pagination, skip, take } = usePagination();
    const { sorting, field, order } = useSorting<"name" | "uname" | "type">(
        "name",
    );

    const {
        data: { count, organizations } = { count: 0, organizations: [] },
        isPending,
    } = useOrganizations({
        filter: {
            search: searchString,
        },
        skip,
        take,
        orderBy: {
            [field]: order,
        } as const,
    });

    const columns = useColumns();

    const handleSearch = (searchString?: string) => {
        setSearchString(searchString);
        pagination.onPaginationChange((prevP) => ({ ...prevP, pageIndex: 0 }));
    };

    return (
        <div className="space-y-4">
            <SearchFilter
                searchString={searchString}
                setSearchString={handleSearch}
            />
            <Table
                columns={columns}
                data={organizations}
                loading={isPending}
                pagination={pagination}
                rowCount={count}
                sorting={sorting}
            />
        </div>
    );
};
