import SubthemeMultiSelect from "@app/shared/components/SubthemeMultiSelect";
import { DocumentsSuggestions } from "@app/usecases/DocumentUseCases/types";
import { Icon } from "@design-system/Icons";
import { MultiSelect } from "@design-system/Inputs/MultiSelect";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { Item } from "./Item";

type FilterTabProps = {
    nameFilter?: string;
    onNameFilterChange: (name: string | undefined) => void;
    onSubthemesFilterChange: (subthemes: (string | null)[]) => void;
    openCustomDocTab: () => void;
    suggestions: DocumentsSuggestions;
    subthemesFilter: string[];
};

export const FilterTab: FC<FilterTabProps> = ({
    nameFilter,
    onNameFilterChange,
    onSubthemesFilterChange,
    openCustomDocTab,
    subthemesFilter,
    suggestions,
}) => {
    const { t } = useTranslation("DocumentsUploadModal");

    const [searchSubthemeString, setSearchSubthemeString] = useState<
        string | undefined
    >();

    return (
        <>
            <FlexRow alignSelf="stretch" gap="2" p="1">
                <SubthemeMultiSelect
                    values={subthemesFilter}
                    onValuesChange={onSubthemesFilterChange}
                    searchFilter={searchSubthemeString}
                    onSearchFilterChange={setSearchSubthemeString}
                />
                <MultiSelect.Search
                    className="w-full"
                    placeholder={t("multiselect_search_placeholder")}
                    searchString={nameFilter}
                    setSearchString={onNameFilterChange}
                />
            </FlexRow>
            <FlexRow py="0.5" px="1" justifyContent="between">
                <Text color="secondary" variant="body-sm">
                    {t("multiselect_results_title")}
                </Text>
                <a
                    className="cursor-pointer inline-flex items-center gap-1"
                    style={{ textDecoration: "none" }}
                    onClick={openCustomDocTab}
                >
                    <Text color="brand" variant="body-sm-bold">
                        {t("multiselect_create_custom")}
                    </Text>
                    <Icon name="add" size="xs" />
                </a>
            </FlexRow>
            {!!suggestions.items.length ? (
                <FlexCol className="max-h-[490px] overflow-y-auto" w="full">
                    {suggestions.items.map(
                        ({ hasAlreadyEntry, id, name, theme }) => {
                            return (
                                <Item
                                    hasEntry={hasAlreadyEntry}
                                    id={id}
                                    key={id}
                                    name={name}
                                    theme={theme}
                                />
                            );
                        },
                    )}
                </FlexCol>
            ) : null}
            {!nameFilter &&
            !subthemesFilter.length &&
            !suggestions.ids.length ? (
                <EmptySuggestionsPlaceholder
                    label={t("multiselect_content_placeholder")}
                />
            ) : null}
            {nameFilter && subthemesFilter.length && !suggestions.ids.length ? (
                <EmptySuggestionsPlaceholder
                    label={t("multiselect_no_results_placeholder")}
                />
            ) : null}
        </>
    );
};

const EmptySuggestionsPlaceholder: FC<{ label: string }> = ({ label }) => (
    <FlexCol py="4" alignItems="center" className="text-center" gap="2">
        <Icon name="search" size="sm" />
        <Text as="p" variant="body-sm-bold">
            {label}
        </Text>
    </FlexCol>
);
