import React from "react";

interface RightModalBodyProps {
    children: React.ReactNode;
}

export default function RightModalBody({ children }: RightModalBodyProps) {
    return (
        <div>
            {React.Children.toArray(children).map((child, idx) => (
                <>
                    <div key={idx}>
                        <div className="items-start w-full p-4 space-y-5">
                            {child}
                        </div>
                    </div>
                    <div className=" w-full border-b border-tertiary last:hidden" />
                </>
            ))}
        </div>
    );
}
