import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "ThemeList", {
    status_tag_text: "{{active}}/{{total}} active subthemes",
    buttonSaveTitle: "Save",
    message: "Warning, there are still unsaved changes !",
});

i18next.addResourceBundle("fr", "ThemeList", {
    status_tag_text: "{{active}}/{{total}} sous-thèmes actifs",
    buttonSaveTitle: "Sauvegarder",
    message: "Attention, sauvegardez vos modifications!",
});
