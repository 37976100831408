import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "DocumentModeDropdown", {
    placeholder: "Document",
    UNKNOWN: "Unknown",
    NOT_ALLOWED: "Not allowed",
    OPTIONAL: "Optional",
    REQUIRED: "Required",
    SEVERAL: "Multiple",
    SEVERAL_REQUIRED: "Multiple, one required",
});

i18next.addResourceBundle("fr", "DocumentModeDropdown", {
    placeholder: "Document",
    UNKNOWN: "Inconnu",
    NOT_ALLOWED: "Non autorisé",
    OPTIONAL: "Optionnel",
    REQUIRED: "Obligatoire",
    SEVERAL: "Plusieurs",
    SEVERAL_REQUIRED: "Plusieurs, un obligatoire",
});
