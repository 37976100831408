import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";

import { Cancel, NavArrowDown } from "iconoir-react";
import React from "react";
import { ButtonType } from "../../../Button/ButtonShared";
import ButtonSmall from "../../../Button/ButtonSmall";

const FilterTrigger = React.forwardRef(
    (
        {
            children,
            unsetCallback,
            ...props
        }: {} & React.ButtonHTMLAttributes<HTMLButtonElement> & {
                active?: boolean;
                unsetCallback?: () => void;
            },
        ref: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <DropdownPrimitive.Trigger asChild>
                <ButtonSmall
                    ref={ref}
                    variant={ButtonType.OUTLINED}
                    IconRight={props.active ? Cancel : NavArrowDown}
                    onRightClick={props.active ? unsetCallback : undefined}
                    {...props}
                >
                    {children}
                </ButtonSmall>
            </DropdownPrimitive.Trigger>
        );
    }
);

export default FilterTrigger;
