import { Page } from "iconoir-react";
import { Check, Trash2 } from "lucide-react";
import { ReactComponent as Progress } from "../../../assets/progress.svg";
import { cn } from "../../../lib/utils";
import { ButtonType } from "../../Button/ButtonShared";
import ButtonSmall from "../../Button/ButtonSmall";

export enum BannerVariant {
    UNKNOWN,
    PENDING,
    CHECK,
}
interface BannerProps {
    name: string;
    docUrl?: string;
    subtitle: string;
    variant: BannerVariant;
    onLeftIconClick?: () => void;
    IconRight?: React.FC;
    onClick?: () => void;
}
export default function Banner(props: BannerProps) {
    return (
        <div className="flex space-x-3 ">
            <div
                className={cn(
                    "p-3 rounded-lg border border-tertiary",
                    props.onLeftIconClick && "cursor-pointer"
                )}
                onClick={props.onLeftIconClick}
            >
                {props.variant === BannerVariant.PENDING && (
                    <Progress className="w-4 h-4" />
                )}
                {props.variant === BannerVariant.CHECK && (
                    <Page className="w-4 h-4 text-green" />
                )}
            </div>
            <div className="flex-grow flex-shrink w-0">
                <div
                    className={cn(
                        "font-regular text-green truncate",
                        props.docUrl && "cursor-pointer hover:underline"
                    )}
                    onClick={() => {
                        props.docUrl && window.open(props.docUrl);
                    }}
                >
                    {props.name}
                </div>

                <div className=" text-sm text-secondary font-regular">
                    {props.subtitle}
                </div>
            </div>

            <ButtonSmall
                variant={ButtonType.OUTLINED}
                IconLeft={
                    props.IconRight
                        ? props.IconRight
                        : props.variant === BannerVariant.PENDING
                        ? Trash2
                        : Check
                }
                onClick={() => props.onClick && props.onClick()}
            />
        </div>
    );
}
