import { forwardRef } from "react";
import { ReactComponent as Loading } from "../../../assets/loading-small.svg";
import { cn } from "../../../lib/utils";
import { Tooltip } from "../../Tooltip";
import { ButtonProps } from "../ButtonShared";

export default forwardRef(function ButtonRegularlBase(
    {
        className,
        textClassName,
        iconClassName,
        onClick,
        IconLeft,
        IconRight,
        onRightClick,
        loading,
        active,
        tooltipContent,
        ...props
    }: ButtonProps &
        React.ButtonHTMLAttributes<HTMLButtonElement> & {
            buttonClassName?: string;
            textClassName?: string;
            iconClassName?: string;
        },
    ref: React.Ref<HTMLButtonElement>
) {
    return (
        <Tooltip triggerAsChild={true} tooltipContent={tooltipContent}>
            <button
                ref={ref}
                className={`h-[36px] min-w-[36px] relative flex items-center justify-center py-2 px-2 cursor-pointer rounded-lg disabled:bg-neutral-150 ${className}`}
                onClick={!loading ? onClick : undefined}
                {...props}
            >
                {loading && (
                    <div className="absolute text-white animate-spin">
                        <Loading className={iconClassName} />
                    </div>
                )}
                {IconLeft && (
                    <div className={`${loading && "invisible"} `}>
                        <IconLeft className={`w-5 h-5 ${iconClassName}`} />
                    </div>
                )}
                {(props.text || props.children) && (
                    <div
                        className={cn(
                            loading && "invisible",
                            "flex-grow text-sm font-bold whitespace-nowrap px-0.5",
                            IconRight && "pr-1.5",
                            IconLeft && "pl-1.5",
                            textClassName,
                            props.disabled && "text-neutral-400 cursor-default"
                        )}
                    >
                        {props.text}
                        {props.children}
                    </div>
                )}

                {IconRight && (
                    <div
                        className={`${
                            loading && "invisible"
                        } hover:cursor-pointer`}
                        onPointerDown={(e) => {
                            if (onRightClick) {
                                e.preventDefault();
                                onRightClick(e);
                            }
                        }}
                    >
                        <IconRight className={`w-5 h-5 ${iconClassName}`} />
                    </div>
                )}
            </button>
        </Tooltip>
    );
});
