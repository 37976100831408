import * as Select from "@radix-ui/react-select";
import { Ref, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import {
    GetUnitsDocument,
    Unit,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import SelectItem from "../../../Dropdown/SelectItem";
import SelectTrigger from "../../SelectTrigger";
import "./i18n";

const UnitInput = forwardRef(
    (
        {
            unitId,
            onUnitIdChange,
            onUnitChange,
        }: {
            unitId: string | undefined;
            onUnitIdChange?: (value: string | undefined) => void;
            onUnitChange?: (u: Unit | undefined) => void;
        },
        ref: Ref<HTMLButtonElement>,
    ) => {
        const { data } = useGraphQL(GetUnitsDocument, undefined, {}, true);
        const { t } = useTranslation("UnitInput");
        const stringValue =
            data?.units?.find((u) => u.id === unitId)?.name || undefined;

        return (
            <Select.Root
                value={unitId || ""}
                onValueChange={(val) => {
                    if (val === "") {
                        onUnitIdChange?.(undefined);
                        onUnitChange?.(undefined);
                    } else {
                        onUnitIdChange?.(val);
                        onUnitChange?.(data?.units?.find((u) => u.id === val));
                    }
                }}
            >
                <SelectTrigger
                    stringValue={stringValue && t(stringValue)}
                    placeholder={t("placeholder")}
                    value={stringValue}
                    ref={ref}
                    className="w-full"
                />

                <Select.Portal className="w-full">
                    <Select.Content
                        position="popper"
                        sideOffset={4}
                        className="border bg-white shadow-dropdown rounded-xl p-1 max-h-[300px] overflow-y-auto"
                        style={{ width: "var(--radix-select-trigger-width)" }}
                    >
                        <Select.Viewport>
                            {data?.units?.map((u) => (
                                <SelectItem value={u.id!}>{u.name}</SelectItem>
                            ))}
                        </Select.Viewport>
                    </Select.Content>
                </Select.Portal>
            </Select.Root>
        );
    },
);

export default UnitInput;
