import { OpenSelectHandGesture } from "iconoir-react";
import { useTranslation } from "react-i18next";
import {
    GetSupplierDocument,
    User,
} from "../../../../generated/client/graphql";
import { useGraphQL } from "../../../QueryClientWithHeaders";
import Container from "../../../components/Container";
import BodyHeader from "../../../shared/components/BodyHeader";

import { useSetAtom } from "jotai";
import { useParams } from "react-router-dom";
import ButtonRegular from "../../../components/Button/ButtonRegular";
import { ButtonType } from "../../../components/Button/ButtonShared";
import { useCapturePageView } from "../../../lib/tracking/utils";
import { newUsersModalOpenAtom } from "../../Users/components/NewUserModal";
import NewSupplierUserModal from "../../Users/components/NewUserModal/NewSupplierUser";
import { UsersTableInner } from "../../Users/components/UsersTable";
import "./i18n";

export default function Supplier() {
    useCapturePageView("supplier");

    const { t } = useTranslation("Supplier");
    const { supplier_id } = useParams<{ supplier_id: string }>();
    const getSuppliers = useGraphQL(
        GetSupplierDocument,
        { id: supplier_id! },
        {},
        true,
    );

    const supplier = getSuppliers.data?.supplier;
    const users = supplier?.users;
    const setNewUserModalOpen = useSetAtom(newUsersModalOpenAtom);

    return (
        <>
            <Container>
                <BodyHeader
                    icon={OpenSelectHandGesture}
                    title={supplier?.name || ""}
                    subTitle={t("subtitle")}
                    // action={<NewSupplierModal refetch={getSuppliers.refetch} />}
                />
                <div className="p-4 space-y-4">
                    {users && (
                        <UsersTableInner
                            supplierMode={true}
                            users={users as User[]}
                            refetch={getSuppliers.refetch}
                        />
                    )}
                    <ButtonRegular
                        variant={ButtonType.OUTLINED}
                        onClick={() => setNewUserModalOpen(true)}
                    >
                        {t("add_user")}
                    </ButtonRegular>
                </div>
            </Container>
            <NewSupplierUserModal
                supplierId={supplier_id}
                onSuccess={getSuppliers.refetch}
            />
        </>
    );
}
