import { DefinedQueryObserverResult } from "@tanstack/react-query";
import { DesignPencil } from "iconoir-react";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    CreateFromTemplateDocument,
    GetDocumentByUrlQuery,
} from "../../../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../../../QueryClientWithHeaders";
import ButtonRegular from "../../../../../components/Button/ButtonRegular";
import ActionWithConfirmation from "../../../../../components/Modal/ActionWithConfirmation";
import { currentUserAtom } from "../../../../../components/TopBar/TopBarProfil";
import ChooseLocale from "../../../../../components/inputs_v2/ChooseLocale";
import OptionLayout from "./OptionLayout";
import "./i18n";

const OptionStartFromTemplate = ({
    getDocumentQuery,
}: {
    getDocumentQuery: DefinedQueryObserverResult<
        GetDocumentByUrlQuery,
        unknown
    >;
}) => {
    const currentUser = useAtomValue(currentUserAtom);
    const { t } = useTranslation("CreateDocumentOption");

    const navigate = useNavigate();

    const createNewVersionMutation = useGraphQLMutation(
        CreateFromTemplateDocument,
        {},
        true
    );

    const onCreate = () => {
        locale &&
            createNewVersionMutation.mutate(
                {
                    id: getDocumentQuery.data.documentByUrl?.id!,
                    locale: locale,
                },
                { onSuccess: () => navigate("edit") }
            );
    };
    const [locale, setLocale] = useState<string | undefined>(
        currentUser?.locale
    );
    const createFromTemplateButton = (
        <ActionWithConfirmation
            title={t("choose_template_locale")}
            confirmButton={
                <ButtonRegular
                    loading={createNewVersionMutation.isPending}
                    onClick={onCreate}
                    className="min-w-[110px]"
                    disabled={!locale}
                >
                    {t("start_from_template_button")}
                </ButtonRegular>
            }
            TriggerButton={({ onClick }) => (
                <ButtonRegular
                    loading={createNewVersionMutation.isPending}
                    onClick={onClick}
                    className="min-w-[110px]"
                >
                    {t("start_from_template_button")}
                </ButtonRegular>
            )}
        >
            <ChooseLocale
                locale={locale}
                setLocale={setLocale}
                availableLocales={
                    getDocumentQuery.data.documentByUrl?.templateLocales
                }
            />
        </ActionWithConfirmation>
    );

    useEffect(() => {
        setLocale(currentUser?.locale);
    }, []);
    return (
        <OptionLayout
            Icon={DesignPencil}
            button={createFromTemplateButton}
            title={t("start_from_template_title")}
            subtitle={t("start_from_template_desc")}
        />
    );
};

export default OptionStartFromTemplate;
