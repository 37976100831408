import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "InputBoolean", {
    true: "Yes",
    false: "No",
    placeholder: "Yes/No",
    unset: "Unset",
});

i18next.addResourceBundle("fr", "InputBoolean", {
    true: "Oui",
    false: "Non",
    placeholder: "Oui/Non",
    unset: "Réinitialiser",
});
