import { Button } from "@design-system/Inputs/Button";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { CreateIndicatorInput } from "@generated/client/graphql";
import { useAtom } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    CenterModal,
    CenterModalContent,
} from "../../../../components/Modal/CenterModal";
import { createCustomIndicatorsModalIsOpenAtom } from "../../context";
import { useCreateCustomIndicators } from "../../data";

import { CSVRow, useValidateCSVRows } from "./hooks";
import "./i18n";
import ImportCSVButton from "./ImportCsvButton";

export default function AddCustomIndicatorModal() {
    const { t } = useTranslation("CreateIndicatorsModal");
    const [open, setOpen] = useAtom(createCustomIndicatorsModalIsOpenAtom);

    const [csvInputs, setCsvInputs] = useState<CSVRow[]>([]);
    const validatedInputs = useValidateCSVRows(csvInputs);
    const inputs = validatedInputs
        .filter((input) => input.isValid)
        .map((input) => input.input) as CreateIndicatorInput[];
    const { mutate, isPending } = useCreateCustomIndicators();

    const handleSave = async () => {
        mutate(inputs, {
            onSuccess: () => {
                setOpen(false);
            },
        });
    };

    return (
        <CenterModal open={open} onOpenChange={setOpen} modal={false}>
            <CenterModalContent
                title={t("title")}
                actions={
                    <Button onClick={handleSave} loading={isPending}>
                        Save
                    </Button>
                }
            >
                <ImportCSVButton onAddIndicators={setCsvInputs} />
                <FlexCol gap="3" alignItems="start">
                    {validatedInputs.map((input, index) => (
                        <FlexRow gap="3" key={index}>
                            {input.isValid ? (
                                <Text>OK</Text>
                            ) : (
                                <Text color="danger">Error</Text>
                            )}
                            <Text
                                className="w-20 text-ellipsis overflow-hidden"
                                whitespace="nowrap"
                            >
                                {input.csvRow?.name}
                            </Text>
                            <Text
                                className="w-20 text-ellipsis overflow-hidden"
                                whitespace="nowrap"
                            >
                                {input.csvRow?.uname}
                            </Text>
                            <Text
                                className="w-20 text-ellipsis overflow-hidden"
                                whitespace="nowrap"
                            >
                                {input.theme?.name}
                            </Text>
                            <Text
                                className="w-20 text-ellipsis overflow-hidden"
                                whitespace="nowrap"
                            >
                                {input.subtheme?.name}
                            </Text>
                            <Text
                                className="w-20 text-ellipsis overflow-hidden"
                                whitespace="nowrap"
                            >
                                {input.unit?.name}
                            </Text>
                        </FlexRow>
                    ))}
                </FlexCol>
            </CenterModalContent>
        </CenterModal>
    );
}
