import { useState } from "react";
import {
    CreateEmptyFileDocument,
    S3File,
} from "../../generated/client/graphql";
import { useGraphQLMutation } from "../QueryClientWithHeaders";

export const useCreateS3File = () => {
    const { mutateAsync } = useGraphQLMutation(
        CreateEmptyFileDocument,
        {},
        true,
    );
    const [isLoading, setIsLoading] = useState(false);

    const uploadFile = async (file: File) => {
        setIsLoading(true);
        if (!file?.name) {
            throw new Error("Cannot upload file with empty name");
        }
        const data = await mutateAsync({ filename: file.name });
        if (data?.createEmptyFile.putUrl)
            await fetch(data?.createEmptyFile.putUrl, {
                method: "PUT",
                body: file,
                headers: {
                    "Content-Length": file.size.toString(),
                    "Access-Control-Allow-Origin": "no-cors",
                },
            }).then(() => {
                setIsLoading(false);
            });
        return data.createEmptyFile as S3File;
    };

    return { isLoading, uploadFile };
};
