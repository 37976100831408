import i18next from "../../../i18n";

i18next.addResources("en", "ChooseLocale", {
    en: "English",
    fr: "French",
});
i18next.addResources("fr", "ChooseLocale", {
    en: "Anglais",
    fr: "Français",
});
