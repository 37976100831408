import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { cn } from "../../../../../lib/utils";

const DropdownContentLayout = ({
    children,
    className,
    portalContainer,
    ...props
}: {
    children: React.ReactNode;
} & {
    portalContainer?: HTMLElement;
} & DropdownPrimitive.DropdownMenuContentProps) => (
    <DropdownPrimitive.Portal container={portalContainer}>
        <DropdownPrimitive.Content
            align="start"
            sideOffset={4}
            className={cn(
                "border w-[220px] bg-white shadow-dropdown rounded-xl p-1 max-h-[300px] overflow-y-auto scrollbar-hide",
                className
            )}
            style={{
                minWidth: "var(--radix-dropdown-menu-trigger-width)",
            }}
            {...props}
        >
            {children}
        </DropdownPrimitive.Content>
    </DropdownPrimitive.Portal>
);

export default DropdownContentLayout;
