import i18next from "../../../../../../i18n";

i18next.addResourceBundle("en", "SustainabilityCenterEditStrategyModal", {
    update_success: "Successfully updated sustainability center",
    update_error: "Error updating sustainability center",
    save: "Save",
    strategy_title: "Strategy title",
    strategy_description: "Strategy description",
});

i18next.addResourceBundle("fr", "SustainabilityCenterEditStrategyModal", {
    update_success: "Centre de conformité mise à jour avec succès",
    update_error: "Erreur lors de la mise à jour du centre de conformité",
    save: "Enregistrer",
    strategy_title: "Titre de la stratégie",
    strategy_description: "Description de la stratégie",
});
