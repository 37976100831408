import { EditPencil, Plus } from "iconoir-react";
import { orderSort } from "../../../lib/utils";
import { ButtonType } from "../../Button/ButtonShared";
import ButtonSmall from "../../Button/ButtonSmall";

interface sideBarItem {
    name: string;
    link?: string;
    icon?:
        | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
        | React.ForwardRefExoticComponent<any>;
    imageUrl?: string;
    order: number;
}

interface SideBarBlockProps {
    editMode: boolean;
    title: string;
    emptyText: string;
    buttonText: string;
    subtitle?: string;
    description?: string;
    items?: sideBarItem[];
    setOpenModal: (open: boolean) => void;
}

export default function SideBarBlock(props: SideBarBlockProps) {
    const isEmpty =
        !props.subtitle &&
        !props.description &&
        (!props.items || props.items.length === 0);

    return (
        <div className="w-full flex flex-col gap-4">
            <div className="flex justify-between items-start">
                <div className="text-primary font-bold text-xl">
                    {props.title}
                </div>
                {props.editMode && (
                    <ButtonSmall
                        variant={ButtonType.OUTLINED}
                        IconLeft={EditPencil}
                        onClick={() => props.setOpenModal(true)}
                    ></ButtonSmall>
                )}
            </div>
            {isEmpty && (
                <div className="min-h-[124px] gap-3 py-7 text-primary rounded-xl border border-secondary flex flex-col justify-center align-middle">
                    <div className="text-primary text-sm text-center">
                        {props.emptyText}
                    </div>
                    <div className="flex justify-center">
                        <ButtonSmall
                            text={props.buttonText}
                            IconRight={Plus}
                            variant={ButtonType.OUTLINED}
                            onClick={() => props.setOpenModal(true)}
                        ></ButtonSmall>
                    </div>
                </div>
            )}
            {(props.subtitle || props.description) && (
                <div className="flex flex-col gap-5 p-5 text-primary rounded-xl border border-secondary">
                    <div className="text-base font-bold">{props.subtitle}</div>
                    <p className="leading-5 text-sm whitespace-pre-wrap">
                        {props.description}
                    </p>
                </div>
            )}
            {props.items && props.items.length > 0 && (
                <div className="flex flex-col gap-5 p-5 text-primary rounded-xl border border-secondary">
                    {orderSort(props.items).map((item) => (
                        <div className="flex items-center">
                            <div className="w-8 h-8 flex justify-center items-center mr-4">
                                {item.imageUrl ? (
                                    <img src={item.imageUrl}></img>
                                ) : item.icon ? (
                                    <div className="p-3 rounded-lg border border-secondary">
                                        <item.icon className="w-4 h-4" />
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                            {item.link ? (
                                <a
                                    href={item.link}
                                    target="_blank"
                                    className="font-bold text-base"
                                >
                                    {item.name}
                                </a>
                            ) : (
                                <div className="text-primary text-base">
                                    {item.name}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
