import { type FC } from "react";

export type ProgressStepBarProps = {
    total: number;
    completed: number;
};

const ProgressStepBar: FC<ProgressStepBarProps> = ({ total, completed }) => {
    return (
        <div className="flex space-x-2 h-1.5">
            {Array(total)
                .fill(null)
                .map((_, index) => (
                    <div
                        key={index}
                        className={`flex-grow rounded-sm ${
                            index < completed ? " bg-green" : "bg-gray-300"
                        }`}
                    />
                ))}
        </div>
    );
};

export default ProgressStepBar;
