import {
    CreateEmptyFileDocument,
    CreateEmptyS3FilesDocument,
    DeleteS3FileDocument,
    DeleteS3FilesDocument,
} from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { S3FileRepository } from "../S3FileRepository";
import { graphqlClientKoyeb } from "../clients";

export class GraphQLS3FileRepository implements S3FileRepository {
    async uploadToBucket(file: File, { url }: { url: string }) {
        await fetch(url, {
            method: "PUT",
            body: file,
            headers: {
                "Content-Length": file.size.toString(),
                "Access-Control-Allow-Origin": "no-cors",
            },
        });
    }
    createOneEmpty(filename: string) {
        return graphqlClientKoyeb.request(
            CreateEmptyFileDocument,
            { filename },
            AccessHeadersHolder.getHeaders(),
        );
    }

    createManyEmpty(filenames: string[]) {
        return graphqlClientKoyeb.request(
            CreateEmptyS3FilesDocument,
            { filenames },
            AccessHeadersHolder.getHeaders(),
        );
    }

    deleteOne(id: string) {
        return graphqlClientKoyeb.request(
            DeleteS3FileDocument,
            { id },
            AccessHeadersHolder.getHeaders(),
        );
    }

    deleteMany(ids: string[]) {
        return graphqlClientKoyeb.request(
            DeleteS3FilesDocument,
            { ids },
            AccessHeadersHolder.getHeaders(),
        );
    }
}
