import { ArrowRight, Check } from "iconoir-react";
import { useTranslation } from "react-i18next";
import { Requirement } from "../../../../generated/client/graphql";
import "./i18n";
export default function ControlsBlockCard({
    theme,
    setTabValue,
}: {
    theme: { name: string; approvedRequirements: Requirement[] };
    setTabValue: (val: string) => void;
}) {
    const { t } = useTranslation("SustainabilityCenterControlsBlock");

    return (
        <div className="gap-5 flex flex-col items-start flex-1 self-stretch p-5 border border-secondary rounded-xl max-w-[386px]">
            <div className="font-bold text-base">{theme.name}</div>
            <div className="flex flex-col gap-3 self-stretch">
                {theme.approvedRequirements.map(
                    (elt, idx2) =>
                        idx2 < 3 && (
                            <div className="flex items-start gap-2">
                                <div className="h-5 w-5 text-white bg-beavrGreen rounded-[20px]">
                                    <Check />
                                </div>
                                <div className="text-sm text-nowrap overflow-ellipsis overflow-hidden">
                                    {" "}
                                    {elt.name}
                                </div>
                            </div>
                        )
                )}
                {theme.approvedRequirements.length > 3 && (
                    <div
                        className="test-sm font-bold text-beavrGreen flex gap-2 items-center cursor-pointer"
                        onClick={() => setTabValue("controls")}
                    >
                        {t("see_more", {
                            count: theme.approvedRequirements.length - 3,
                        })}
                        <div className="w-4 h-4 text-beavrGreen flex items-center justify-center">
                            <ArrowRight
                                width={16}
                                height={16}
                                fontWeight={"bold"}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
