import {
    GetCurrentUserDocument,
    GetCurrentUserNoTenantDocument,
    GetCurrentUserNoTenantQuery,
    GetCurrentUserOrgsWithCustomizationDocument,
    GetCurrentUserOrgsWithCustomizationQuery,
    GetCurrentUserQuery,
    GetUsersDocument,
    GetUsersQuery,
    GetUsersWithReportingStatsDocument,
    GetUsersWithReportingStatsQuery,
} from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { UserRepository } from "../UserRepository";
import { graphqlClientKoyeb } from "../clients";

export class GraphQLUserRepository implements UserRepository {
    get = (): Promise<GetUsersQuery> => {
        return graphqlClientKoyeb.request(
            GetUsersDocument,
            undefined,
            AccessHeadersHolder.getHeaders(),
        );
    };
    getCurrentUser = (): Promise<GetCurrentUserQuery> =>
        graphqlClientKoyeb.request(
            GetCurrentUserDocument,
            undefined,
            AccessHeadersHolder.getHeaders(),
        );
    getCurrentUserNoTenant = (): Promise<GetCurrentUserNoTenantQuery> =>
        graphqlClientKoyeb.request(
            GetCurrentUserNoTenantDocument,
            undefined,
            AccessHeadersHolder.getHeaders(),
        );
    getWithReportingStats = (): Promise<GetUsersWithReportingStatsQuery> => {
        return graphqlClientKoyeb.request(
            GetUsersWithReportingStatsDocument,
            undefined,
            AccessHeadersHolder.getHeaders(),
        );
    };
    getCurrentUserOrgs =
        (): Promise<GetCurrentUserOrgsWithCustomizationQuery> => {
            return graphqlClientKoyeb.request(
                GetCurrentUserOrgsWithCustomizationDocument,
                undefined,
                AccessHeadersHolder.getHeaders(),
            );
        };
}
