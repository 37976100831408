import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Document,
    DocumentActivationStatus,
    GetDocumentsV2Document,
    ScDocument,
} from "../../../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../../../QueryClientWithHeaders";
import ButtonRegular from "../../../../../../components/Button/ButtonRegular";
import SearchFilter from "../../../../../../components/Filters/Search";
import {
    CenterModal,
    CenterModalContent,
} from "../../../../../../components/Modal/CenterModal";
import DataTable from "../../../../../../components/Table/DataTable";
import { useDocumentsTable } from "../../../hooks/useDocumentsTable";
import "./i18n";

export default function SelectDocumentsModal({
    publicDocuments,
    setDocuments,
    open,
    setOpen,
}: {
    publicDocuments: ScDocument[];
    setDocuments: (documents: ScDocument[]) => void;
    open: boolean;
    setOpen: (open: boolean) => void;
}) {
    const { t } = useTranslation("SustainabilityCenterEditDocumentsModal");
    const [searchString, setSearchString] = useState<string>();
    const { data, isLoading } = useGraphQL(
        GetDocumentsV2Document,
        {
            activationStatus: DocumentActivationStatus.Relevant,
            hasFile: true,
        },
        {},
        true
    );

    const getNonPublicDocuments = (
        publicDocuments: ScDocument[],
        documents: Document[]
    ) => {
        return (documents.filter(
            (doc) =>
                !publicDocuments.map((elt) => elt.documentId).includes(doc.id)
        ) || []) as Document[];
    };

    const [selectableDocuments, setSelectableDocuments] = useState<Document[]>(
        getNonPublicDocuments(
            publicDocuments,
            (data?.documents || []) as Document[]
        )
    );

    useEffect(() => {
        setSelectableDocuments(
            getNonPublicDocuments(
                publicDocuments,
                (data?.documents || []) as Document[]
            )
        );
    }, [publicDocuments, data]);

    const { table } = useDocumentsTable(
        (selectableDocuments || []) as Document[]
    );

    const selectedDocuments = table
        .getSelectedRowModel()
        .flatRows.map((row) => row.original);

    const onSelect = () => {
        setDocuments(
            [
                ...publicDocuments,
                ...selectedDocuments.map((elt) => {
                    return {
                        documentId: elt.id,
                        name: elt.name,
                        document: {
                            name: elt.name,
                            lastVersion: elt.lastVersion,
                        },
                    } as ScDocument;
                }),
            ].map((elt, idx) => {
                return { ...elt, order: idx };
            })
        );
        setOpen(false);
    };

    return (
        <CenterModal open={open} onOpenChange={setOpen} modal={false}>
            <CenterModalContent
                className="w-[820px]"
                title={t("select_docs_modal_title")}
                actions={
                    <ButtonRegular
                        text={t("select_docs_modal_action")}
                        onClick={onSelect}
                        loading={isLoading}
                        disabled={selectedDocuments.length === 0}
                    />
                }
            >
                <div className="w-[820px] min-h-[395px]">
                    {data?.documents ? (
                        <div className="flex flex-col gap-4 p-5 items-start self-stretch">
                            <div className="flex flex-col items-start flex-1 w-full">
                                <SearchFilter
                                    searchString={searchString}
                                    setSearchString={setSearchString}
                                    placeholder={t(
                                        "select_docs_modal_search_placeholder"
                                    )}
                                />
                            </div>
                            <DataTable
                                table={table}
                                rowClassName={() => "cursor-pointer"}
                            />
                        </div>
                    ) : (
                        <div>Error, refresh ...</div>
                    )}
                </div>
            </CenterModalContent>
        </CenterModal>
    );
}
