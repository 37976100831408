import { Glasses, X } from "lucide-react";
import { Variant } from "..";
import { ReactComponent as CheckStatus } from "../../../../assets/Status.svg";
import { ReactComponent as ArrowDown } from "../../../../assets/long-arrow-right-down.svg";
import { ReactComponent as InProgressStatus } from "../../../../assets/progress.svg";

const Status = ({
    variant,
    children,
}: {
    variant: Variant;
    children: React.ReactNode;
}) => {
    return (
        <div className="space-x-1.5 flex items-center">
            {variant === Variant.Done && <CheckStatus className="w-4 h-4" />}
            {variant === Variant.InProgress && (
                <InProgressStatus className="w-4 h-4" />
            )}
            {variant === Variant.PendingReview && (
                <Glasses className="w-4 h-4" />
            )}
            {variant === Variant.ArrowDown && <ArrowDown className="w-4 h-4" />}
            {variant === Variant.Nothing && <X className="w-4 h-4" />}
            <div className="text-base font-regular">{children}</div>
        </div>
    );
};

export default Status;
