import { atom } from "jotai";
import { X } from "lucide-react";
import { useTranslation } from "react-i18next";
import { GetThemesOnlyDocument } from "../../../../generated/client/graphql";
import { useGraphQL } from "../../../QueryClientWithHeaders";
import { ButtonType } from "../../Button/ButtonShared";
import ButtonSmall from "../../Button/ButtonSmall";
import SubthemeFilter from "../../Dropdown/dropdowns/Subthemes/filter";
import { Tooltip } from "../../Tooltip";
import "./i18n";

export const filterThemeIdAtom = atom<string | undefined>(undefined);
const ThemeTabFilter = ({
    themeId,
    setThemeId,
    subthemeIds,
    setSubthemeIds,
}: {
    themeId: string | undefined;
    setThemeId: (themeId: string | undefined) => void;
    subthemeIds: string[] | undefined;
    setSubthemeIds: (subthemeIds: string[] | undefined) => void;
}) => {
    const { t } = useTranslation("ThemeTabFilter");

    const getThemeQuery = useGraphQL(
        GetThemesOnlyDocument,
        undefined,
        {},
        true,
    );

    const activeTheme = getThemeQuery.data?.themes.find(
        (theme) => theme.id === themeId,
    );
    return (
        <div className="flex gap-2 items-center">
            <div className="text-secondary text-base text-nowrap">
                {t("filter_by_theme")}
            </div>
            {activeTheme ? (
                <>
                    <ButtonSmall
                        onClick={() => {
                            setThemeId(undefined);
                            setSubthemeIds(undefined);
                        }}
                        variant={ButtonType.OUTLINED}
                        className="rounded-full"
                        IconLeft={X}
                    />
                    <ButtonSmall
                        active={true}
                        variant={ButtonType.FLAT_PRIMARY}
                        className="rounded-full "
                    >
                        {activeTheme?.name}
                    </ButtonSmall>
                    <SubthemeFilter
                        themeId={themeId}
                        subthemeIds={subthemeIds}
                        setSubthemeIds={setSubthemeIds}
                        className="rounded-full shadow"
                    />
                </>
            ) : (
                getThemeQuery.data?.themes
                    .sort((t) => t.order)
                    .map(({ id, name }, idx) => (
                        <Tooltip
                            key={idx}
                            tooltipContent={name}
                            delayDuration={500}
                            triggerAsChild
                        >
                            <ButtonSmall
                                active={id === themeId}
                                key={idx}
                                onClick={() => id && setThemeId(id)}
                                variant={ButtonType.OUTLINED}
                                className="rounded-full shadow"
                            >
                                {name}
                            </ButtonSmall>
                        </Tooltip>
                    ))
            )}
        </div>
    );
};

export default ThemeTabFilter;
