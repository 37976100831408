import { Fragment, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useNavigation } from "./hooks";

import {
    activeNavigationButtonKey,
    navigationButtonType,
    type NavigationButtonTypeKey,
} from "@app/store/routingStore";
import { Navigation } from "@design-system/Navigation/NavBar";
import posthog from "posthog-js";
import { useTranslation } from "react-i18next";
import { useDocumentPageState } from "../../screens/Documents2/hooks/pageState";

import { resetContextAtom } from "@app/pages/Requirements/context";
import { isSaveBarOpenAtom, setSaveBarWarningAtom } from "../SaveBar";
import { Logo as BeavrLogo } from "./components/Logo";
import OrganizationSelect from "./components/OrganizationSelect";
import "./i18n";
import { navigationButtons } from "./utils";
import { FlexRow } from "@design-system/Layout/Flex";

export default function Navbar() {
    // Capture page views
    const location = useLocation();
    useEffect(() => {
        posthog.capture("$pageview");
    }, [location]);

    const { t } = useTranslation("Navbar");
    const navItems = useNavigation();

    const { org_uname } = useParams();
    const navigate = useNavigate();

    const [activeButtonKey, setActiveNavigationButtonKey] = useAtom(
        activeNavigationButtonKey,
    );
    const saveBarIsOpen = useAtomValue(isSaveBarOpenAtom);
    const setSaveBarWarning = useSetAtom(setSaveBarWarningAtom);

    const { resetDefaultState: resetDocumentPageState } =
        useDocumentPageState();
    const resetRequirementPageState = useSetAtom(resetContextAtom);

    const handleMenuChange = (id: string) => {
        const key = id as NavigationButtonTypeKey;
        const item = navigationButtons[key];
        if (saveBarIsOpen) {
            setSaveBarWarning(true);
            return;
        }
        setActiveNavigationButtonKey(key);
        navigate(item.path);

        if (
            (
                [
                    navigationButtonType.policies,
                    navigationButtonType.measures,
                    navigationButtonType.custom_documents,
                ] as NavigationButtonTypeKey[]
            ).includes(key)
        ) {
            resetDocumentPageState();
        }
        if (key === navigationButtonType.requirements) {
            resetRequirementPageState();
        }
    };

    return (
        <Navigation
            className="relative"
            selectedId={activeButtonKey}
            onSelectedIdChange={handleMenuChange}
        >
            <Navigation.Header>
                <BeavrLogo />
            </Navigation.Header>
            <Navigation.Content>
                {org_uname &&
                    navItems?.map((group, index) => (
                        <Fragment key={index}>
                            {group.i18Key && (
                                <Navigation.GroupTitle>
                                    {t(group.i18Key)}
                                </Navigation.GroupTitle>
                            )}
                            {group.items.map((key, idx) => {
                                const item = navigationButtons[key];
                                return (
                                    <Navigation.Button
                                        key={idx}
                                        id={key}
                                        iconName={item.icon}
                                        text={t(item.i18Key)}
                                    />
                                );
                            })}
                        </Fragment>
                    ))}
                <div className="min-h-10" />
            </Navigation.Content>

            <FlexRow
                className="absolute bottom-0 right-0"
                justifyContent="end"
                px="6"
                py="4"
                w="fit"
            >
                <OrganizationSelect />
            </FlexRow>
        </Navigation>
    );
}
