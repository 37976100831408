import { cn } from "../../../lib/utils";

const LoadingTable = () => {
    return (
        <div className={cn("border rounded-lg border-tertiary w-full", "")}>
            <table className="w-full">
                <tbody>
                    {Array.from({ length: 10 }).map((_, idx) => (
                        <tr
                            key={idx}
                            className=" border-b border-tertiary last:border-none"
                        >
                            {Array.from({ length: 4 }).map((_, jdx) => (
                                <td
                                    key={jdx}
                                    className="px-4 py-3 animate-pulse "
                                >
                                    <div className="rounded-full w-full h-2 bg-gradient-to-r from-neutral-150 to-white"></div>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default LoadingTable;
