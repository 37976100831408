import { useAtom } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { AddUser, Trash } from "iconoir-react";
import { newUsersModalOpenAtom } from ".";
import {
    CreateUserInput,
    CreateUsersDocument,
    UserRole,
} from "../../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../../QueryClientWithHeaders";
import ButtonLarge from "../../../../components/Button/ButtonLarge";
import ButtonRegular from "../../../../components/Button/ButtonRegular";
import { ButtonType } from "../../../../components/Button/ButtonShared";
import InputSimple from "../../../../components/InputSimple";
import {
    CenterModal,
    CenterModalContent,
} from "../../../../components/Modal/CenterModal";
import { useSetToast } from "../../../../components/Toast";
import { validateEmail } from "../../../../utils/emailRegex";
import "./i18n";

export default function NewSupplierUserModal({
    supplierId,
    onSuccess,
}: {
    supplierId?: string;
    onSuccess?: () => void;
}) {
    const { t } = useTranslation("NewUserModal");

    const { setToastSuccess, setToastError } = useSetToast();
    const { mutate, isPending } = useGraphQLMutation(
        CreateUsersDocument,
        undefined,
        true
    );
    const [open, setOpen] = useAtom(newUsersModalOpenAtom);
    const [newUserInputs, setNewUserInputs] = useState<CreateUserInput[]>([
        {
            email: "",
            firstName: "",
            lastName: "",
            role: UserRole.Supplier,
            supplierId: supplierId,
        },
    ]);

    const canSave =
        newUserInputs.length > 0 &&
        newUserInputs.every((input) => {
            return (
                input.email &&
                validateEmail(input.email) &&
                input.firstName &&
                input.lastName &&
                input.role
            );
        });
    const handleSave = () => {
        newUserInputs.length > 0 &&
            mutate(
                { inputs: newUserInputs },
                {
                    onSuccess() {
                        setOpen(false);
                        onSuccess && onSuccess();
                        setToastSuccess(t("user_create_success"));
                    },
                    onError() {
                        setOpen(false);
                        setToastError(t("user_create_error"));
                    },
                }
            );
    };
    const handlePlus = () => {
        const users = [...newUserInputs];
        users.push({
            email: "",
            firstName: "",
            lastName: "",
            role: UserRole.Supplier,
        });
        setNewUserInputs(users);
    };
    const handleMinus = (idx: number) => {
        const users = [...newUserInputs];
        users.splice(idx, 1);
        setNewUserInputs(users);
    };
    const handleChange = (idx: number, patch: Partial<CreateUserInput>) => {
        const users = [...newUserInputs];
        users[idx] = { ...users[idx], ...patch };
        setNewUserInputs(users);
    };
    return (
        <CenterModal open={open} onOpenChange={setOpen} modal={false}>
            <CenterModalContent
                title={t("title")}
                subtitle={t("subtitle")}
                actions={
                    <>
                        <ButtonLarge
                            text={t("create", { count: newUserInputs.length })}
                            variant={ButtonType.ELEVATED}
                            disabled={!canSave}
                            onClick={() => {
                                handleSave();
                            }}
                            loading={isPending}
                        />
                    </>
                }
            >
                <div className="space-y-4">
                    {newUserInputs.map((user, idx) => (
                        <div className="flex space-x-3" key={idx}>
                            <InputSimple
                                id={`email-${idx}`}
                                title={t("email")}
                                className="w-[200px]"
                                value={user.email || ""}
                                onChange={(e) => {
                                    handleChange(idx, {
                                        email: e.target.value,
                                    });
                                }}
                            />

                            <InputSimple
                                id={`firstname-${idx}`}
                                title={t("firstname")}
                                className="w-[100px]"
                                onChange={(e) => {
                                    handleChange(idx, {
                                        firstName: e.target.value,
                                    });
                                }}
                            />
                            <InputSimple
                                id={`lastname-${idx}`}
                                title={t("lastname")}
                                className="w-[100px]"
                                onChange={(e) => {
                                    handleChange(idx, {
                                        lastName: e.target.value,
                                    });
                                }}
                            />

                            <ButtonLarge
                                IconLeft={Trash}
                                variant={ButtonType.TEXT}
                                onClick={() => {
                                    handleMinus(idx);
                                }}
                            />
                        </div>
                    ))}
                    <ButtonRegular
                        text={t("add_user")}
                        variant={ButtonType.TONAL}
                        IconRight={AddUser}
                        onClick={handlePlus}
                    />
                </div>
            </CenterModalContent>
        </CenterModal>
    );
}
