import { Icon } from "@design-system/Icons";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Sidebar } from "@design-system/Navigation/SideBar";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { CsrdEsrs } from "@generated/client/graphql";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { activeDisclosureRequirementIdAtom, filterAtom } from "../../ctx";
import { useCsrdPillar, useCsrdPillarStats } from "../../data";
import { RESOURCE_NAME, keys } from "../../i18n";

const useNavigate = () => {
    const setSelectedDrid = useSetAtom(activeDisclosureRequirementIdAtom);
    const setFirstDrActive = (esrs: CsrdEsrs) => {
        if (esrs?.disclosureRequirements?.length) {
            return setSelectedDrid(esrs.disclosureRequirements[0].cmsId);
        }
    };
    return { setFirstDrActive };
};

export const PillarSidebar = () => {
    const { pillar_id } = useParams();
    const { t } = useTranslation(RESOURCE_NAME);

    const [selectedDrId, setSelectedDrid] = useAtom(
        activeDisclosureRequirementIdAtom,
    );

    const filter = useAtomValue(filterAtom);
    const { isPending, filteredPillar } = useCsrdPillar(pillar_id, filter);
    const isEmptyPillar = !filteredPillar?.esrss?.length;
    const { disclosureRequirementStats } = useCsrdPillarStats(
        pillar_id,
        filter,
    );

    const { setFirstDrActive } = useNavigate();
    const isDisclosureRequirementValid = (drId: string) => {
        const stats = disclosureRequirementStats?.find(
            (dr) => dr.drCmsId === drId,
        )?.stats;
        return stats && stats.completed === stats.total;
    };
    const disclosureRequirementCompletionRatio = (drId: string) => {
        return disclosureRequirementStats?.find((dr) => dr.drCmsId === drId)
            ?.stats.percentage;
    };
    const isLastEsrs = (idx: number, esrss: CsrdEsrs[]) => {
        return !esrss || idx === esrss.length - 1;
    };

    return (
        <Sidebar
            selectedId={selectedDrId}
            onSelectedIdChange={setSelectedDrid}
            className="w-[232px] sticky -top-3 max-h-screen overflow-y-auto"
            isLoading={isPending}
        >
            <Sidebar.Content>
                {isEmptyPillar ? (
                    <FlexRow justifyContent="center" className="w-[232px]">
                        <Text
                            variant="body-sm-bold"
                            className="text-neutral-600"
                        >
                            {t(keys.no_data)}
                        </Text>
                    </FlexRow>
                ) : (
                    <>
                        {filteredPillar?.esrss.map((esrs, idx) => (
                            <Sidebar.Group key={idx}>
                                <FlexCol w="full">
                                    <Sidebar.GroupTitle
                                        key={idx}
                                        title={esrs?.name}
                                        onClick={() =>
                                            setFirstDrActive(esrs as CsrdEsrs)
                                        }
                                    />
                                    {esrs.disclosureRequirements?.map(
                                        (dr, idx2) => (
                                            <Sidebar.Item
                                                key={idx2}
                                                id={dr.cmsId}
                                            >
                                                {({ isSelected }) => (
                                                    <FlexRow
                                                        p="2"
                                                        alignItems="center"
                                                        justifyContent="between"
                                                        gap="3"
                                                        w="full"
                                                        className={cn(
                                                            "rounded-lg",
                                                            isSelected &&
                                                                "bg-tertiary text-brand-650 font-bold",
                                                        )}
                                                    >
                                                        <Sidebar.ItemLabel
                                                            title={dr.name}
                                                        />
                                                        <Sidebar.ItemBox>
                                                            {isDisclosureRequirementValid(
                                                                dr.cmsId,
                                                            ) ? (
                                                                <Icon
                                                                    name="check"
                                                                    className="text-brand-650"
                                                                />
                                                            ) : (
                                                                <Text
                                                                    whitespace="nowrap"
                                                                    variant="body-sm"
                                                                    className={cn(
                                                                        "text-neutral-400",
                                                                        isSelected &&
                                                                            "text-brand-650",
                                                                    )}
                                                                >
                                                                    {`${disclosureRequirementCompletionRatio(
                                                                        dr.cmsId,
                                                                    )} %`}
                                                                </Text>
                                                            )}
                                                        </Sidebar.ItemBox>
                                                    </FlexRow>
                                                )}
                                            </Sidebar.Item>
                                        ),
                                    )}
                                </FlexCol>
                                {!isLastEsrs(
                                    idx,
                                    (filteredPillar?.esrss || []) as CsrdEsrs[],
                                ) && <Sidebar.Divider />}
                            </Sidebar.Group>
                        ))}
                    </>
                )}
            </Sidebar.Content>
        </Sidebar>
    );
};
