import { useEffect, useState } from "react";
import { useAtomValue } from "jotai";
import { addVersionTipTapDocAtom } from "@app/store/versionStore";

/**
 * This hook is used to add a TipTap document to a version
 * in case the version is not already associated with a TipTap
 * document; the check is done automatically by the useCase, no
 * need to do it again here or in the component.
 */
export function useAddTipTapDoc() {
    const [isLoading, setIsLoading] = useState(true);
    const { mutate } = useAtomValue(addVersionTipTapDocAtom);

    useEffect(() => {
        mutate(undefined, {
            onSettled: () => setIsLoading(false),
        });
    }, [mutate]);

    return { isLoading };
}
