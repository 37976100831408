import { ReactComponent as LighthouseIllustration } from "@app/assets/illustrations/lighthouse.svg";
import { useGoToHomepage } from "@app/routing/utils";
import ErrorPage from "@app/shared/components/ErrorPage";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { SustainabilityCenter as SustainabilityCenterType } from "../../../../generated/client/graphql";
import Loading from "../../../components/Loading";
import SustainabilityCenterBackground from "./components/SustainabilityCenterBackground";
import SustainabilityCenterContent from "./components/SustainabilityCenterContent";
import SustainabilityCenterFooter from "./components/SustainabilityCenterFooter";
import { useSustainabilityCenter } from "./hooks/useSustainabilityCenter";

import "@app/shared/components/ErrorPage/i18n.tsx";
import "./i18n";

export default function SustainabilityCenter({
    editMode,
}: {
    editMode: boolean;
}) {
    const { org_uname } = useParams<{
        org_uname: string;
    }>();

    const { t } = useTranslation("ErrorPage");
    const { sustainabilityCenter, isLoading } = useSustainabilityCenter(
        !editMode,
        org_uname
    );

    const goToHomepage = useGoToHomepage();
    if (isLoading) {
        return <Loading size="large" />;
    }
    if (!isLoading && !sustainabilityCenter?.published && !editMode) {
        return (
            <ErrorPage>
                <ErrorPage.Header />
                <ErrorPage.Body>
                    <ErrorPage.Image Image={LighthouseIllustration} />
                    <ErrorPage.Text
                        text={t("not_found.status_code")}
                        type="errorCode"
                    />
                    <ErrorPage.Text
                        text={t("not_found.status_message")}
                        type="errorMessage"
                    />
                    <ErrorPage.Text
                        text={t("not_found.description")}
                        type="errorDescription"
                    />
                    <ErrorPage.CTA onClick={goToHomepage}>
                        {t("go_to_homepage")}
                    </ErrorPage.CTA>
                </ErrorPage.Body>
            </ErrorPage>
        );
    }

    return (
        <div className="h-screen w-full overflow-scroll scrollbar-hide">
            <div className="relative">
                <SustainabilityCenterBackground
                    color={sustainabilityCenter?.hexColor}
                >
                    <div className="flex flex-col w-full justify-center items-center gap-20 pt-8">
                        <SustainabilityCenterContent
                            sustainabilityCenter={
                                sustainabilityCenter as SustainabilityCenterType
                            }
                            editMode={editMode}
                            orgUname={org_uname}
                        />
                        <SustainabilityCenterFooter
                            locale={sustainabilityCenter?.locale || "fr"}
                        />
                    </div>
                </SustainabilityCenterBackground>
            </div>
        </div>
    );
}
