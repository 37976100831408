import { WebSocketStatus } from "@hocuspocus/provider";

const StatusBlock = ({ collabState }: { collabState: WebSocketStatus }) => {
    return (
        <div className="flex px-5">
            <div className="bg-white flex items-center space-x-1.5 px-1.5 py-0.5 border border-tertiary rounded-lg">
                <div className="w-[7px] h-[7px] bg-green rounded-full" />
                <div className="text-xs font-bold">
                    Auto saved : {collabState}
                </div>
            </div>
        </div>
    );
};

export default StatusBlock;
