import { queryClient } from "@app/QueryClientWithHeaders";
import { GraphQLTagRepository } from "@app/repositories/GraphQLRepositories/TagRepository";
import { CreateTagInput, TagType } from "@generated/client/graphql";
import { atomWithMutation, atomWithQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/utils";
import { tagKeys } from "./queryKeys";

const tagRepository = new GraphQLTagRepository();

export const tagsAtomFamily = atomFamily((type: TagType) =>
    atomWithQuery(() => {
        return {
            queryKey: [tagKeys.tags, type],
            queryFn: async ({ queryKey: [_, t] }) =>
                tagRepository.get(t as TagType),
        };
    }),
);

export const updateTagAtom = atomWithMutation(() => ({
    mutationKey: [tagKeys.updateTag],
    mutationFn: async ({ id, name }: { id: string; name: string }) => {
        await tagRepository.update(id, name);
    },
    onSettled() {
        queryClient.invalidateQueries({
            queryKey: [tagKeys.tags],
        });
    },
}));

export const createTagAtom = atomWithMutation(() => ({
    mutationKey: [tagKeys.createTag],
    mutationFn: async (input: CreateTagInput) => {
        await tagRepository.create(input.type, input.name);
    },
    onSettled() {
        queryClient.invalidateQueries({
            queryKey: [tagKeys.tags],
        });
    },
}));
