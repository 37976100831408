import i18next from "../../../../../i18n";

i18next.addResourceBundle("en", "SupplierTable", {
    name: "Name",
    empty_table_small_text: "No suppliers found",
    empty_table_button_text: "Create your first supplier",
    tags: "Tags",
    last_questionnaire: "Last questionnaire",
    n_users_zero: "No user",
    n_users_one: "{{count}} user",
    n_users_other: "{{count}} users",
    no_questionnaire: "No questionnaire yet",
    last_questionnaire_stats: "Progress",
    action: "Action",
    copied_to_clipboard: "Link copied to clipboard",
    delete_button: "Delete",
    delete_title: "Are you sure you want to delete ?",
    delete_text_1: ' This will delete the supplier : "{{supplier}}" and',
    delete_text_2: " all its associated data.",
    delete_text_3: "This action cannot be undone",
    delete_confirm_button: "Delete permanently",
    supplier_deleted: "Supplier succesfully deleted",
    created_on: "Created on ",
    closed_on: "Closed on ",
    last_reminder_sent_on: "Last reminder sent on ",
    start_questionnaire_button: "Start a new questionnaire",
    close_questionnaire_button: "Close active questionnaire",
    reopen_last_questionnaire_button: "Reopen last questionnaire",
    questionnaire_updated_success: "Last questionnaire updated successfully",
    send_reminder_button: "Send reminder",
});

i18next.addResourceBundle("fr", "SupplierTable", {
    name: "Nom",
    empty_table_small_text: "Aucun fournisseur trouvé",
    empty_table_button_text: "Créez votre premier fournisseur",
    tags: "Tags",
    last_questionnaire: "Dernier questionnaire",
    n_users_zero: "Aucun utilisateur",
    n_users_one: "{{count}} utilisateur",
    n_users_other: "{{count}} utilisateur",
    no_questionnaire: "Aucun questionnaire",
    last_questionnaire_stats: "Progression",
    action: "Action",
    copied_to_clipboard: "Lien copié dans le presse-papier",
    delete_button: "Supprimer",
    delete_title: "Êtes-vous sûr de vouloir supprimer ?",
    delete_text_1: ' Cela supprimera le fournisseur : "{{supplier}}" et',
    delete_text_2: " toutes ses données associées.",
    delete_text_3: "Cette action ne peut pas être annulée",
    delete_confirm_button: "Supprimer définitivement",
    supplier_deleted: "Fournisseur supprimé avec succès",
    created_on: "Créé le ",
    closed_on: "Cloturé le ",
    last_reminder_sent_on: "Dernier rappel envoyé le ",
    start_questionnaire_button: "Démarrer un nouveau questionnaire",
    close_questionnaire_button: "Fermer le questionnaire actif",
    reopen_last_questionnaire_button: "Rouvrir le dernier questionnaire",
    questionnaire_updated_success:
        "Dernier questionnaire mis à jour avec succès",
    send_reminder_button: "Envoyer un rappel",
});
