import {
    createContext,
    forwardRef,
    useContext,
    type ForwardRefExoticComponent,
    type RefAttributes,
} from "react";
import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group";
import { cn } from "../../Utilities";
import "./index.css";
import { cva, type VariantProps } from "class-variance-authority";

const itemDefaultClasses = [
    /** ITEM SELECTOR */
    "__SegmentButton__item",
    /** LAYOUT */
    "flex items-center justify-center self-stretch flex-1",
    /** SIZING  */
    "h-[36px] p-2",
    /** TYPOGRAPHY */
    "font-bold text-secondary hover:text-primary",
    /** BG */
    "bg-primary",
    /** BORDER */
    "border-r border-r-secondary",
];

const itemClasses = cva(itemDefaultClasses.join(" "), {
    variants: {
        size: {
            sm: "text-sm h-[28px] p-1",
            md: "text-base h-[36px] p-2",
        },
    },
    defaultVariants: {
        size: "md",
    },
});

const SegmentButtonCtx = createContext<VariantProps<typeof itemClasses> | null>(
    null,
);

const Item = forwardRef<
    HTMLButtonElement,
    ToggleGroupPrimitive.ToggleGroupItemProps
>(({ className, ...props }, ref) => {
    const ctx = useContext(SegmentButtonCtx);
    const size = ctx?.size;

    if (!size) {
        throw new Error(
            "SegmentButton.Item should be used within a SegmentButton. If you need a button, use a <Button /> componenet instead.",
        );
    }
    return (
        <ToggleGroupPrimitive.Item
            className={cn(itemClasses({ size }), className)}
            ref={ref}
            {...props}
        />
    );
});

export type SegmentButtonProps = VariantProps<typeof itemClasses> &
    Omit<ToggleGroupPrimitive.ToggleGroupSingleProps, "type"> &
    RefAttributes<HTMLDivElement>;

type SegmentButtonType = ForwardRefExoticComponent<SegmentButtonProps> & {
    Item: typeof Item;
};

export const SegmentButton = forwardRef<HTMLDivElement, SegmentButtonProps>(
    ({ className, size, ...props }, ref) => {
        return (
            <SegmentButtonCtx.Provider value={{ size: size ?? "md" }}>
                <ToggleGroupPrimitive.Root
                    className={cn(
                        "inline-flex border border-secondary rounded-lg overflow-hidden w-full",
                        className,
                    )}
                    ref={ref}
                    {...props}
                    type="single"
                />
            </SegmentButtonCtx.Provider>
        );
    },
    // casting the type here is ugly, but I have no choice
) as SegmentButtonType;

SegmentButton.Item = Item;
