import {
    filterDocuments,
    hasUnapprovedLastVersion,
} from "@app/usecases/DocumentUseCases";
import { DocumentsSuggestions } from "@app/usecases/DocumentUseCases/types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDocumentUploadModalItem } from "../useDocumentsUploadModalItem";

export type DocumentsMultidropdownSelectProps = {
    fileId: string;
};

export const tab = {
    matched: "matched",
    filter: "filter",
    createCustom: "createCustom",
} as const;

type Tab = (typeof tab)[keyof typeof tab];

export function useDocumentsMultidropdownSelect({
    fileId,
}: DocumentsMultidropdownSelectProps) {
    const { t } = useTranslation("DocumentsUploadModal");

    const {
        currentFile: { associatedDocuments },
        documentsForSearch,
        handleAssociatedDocumentsChange,
    } = useDocumentUploadModalItem(fileId);

    const [docIds, setDocIds] = useState<string[]>(
        associatedDocuments?.map(({ id }) => id) ?? [],
    );
    const [nameFilter, setNameFilter] = useState<string | undefined>("");
    const [subthemesFilter, setSubthemesFilter] = useState<string[]>([]);
    const [currentTab, setCurrentTab] = useState<Tab>(
        !!associatedDocuments?.length ? tab.matched : tab.filter,
    );

    const suggestions = useSuggestions({
        currentTab,
        docIds,
        documentsForSearch,
        nameFilter,
        subthemesFilter,
    });

    const dropdownTriggerTxt = t("match_documents_dropdown_trigger", {
        count: associatedDocuments?.length ?? 0,
    });

    const handleOpenChange = (isOpen: boolean) => {
        if (isOpen) {
            setCurrentTab(
                !!associatedDocuments?.length ? tab.matched : tab.filter,
            );
            return;
        }

        setNameFilter("");
        setSubthemesFilter([]);
        handleAssociatedDocumentsChange(docIds);
    };

    const handleValuesChange = (values: (string | null)[]) => {
        setDocIds(values.filter((val) => !!val) as string[]);
    };

    const handleSubthemesFilterChange = (values: (string | null)[]) => {
        setSubthemesFilter(values.filter((val) => !!val) as string[]);
    };

    const openFilterTab = () => {
        setCurrentTab(tab.filter);
    };

    const closeCustomDoc = () => {
        setCurrentTab(tab.filter);
    };

    const openCustomDocTab = () => {
        setCurrentTab(tab.createCustom);
    };

    useEffect(() => {
        setDocIds(associatedDocuments?.map(({ id }) => id) ?? []);
    }, [associatedDocuments]);

    return {
        closeCustomDoc,
        currentTab,
        docIds,
        dropdownTriggerTxt,
        handleNameFilterChange: setNameFilter,
        handleOpenChange,
        handleSubthemesFilterChange,
        handleValuesChange,
        nameFilter,
        openCustomDocTab,
        openFilterTab,
        subthemesFilter,
        suggestions,
    };
}

function useSuggestions({
    currentTab,
    docIds,
    documentsForSearch,
    nameFilter,
    subthemesFilter,
}: {
    currentTab: Tab;
    docIds: string[];
    documentsForSearch: any[];
    nameFilter: string | undefined;
    subthemesFilter: string[];
}) {
    const suggestions = useMemo(() => {
        const searchFilter = {
            name: nameFilter,
            subthemes: subthemesFilter,
        };
        // first, we exctract documents that are already selected
        const selectedDocs = documentsForSearch.filter(({ id }) =>
            docIds.includes(id),
        );

        // then, filter by search, removing documents that are already selected
        const docsFromSearchWithoutSelected =
            currentTab !== tab.filter
                ? [] // we don't want to show search results in the matched tab
                : filterDocuments(
                      documentsForSearch.filter(
                          ({ id }) => !docIds.includes(id),
                      ),
                      searchFilter,
                  ) ?? [];
        return (
            [
                ...selectedDocs, // we want to show selected documents first
                ...(filterDocuments(
                    docsFromSearchWithoutSelected,
                    searchFilter,
                ) ?? []),
            ].reduce(
                (acc, doc) => {
                    acc.items.push({
                        hasAlreadyEntry: hasUnapprovedLastVersion(doc),
                        id: doc.id,
                        name: doc.name,
                        theme: {
                            name: doc.theme?.name ?? "",
                            color: doc.theme?.color ?? "",
                        },
                    });
                    acc.ids.push(doc.id);
                    return acc;
                },
                { items: [], ids: [] } as DocumentsSuggestions,
            ) ?? { items: [], ids: [] }
        );
    }, [currentTab, docIds, documentsForSearch, nameFilter, subthemesFilter]);
    return suggestions;
}
