import { useSetToast } from "@app/components/Toast";
import {
    toFillSwitchAtom,
    upsertDatapointAtom,
} from "@app/store/reportingStore";
import {
    ReportingDatpoint,
    hasDatapointValue,
    isNumCell,
} from "@app/usecases/ReportingUseCases";
import Input from "@design-system/Table/CellInput";
import { ValueInputProps } from "@design-system/Table/CellInput/ValueInput";
import { cn } from "@design-system/Utilities";
import { S3File, Unit } from "@generated/client/graphql";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../i18n";

const IndicatorDatapointCellInput = ({
    indicatorId,
    groupId,
    unit,
    datapoint,
    calculation,
    proofs,
}: {
    indicatorId: string;
    groupId: string;
    unit: Unit;
    datapoint: ReportingDatpoint | undefined | null;
    calculation: string | undefined;
    proofs: S3File[] | undefined;
}) => {
    const { t } = useTranslation("ReportingPage");
    const hasDatapoint = !!datapoint;
    const jsonValue = datapoint?.value;

    const [noDataCheck, setNoDataChecked] = useState<boolean | undefined>(
        jsonValue?.noData,
    );

    const [value, setValue] = useState<string | number | undefined>(
        noDataCheck
            ? undefined
            : isNumCell(unit?.type)
              ? jsonValue?.float
              : jsonValue?.string,
    );

    const onValueChange = (value: string | number | undefined) => {
        setValue(value);
        if (value !== undefined) {
            setNoDataChecked(false);
        }
    };
    const [comment, setComment] = useState<string | undefined>(
        datapoint?.comment || "",
    );
    const [datapointHasValue, setDatapointHasValue] = useState<boolean>(
        hasDatapointValue(
            datapoint,
            unit?.type,
            jsonValue?.noData,
            jsonValue?.float,
            jsonValue?.string,
        ),
    );

    useEffect(() => {
        setDatapointHasValue(
            hasDatapointValue(
                datapoint,
                unit?.type,
                noDataCheck,
                value as number,
                value as string,
            ),
        );
    }, [value, noDataCheck, datapoint]);

    const [{ mutate: upsertMutate }] = useAtom(upsertDatapointAtom);
    const toFillSwitch = useAtomValue(toFillSwitchAtom);

    const { setToastSuccess, setToastError } = useSetToast();

    const onBlur = async (value: string | number | undefined) => {
        if (
            (isNumCell(unit?.type) && value === jsonValue?.float) ||
            (!isNumCell && value === jsonValue?.string)
        ) {
            return;
        }
        const newValue = isNumCell(unit?.type)
            ? { float: value as number }
            : { string: value as string };

        upsertMutate(
            {
                set: {
                    datapointId: hasDatapoint ? datapoint?.id : undefined,
                    value: newValue,
                    datapointGroupId: groupId,
                    indicatorId: indicatorId,
                },
            },
            {
                onError: () => {
                    setToastError(t("update_error"));
                },
                onSuccess: () => {
                    setToastSuccess(t("update_success"));
                },
            },
        );
    };

    const onBlurComment = async () => {
        upsertMutate(
            {
                set: {
                    datapointId: hasDatapoint ? datapoint?.id : undefined,
                    comment: comment || null,
                    datapointGroupId: groupId,
                    indicatorId: indicatorId,
                },
            },
            {
                onError: () => {
                    setToastError(t("update_error"));
                },
                onSuccess: () => {
                    setToastSuccess(t("update_success"));
                },
            },
        );
    };

    const handleCheckNodata = async (checked: boolean) => {
        setNoDataChecked(checked);
        setValue(undefined);
        upsertMutate(
            {
                set: {
                    datapointId: hasDatapoint ? datapoint?.id : undefined,
                    value: { noData: checked },
                    datapointGroupId: groupId,
                    indicatorId: indicatorId,
                },
            },
            {
                onError: () => {
                    setToastError(t("update_error"));
                },
                onSuccess: () => {
                    setToastSuccess(t("update_success"));
                },
            },
        );
    };

    const tableCellInput: ValueInputProps = isNumCell(unit?.type)
        ? ({
              type: "number",
              value: value,
              datapointHasValue,
              datapointHasComment: !!comment,
              datapointHasProofs: !!proofs?.length,
              unit: unit?.shortName || "",
              calculation: calculation || "",
              onValueChange: onValueChange,
              onBlur,
              noDataStateText: t("no_data_available"),
              noData: noDataCheck,
          } as ValueInputProps)
        : ({
              type: "string",
              value: value,
              datapointHasValue,
              datapointHasComment: !!comment,
              datapointHasProofs: !!proofs?.length,
              unit: unit?.shortName || "",
              calculation: calculation || "",
              onValueChange: onValueChange,
              onBlur,
              noDataStateText: t("no_data_available"),
              noData: noDataCheck,
          } as ValueInputProps);

    return (
        <div className="h-[84px] py-1">
            <Input>
                <Input.ValueInput
                    {...tableCellInput}
                    className={cn(
                        !datapointHasValue && toFillSwitch && "bg-warning-100",
                    )}
                />
                <Input.CommentPopover
                    comment={comment}
                    onCommentChange={setComment}
                    onCommentBlur={onBlurComment}
                    checked={noDataCheck}
                    onChecked={handleCheckNodata}
                    checkTextPlaceholder={t("no_data_available_checkbox")}
                    commentTriggerTextPlaceholder={t("comment_trigger")}
                    noDataTriggerTextPlaceholder={t("no_data_available")}
                    proofs={proofs}
                ></Input.CommentPopover>
            </Input>
        </div>
    );
};

export default IndicatorDatapointCellInput;
