import {
    bulkS3FileUploadToS3MutationAtom,
    deleteS3FilesMutationAtom,
} from "@app/store/versionStore";
import { S3FileFromBulkUpload } from "@app/usecases/VersionUseCases";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { modals } from "../../utils";

export function useDocumentsUploadModal() {
    /** Handle open state of modal */
    const [searchParams, setSearchParams] = useSearchParams();
    const isOpen = searchParams.get("modal") === modals.uploadDocuments;

    const deleteS3FilesMutation = useAtomValue(deleteS3FilesMutationAtom);
    const bulkS3FileUploadToS3Mutation = useAtomValue(
        bulkS3FileUploadToS3MutationAtom,
    );

    /** Store updated files state */
    const [uploadedFiles, setUploadedFiles] = useState<{
        list: S3FileFromBulkUpload[];
        byId: Record<string, S3FileFromBulkUpload>;
    }>({ list: [], byId: {} });

    const deleteAllFiles = () => {
        if (!uploadedFiles.list.length) return;

        deleteS3FilesMutation.mutate(uploadedFiles.list.map((file) => file.id));
        setUploadedFiles({ list: [], byId: {} });
    };
    const closeModal = () => {
        deleteAllFiles();
        setSearchParams({});
    };

    const handleOpenChange = (open: boolean) => {
        if (open) return;
        closeModal();
    };

    return {
        closeModal,
        handleOpenChange,
        isOpen,
        isUploading: bulkS3FileUploadToS3Mutation.isPending,
        setUploadedFiles,
        uploadedFiles,
    };
}
