import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import {
    GetCmsUnitsDocument,
    Unit,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import { InnerSelectTrigger } from "../../../inputs_v2/SelectTrigger";
import { DropdownTrigger } from "../../components";
import UnitDropdown from "./dropdown";

const UnitInputSetter = ({
    unitId,
    onUnitIdChange,
    portalContainer,
}: {
    unitId?: string | undefined;
    onUnitIdChange: (unitId?: string) => void;
    portalContainer?: HTMLElement;
}) => {
    const { data } = useGraphQL(GetCmsUnitsDocument, undefined, {}, true);

    const selectedUnit = data?.units?.find((unit) => unit.id === unitId);
    return (
        <DropdownMenu>
            <DropdownTrigger className="w-full">
                <InnerSelectTrigger
                    stringValue={selectedUnit?.name || undefined}
                    placeholder="Unit"
                />
            </DropdownTrigger>
            <UnitDropdown
                unitId={unitId}
                onUnitIdChange={(id) => id && onUnitIdChange(id)}
                units={data?.units as Unit[]}
                portalContainer={portalContainer}
            />
        </DropdownMenu>
    );
};

export default UnitInputSetter;
