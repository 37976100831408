import i18next from "../../../../../../i18n";

i18next.addResourceBundle("en", "SustainabilityCenterEditDocumentsModal", {
    update_success: "Successfully updated sutainability center",
    update_error: "Error updating sutainability center",
    title: "Edit Documents",
    save: "Save",
    document: "Document",
    document_name_input_placeholder: "Document public name",
    select_docs_modal_title: "Select documents",
    select_docs_modal_action: "Add selection",
    select_docs_modal_search_placeholder: "Search by name",
});

i18next.addResourceBundle("fr", "SustainabilityCenterEditDocumentsModal", {
    update_success: "Centre de conformité mis à jour avec succès",
    update_error: "Erreur lors de la mise à jour du centre de conformité",
    title: "Editer les documents",
    save: "Enregistrer",
    document: "Document",
    document_name_input_placeholder: "Nom public du document",
    select_docs_modal_title: "Selectionner des documents",
    select_docs_modal_action: "Ajouter la selection",
    select_docs_modal_search_placeholder: "Rechercher par nom",
});
