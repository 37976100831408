import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { EyeOff } from "lucide-react";
import {
    Document,
    DocumentActivationStatus,
} from "../../../../../../../generated/client/graphql";
import { ButtonType } from "../../../../../../components/Button/ButtonShared";
import ButtonSmall from "../../../../../../components/Button/ButtonSmall";
import UserSelect from "../../../../../../components/Dropdown/dropdowns/User";
import Floatbar from "../../../../../../components/Floatbar";
import { useUpdateDocuments } from "../../../../hooks";
import "./i18n";

enum Actions {
    ASSIGN_OWNER = "ASSIGN_OWNER",
}
const ActionBar = ({
    documents,
    unset,
}: {
    documents: Document[];
    unset: () => void;
}) => {
    const { t } = useTranslation("DocumentTableSavebar");

    const [activeAction, setActiveAction] = useState<Actions>();

    const [ownerId, setOwnerId] = useState<string | null>();

    const { mutate, isPending } = useUpdateDocuments();

    const onOwnerApply = () => {
        mutate(
            documents.map((d) => d.id),
            { assignedToId: ownerId }
        );
    };

    const onRelevanceApply = () => {
        mutate(
            documents.map((d) => d.id),
            { activationStatus: DocumentActivationStatus.NotRelevant },
            unset
        );
    };

    useEffect(() => {
        setActiveAction(undefined);
    }, []);
    return (
        <Floatbar
            unset={unset}
            open={documents.length > 0}
            primary={
                <ButtonSmall
                    onClick={() => setActiveAction(Actions.ASSIGN_OWNER)}
                >
                    {t("set_owner_button")}
                </ButtonSmall>
            }
            secondary={
                <ButtonSmall
                    loading={isPending}
                    variant={ButtonType.OUTLINED}
                    onClick={() => onRelevanceApply()}
                    IconRight={EyeOff}
                >
                    {t("mark_not_relevant")}
                </ButtonSmall>
            }
            actions={[
                {
                    id: Actions.ASSIGN_OWNER,
                    text: t("set_owner_text", { count: documents.length }),
                    selector: (
                        <UserSelect
                            selectedUserId={ownerId || undefined}
                            onChange={(id) => setOwnerId(id || null)}
                        />
                    ),
                    apply: (
                        <ButtonSmall
                            loading={isPending}
                            onClick={onOwnerApply}
                            disabled={ownerId === undefined}
                        >
                            {t("apply")}
                        </ButtonSmall>
                    ),
                },
            ]}
            activeAction={activeAction}
            setActiveAction={setActiveAction}
        ></Floatbar>
    );
};

export default ActionBar;
