import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { activeDisclosureRequirementIdAtom } from "../ctx";
export const useNavigate = () => {
    const activeDisclosureRequirementId = useAtomValue(
        activeDisclosureRequirementIdAtom,
    );

    useEffect(() => {
        if (activeDisclosureRequirementId) {
            const elem = document.getElementById(activeDisclosureRequirementId);
            elem?.scrollIntoView({ behavior: "smooth" });
        }
    }, [activeDisclosureRequirementId]);
};
