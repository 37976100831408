import Container from "@app/components/Container";
import BodyHeader from "@app/shared/components/BodyHeader";
import EntitySelect from "@app/shared/components/EntitySelect";
import { entitiesAtom } from "@app/store/entityStore";
import { activeReportingEntityIdAtom } from "@app/store/reportingStore";
import { FlexCol } from "@design-system/Layout/Flex";

import { useAtom } from "jotai";
import { MegaphoneIcon } from "lucide-react";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AddPeriodModal from "./components/AddPeriodModal";
import AddCustomIndicatorModal from "./components/CreateCustomIndicatorsModal";
import InactiveIndicatorsModal from "./components/InactiveIndicatorsModal";
import IndicatorsTable from "./components/IndicatorsTable";
import PeriodChipsSelect from "./components/PeriodChipsSelect";
import ReportingFilters from "./components/ReportingFilters";
import ReportingHeaderButton from "./header";
import "./i18n";

const ReportingV2: FC = () => {
    const { t } = useTranslation("ReportingPage");

    const [{ data: entitiesData, isPending: isEntitiesLoading }] =
        useAtom(entitiesAtom);
    const [activeReportingEntityId, setActiveReportingEntityId] = useAtom(
        activeReportingEntityIdAtom,
    );

    useEffect(() => {
        if (
            !activeReportingEntityId &&
            !isEntitiesLoading &&
            entitiesData?.entities?.length
        ) {
            setActiveReportingEntityId(entitiesData?.entities[0].id);
        }
    }, [entitiesData, activeReportingEntityId]);

    return (
        <Container>
            <BodyHeader
                dataChameleon={{
                    key: "reporting",
                    type: "tutorial",
                }}
                icon={MegaphoneIcon}
                title={t("page_title")}
                subTitle={t("page_subtitle")}
                action={<ReportingHeaderButton />}
            />

            <FlexCol p="4" gap="4" alignItems="start" alignSelf="stretch">
                <ReportingFilters>
                    {!!entitiesData?.entities?.length && (
                        <EntitySelect
                            entityId={activeReportingEntityId}
                            setEntityId={setActiveReportingEntityId}
                        ></EntitySelect>
                    )}
                    <PeriodChipsSelect />
                </ReportingFilters>
                <IndicatorsTable />
            </FlexCol>
            <AddPeriodModal />
            <InactiveIndicatorsModal />
            <AddCustomIndicatorModal />
        </Container>
    );
};

export default ReportingV2;
