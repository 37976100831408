import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "ThemeAndSubthemeInput", {
    theme_placeholder: "Theme",
    subtheme_placeholder: "Subtheme",
});

i18next.addResourceBundle("fr", "ThemeAndSubthemeInput", {
    theme_placeholder: "Thème",
    subtheme_placeholder: "Sous-thème",
});
