import React, { useState } from "react";

import CheckboxItem from "./items/CheckboxItem";
import DropdownItemSearch from "./items/Search";
import DropdownContentLayout from "./layout";

interface MultiSelectLayoutProps {
    activeValueIds: (string | null)[] | undefined;
    onActiveValueIdsChange: (v: (string | null)[] | undefined) => void;
    values:
        | {
              id: string | null;
              name?: string;
              node?: React.ReactNode;
          }[]
        | undefined;
    preItems?: React.ReactNode;
    disableSearch?: boolean;
}
const MultiSelectLayout = ({
    activeValueIds,
    values,
    onActiveValueIdsChange,
    preItems,
    disableSearch,
}: MultiSelectLayoutProps) => {
    const [searchFilterString, setSearchFilterString] = useState("");

    const onToggle = (value: string | null) => {
        if (activeValueIds?.includes(value)) {
            const newValues = [...activeValueIds];
            activeValueIds &&
                newValues.splice(activeValueIds.indexOf(value), 1);
            onActiveValueIdsChange(
                newValues.length > 0 ? newValues : undefined
            );
        } else {
            onActiveValueIdsChange([...(activeValueIds || []), value]);
        }
    };

    return (
        <DropdownContentLayout align="start" sideOffset={4}>
            {!disableSearch && (
                <DropdownItemSearch
                    searchFilterString={searchFilterString}
                    setSearchFilterString={setSearchFilterString}
                />
            )}
            {preItems}
            {values
                ?.filter(
                    ({ name }) =>
                        !searchFilterString ||
                        (name &&
                            name
                                .toLowerCase()
                                .includes(searchFilterString.toLowerCase()))
                )
                .map(({ id, name, node }) => (
                    <CheckboxItem
                        key={id}
                        onSelect={(e) => {
                            e.preventDefault();
                        }}
                        checked={activeValueIds?.includes(id)}
                        onCheckedChange={() => {
                            onToggle(id);
                        }}
                    >
                        {node || name}
                    </CheckboxItem>
                ))}
        </DropdownContentLayout>
    );
};

export default MultiSelectLayout;
