import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "UpdateUserModal", {
    title: "Modify user",
    subtitle: "Modify name, email, role or department",
    firstname: "First name",
    lastname: "Last name",
    email: "Email",
    department: "Department",
    promote: "Promote to admin",
    user_update_success: "User updated successfully",
    user_update_error: "Error updating users",
    give_admin_success: "Admin access given successfully",
    give_admin_error: "Error giving admin access",

    reset_password: "Reset password",
    reset_password_success:
        "User has been sent a email to reset their password",
    reset_password_error: "Error resetting password",
});

i18next.addResourceBundle("fr", "UpdateUserModal", {
    title: "Modifier l'utilisateur",
    subtitle: "Modifier le nom, l'e-mail, le rôle ou le département",
    firstname: "Prénom",
    lastname: "Nom",
    email: "Email",
    department: "Département",
    promote: "Donner un accès Admin",
    user_update_success: "Utilisateurs modifiés avec succès",
    user_update_error: "Erreur lors de la modification des utilisateurs",
    give_admin_success: "Accès administrateur donné avec succès",
    give_admin_error: "Erreur lors de l'octroi de l'accès administrateur",

    reset_password: "Réinitialiser le mot de passe",
    reset_password_success:
        "Un e-mail a été envoyé à l'utilisateur pour réinitialiser leur mot de passe",
    reset_password_error: "Erreur lors de la réinitialisation du mot de passe",
});
