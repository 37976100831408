import i18next from "../../i18n";

i18next.addResourceBundle("en", "IndicatorTable", {
    idx: "Id",
    status: "Status",
    tags: "Tags",
    indicator: "Indicator",
    unit: "Unit",
    description: "Description",
    theme: "Theme",
    questionnaire: "Questionnaires",
    documentAcceptance: "Document",
    empty_table_small_text: "No indicator yet",
    empty_table_button_text: "Add an indicator",
    edit: "Edit",
});

i18next.addResourceBundle("fr", "IndicatorTable", {
    idx: "Id",
    status: "Statut",
    tags: "Tags",
    indicator: "Indicateur",
    theme: "Thème",
    questionnaire: "Questionnaires",
    unit: "Unité",
    description: "Description",
    documentAcceptance: "Document",
    empty_table_small_text: "Vous n'avez pas encore d'indicateur",
    empty_table_button_text: "Ajouter un indicateur",
    edit: "Modifier",
});
