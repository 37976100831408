import i18next from "../../../../i18n";

i18next.addResources("en", "DocumentVersionBlock", {
    view: "View",
    edit: "Edit",
    delete: "Delete",
    approve: "Approve",
    approve_tooltip: "Edit and mark as ready for approval first",
    last_edited_by: "Last edited by",
    approved_by: "Approved by",
    approve_desc:
        "After you approve a version, you will not be able to unapprove or modify it. You will only be able to create a new version of your document.",
    draft: "(Draft)",
    download: "Download",
});
i18next.addResources("fr", "DocumentVersionBlock", {
    view: "Voir",
    edit: "Modifier",
    delete: "Supprimer",
    approve: "Approuver",
    approve_tooltip: "Modifier et marquer comme prêt pour approbation d'abord",
    last_edited_by: "Dernière modification par",
    approved_by: "Approuvé par",
    approve_desc:
        "Après avoir approuvé une version, vous ne pourrez pas la désapprouver ou la modifier. Vous ne pourrez créer qu'une nouvelle version de votre document.",
    draft: "(Brouillon)",
    download: "Télécharger",
});
