import {
    createContext,
    useContext,
    type FC,
    type PropsWithChildren,
} from "react";
import { cn } from "../../Utilities";

const THREAD_CLASSNAME = "shutters-thread";

type ShuttersProps = {
    classNames?: string;
    threadsOffset?: number;
    threadsHeight?: number;
};

type ThreadProps = {
    height?: number;
    offset?: number;
};

const ctx = createContext<{
    threadsOffset: number;
    threadsHeight: number;
} | null>(null);

/**
 * Component that renders a vertical thread connecting the Louvers
 * or any other component inside Shutters. It comes with defalt height
 * and offset values, but they can be overridden for each single Thread
 * by passing the props.
 *
 * @component
 * @example
 * // Example usage of Thread component
 * <Shutters.Thread height={20} offset={26} />
 *
 * @param {object} props - The properties that define the `Thread` component.
 * @param {number} props.height - The height of the thread.
 * @param {number} props.offset - The offset of the thread.
 *
 * @returns {ReactElement} Returns a `div` element with the applied classes and styles.
 */
const Thread: FC<ThreadProps> = ({ height, offset }) => {
    const styleCtx = useContext(ctx);
    if (!styleCtx) {
        throw new Error(
            "Thread component must be used within a Shutters component."
        );
    }
    const style = {
        height: height ?? styleCtx.threadsHeight,
        width: offset ?? styleCtx.threadsOffset,
    };

    return (
        <div
            className={cn(THREAD_CLASSNAME, "border-r border-seconday")}
            style={style}
        />
    );
};

const Louver: FC<PropsWithChildren> = ({ children }) => (
    <article className="w-full rounded-xl border border-tertiary bg-white shadow-container">
        {children}
    </article>
);

export type ShuttersComponentType = FC<PropsWithChildren<ShuttersProps>> & {
    Thread: typeof Thread;
    Louver: typeof Louver;
};

/**
 * Container component that renders its children in a shutters-like layout.
 * It applies specific styles to the Thread and Louver components.
 *
 * @component
 * @example
 * // Example usage of Shutters component
 * <Shutters>
 * {
 *   versionBlocks.map((versionBlock, idx) => (
 *     <React.Fragment key={idx}>
 *       {idx !== 0 && <Shutters.Thread />}
 *       <Shutters.Louver>
 *         <VersionBlock version={versionBlock} />
 *       </Shutters.Louver>
 *     </React.Fragment>
 *   ))
 * }
 * </Shutters>
 *
 * @param {object} props - The properties that define the `Shutters` component.
 * @param {ReactNode} props.children - The child components to be rendered within the `Shutters` component.
 * @param {string} props.classNames - Additional class names to be applied to the `Shutters` component.
 * @param {number} props.threadsOffset - The offset of the threads. Defaults to 26px if not provided.
 * @param {number} props.threadsHeight - The height of the threads. Defaults to 20px if not provided.
 *
 * @returns {ReactElement} Returns a `section` element with the applied classes and styles, containing the child components.
 */
export const Shutters: ShuttersComponentType = ({
    children,
    classNames,
    threadsOffset,
    threadsHeight,
}) => {
    return (
        <section className={cn("flex flex-col", classNames)}>
            <ctx.Provider
                value={{
                    threadsOffset: threadsOffset ?? 26,
                    threadsHeight: threadsHeight ?? 20,
                }}
            >
                {children}
            </ctx.Provider>
        </section>
    );
};

Shutters.Louver = Louver;
Shutters.Thread = Thread;
