import { ReactComponent as StatusEmptyIcon } from "@app/assets/Status-empty.svg";
import { ReactComponent as StatusCheckedIcon } from "@app/assets/Status16.svg";
import { ButtonType } from "@app/components/Button/ButtonShared";
import ButtonSmall from "@app/components/Button/ButtonSmall";
import Loading from "@app/components/Loading";
import { FloatingBadge } from "@design-system/DataDisplay/Badge";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Shutters } from "@design-system/Layout/RollupShutters";
import { Text } from "@design-system/Typography/Text";
import { Fragment, type FC } from "react";
import { Link } from "react-router-dom";
import "../../i18n";
import { DashboardCard } from "../DashboardCard";
import { DeadlineStatusBadge } from "./DeadlineStatusBadge";
import { EditStepModal } from "./EditStepModal";
import { usePlanSection } from "./usePlanSection";

export const PlanSection: FC = () => {
    const {
        agendaCards,
        floatingBadgeContent,
        isCS,
        isPending,
        lang,
        planStepsData,
        showFloatingBadge,
        t,
    } = usePlanSection();
    return isPending ? (
        <Loading size="medium" />
    ) : planStepsData ? (
        <DashboardCard
            as="section"
            elevation={200}
            className="flex justify-center"
        >
            <Shutters classNames="flex-auto max-w-[1080px]">
                <FloatingBadge
                    content={floatingBadgeContent}
                    show={showFloatingBadge}
                    variant="error"
                >
                    <DashboardCard
                        as="article"
                        className="rounded-full py-1.5 w-fit"
                        elevation={0}
                    >
                        <p className=" text-sm font-bold">
                            {t("agenda_title")}
                        </p>
                    </DashboardCard>
                </FloatingBadge>
                <Shutters.Thread />
                {agendaCards.map(
                    (
                        {
                            id,
                            titleI18nKey,
                            descriptionI18nKey,
                            path,
                            learnMoreCb,
                        },
                        idx,
                    ) => (
                        <Fragment key={id}>
                            <DashboardCard
                                as="article"
                                className="flex gap-5"
                                hoverBorder
                            >
                                <Link
                                    className="block flex-auto hover:no-underline text-primary"
                                    key={id}
                                    to={path}
                                >
                                    <FlexRow gap="5">
                                        {planStepsData[id].checked ? (
                                            <StatusCheckedIcon
                                                className="min-w-5 m-0.5"
                                                height={20}
                                                width={20}
                                            />
                                        ) : (
                                            <StatusEmptyIcon className="min-w-5 m-0.5" />
                                        )}
                                        <FlexCol gap="1">
                                            <FlexRow
                                                alignItems="center"
                                                gap="2"
                                            >
                                                <h3 className="text-lg font-bold inline-block">
                                                    {t(titleI18nKey)}
                                                </h3>
                                                {planStepsData[id].dueDate ? (
                                                    <DeadlineStatusBadge
                                                        date={
                                                            planStepsData[id]
                                                                .dueDate
                                                        }
                                                        hideBadge={
                                                            planStepsData[id]
                                                                .checked
                                                        }
                                                    />
                                                ) : null}
                                            </FlexRow>
                                            <p className="text-sm">
                                                {t(descriptionI18nKey)}
                                            </p>
                                            {planStepsData[id].dueDate ? (
                                                <Text
                                                    variant="body-sm"
                                                    color="secondary"
                                                >
                                                    {t("due_date", {
                                                        date: planStepsData[
                                                            id
                                                        ].dueDate.toLocaleDateString(),
                                                    })}
                                                </Text>
                                            ) : null}
                                        </FlexCol>
                                    </FlexRow>
                                </Link>
                                <div className="flex min-w-40 items-end justify-end gap-4">
                                    <ButtonSmall
                                        onClick={learnMoreCb[lang]}
                                        variant={ButtonType.TONAL}
                                    >
                                        {t("learn_more")}
                                    </ButtonSmall>
                                    {isCS ? (
                                        <EditStepModal
                                            dueDate={planStepsData[id].dueDate}
                                            stepType={id}
                                            isChecked={
                                                planStepsData[id].checked
                                            }
                                            uid={planStepsData[id].id}
                                        />
                                    ) : null}
                                </div>
                            </DashboardCard>
                            {idx < agendaCards.length - 1 && (
                                <Shutters.Thread />
                            )}
                        </Fragment>
                    ),
                )}
            </Shutters>
        </DashboardCard>
    ) : null;
};
