import {
    DocumentVersionStatus,
    type Document,
    type GetDocumentsForSearchQuery,
} from "@generated/client/graphql";

import Fuse from "fuse.js";

export const filterDocuments = (
    documents: GetDocumentsForSearchQuery["documents"] | undefined,
    searchFilter?: {
        name?: string;
        subthemes?: string[];
    },
) => {
    
    if (!searchFilter || !documents) {
        return null;
    }

    if (searchFilter.subthemes?.length === 0 && !searchFilter.name) {
        return documents;
    }

    const fuse = new Fuse(documents, {
        keys: ["name", "subtheme.id"],
        useExtendedSearch: true,
    });

    const queryAnd: (
        | {
              name: string;
          }
        | {
              $or: {
                  "subtheme.id": string;
              }[];
          }
    )[] = [];

    if (searchFilter.name?.length) {
        queryAnd.push({ name: searchFilter.name });
    }

    if (!!searchFilter.subthemes?.length) {
        queryAnd.push({
            $or: searchFilter.subthemes.map((id) => ({
                "subtheme.id": "=" + id,
            })),
        });
    }

    return fuse.search({ $and: queryAnd }).map(({ item }) => item);
};

export function hasUnapprovedLastVersion(
    document:
        | NonNullable<GetDocumentsForSearchQuery["documents"]>[number]
        | Document,
) {
    return (
        !!document.lastVersion &&
        document.lastVersion.status !== DocumentVersionStatus.Approved
    );
}
