import i18next from "../../../i18n";

i18next.addResources("en", "UserChipSelect", {
    searchbar_placeholder: "search",
    unset_user: "Unset",
});
i18next.addResources("fr", "UserChipSelect", {
    searchbar_placeholder: "chercher",
    unset_user: "Retirer",
});
