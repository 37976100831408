import { Provider } from "jotai/react";
import { useHydrateAtoms } from "jotai/react/utils";

import { queryClient } from "@app/QueryClientWithHeaders";
import { queryClientAtom } from "jotai-tanstack-query";
import { type FC, type PropsWithChildren } from "react";

const HydrateAtoms: FC<PropsWithChildren> = ({ children }) => {
    useHydrateAtoms([[queryClientAtom, queryClient]]);
    return children;
};

export const JotaiProvider: FC<PropsWithChildren> = ({ children }) => (
    <Provider>
        <HydrateAtoms>{children}</HydrateAtoms>
    </Provider>
);
