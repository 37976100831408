import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "SupplierDatapointTable", {
    order: "Id",
    indicator: "Indicator",
    supplier: "Supplier",
    answered_by: "Answered by",
    value: "Value",
    year: "Year",
    entry_date: "Entry date",
    CREATED: "Answered",
    VALIDATED: "Validated",
    empty_table_small_text: "No supplier data yet",
    empty_table_button_text: "Launch questionnaires",
});

i18next.addResourceBundle("fr", "SupplierDatapointTable", {
    order: "Id",
    indicator: "Indicateur",
    supplier: "Fournisseur",
    answered_by: "Répondu par",
    value: "Valeur",
    year: "Année",
    entry_date: "Date d'entrée",
    CREATED: "Répondu",
    VALIDATED: "Validé",
    empty_table_small_text: "Aucune donnée fournisseur",
    empty_table_button_text: "Lancer des questionnaires",
});
