import i18next from "../../../i18n";

i18next.addResourceBundle("en", "LoadIndicators", {
    title: "Load indicators",
    subtitle: "Load indicators from a CSV file",
});

i18next.addResourceBundle("fr", "LoadIndicators", {
    title: "Charger des indicateurs",
    subtitle: "Charger des indicateurs depuis un fichier CSV",
});
