import { DefinedQueryObserverResult } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    DocumentExtType,
    DocumentVersion,
    GetDocumentByUrlQuery,
} from "../../../../../generated/client/graphql";
import { ButtonType } from "../../../../components/Button/ButtonShared";
import ButtonSmall from "../../../../components/Button/ButtonSmall";
import FullFrameBlock from "../../../../components/Layouts/FullFrame/Block";
import InnerLayout from "../../../../components/Layouts/FullFrame/Block/InnerLayout";
import GetStartedChip from "./GetStartedChip";
import OptionStartFromPrevious from "./Option/OptionStartFromPrevious";
import OptionStartFromScratch from "./Option/OptionStartFromScratch";
import OptionStartFromTemplate from "./Option/OptionStartFromTemplate";
import OptionUploadFile from "./Option/OptionUploadFile";
import "./i18n";

const CreateVersionBlock = ({
    getDocumentQuery,
    lastVersion,
}: {
    getDocumentQuery: DefinedQueryObserverResult<
        GetDocumentByUrlQuery,
        unknown
    >;
    lastVersion?: DocumentVersion;
}) => {
    const [expanded, setExpanded] = useState(false);
    const expandedOrFirst = expanded || !lastVersion;
    const { t } = useTranslation("CreateDocumentVersionBlock");
    const type = getDocumentQuery.data?.documentByUrl?.type;

    return (
        <div className="group flex flex-col">
            {!lastVersion && (
                <div className=" self-center">
                    <GetStartedChip />
                </div>
            )}
            <FullFrameBlock>
                <InnerLayout className="flex space-x-5">
                    <div className="flex-grow space-y-1">
                        <div className="text-lg font-bold">
                            {!lastVersion
                                ? t(`${type}_start_new_version_title_first`)
                                : t(`${type}_start_new_version_title`)}
                        </div>
                        <div className="text-sm font-regulat">
                            {!lastVersion
                                ? t(`${type}_start_new_version_desc_first`)
                                : t(`${type}_start_new_version_desc`)}
                        </div>
                    </div>
                    {!expandedOrFirst && (
                        <ButtonSmall
                            variant={ButtonType.TONAL}
                            onClick={() => setExpanded(true)}
                        >
                            {t(`start_button`)}
                        </ButtonSmall>
                    )}
                </InnerLayout>
                {expandedOrFirst && (
                    <InnerLayout>
                        <div className="rounded-lg border border-tertiary">
                            <OptionUploadFile
                                getDocumentQuery={getDocumentQuery}
                            />
                            {getDocumentQuery.data?.documentByUrl?.extType ===
                                DocumentExtType.Text && (
                                <>
                                    {getDocumentQuery.data?.documentByUrl
                                        ?.templateLocales &&
                                        getDocumentQuery.data.documentByUrl
                                            .templateLocales.length > 0 && (
                                            <OptionStartFromTemplate
                                                getDocumentQuery={
                                                    getDocumentQuery
                                                }
                                            />
                                        )}
                                    <OptionStartFromScratch
                                        getDocumentQuery={getDocumentQuery}
                                    />
                                    {lastVersion && (
                                        <OptionStartFromPrevious
                                            getDocumentQuery={getDocumentQuery}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </InnerLayout>
                )}
            </FullFrameBlock>
            <div className="relative group-last:hidden">
                <div className="absolute w-[34px]  h-5 border-r border-seconday" />
            </div>
        </div>
    );
};

export default CreateVersionBlock;
