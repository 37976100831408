import i18next from "../../i18n";

i18next.addResourceBundle("en", "Users", {
    title: "Users",
    subtitle:
        "Invite your coworkers to collaborate on your Beavr account. Admins have the ability to create, edit, and delete other users",
    add_user: "Invite",
});

i18next.addResourceBundle("fr", "Users", {
    title: "Utilisateurs",
    subtitle:
        "Invitez vos collègues à collaborer sur votre compte Beavr. Les administrateurs ont la possibilité de créer, modifier et supprimer d’autres utilisateurs.",
    add_user: "Inviter",
});
