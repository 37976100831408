import { useAddTipTapDoc } from "@app/pages/Editor/hooks/useAddTipTapDoc";
import { versionAtom } from "@app/store/versionStore";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { useAtomValue } from "jotai";
import Loading from "../../../components/Loading";
import { useCapturePageView } from "../../../lib/tracking/utils";
import BottomLeftBlock from "./components/BottomLeftBlock";
import BottomRightBlock from "./components/BottomRightBlock";
import Header from "./components/Header";
import TopLeftBlock from "./components/TopLeftBlock";
import TopRightBlock from "./components/TopRightBlock";
import {
    EditorContext,
    useCreateDocumentEditorContext,
} from "./hooks/useEditor";

import "./i18n";

const EditDocument = () => {
    useCapturePageView("document-edit");
    const { isLoading: isAddingTipTap } = useAddTipTapDoc();

    const { data: { version } = { version: undefined }, isPending } =
        useAtomValue(versionAtom);

    const ctxValue = useCreateDocumentEditorContext(version);

    if (isAddingTipTap || isPending) {
        return <Loading size="small" />;
    }

    return (
        <EditorContext.Provider value={ctxValue}>
            <FlexCol className="fixed top-0 left-0 h-screen w-screen bg-white overflow-scroll">
                <FlexCol className="h-screen overflow-hidden">
                    <Header />
                    <FlexRow className="min-h-0 flex-shrink flex-grow">
                        {ctxValue.editor ? (
                            <>
                                <FlexCol className="border-r border-tertiary flex-grow h-full">
                                    <TopLeftBlock />
                                    <BottomLeftBlock />
                                </FlexCol>
                                <FlexCol className="w-[320px] min-h-0 flex-shrink h-full">
                                    <TopRightBlock />
                                    <FlexCol className="w-[320px] min-h-0 flex-shrink h-full">
                                        <BottomRightBlock />
                                    </FlexCol>
                                </FlexCol>
                            </>
                        ) : (
                            <Loading size="medium" />
                        )}
                    </FlexRow>
                </FlexCol>
            </FlexCol>
        </EditorContext.Provider>
    );
};

export default EditDocument;
