import { NavArrowRight } from "iconoir-react";

interface CollapsedAccordionProps {
    collapsed: boolean;
    onClick: () => void;
    title: string;
    color: string;
    tags: React.ReactNode;
}

export default function CollapsedAccordion({
    collapsed,
    onClick,
    title,
    color,
    tags,
}: CollapsedAccordionProps) {
    return (
        <button
            className="w-full flex justify-between items-center bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] focus:outline-none"
            type="button"
            onClick={onClick}
            aria-expanded="true"
        >
            <div className="flex justify-start items-center">
                <span
                    className={`${
                        !collapsed ? `rotate-[+90deg]` : `rotate-0`
                    } transition-transform duration-200 ease-in-out motion-reduce:transition-none`}
                >
                    <NavArrowRight className="w-4 h-4" />
                </span>
                <span
                    className={`h-[10px] w-[10px] rounded-full ml-[15px]`}
                    style={{ backgroundColor: color }}
                ></span>
                <div className="ml-[13px] text-lg text-neutral-700">
                    {title}
                </div>
            </div>

            {tags}
        </button>
    );
}
