import i18next from "../../../i18n";

i18next.addResourceBundle("en", "Sites", {
    title: "Entityes",
    subtitle: " Create, edit, and delete entities",
    add_site: "Create new",
    empty_table_small_text: "No entity yet",
    empty_table_button_text: "Add an entity",
});

i18next.addResourceBundle("fr", "Sites", {
    title: "Entités",
    subtitle: "Créer, modifier et supprimer des entités",
    add_site: "Créer",
    empty_table_small_text: "Vous n'avez pas encore d'entité",
    empty_table_button_text: "Créer une entité",
});
