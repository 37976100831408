import { Skeleton } from "@design-system/DataDisplay/Skeleton";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { FlexRow } from "@design-system/Layout/Flex";
import { cn } from "@design-system/Utilities";
import chroma from "chroma-js";
import React, {
    FC,
    PropsWithChildren,
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";

type GaugeContext = {
    total: number;
    setValue: (index: number, val: number) => void;
};

export const GaugeContext = createContext<GaugeContext | null>(null);

type GaugeComponent = FC<
    PropsWithChildren<{ isLoading?: boolean; className?: string }>
> & {
    Label: typeof Label;
    List: typeof List;
    Item: typeof Item;
};

const Label: FC<{
    children: ReactNode;
}> = (props) => {
    return props.children;
};

const Item: FC<{
    value: number; // Should be between 0 and 1
    color: chroma.Color;
    label: string;
    className?: string;
    index?: number;
}> = (props) => {
    const ctx = useContext(GaugeContext);
    if (!ctx) throw new Error("Context must not be null");
    const { setValue, total } = ctx;
    useEffect(() => {
        if (props.index !== undefined) {
            setValue(props.index, props.value);
        }
    }, [props.value, props.index]);

    return (
        <Tooltip delayDuration={0}>
            <Tooltip.Trigger
                className={cn(
                    `h-full cursor-default`,
                    `w-[${Math.floor((props.value / total) * 100)}%]`,
                )}
            >
                <div
                    className={cn("h-full", props.className)}
                    style={{
                        backgroundColor:
                            props.color && chroma(props.color).hex(),
                    }}
                ></div>
            </Tooltip.Trigger>
            {props.label && <Tooltip.Content>{props.label}</Tooltip.Content>}
        </Tooltip>
    );
};

const List: FC<PropsWithChildren> = (props) => {
    return (
        <FlexRow w="full" h="2" alignItems="center" gap="0.5">
            {React.Children.map(props.children, (child, i) => {
                return React.cloneElement(child as React.ReactElement, {
                    className: cn(
                        i == 0 && "first:rounded-l-md",
                        i ===
                            React.Children.toArray(props.children).length - 1 &&
                            "last:rounded-r-md",
                    ),
                    index: i,
                });
            })}
        </FlexRow>
    );
};

export const Gauge: GaugeComponent = (props) => {
    const [values, setValues] = useState({});
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
        const valuesArray: number[] = Object.values(values);
        setTotal(valuesArray.reduce((acc, curr) => acc + curr, 0));
    }, [values]);

    const setValue = (index: number, value: number) => {
        setValues((values) => ({ ...values, [index]: value }));
    };

    return (
        <GaugeContext.Provider
            value={{
                total: total,
                setValue,
            }}
        >
            {props.isLoading ? (
                <Skeleton
                    h="2"
                    minW="12"
                    className={cn("rounded-md", props.className)}
                ></Skeleton>
            ) : (
                <FlexRow
                    minW="12"
                    alignItems="center"
                    gap="2"
                    className={props.className}
                >
                    {props.children}
                </FlexRow>
            )}
        </GaugeContext.Provider>
    );
};

Gauge.Label = Label;
Gauge.List = List;
Gauge.Item = Item;
