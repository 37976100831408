import { RefreshDouble } from "iconoir-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";
import {
    Document,
    UpdateDocumentV2Document,
} from "../../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../../QueryClientWithHeaders";
import FullFrameBlock from "../../../../components/Layouts/FullFrame/Block";
import InnerLayout from "../../../../components/Layouts/FullFrame/Block/InnerLayout";
import TextAreaInput from "../../../../components/inputs_v2/TextArea";
import "./i18n";
const InfoBlock = ({ Document }: { Document?: Document }) => {
    const { t } = useTranslation("DocumentInfoBlock");
    const [comment, setComment] = useState<string | undefined>(
        Document?.comment || undefined
    );
    const updateDocumentMutation = useGraphQLMutation(
        UpdateDocumentV2Document,
        {},
        true
    );
    useEffect(() => {
        setComment(Document?.comment || undefined);
    }, [Document?.comment]);

    const onCommentBlur = () => {
        Document &&
            updateDocumentMutation.mutate({
                documentId: Document.id,
                patch: { comment: comment || null },
            });
    };

    return (
        <FullFrameBlock>
            <div className="flex">
                <InnerLayout className="w-1/2 border-r border-tertiary space-y-1">
                    <div className="text-base font-bold">
                        {t("description")}
                    </div>
                    <ReactMarkdown
                        className="markdown text-base font-regular"
                        children={Document?.description || ""}
                        remarkPlugins={[remarkGfm, remarkBreaks]}
                    />
                </InnerLayout>
                <InnerLayout className="w-1/2 border-r border-tertiary space-y-1">
                    <div className="text-base font-bold">
                        {t("recurrence_title")}
                    </div>
                    <div className="text-base font-regular space-x-1.5 flex items-center ">
                        <RefreshDouble className="text-green w-4 h-4" />
                        <span>{t("recurrence")}</span>
                    </div>
                </InnerLayout>
            </div>
            <InnerLayout>
                <TextAreaInput
                    placeholder={t("comment_placeholder")}
                    text={comment || undefined}
                    setText={setComment}
                    onBlurCallback={onCommentBlur}
                    className="min-h-[80px]"
                />
            </InnerLayout>
        </FullFrameBlock>
    );
};

export default InfoBlock;
