import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Button } from "@design-system/Inputs/Button";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { CSVRow, useCsvImport } from "./hooks";

const ImportCSVButton = ({
    onAddIndicators,
}: {
    onAddIndicators: (suppliers: CSVRow[]) => void;
}) => {
    const { t } = useTranslation("CreateIndicatorsModal");
    const ref = useRef<HTMLInputElement>(null);
    const onCsvImport = useCsvImport(onAddIndicators);

    return (
        <>
            <input
                ref={ref}
                type="file"
                className="hidden"
                onChange={(e) => {
                    e.target.files && onCsvImport(e.target.files[0]);
                    if (ref.current) {
                        ref.current.files = null;
                        ref.current.value = "";
                    }
                }}
            />
            <Tooltip>
                <Tooltip.Trigger>
                    <Button
                        size="md"
                        variant="tonal"
                        onClick={() => ref.current?.click()}
                    >
                        <Button.Icon name="info" />

                        {t("import_csv")}
                    </Button>
                </Tooltip.Trigger>
                <Tooltip.Content>{t("import_csv_tooltip")}</Tooltip.Content>
            </Tooltip>
        </>
    );
};

export default ImportCSVButton;
