// inspired by this wonderful article 😍 https://medium.com/@aylo.srd/server-side-pagination-and-sorting-with-tanstack-table-and-react-bd493170125e
import { Skeleton } from "@design-system/DataDisplay/Skeleton";
import { Icon } from "@design-system/Icons";
import { FlexCol } from "@design-system/Layout/Flex";
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    type ColumnDef,
    type PaginationState,
    type SortingState,
    type TableOptions,
} from "@tanstack/react-table";
import { useState } from "react";
import {
    Table as HTMLTable,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "./HTMLTable";
import { Pagination } from "./Pagination";
import { cn } from "@design-system/Utilities";

type Options<TData> = Omit<
    TableOptions<TData>,
    "columns" | "data" | "getCoreRowModel"
>;
type PaginationType = {
    state: PaginationState;
    onPaginationChange: TableOptions<any>["onPaginationChange"];
};
type SortingType = {
    state: SortingState;
    onSortingChange: TableOptions<any>["onSortingChange"];
};

type TableProps<TData extends unknown, TValue = unknown> = {
    /*
     * The column definitions for the table, based on tanstack's table definition.
     */
    columns: ColumnDef<TData, TValue>[];
    /*
     * The data for the table.
     */
    data: TData[];
    /*
     * Whether the table is currently loading data.
     */
    loading?: boolean;
    /*
     * Additional options for the table that you would typically pass to useReactTable (except "columns" | "data" | "getCoreRowModel"); You can use it to override the tabs behavior.
     */
    options?: Options<TData>;
    /*
     * The pagination state and change handler for the table.
     */
    pagination?: PaginationType;
    /*
     * The sorting state and change handler for the table.
     */
    sorting?: SortingType;
    /*
     * The number of rows in the table.  It's primarily used for managing pagination. Typically, this count is retrieved from an API, which also depends on the data that the 'usePagination' hook uses. However, since the 'rowCount' is usually dependent on the data from the API, it's not possible to pass it to the 'usePagination' hook for it to include it in the pagination object. To maintain clarity and avoid circular dependencies, we've chosen to define 'rowCount' separately.
     */
    rowCount?: number;
    /*
     * Whether the table is compact or regular. Compact will have smaller padding and font size.
     */
    variant?: "regular" | "compact";
};
/**
 * Table component that uses the react-table library for rendering and functionality.
 *
 * @template TData The type of data that the table will be displaying.
 * @template TValue The type of value that the table cells will be displaying.
 *
 * @param {Object} props The properties for the table.
 * @param {ColumnDef<TData, TValue>[]} props.columns The column definitions for the table.
 * @param {TData[]} props.data The data for the table.
 * @param {boolean} [props.loading=false] Whether the table is currently loading data.
 * @param {Options<TData>} [props.options={}] Additional options for the table that you would typically pass to useReactTable (except "columns" | "data" | "getCoreRowModel"); You can use it to override the tabs behavior.
 * @param {PaginationType} [props.pagination] The pagination state and change handler for the table.
 * @param {SortingType} [props.sorting] The sorting state and change handler for the table.
 * @param {number} [props.rowCount] The number of rows in the table.
 * @param {"regular" | "compact"} [props.variant="regular"] Whether the table is compact or regular. Compact will have smaller padding and font size.
 *
 * @returns {JSX.Element} The table component.
 */
export const Table = <TData, TValue>({
    columns,
    data,
    loading,
    options = {},
    pagination,
    sorting,
    rowCount,
    variant = "regular",
}: TableProps<TData, TValue>) => {
    const tableOptions: () => TableOptions<TData> = () => {
        let state;
        let paginationOpts;
        let sortingOpts;

        if (pagination) {
            const { state: paginationState, onPaginationChange } = pagination;
            state = { pagination: paginationState };
            paginationOpts = {
                manualPagination: true,
                onPaginationChange,
            };
        }

        if (sorting) {
            const { state: sortingState, onSortingChange } = sorting;
            state = { ...(state ?? {}), sorting: sortingState };
            sortingOpts = { manualSorting: true, onSortingChange };
        }

        const opts: TableOptions<TData> = {
            data,
            columns,
            getCoreRowModel: getCoreRowModel(),
            ...({ state } ?? {}),
            ...(paginationOpts ?? {}),
            ...(sortingOpts ?? {}),
            rowCount,
            ...options,
        };
        return opts;
    };

    const table = useReactTable(tableOptions());

    return (
        <FlexCol gap="4">
            <HTMLTable>
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <TableHead
                                        className={cn(
                                            header.column.columnDef.meta
                                                ?.headerClassName,
                                        )}
                                        key={header.id}
                                        {...(typeof header.column.columnDef.meta
                                            ?.colWidth === "string"
                                            ? {
                                                  style: {
                                                      width: header.column
                                                          .columnDef.meta
                                                          .colWidth,
                                                  },
                                              }
                                            : {})}
                                              variant={variant}
                                    >
                                        {header.column.getCanSort() ? (
                                            <button
                                                className="cursor-pointer flex flex-row items-center gap-1 text-sm font-bold"
                                                onClick={header.column.getToggleSortingHandler()}
                                            >
                                                {flexRender(
                                                    header.column.columnDef
                                                        .header,
                                                    header.getContext(),
                                                )}
                                                {!header.column.getIsSorted() && (
                                                    <Icon
                                                        name="angleUpDown"
                                                        size="xs"
                                                    />
                                                )}
                                                {header.column.getIsSorted() ===
                                                    "desc" && (
                                                    <Icon
                                                        name="angleUp"
                                                        size="xs"
                                                    />
                                                )}
                                                {header.column.getIsSorted() ===
                                                    "asc" && (
                                                    <Icon
                                                        name="angleDown"
                                                        size="xs"
                                                    />
                                                )}
                                            </button>
                                        ) : (
                                            flexRender(
                                                header.column.columnDef.header,
                                                header.getContext(),
                                            )
                                        )}
                                    </TableHead>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody>
                    {loading
                        ? Array.from({
                              length: table.getState().pagination.pageSize,
                          }).map((_, i) => (
                              <TableRow key={i}>
                                  {table.getHeaderGroups().map((headerGroup) =>
                                      headerGroup.headers.map((_, idx) => (
                                          <TableCell key={idx}>
                                              <Skeleton
                                                  br="xl"
                                                  h="9"
                                                  w="full"
                                              />
                                          </TableCell>
                                      )),
                                  )}
                              </TableRow>
                          ))
                        : table.getRowModel().rows.map((row) => (
                              <TableRow key={row.id}>
                                  {row.getVisibleCells().map((cell) => (
                                      <TableCell
                                          className={cn(
                                              cell.column.columnDef.meta
                                                  ?.cellClassName ?? "",
                                          )}
                                          key={cell.id}
                                          variant={variant}
                                      >
                                          {flexRender(
                                              cell.column.columnDef.cell,
                                              cell.getContext(),
                                          )}
                                      </TableCell>
                                  ))}
                              </TableRow>
                          ))}
                </TableBody>
            </HTMLTable>
            <Pagination loading={loading} table={table} />
        </FlexCol>
    );
};

/**
 * Hook for managing pagination state.
 *
 * @param {number} [initialSize=10] The initial page size.
 * @param {number} [initialIndex=0] The initial page index.
 *
 * @returns {Object} The pagination state and change handler, and the current take and skip values (suited for a common pagination pattern in many APIs).
 */
export function usePagination(initialSize = 10, initialIndex = 0) {
    const [paginationState, setPaginationState] = useState({
        pageSize: initialSize,
        pageIndex: initialIndex,
    });
    const { pageSize, pageIndex } = paginationState;

    return {
        // table state
        pagination: {
            state: paginationState,
            onPaginationChange: setPaginationState,
        },
        // API
        take: pageSize,
        skip: pageSize * pageIndex,
    };
}

/**
 * Hook for managing sorting state according to the Table component.
 *
 * @template TField The type of field that the sorting is based on (cooresponds to the accessorKey of the column).
 *
 * @param {TField} initialField The initial field to sort by.
 * @param {"asc" | "desc"} [initialOrder="asc"] The initial order of the sorting.
 *
 * @returns {Object} The sorting state and change handler, and the current order and field.
 */
export function useSorting<TField extends string>(
    initialField: TField,
    initialOrder: "asc" | "desc" = "asc",
) {
    const [sortingState, setSorting] = useState([
        { id: initialField, desc: initialOrder === "desc" },
    ]);

    const order: "asc" | "desc" = !sortingState.length
        ? initialOrder
        : sortingState[0].desc
          ? "desc"
          : "asc";

    const sorting: SortingType = {
        state: sortingState,
        // @ts-ignore
        onSortingChange: setSorting,
    };
    return {
        sorting,
        order,
        field: sortingState.length ? sortingState[0].id : initialField,
    };
}
