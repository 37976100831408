import { ChevronDown, ChevronUp } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentType } from "../../../../../generated/client/graphql";
import "./i18n";

const InformationBlock = ({
    documentType,
}: {
    documentType: DocumentType | undefined;
}) => {
    const { t } = useTranslation("DocumentEditorSideBar");

    const [activeInfo, setActiveInfo] = useState<boolean>(false);

    return (
        <div className="flex flex-col items-start self-stretch rounded-lg">
            <div
                className="flex py-2.5 px-3 flex-col items-start self-stretch rounded-lg border"
                style={{
                    backgroundColor: "#F2FDFE",
                    borderColor: "#5E89AE",
                }}
            >
                <div className="flex flex-col items-starts gap-3 flex-1">
                    <div className="flex items-center self-stretch font-bold text-sm">
                        {(documentType === DocumentType.Policy ||
                            documentType === DocumentType.MasterPolicy) &&
                            t("policy_main_desc")}
                        {documentType === DocumentType.Procedure &&
                            t("procedure_main_desc")}
                    </div>

                    {activeInfo && (
                        <div className="flex flex-col gap-3">
                            <div className="flex items-center self-stretch text-sm">
                                {documentType === DocumentType.Policy ||
                                documentType === DocumentType.MasterPolicy
                                    ? t("policy_extended_intro")
                                    : t("procedure_extended_intro")}
                            </div>

                            <ul className="list-disc list-outside text-left flex flex-col gap-1">
                                <div
                                    className="ml-4"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            documentType ===
                                                DocumentType.Policy ||
                                            documentType ===
                                                DocumentType.MasterPolicy
                                                ? t("policy_extended_content", {
                                                      interpolation: {
                                                          escapeValue: false,
                                                      },
                                                  })
                                                : t(
                                                      "procedure_extended_content",
                                                      {
                                                          interpolation: {
                                                              escapeValue:
                                                                  false,
                                                          },
                                                      },
                                                  ),
                                    }}
                                />
                            </ul>
                            <div className="flex items-center self-stretch text-sm">
                                {t("conclusion")}
                            </div>
                        </div>
                    )}

                    <div
                        className="flex items-center gap-0.5 cursor-pointer"
                        onClick={() => setActiveInfo(!activeInfo)}
                    >
                        <div className="font-bold text-sm">
                            {activeInfo ? t("show_less") : t("show_more")}
                        </div>
                        {activeInfo ? (
                            <ChevronUp className="w-4" />
                        ) : (
                            <ChevronDown className="w-4" />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InformationBlock;
