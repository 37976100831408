import * as RadixSelect from "@radix-ui/react-select";
import { ComponentType, ReactNode } from "react";
import { cn } from "../../../lib/utils";

export default function DropdownItem({
    children,
    IconLeft,
    IconRight,
    className,
    ...props
}: {
    children: ReactNode;
    IconLeft?: ComponentType<Partial<{ className: string }>>;
    IconRight?: ComponentType<Partial<{ className: string }>>;
} & RadixSelect.SelectItemProps) {
    return (
        <RadixSelect.Item
            {...props}
            className={cn(
                `h-[32px] flex px-2 py-1.5 space-x-2.5 rounded-lg focus:outline-none focus:bg-green-50`,
                className
            )}
        >
            <div className="w-4 ">
                {IconLeft && (
                    <IconLeft className={cn("text-primary w-4 h-4")} />
                )}
            </div>
            <div className="font-small truncate">{children}</div>
            {IconRight && <IconRight className="text-primary w-4 h-4" />}
        </RadixSelect.Item>
    );
}
