import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "UpdateIndicatorModal", {
    title: "Update Indicator",
    name: "Name",
    description: "Description",
    save_button: "Save changes",
    set_options: 'Set options, seperated by a semicolon ";"',
});

i18next.addResourceBundle("fr", "UpdateIndicatorModal", {
    title: "Modifier l'indicateur",
    name: "Nom",
    description: "Description",
    save_button: "Sauvegarder les modifications",
    set_options: 'Définir les options, séparées par un point-virgule ";"',
});
