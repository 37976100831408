import i18next from "../../../../../i18n";

i18next.addResourceBundle("en", "SitesTable", {
    site: "Nom",
    shortName: "Nom court",
    type: "Type",
    edit_site: "Modifier",
    delete_site: "Supprimer",
});

i18next.addResourceBundle("fr", "SitesTable", {
    site: "Nom",
    shortName: "Nom court",
    type: "Type",
    edit_site: "Modifier",
    delete_site: "Supprimer",
});
