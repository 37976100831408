import { Breadcrumb } from "@app/shared/components/Breadcrumb";
import { currentUserAtom } from "@app/store/userStore";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { Icon } from "@design-system/Icons";
import { Flex } from "@design-system/Layout/Flex";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import NotificationCenter from "../Notifications/NotificationCenter";
import TopBarHelp from "./TopBarHelp";
import TopBarProfil from "./TopBarProfil";
import "./i18n";

export default function TopBar() {
    const { data } = useAtomValue(currentUserAtom);
    const { t } = useTranslation("TopBar");

    return (
        <Flex w="full" justifyContent="between" alignItems="center">
            <Flex className="flex-1">
                <Breadcrumb />
            </Flex>
            <div className="flex justify-end items-center space-x-4 flex-1">
                <TopBarHelp />
                <Tooltip>
                    <Tooltip.Trigger data-chameleon="launch-referral">
                        <Icon
                            name="party"
                            className="text-secondary hover:text-primary"
                        />
                    </Tooltip.Trigger>
                    <Tooltip.Content>{t("referral")}</Tooltip.Content>
                </Tooltip>
                <NotificationCenter />
                <TopBarProfil currentUser={data?.currentUser} />
            </div>
        </Flex>
    );
}
