import { Button } from "@design-system/Inputs/Button";
import { Flex } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    Document,
    DocumentType,
    DocumentVersion,
    DocumentVersionStatus,
    Requirement,
} from "../../../generated/client/graphql";
import {
    DropdownMenu,
    DropdownTrigger,
} from "../../components/Dropdown/components";
import DropdownContentLayout from "../../components/Dropdown/components/dropdowns/layout";
import FullFrameBodyLayout from "../../components/Layouts/FullFrame/BodyLayout";
import Loading from "../../components/Loading";
import { useCapturePageView } from "../../lib/tracking/utils";
import CreateVersionBlock from "./components/CreateVersionBlock";
import InfoBlock from "./components/InfoBlock";
import RequirementTable from "./components/RequirementTable";
import ResetWithConfirmation from "./components/ResetWithConfirmation";
import SetRelevanceButton from "./components/SetRelevanceButton";
import UpdateDocumentModal from "./components/UpdateDocumentModal";
import VersionBlock from "./components/VersionBlock";
import { useDocument } from "./hooks";

import { documentPaths } from "@app/routing/paths";
import { useBreadcrumb } from "@app/shared/components/Breadcrumb/useBreadcrumb";
import { useEffect } from "react";
import "./i18n";

const DocumentScreen = () => {
    const { t } = useTranslation("DocumentScreen");
    const setBreadcrumb = useBreadcrumb();

    const { document, getDocumentQuery } = useDocument();

    const type = document?.type;
    useCapturePageView(
        type === DocumentType.Policy
            ? "policies"
            : type === DocumentType.Procedure
              ? "measures"
              : type === DocumentType.CustomDocument
                ? "custom-docs"
                : undefined,
    );

    const lastVersion =
        document?.versions &&
        document?.versions.length > 0 &&
        document?.versions[0];

    const showCreate =
        getDocumentQuery.data &&
        (!lastVersion || lastVersion.status === DocumentVersionStatus.Approved);

    const navigate = useNavigate();
    const goBackToList = () => navigate("..");

    useEffect(() => {
        setBreadcrumb(documentPaths.document_id_param, document?.name ?? "...");
    }, [document?.name]);

    if (!getDocumentQuery.data) {
        return <Loading size="large" />;
    } else {
        return (
            <div className="flex-grow flex flex-col">
                <FullFrameBodyLayout>
                    <Flex
                        as="header"
                        justifyContent="between"
                        gap="4"
                        mb="1"
                        w="full"
                    >
                        <Button
                            variant="tonal"
                            size="sm"
                            onClick={goBackToList}
                        >
                            <Button.Icon name="arrowLeft" />{" "}
                            {t("previous_page")}
                        </Button>

                        <Text variant="header2" as="h3">
                            {document?.name ?? ""}
                        </Text>

                        <DropdownMenu modal={false}>
                            <DropdownTrigger asChild>
                                <Button variant="tonal" size="sm">
                                    {t("more")}
                                    <Button.Icon name="angleDown" />
                                </Button>
                            </DropdownTrigger>
                            <DropdownContentLayout align="end">
                                {document && (
                                    <ResetWithConfirmation
                                        documentId={document?.id}
                                    />
                                )}
                                {getDocumentQuery.data && (
                                    <SetRelevanceButton
                                        getDocumentQuery={getDocumentQuery}
                                    />
                                )}
                                {getDocumentQuery.data && (
                                    <UpdateDocumentModal
                                        document={
                                            getDocumentQuery.data
                                                .documentByUrl as Document
                                        }
                                        refetch={getDocumentQuery.refetch}
                                    />
                                )}
                            </DropdownContentLayout>
                        </DropdownMenu>
                    </Flex>
                    <div className="space-y-5">
                        {showCreate && (
                            <CreateVersionBlock
                                getDocumentQuery={getDocumentQuery}
                                lastVersion={lastVersion as DocumentVersion}
                            />
                        )}
                        {document?.versions.map((version, idx) => (
                            <VersionBlock
                                key={version.id}
                                version={version as DocumentVersion}
                                getDocumentQuery={getDocumentQuery}
                                isLatest={idx === 0}
                            />
                        ))}
                    </div>

                    <InfoBlock Document={document as Document} />
                    {document?.requirements && (
                        <RequirementTable
                            showUpdateReqsModal={
                                document?.type === DocumentType.CustomDocument
                            }
                            documentId={document.id}
                            requirements={
                                document?.requirements as Requirement[]
                            }
                        />
                    )}
                </FullFrameBodyLayout>
            </div>
        );
    }
};

export default DocumentScreen;
