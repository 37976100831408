import { Requirement, RequirementStatus } from "@generated/client/graphql";

// This is where we would put some business logic that is related to requirements
export const filterAndSortRequirements = (
    requirements: Requirement[] | undefined,
) => {
    // For better reusability, template typing could be used so that the function can be used for any type of object that has the minimal filter requirements
    return requirements
        ?.sort((r1, r2) => r1.order - r2.order)
        ?.filter((req) => req.proofs && req.proofs.length > 0) as
        | Requirement[]
        | undefined;
};

export const computeStats = (requirements: Requirement[] | undefined) => {
    if (!requirements) {
        return {
            total: 0,
            assigned: 0,
            unassigned: 0,
            ok: 0,
            notOk: 0,
        };
    }
    const uniqueIds = new Set(requirements.map((r) => r.id));
    const uniqueRequirements = requirements.filter((r) => {
        if (uniqueIds.has(r.id)) {
            uniqueIds.delete(r.id);
            return true;
        } else {
            return false;
        }
    });
    const total = uniqueRequirements.length;
    const assigned = uniqueRequirements.filter((r) => r.ownerId).length;
    const ok = uniqueRequirements.filter(
        (r) => r.status === RequirementStatus.Ok,
    ).length;
    return {
        total: total,
        assigned,
        unassigned: (total || 0) - (assigned || 0),
        ok,
        notOk: total - ok,
    };
};
