import { type FC } from "react";

import { FlexRow } from "@design-system/Layout/Flex";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import { EnrichedCsrdDatapoint } from "../../types";

type DatapointsCompletionCardProps = {
    datapoint: EnrichedCsrdDatapoint;
};

export const DatapointValueCell: FC<DatapointsCompletionCardProps> = ({
    datapoint,
}) => {
    return (
        <FlexRow
            px="2"
            py="1"
            justifyContent="start"
            alignItems="start"
            w="full"
        >
            {datapoint?.info.isNotMaterial ? (
                <Text variant="body-sm" className="text-neutral-500">
                    Not material
                </Text>
            ) : datapoint?.info.hasNoData ? (
                <Text variant="body-sm" className="text-neutral-500">
                    No data
                </Text>
            ) : datapoint?.info.isNarrative ? (
                <Ellipsis asChild hasTooltip>
                    <Text variant="body-sm">{datapoint?.comment}</Text>
                </Ellipsis>
            ) : datapoint?.info.isSeminarNarrative ? (
                <Ellipsis asChild hasTooltip>
                    <Text variant="body-sm">
                        {!datapoint?.info.hasBooleanValue
                            ? ""
                            : datapoint?.value?.boolean
                              ? "Yes"
                              : "No"}
                    </Text>
                </Ellipsis>
            ) : datapoint?.info.hasNumberValue ? (
                <FlexRow w="full" justifyContent="start" gap="2">
                    <Text variant="body-sm-bold">
                        {datapoint?.value?.number}
                    </Text>
                    <Text color="secondary" variant="body-sm">
                        {datapoint?.unit?.shortName}
                    </Text>
                </FlexRow>
            ) : (
                <Ellipsis asChild hasTooltip>
                    <Text variant="body-sm">{datapoint?.value?.string}</Text>
                </Ellipsis>
            )}
        </FlexRow>
    );
};
