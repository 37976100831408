// Create a hook to seperate out logic.

import { useAtomValue } from "jotai";
import { useCallback, useEffect, useRef, useState } from "react";
import { GetHubspotTokenDocument } from "../../../generated/client/graphql";
import { useGraphQL } from "../../QueryClientWithHeaders";
import { bearerTokenIsSetAtom } from "@app/store/authStore";

declare global {
    interface Window {
        hsConversationsOnReady: any;
        HubSpotConversations: any;
        hsConversationsSettings: any;
    }
}

const BEAVR_PORTAL_ID = import.meta.env.VITE_REACT_APP_HUBSPOT_PORTAL_ID;

const useHubspotChat = (userId?: string) => {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [activeConversation, setActiveConversation] = useState<string>();
    const bearerTokenIsSet = useAtomValue(bearerTokenIsSetAtom);

    const { data } = useGraphQL(
        GetHubspotTokenDocument,
        {
            userId: userId,
        },
        { enabled: bearerTokenIsSet || userId !== undefined },
        true
    );

    const eventRef = useRef<any>(null);

    useEffect(() => {
        // Add event listener.
        window.hsConversationsSettings = {
            loadImmediately: false,
        };
        window.hsConversationsOnReady = [
            () => {
                setHasLoaded(true);
            },
        ];

        // Create script component.
        let script = document.createElement("script");
        script.src = `//js.hs-scripts.com/${BEAVR_PORTAL_ID}.js`;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
            window.hsConversationsOnReady = [];
        };
    }, []);

    // Subscripe to conversation events.
    useEffect(() => {
        if (hasLoaded && eventRef.current) {
            eventRef.current = (payload: {
                conversation: { conversationId: string };
            }) => {
                setActiveConversation(payload.conversation.conversationId);
            };

            if (hasLoaded) {
                window.HubSpotConversations.on(
                    "conversationStarted",
                    eventRef.current
                );
            }

            return () => {
                window.HubSpotConversations.off(
                    "conversationStarted",
                    eventRef.current
                );
            };
        }
    }, [hasLoaded]);

    const openHandler = useCallback(() => {
        if (hasLoaded) {
            window.HubSpotConversations.widget.open();
        }
    }, [hasLoaded]);

    const closeHandler = useCallback(() => {
        if (hasLoaded) {
            window.HubSpotConversations.widget.close();
        }
    }, [hasLoaded]);

    useEffect(() => {
        if (hasLoaded && data && data.hubspotToken) {
            window.hsConversationsSettings = {
                identificationEmail: data.hubspotToken.email,
                identificationToken: data.hubspotToken.token,
            };
            window.HubSpotConversations.widget.load();
        } else if (hasLoaded && data === null) {
            window.HubSpotConversations.widget.load();
        }
    }, [data, hasLoaded]);

    return {
        hasLoaded,
        activeConversation,
        openHandler,
        closeHandler,
    };
};

export default useHubspotChat;
