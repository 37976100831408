import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { useState } from "react";
import { SubTheme } from "../../../../../generated/client/graphql";
import CheckboxItem from "../../components/dropdowns/items/CheckboxItem";
import DropdownItemSearch from "../../components/dropdowns/items/Search";
import DropdownContentLayout from "../../components/dropdowns/layout";

const SubthemeDropdown = ({
    subthemeIds,
    onSubthemeIdsChange,
    subthemes,
    ...props
}: {
    subthemeIds?: string[] | undefined;
    onSubthemeIdsChange: (themeIds: string[]) => void;
    subthemes: SubTheme[] | undefined;
} & DropdownPrimitive.DropdownMenuContentProps) => {
    const [searchFilterString, setSearchFilterString] = useState("");

    const onToggle = (id: string) => {
        if (subthemeIds?.includes(id)) {
            const newValues = [...subthemeIds];
            newValues.splice(subthemeIds.indexOf(id), 1);
            onSubthemeIdsChange(newValues);
        } else {
            onSubthemeIdsChange([...(subthemeIds || []), id]);
        }
    };
    return (
        <DropdownContentLayout {...props}>
            <DropdownItemSearch
                searchFilterString={searchFilterString}
                setSearchFilterString={setSearchFilterString}
            />

            {subthemes
                ?.filter(
                    ({ name }) =>
                        !searchFilterString ||
                        (name &&
                            name
                                .toLowerCase()
                                .includes(searchFilterString.toLowerCase()))
                )
                .map(({ id, name }) => (
                    <CheckboxItem
                        key={id}
                        onSelect={(e) => {
                            e.preventDefault();
                        }}
                        checked={subthemeIds?.includes(id)}
                        onCheckedChange={() => onToggle(id)}
                    >
                        {name}
                    </CheckboxItem>
                ))}
        </DropdownContentLayout>
    );
};

export default SubthemeDropdown;
