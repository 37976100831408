import chroma from "chroma-js";

import { cn } from "../../lib/utils";
import NumberBadge from "./NumberBadge";

interface IProps {
    name: string;
    selected?: boolean;
    value?: number;
    bgColor?: chroma.Color;
}

const Chip = ({
    name,
    selected,
    value,
    bgColor,
    ...props
}: IProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    return (
        <button
            {...props}
            className={cn(
                "w-fit flex items-center px-3 py-1.5 rounded-full space-x-1.5",
                selected ? "bg-green" : "bg-tertiary hover:bg-quarternary"
            )}
            style={selected ? { backgroundColor: bgColor?.css() } : {}}
        >
            <div
                className={cn(
                    "text-sm font-regular whitespace-nowrap",
                    selected ? "text-white" : "text-primary"
                )}
            >
                {name}
            </div>
            {value && (
                <NumberBadge
                    value={value}
                    selected={selected}
                    color={bgColor?.darken(0.4)}
                />
            )}
        </button>
    );
};

export default Chip;
