import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Sidebar } from "@design-system/Navigation/SideBar";
import { cn } from "@design-system/Utilities";
import { Framework, FrameworkGroup } from "@generated/client/graphql";
import { useMouse, useWindowScroll } from "@uidotdev/usehooks";
import { useAtomValue, useSetAtom } from "jotai";
import { atomWithHash } from "jotai-location";
import { useEffect } from "react";

export const activeSubgroupAtom = atomWithHash<string | undefined>(
    "subgroup",
    undefined,
);

export const useNavigate = () => {
    const setActiveSubgroup = useSetAtom(activeSubgroupAtom);

    const [{ x, y }] = useWindowScroll();

    const [mouse] = useMouse();
    const scrollToSubGroup = (anchor: string) => {
        document.getElementById(anchor)?.scrollIntoView({ behavior: "smooth" });
        setActiveSubgroup(anchor);
    };
    const scrollToFirstSubgroup = (group: FrameworkGroup) => {
        if (!!group.subgroups?.length) {
            scrollToSubGroup(group.subgroups[0].name);
        }
    };
    const handleScroll = () => {
        const element = document.elementFromPoint(mouse.x, y || mouse.y);
        const anchorId = element
            ?.closest("[data-anchorid]")
            ?.getAttribute("data-anchorid");

        if (anchorId) {
            setActiveSubgroup(anchorId);
        }
    };
    useEffect(() => {
        handleScroll();
    }, [mouse.x, mouse.y, x, y]);

    return { scrollToSubGroup, scrollToFirstSubgroup, handleScroll };
};

const Navigation = ({
    framework,
    isLoading,
}: {
    framework?: Framework;
    isLoading?: boolean;
}) => {
    const { scrollToSubGroup, scrollToFirstSubgroup } = useNavigate();
    const activeGroup = useAtomValue(activeSubgroupAtom);
    const isLastGroup = (idx: number, subgroups: FrameworkGroup[]) => {
        return !subgroups || idx === subgroups.length - 1;
    };

    return (
        <Sidebar
            selectedId={activeGroup}
            onSelectedIdChange={scrollToSubGroup}
            className="w-[232px]"
            isLoading={isLoading}
        >
            <Sidebar.Content>
                {framework?.groups?.map((group, idx) => (
                    <Sidebar.Group>
                        <FlexCol w="full">
                            <Sidebar.GroupTitle
                                key={idx}
                                title={`${idx + 1}. ${group.name}`}
                                onClick={() => scrollToFirstSubgroup(group)}
                            />
                            {group.subgroups?.map((subgroup, idx2) => (
                                <Sidebar.Item key={idx2} id={subgroup.name}>
                                    {({ isSelected }) => (
                                        <FlexRow
                                            p="2"
                                            alignItems="center"
                                            w="full"
                                            className={cn(
                                                "rounded-lg",
                                                isSelected &&
                                                    "bg-tertiary text-green font-bold",
                                            )}
                                        >
                                            <Sidebar.ItemLabel
                                                title={subgroup.name}
                                            />
                                        </FlexRow>
                                    )}
                                </Sidebar.Item>
                            ))}
                        </FlexCol>
                        {!isLastGroup(idx, framework?.groups || []) && (
                            <Sidebar.Divider />
                        )}
                    </Sidebar.Group>
                ))}
            </Sidebar.Content>
        </Sidebar>
    );
};

export default Navigation;
