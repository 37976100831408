import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { RequirementStatus } from "../../../../../generated/client/graphql";

export type FrameworkPageFilterState = {
    searchFilter: string | undefined;
    ownerIdsFilter: (string | null)[] | undefined;
    statusFilter: RequirementStatus[] | undefined;
};

const defaultState: FrameworkPageFilterState = {
    searchFilter: undefined,
    statusFilter: undefined,
    ownerIdsFilter: undefined,
};
export const FrameworkPageFilterStateAtom =
    atomWithStorage<FrameworkPageFilterState>(
        "FrameworksPageFilterStateAtom",
        defaultState,
    );

export const useFrameworkPageState = () => {
    const [state, setState] = useAtom(FrameworkPageFilterStateAtom);
    const setPartialState = (
        partialState: Partial<FrameworkPageFilterState>,
    ) => {
        setState((state) => {
            const newState = { ...state, ...partialState };
            return newState;
        });
    };

    const resetDefaultState = () => setState(defaultState);

    return { state, setState: setPartialState, resetDefaultState };
};
