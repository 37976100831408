import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as BeavrLogo } from "../../../../assets/beavr-h10.svg";
import i18next from "../../../../i18n";
import ChooseLanguage from "../lang";

interface TitleBarProps {
    logoSrc?: string;
    campaignTitle?: string;
    children: React.ReactNode;
    submitButton: React.ReactNode;
}
i18next.addResourceBundle("en", "TitleBar", {
    powered_by: "Powered by",
});
i18next.addResourceBundle("fr", "TitleBar", {
    powered_by: "Propulsé par",
});

const TitleBar = ({
    logoSrc,
    campaignTitle,
    children,
    submitButton,
}: TitleBarProps) => {
    const { t } = useTranslation("TitleBar");

    return (
        <div className="flex items-center p-4 pl-[40px] w-screen">
            <div className="w-1/6 flex space-x-[20px] items-center flex-wrap">
                {logoSrc && <img className="max-h-[44px]" src={logoSrc} />}
                <div className=" text-right">
                    <div className="font-bold">{campaignTitle}</div>
                    <div className="space-x-1 flex items-center">
                        <div className=" text-sm whitespace-nowrap">
                            {t("powered_by")}
                        </div>
                        <BeavrLogo className="h-4 text-green" />
                    </div>
                </div>
            </div>
            <div className="relative w-2/3 ">
                <div className="absolute right-0 h-[32px] w-2 bg-gradient-radial from-black/10 to-transparent " />
                <div className="flex justify-left overflow-scroll space-x-3 scrollbar-hide">
                    {children}
                </div>
            </div>
            <div className="w-1/6 flex justify-end space-x-7 items-center">
                <ChooseLanguage />
                {submitButton}
            </div>
        </div>
    );
};

export default TitleBar;
