import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
    DocumentVersion,
    SaveTemplateDocument,
} from "../../../../../generated/client/graphql";
import { ButtonType } from "../../../../components/Button/ButtonShared";
import ButtonSmall from "../../../../components/Button/ButtonSmall";

import { ClockRotateRight, MoreHoriz } from "iconoir-react";
import { Copy, Download } from "lucide-react";
import { useGraphQLMutation } from "../../../../QueryClientWithHeaders";
import { DropdownTrigger } from "../../../../components/Dropdown/components";
import BaseItem from "../../../../components/Dropdown/components/dropdowns/items/BaseItem";
import DropdownContentLayout from "../../../../components/Dropdown/components/dropdowns/layout";
import HeaderLayout from "../../../../components/Layouts/FullFrame/HeaderLayout";
import { usePDFGenerator, useSaveDocumentData } from "../hooks";
import { EditorMode, useEditorContext } from "../hooks/useEditor";

export const TEMPLATE_FR_UNAME = "template_fr";
export const TEMPLATE_EN_UNAME = "template_en";

const Header = () => {
    const { t } = useTranslation("EditDocument");
    const { saveDocumentData } = useSaveDocumentData();
    const navigate = useNavigate();
    const { version, editor, editorMode, setEditorMode } = useEditorContext();
    const { generatePdf } = usePDFGenerator(version as DocumentVersion);

    const { org_uname } = useParams();
    const displaySaveTemplate =
        org_uname === TEMPLATE_FR_UNAME || org_uname === TEMPLATE_EN_UNAME;
    const { mutate: saveTemplate } = useGraphQLMutation(
        SaveTemplateDocument,
        undefined,
        true,
    );

    const handleSaveAndCloseClick = () => {
        saveDocumentData();
        navigate("../../");
    };

    const handleSetHistoryMode = () => {
        saveDocumentData();
        if (editor) editor.setOptions({ editable: false });
        setEditorMode(EditorMode.HISTORY);
    };
    const handleSetRegularMode = () => {
        setEditorMode(EditorMode.REGULAR);
        if (!editor) return;
        editor.setOptions({ editable: true });
        version?.checkpoints &&
            version?.checkpoints?.length > 0 &&
            editor.commands.setContent(version?.checkpoints[0]?.data || "{}");
    };

    return (
        <HeaderLayout title={version?.document?.name || ""}>
            {editorMode !== EditorMode.HISTORY ? (
                <div className="flex gap-3">
                    <ButtonSmall
                        onClick={handleSaveAndCloseClick}
                        variant={ButtonType.DEFAULT}
                    >
                        {t("save_and_close")}
                    </ButtonSmall>

                    {version?.withEditor && (
                        <DropdownMenu modal={false}>
                            <DropdownTrigger asChild>
                                <ButtonSmall
                                    variant={ButtonType.OUTLINED}
                                    IconLeft={MoreHoriz}
                                />
                            </DropdownTrigger>
                            <DropdownContentLayout
                                align="end"
                                className="w-full"
                            >
                                <BaseItem
                                    onClick={handleSetHistoryMode}
                                    IconLeft={ClockRotateRight}
                                >
                                    {t("view_history")}
                                </BaseItem>
                                <BaseItem
                                    onClick={generatePdf}
                                    IconLeft={Download}
                                >
                                    {t("pdf_generate")}
                                </BaseItem>
                                {displaySaveTemplate && (
                                    <BaseItem
                                        className="w-full"
                                        onClick={() =>
                                            version?.document?.cmsDocumentId &&
                                            saveTemplate({
                                                cmsId: version?.document
                                                    ?.cmsDocumentId,
                                            })
                                        }
                                        IconLeft={Copy}
                                    >
                                        {t("save_template_to_cms")}
                                    </BaseItem>
                                )}
                            </DropdownContentLayout>
                        </DropdownMenu>
                    )}
                </div>
            ) : (
                <div className="flex gap-3">
                    <ButtonSmall
                        onClick={handleSetRegularMode}
                        variant={ButtonType.TONAL}
                    >
                        {t("close_historic_mode")}
                    </ButtonSmall>
                </div>
            )}
        </HeaderLayout>
    );
};

export default Header;
