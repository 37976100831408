import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import React, { useState } from "react";
import MultiSelectLayout from "../components/dropdowns/MultiSelectLayout";
import "./i18n";

interface MultiSelectProps {
    activeValueIds: (string | null)[] | undefined;
    onActiveValueIdsChange: (v: (string | null)[] | undefined) => void;
    values:
        | {
              id: string | null;
              name?: string;
              node?: React.ReactNode;
          }[]
        | undefined;
    triggerButton: React.ReactNode;
    preItems?: React.ReactNode;
    disableSearch?: boolean;
}
const MultiSelect = ({
    activeValueIds,
    values,
    onActiveValueIdsChange,
    triggerButton,
    preItems,
    disableSearch,
}: MultiSelectProps) => {
    const [open, setOpen] = useState(false);

    return (
        <DropdownPrimitive.Root open={open} onOpenChange={setOpen}>
            <DropdownPrimitive.Trigger asChild>
                {triggerButton}
            </DropdownPrimitive.Trigger>

            <MultiSelectLayout
                activeValueIds={activeValueIds}
                onActiveValueIdsChange={onActiveValueIdsChange}
                values={values}
                preItems={preItems}
                disableSearch={disableSearch}
            />
        </DropdownPrimitive.Root>
    );
};

export default MultiSelect;
