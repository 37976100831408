import i18next from "../../i18n";

i18next.addResourceBundle("en", "SaveBar", {
    buttonSaveTitle: "Save",
    message: "Warning, there are still unsaved changes !",
});

i18next.addResourceBundle("fr", "SaveBar", {
    buttonSaveTitle: "Sauvegarder",
    message: "Attention, sauvegardez vos modifications!",
});
