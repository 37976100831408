import * as Dialog from "@radix-ui/react-dialog";

interface RightModalProps {
    children: React.ReactNode;
}

export const RightModal = Dialog.Root;
export const RightModalClose = Dialog.Close;

export function RightModalContent({ children }: RightModalProps) {
    return (
        <Dialog.Portal>
            <Dialog.Overlay className=" bg-neutral-500/5 fixed inset-0 data-[state='open']:animate-[fadeIn_0.3s_ease-in-out] data-[state='closed']:animate-[fadeOut_0.3s_ease-in-out]" />
            <Dialog.Content
                className="w-[580px] fixed right-0 top-0 h-screen bg-white border-l overflow-y-scroll

                data-[state='open']:animate-[dialogIn_0.3s_ease-in-out] data-[state='closed']:animate-[dialogOut_0.3s_ease-in-out]"
            >
                {children}
            </Dialog.Content>
        </Dialog.Portal>
    );
}
