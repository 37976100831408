import { NavArrowDown } from "iconoir-react";
import React, { useRef } from "react";
import { Tooltip } from "react-tooltip";
import { cn } from "../../lib/utils";
interface TextWithTooltipProps {
    children: React.ReactNode;
}
const TextWithTooltip = ({
    children,
    className,
    ...props
}: TextWithTooltipProps & React.HTMLAttributes<HTMLDivElement>) => {
    const [overflows, setOverflows] = React.useState(false);
    const ref = useRef<HTMLSpanElement>(null);

    React.useLayoutEffect(() => {
        if (!ref.current) return;
        if (
            ref.current.clientHeight < ref.current.scrollHeight ||
            ref.current.clientWidth < ref.current.scrollWidth
        ) {
            setOverflows(true);
        } else {
            setOverflows(false);
        }
    }, [ref]);
    return (
        <>
            <div
                className={cn("flex items-center space-x-2", className)}
                {...props}
            >
                <span className="truncate" ref={ref}>
                    {children}
                </span>
                {overflows && (
                    <NavArrowDown className="text-secondary w-4 h-4 shrink-0" />
                )}
            </div>
            {overflows && (
                <Tooltip
                    anchorSelect={`#${props.id}`}
                    place="bottom"
                    clickable={true}
                    delayShow={200}
                >
                    {children}
                </Tooltip>
            )}
        </>
    );
};

export default TextWithTooltip;
