import { useMemo, type FC } from "react";
import { DocumentVersionStatus } from "../../../../../generated/client/graphql";
import { ReactComponent as EditorApproved } from "../../../../assets/illustrations/version-editor-approved.svg";
import { ReactComponent as EditorDraft } from "../../../../assets/illustrations/version-editor-draft.svg";
import { ReactComponent as ExcelApproved } from "../../../../assets/illustrations/version-excel-approved.svg";
import { ReactComponent as ExcelDraft } from "../../../../assets/illustrations/version-excel-draft.svg";
import { ReactComponent as PdfApproved } from "../../../../assets/illustrations/version-pdf-approved.svg";
import { ReactComponent as PdfDraft } from "../../../../assets/illustrations/version-pdf-draft.svg";
import { ReactComponent as PptApproved } from "../../../../assets/illustrations/version-ppt-approved.svg";
import { ReactComponent as PptDraft } from "../../../../assets/illustrations/version-ppt-draft.svg";
import { ReactComponent as UnknownApproved } from "../../../../assets/illustrations/version-unknown-approved.svg";
import { ReactComponent as UnknownDraft } from "../../../../assets/illustrations/version-unknown-draft.svg";
import {
    isExcelLikeFile,
    isPdfFile,
    isPowerPointLikeFile,
} from "@app/usecases/VersionUseCases/utils";

type VersionBlockIllustrationProps = {
    extension?: string | null;
    status: DocumentVersionStatus;
};

export const VersionBlockIllustration: FC<VersionBlockIllustrationProps> = ({
    extension,
    status,
}) => {
    const Illustration = useMemo(() => {
        const isApproved = status === DocumentVersionStatus.Approved;

        // no extension means we have a document from the editor
        if (extension == null) {
            return isApproved ? EditorApproved : EditorDraft;
        }

        if (isExcelLikeFile(extension)) {
            return isApproved ? ExcelApproved : ExcelDraft;
        }

        if (isPdfFile(extension)) {
            return isApproved ? PdfApproved : PdfDraft;
        }

        if (isPowerPointLikeFile(extension)) {
            return isApproved ? PptApproved : PptDraft;
        }

        // all other files are considered unknown
        return isApproved ? UnknownApproved : UnknownDraft;
    }, [extension, status]);
    return <Illustration />;
};
