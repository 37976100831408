import {
    createCustomDocumentMutationAtom,
    documentsForSearchAtom,
} from "@app/store/documentStore";
import {
    bulkS3FileUploadToS3MutationAtom,
    createOrUpdateManyVersionsFromBulkS3FilesUploadMutationAtom,
    deleteS3FileMutationAtom,
} from "@app/store/versionStore";
import { type S3FileFromBulkUpload } from "@app/usecases/VersionUseCases";
import { useAtomValue } from "jotai";
import { useUploadedFiles } from "./DocumentsUploadModalContext";

export function useModalActions() {
    const ctx = useUploadedFiles();

    const { uploadedFiles, setUploadedFiles } = ctx;
    const bulkS3FileUploadToS3Mutation = useAtomValue(
        bulkS3FileUploadToS3MutationAtom,
    );
    const createOrUpdateManyVersionsMutation = useAtomValue(
        createOrUpdateManyVersionsFromBulkS3FilesUploadMutationAtom,
    );
    const deleteS3FileMutation = useAtomValue(deleteS3FileMutationAtom);
    const createCustomDocumentMutation = useAtomValue(
        createCustomDocumentMutationAtom,
    );

    const handleDropFiles = async (files: File[] | File) => {
        if (!files || bulkS3FileUploadToS3Mutation.isPending) return;
        const res = await bulkS3FileUploadToS3Mutation.mutateAsync({
            files: Array.isArray(files) ? files : [files],
        });

        setUploadedFiles((prev) => ({
            list: [...prev.list, ...res.list],
            byId: { ...prev.byId, ...res.byId },
        }));
    };

    const associateFileToDocuments = (
        fileId: string,
        documents: NonNullable<
            NonNullable<S3FileFromBulkUpload["associatedDocuments"]>[number]
        >[],
    ) => {
        setUploadedFiles((prevUploadedFiles) => {
            const updatedFile = {
                ...prevUploadedFiles.byId[fileId],
                associatedDocuments: documents,
            };

            const byId = { ...prevUploadedFiles.byId, [fileId]: updatedFile };
            const list = prevUploadedFiles.list.map((file) =>
                file.id === fileId ? updatedFile : file,
            );

            return {
                byId,
                list,
            };
        });
    };

    const dissociateFileFromDocuments = (fileId: string) => {
        setUploadedFiles((prevUploadedFiles) => {
            const updatedFile = {
                ...prevUploadedFiles.byId[fileId],
                associatedDocuments: [],
            };

            const byId = { ...prevUploadedFiles.byId, [fileId]: updatedFile };
            const list = prevUploadedFiles.list.map((file) =>
                file.id === fileId ? updatedFile : file,
            );

            return {
                byId,
                list,
            };
        });
    };

    const { data } = useAtomValue(documentsForSearchAtom);
    const documentsForSearch = data?.documents ?? [];

    const createCustomDocument = (name: string) => {
        if (createCustomDocumentMutation.isPending) return;

        return createCustomDocumentMutation.mutateAsync({ name });
    };

    const handleCreateVersions = async () => {
        // Call mutation
        await createOrUpdateManyVersionsMutation.mutateAsync(
            uploadedFiles.list,
        );

        // Remove files that have been associated with documents
        const updatedUploadedFilesList = uploadedFiles.list.filter(
            (file) => !file.associatedDocuments?.length,
        );
        setUploadedFiles({
            list: updatedUploadedFilesList,
            byId: updatedUploadedFilesList.reduce(
                (acc, file) => {
                    acc[file.id] = file;
                    return acc;
                },
                {} as Record<string, S3FileFromBulkUpload>,
            ),
        });
    };

    const deleteFile = (id: string) => {
        // Remove from state
        const list = uploadedFiles.list.filter((file) => file.id !== id);
        const byId = list.reduce(
            (acc, file) => {
                acc[file.id] = file;
                return acc;
            },
            {} as Record<string, S3FileFromBulkUpload>,
        );
        setUploadedFiles({ list, byId });

        // remove from DB ans S3
        deleteS3FileMutation.mutate(id);
    };
    return {
        associateFileToDocuments,
        createCustomDocument,
        documentsForSearch,
        handleDropFiles,
        deleteFile,
        handleCreateVersions,
        isCreateVersionLoading: createOrUpdateManyVersionsMutation.isPending,
        isCreateCustomLoading: createCustomDocumentMutation.isPending,
        isUploading: bulkS3FileUploadToS3Mutation.isPending,
        dissociateFileFromDocuments,
        uploadedFiles,
    };
}
