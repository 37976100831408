import { Box } from "@design-system/Layout/Box";

import { Text } from "@design-system/Typography/Text";
import { FC } from "react";
import { ReactComponent as SwitchOff } from "./switch-off.svg";
import { ReactComponent as SwitchOnDefault } from "./switch-on-default.svg";
import { ReactComponent as SwitchOnHelper } from "./switch-on-helper.svg";

type SwitchProps = {
    checked: boolean;
    onChange: (checked: boolean) => void;
    type?: "default" | "helper";
    label?: string;
    labelPosition?: "left" | "right";
};

export const Switch: FC<SwitchProps> = ({
    checked,
    onChange,
    type,
    label,
    labelPosition,
}) => {
    const handleClick = () => {
        onChange(!checked);
    };
    return (
        <Box
            alignItems="center"
            display="flex"
            gap="2"
            direction={labelPosition === "right" ? "row-reverse" : "row"}
        >
            <Text whitespace="nowrap">{label}</Text>
            <button onClick={handleClick}>
                {checked ? (
                    type === "helper" ? (
                        <SwitchOnHelper />
                    ) : (
                        <SwitchOnDefault />
                    )
                ) : (
                    <SwitchOff />
                )}
            </button>
        </Box>
    );
};
