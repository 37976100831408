import { Box, BoxProps } from "@design-system/Layout/Box";
import { FlexCol } from "@design-system/Layout/Flex";
import { cn } from "@design-system/Utilities";

import React, {
    forwardRef,
    ForwardRefExoticComponent,
    PropsWithChildren,
} from "react";

const Block = forwardRef<
    HTMLDivElement,
    PropsWithChildren<{ className?: string }>
>((props, ref) => {
    return <FlexCol px="4" py="3" gap="3" {...props} ref={ref} />;
});

const BlockHeader = Box;

const BlockBody: typeof Box = forwardRef<HTMLDivElement, BoxProps>(
    ({ className, ...props }, ref) => {
        return (
            <Box
                h="fit"
                className={cn(
                    "bg-white rounded border border-secondary",
                    className,
                )}
                ref={ref}
                {...props}
            />
        );
    },
);

type SectionProps = PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>;
type SectionComponent = ForwardRefExoticComponent<SectionProps> & {
    Block: typeof Block;
    BlockHeader: typeof BlockHeader;
    BlockBody: typeof BlockBody;
};

export const Section: SectionComponent = forwardRef<
    HTMLDivElement,
    SectionProps
>(({ className, children }, ref) => {
    return (
        <FlexCol
            className={cn(
                "border border-tertiary bg-tertiary rounded-lg box-border overflow-hidden",
                className,
            )}
            ref={ref}
        >
            {children}
        </FlexCol>
    );
}) as SectionComponent;

Section.Block = Block;
Section.BlockHeader = BlockHeader;
Section.BlockBody = BlockBody;

export default Section;
