import i18next from "@app/i18n";

const enResources = {
    completion_card_notStarted: "{{ count }} not  started",
    completion_card_inProgress: "{{ count }} in progress",
    completion_card_validated: "{{ count }} validated",
    completion_card_notMaterial: "{{ count }} not material",

    tab_overview: "Overview",
    tab_datapoints: "Datapoints",

    btn_export_documents: "Export documents",
    search_datapoint: "Search datapoint",

    select_all: "All",

    table_col_header_description: "Datapoint",
    table_col_header_value: "Value",
    table_col_header_esrs: "ESRS",
    table_col_header_disclosure_requirement: "Disclosure Requirement",
    table_col_header_status: "Status",

    datapoint_status: "Status",
    datapoint_status_not_material: "Not Material",
    datapoint_status_not_started: "Not Started",
    datapoint_status_in_progress: "In Progress",
    datapoint_status_validated: "Validated",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    completion_card_notStarted: "{{ count }} non commencés",
    completion_card_inProgress: "{{ count }} en cours",
    completion_card_validated: "{{ count }} validés",
    completion_card_notMaterial: "{{ count }} non matériel",

    tab_overview: "Aperçu",
    tab_datapoints: "Données",

    btn_export_documents: "Exporter les documents",
    search_datapoint: "Rechercher des données",

    select_all: "Tous",

    table_col_header_description: "Datapoint",
    table_col_header_value: "Valeur",
    table_col_header_disclosure_requirement: "Disclosure Requirement",
    table_col_header_esrs: "ESRS",
    table_col_header_status: "Statut",

    datapoint_status: "Statut",
    datapoint_status_not_material: "Non matériel",
    datapoint_status_not_started: "Non commencé",
    datapoint_status_in_progress: "En cours",
    datapoint_status_validated: "Validaté",
} as const;

// hack to use have some sort of typesafty outside this file
// needs further digging into i18next typing
export const keys = Object.fromEntries(
    Object.keys(enResources).map((k) => [k, k]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "CsrdGeneral";

i18next.addResources("en", RESOURCE_NAME, enResources);
i18next.addResources("fr", RESOURCE_NAME, frResources);
