// Centralized Modal component

import * as DialogPrimitive from "@radix-ui/react-dialog";

import { Cancel } from "iconoir-react";
import React from "react";
import { cn } from "../../../lib/utils";
import ButtonLarge from "../../Button/ButtonLarge";
import ButtonRegular from "../../Button/ButtonRegular";
import { ButtonType } from "../../Button/ButtonShared";

import { useTranslation } from "react-i18next";
import i18next from "../../../i18n";

i18next.addResourceBundle("en", "CenterModal", {
    cancel: "Cancel",
});

i18next.addResourceBundle("fr", "CenterModal", {
    cancel: "Annuler",
});

interface CenterModalContentProps {
    title: React.ReactNode;
    subtitle?: string;
    actions?: React.ReactNode;
    children: React.ReactNode;
    contentClassName?: string;
}
export const CenterModalContent = React.forwardRef(
    (
        {
            title,
            subtitle,
            children,
            actions,
            contentClassName,
            ...props
        }: CenterModalContentProps & DialogPrimitive.DialogContentProps,
        forwardedRef: React.Ref<HTMLDivElement>
    ) => {
        const { t } = useTranslation("CenterModal");

        return (
            <DialogPrimitive.Portal>
                <div className="fixed inset-0 bg-black/50" />
                <DialogPrimitive.Content
                    className={cn(props.className)}
                    {...props}
                    ref={forwardedRef}
                >
                    <div
                        className={cn(
                            "min-w-[444px] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  overflow-y-scroll max-h-screen py-5 scrollbar-hide",
                            contentClassName
                        )}
                    >
                        <div className="relative shadow-dropdown rounded-lg bg-white ">
                            <div className="absolute right-[14px] top-[14px]">
                                <DialogPrimitive.Close
                                    aria-label="Close"
                                    asChild
                                >
                                    <ButtonRegular
                                        IconLeft={Cancel}
                                        variant={ButtonType.TEXT}
                                    />
                                </DialogPrimitive.Close>
                            </div>

                            <div className="items-start p-5 pb-3 space-y-2">
                                <h3>{title}</h3>
                                <div className="min-w-full max-w-min text-secondary text-base font-regular text-justify">
                                    {subtitle}
                                </div>
                            </div>

                            <div className="px-5 py-3 space-y-3">
                                {children}
                            </div>
                            {actions && (
                                <div className="p-5 flex justify-end space-x-2">
                                    <DialogPrimitive.Close
                                        aria-label="Close"
                                        asChild
                                    >
                                        <ButtonLarge variant={ButtonType.TEXT}>
                                            <div className="text-secondary font-bold">
                                                {t("cancel")}
                                            </div>
                                        </ButtonLarge>
                                    </DialogPrimitive.Close>
                                    {actions}
                                </div>
                            )}
                        </div>
                    </div>
                </DialogPrimitive.Content>
            </DialogPrimitive.Portal>
        );
    }
);

export const CenterModal = DialogPrimitive.Root;
export const CenterModalTrigger = DialogPrimitive.Trigger;
