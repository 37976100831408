import { Editor, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useTranslation } from "react-i18next";
import { ButtonType } from "../Button/ButtonShared";
import ButtonSmall from "../Button/ButtonSmall";
import "./i18n";

const MenuBar = ({
    editor,
    supplier,
}: {
    editor: Editor | null;
    supplier?: boolean;
}) => {
    return (
        <div className="flex flex-wrap mb-2 space-x-1">
            <ButtonSmall
                variant={ButtonType.OUTLINED}
                onClick={() => editor?.commands.insertContent("{{first_name}}")}
            >
                <div className="text-sm">{`{{first_name}}`}</div>
            </ButtonSmall>
            <ButtonSmall
                variant={ButtonType.OUTLINED}
                onClick={() => editor?.commands.insertContent("{{last_name}}")}
            >
                <div className="text-sm">{`{{last_name}}`}</div>
            </ButtonSmall>

            {supplier && (
                <ButtonSmall
                    variant={ButtonType.OUTLINED}
                    onClick={() =>
                        editor?.commands.insertContent("{{supplier}}")
                    }
                >
                    <div className="text-sm">{`{{supplier}}`}</div>
                </ButtonSmall>
            )}
            <ButtonSmall
                variant={ButtonType.OUTLINED}
                onClick={() =>
                    editor?.commands.insertContent("{{personal_link}}")
                }
            >
                <div className="text-sm">{`{{personal_link}}`}</div>
            </ButtonSmall>
            <ButtonSmall
                variant={ButtonType.OUTLINED}
                onClick={() => editor?.commands.insertContent("{{n_requests}}")}
            >
                <div className="text-sm">{`{{n_requests}}`}</div>
            </ButtonSmall>
        </div>
    );
};

export const useReminderTextEditor = (type: "reporting" | "supplier") => {
    const { t } = useTranslation("ReminderTextEditor");

    const extensions = [StarterKit];
    const content = t(`${type}_default_message`);
    const editorProps = {
        attributes: {
            class: "prose prose-sm sm:prose-base lg:prose-lg xl:prose-2xl focus:outline-none p-4 border border-secondary rounded-lg",
        },
    };
    const editor = useEditor({
        extensions,
        content,
        editorProps,
    });
    return editor;
};
export default ({
    editor,
    supplier,
}: {
    editor: Editor | null;
    supplier?: boolean;
}) => {
    return (
        <div className="space-y-3">
            <EditorContent editor={editor} />
            <MenuBar editor={editor} supplier={supplier} />
        </div>
    );
};
