import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import { MoreHorizontal } from "lucide-react";
import { ButtonType } from "../../Button/ButtonShared";
import ButtonSmall from "../../Button/ButtonSmall";

export default function ActionDropdown({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <DropdownMenu.Root modal={false}>
            <DropdownMenu.Trigger asChild>
                <ButtonSmall
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    IconLeft={MoreHorizontal}
                    variant={ButtonType.OUTLINED}
                />
            </DropdownMenu.Trigger>

            <DropdownMenu.Portal>
                <DropdownMenu.Content
                    sideOffset={5}
                    className="p-1 rounded-lg bg-white shadow-dropdown"
                    align="end"
                >
                    {children}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
}
