import {
    editValidationTypeAtom,
    updateVersionCheckAtom,
    versionChecksAtom,
} from "@app/store/versionStore";
import { EditValidationType } from "@app/usecases/VersionUseCases";
import { Box } from "@design-system/Layout/Box";
import { FlexCol } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useAtomValue } from "jotai";
import { useMemo, type FC } from "react";
import { useTranslation } from "react-i18next";
import { CheckCard } from "./CheckCard";
import "./i18n";
import { checkListCards } from "./utils";

export const CheckList: FC = () => {
    const { t } = useTranslation("Checklist");
    const {
        data: { versionChecks },
    } = useAtomValue(versionChecksAtom);

    const validationType = useAtomValue(editValidationTypeAtom);
    const disableCheckboxes =
        validationType === EditValidationType.Approved ||
        validationType === EditValidationType.PendingCsReview;
    const cards = useMemo(() => {
        return checkListCards.reduce(
            (acc, card) => {
                // find the check in the version from the db
                const check = versionChecks.find(
                    (check) => check.checkType === card.checkType,
                );
                // add the id for the mutation, andwhether is checked or not
                if (check) {
                    acc.push({ ...card, id: check.id, checked: check.checked });
                }
                return acc;
            },
            [] as typeof checkListCards,
        );
    }, [versionChecks]);

    const { mutate } = useAtomValue(updateVersionCheckAtom);

    const handleCheck = (id: string, checked: boolean) =>
        mutate({ id, set: { checked } });

    if (!versionChecks.length) return null;

    return (
        <FlexCol px="4" gap="4">
            <Box
                br="lg"
                p="2.5"
                className="bg-accent-100 border border-accent-700"
            >
                <Text variant="body-sm-bold">{t("info_box")}</Text>
            </Box>
            <FlexCol gap="3">
                {cards.map(({ id, checked, descriptionKey, titleKey }) => (
                    <CheckCard
                        key={id}
                        id={id}
                        checked={checked}
                        disabled={disableCheckboxes}
                        title={t(titleKey)}
                        description={t(descriptionKey)}
                        onCheck={handleCheck}
                    />
                ))}
            </FlexCol>
        </FlexCol>
    );
};
