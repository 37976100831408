import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Requirement, Theme } from "../../../../generated/client/graphql";
import { orderSort } from "../../../lib/utils";
import ControlsBlockCard from "./ControlsBlockCard";
import "./i18n";
export default function ControlsBlock({
    themes,
    locale,
    setTabValue,
}: {
    themes: Theme[];
    locale: string;
    setTabValue: (val: string) => void;
}) {
    const { t } = useTranslation("SustainabilityCenterControlsBlock", {
        lng: locale,
    });
    const loadData = (themes: Theme[]) => {
        return orderSort(
            themes
                .filter((elt) => elt.approvedRequirements.length > 0)
                .sort((a, b) => {
                    return (
                        b.approvedRequirements.length -
                        a.approvedRequirements.length
                    );
                }),
        );
    };

    const [requirementsByTheme, setRequirementsByTheme] = useState<
        {
            name: string;
            approvedRequirements: Requirement[];
        }[]
    >(loadData(themes || []));

    useEffect(() => {
        setRequirementsByTheme(loadData(themes || []));
    }, [themes]);

    return (
        <div className="flex flex-col flex-start gap-4 self-stretch">
            <div className="flex h-7.5 justify-between items-start self-stretch">
                <div className="text-primary font-bold text-xl">
                    {t("title")}
                </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
                {requirementsByTheme.map(
                    (theme, idx) =>
                        idx < 8 && (
                            <div className="flex h-[192px] items-start gap-4 self-stretch">
                                <ControlsBlockCard
                                    theme={theme}
                                    setTabValue={setTabValue}
                                />
                            </div>
                        ),
                )}
            </div>
        </div>
    );
}
