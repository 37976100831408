import React from "react";
import { default as ButtonElevated } from "../ButtonElevated";
import ButtonFlatDanger from "../ButtonFlatDanger";
import ButtonFlatPrimary from "../ButtonFlatPrimary";
import ButtonOutline from "../ButtonOutline";
import ButtonOutlineDanger from "../ButtonOutlineDanger";
import { ButtonProps, ButtonType } from "../ButtonShared";
import ButtonText from "../ButtonText";
import ButtonTonal from "../ButtonTonal";
import ButtonRegularBase from "./ButtonRegularBase";

const ButtonRegularElevated = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonElevated
                ref={forwardedRef}
                Base={ButtonRegularBase}
                {...props}
            />
        );
    }
);
const ButtonRegularFlatPrimary = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonFlatPrimary
                ref={forwardedRef}
                Base={ButtonRegularBase}
                {...props}
            />
        );
    }
);

const ButtonRegularFlatDanger = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonFlatDanger
                ref={forwardedRef}
                Base={ButtonRegularBase}
                {...props}
            />
        );
    }
);

const ButtonRegularTonal = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonTonal
                ref={forwardedRef}
                Base={ButtonRegularBase}
                {...props}
            />
        );
    }
);

const ButtonRegularOutlined = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonOutline
                Base={ButtonRegularBase}
                {...props}
                ref={forwardedRef}
            />
        );
    }
);

const ButtonRegularOutlinedDanger = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonOutlineDanger
                Base={ButtonRegularBase}
                {...props}
                ref={forwardedRef}
            />
        );
    }
);

const ButtonRegularText = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonText
                Base={ButtonRegularBase}
                {...props}
                ref={forwardedRef}
            />
        );
    }
);

/**
 * @deprecated
 */
const ButtonRegular = React.forwardRef(
    (
        {
            variant,
            ...props
        }: { variant?: ButtonType } & ButtonProps &
            React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        if (!variant) {
            return <ButtonRegularElevated {...props} ref={forwardedRef} />;
        }
        switch (variant) {
            case ButtonType.ELEVATED:
                return <ButtonRegularElevated {...props} ref={forwardedRef} />;
            case ButtonType.FLAT_PRIMARY:
                return (
                    <ButtonRegularFlatPrimary {...props} ref={forwardedRef} />
                );
            case ButtonType.FLAT_DANGER:
                return (
                    <ButtonRegularFlatDanger {...props} ref={forwardedRef} />
                );
            case ButtonType.TONAL:
                return <ButtonRegularTonal {...props} ref={forwardedRef} />;
            case ButtonType.OUTLINED:
                return <ButtonRegularOutlined {...props} ref={forwardedRef} />;
            case ButtonType.OUTLINED_DANGER:
                return (
                    <ButtonRegularOutlinedDanger
                        {...props}
                        ref={forwardedRef}
                    />
                );
            case ButtonType.TEXT:
                return <ButtonRegularText {...props} ref={forwardedRef} />;
        }
    }
);

export default ButtonRegular;
