import {
    FrameworkPatch,
    GetFrameworkDocument,
    GetFrameworkDocumentsDocument,
    GetFrameworkDocumentsQuery,
    GetFrameworkQuery,
    GetFrameworksDocument,
    GetFrameworksQuery,
    InitializeFrameworkDocument,
    InitializeFrameworkMutation,
    UpdateFrameworkDocument,
    UpdateFrameworkMutation,
} from "@generated/client/graphql";
import { AccessHeadersHolder } from "../../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../../clients";
import { FrameworkRepository } from "../../FrameworkRepository";

export class GraphQLFrameworkRepository implements FrameworkRepository {
    get = (id: string): Promise<GetFrameworkQuery> => {
        return graphqlClientKoyeb.request(
            GetFrameworkDocument,
            { id },
            AccessHeadersHolder.getHeaders(),
        );
    };
    getFrameworkDocuments = (
        id: string,
    ): Promise<GetFrameworkDocumentsQuery> => {
        return graphqlClientKoyeb.request(
            GetFrameworkDocumentsDocument,
            { id },
            AccessHeadersHolder.getHeaders(),
        );
    };
    getMany = (): Promise<GetFrameworksQuery> => {
        return graphqlClientKoyeb.request(
            GetFrameworksDocument,
            undefined,
            AccessHeadersHolder.getHeaders(),
        );
    };

    update = (
        id: string,
        patch: FrameworkPatch,
    ): Promise<UpdateFrameworkMutation> => {
        return graphqlClientKoyeb.request(
            UpdateFrameworkDocument,
            { id, patch },
            AccessHeadersHolder.getHeaders(),
        );
    };

    initialize: (id: string) => Promise<InitializeFrameworkMutation> = (
        id: string,
    ) => {
        return graphqlClientKoyeb.request(
            InitializeFrameworkDocument,
            { id },
            AccessHeadersHolder.getHeaders(),
        );
    };
}
