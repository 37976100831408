import Checkbox from "@app/components/Checkbox";
import { Icon } from "@design-system/Icons";
import { Button } from "@design-system/Inputs/Button";
import { MultiSelect } from "@design-system/Inputs/MultiSelect";
import { Flex, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { CsrdDatapointStatus } from "@generated/client/graphql";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RESOURCE_NAME, keys } from "./i18n";

export const CsrdDatapointStatusMultiSelect: FC<{
    values: CsrdDatapointStatus[] | undefined;
    onValuesChange: (values: CsrdDatapointStatus[] | undefined) => void;
}> = ({ values, onValuesChange }) => {
    const { t } = useTranslation(RESOURCE_NAME);

    const onStrValuesChange = (strStatuses: (string | null)[]) => {
        onValuesChange(
            strStatuses.map((status) => {
                switch (status) {
                    case "NOT_STARTED":
                        return CsrdDatapointStatus.NotStarted;
                    case "VALIDATED":
                        return CsrdDatapointStatus.Validated;
                    case "NOT_MATERIAL":
                        return CsrdDatapointStatus.NotMaterial;
                    default:
                        return CsrdDatapointStatus.InProgress;
                }
            }),
        );
    };

    const options = useMemo(() => {
        return [
            {
                value: CsrdDatapointStatus.NotStarted,
                label: t(keys.not_started),
            },
            {
                value: CsrdDatapointStatus.InProgress,
                label: t(keys.in_progress),
            },
            { value: CsrdDatapointStatus.Validated, label: t(keys.validated) },
            {
                value: CsrdDatapointStatus.NotMaterial,
                label: t(keys.not_material),
            },
        ];
    }, []);

    return (
        <MultiSelect values={values || []} onValuesChange={onStrValuesChange}>
            <MultiSelect.Trigger>
                <Button variant="tonal" size="sm">
                    <Text variant="body-sm-bold">{t(keys.status)}</Text>
                    <Icon name="angleDown" />
                </Button>
            </MultiSelect.Trigger>
            <MultiSelect.Content>
                {options.map(({ label, value }) => (
                    <MultiSelect.Item key={value} value={value}>
                        {({ isSelected }) => (
                            <FlexRow
                                alignItems="center"
                                alignSelf="stretch"
                                px="1.5"
                                py="2"
                                gap="3"
                                br="lg"
                                className={cn(
                                    "flex-1 hover:bg-secondary hover:text-green",
                                    isSelected && "font-bold",
                                )}
                            >
                                <Flex alignItems="center">
                                    <Checkbox checked={isSelected}></Checkbox>
                                </Flex>
                                <Text
                                    variant="body-sm"
                                    className={cn(
                                        "group-hover:text-green",
                                        isSelected && "font-bold",
                                    )}
                                >
                                    {label}
                                </Text>
                            </FlexRow>
                        )}
                    </MultiSelect.Item>
                ))}
            </MultiSelect.Content>
        </MultiSelect>
    );
};
