import Loading from "@app/components/Loading";
import { FlexCol } from "@design-system/Layout/Flex";
import { Modal } from "@design-system/Modal";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { DocumentsUploadModalCTA } from "./DocumentsUploadModalCTA";
import { UploadedFilesProvider } from "./DocumentsUploadModalContext";
import { DocumentsUploadModalDropzone } from "./DocumentsUploadModalDropzone";
import { DocumentsUploadModalItem } from "./DocumentsUploadModalItem";
import "./i18n";
import { useDocumentsUploadModal } from "./useDocumentsUploadModal";

export const DocumentUploadModal = () => {
    const { t } = useTranslation("DocumentsUploadModal");
    const {
        handleOpenChange,
        isOpen,
        isUploading,
        uploadedFiles,
        setUploadedFiles,
    } = useDocumentsUploadModal();
    return (
        <UploadedFilesProvider
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
        >
            <Modal open={isOpen} onOpenChange={handleOpenChange}>
                <Modal.Overlay className="pb-[200px]" scrollable>
                    <Modal.Content className="w-[546px]">
                        <Modal.Header>
                            <Modal.Title>{t("title")}</Modal.Title>
                            <Modal.Subtitle>{t("subtitle")}</Modal.Subtitle>
                        </Modal.Header>
                        <Modal.Body className="w-[546px]">
                            <FlexCol gap="5" maxW="full">
                                <DocumentsUploadModalDropzone />
                                {isUploading && <Loading size="small" />}
                                {uploadedFiles.list.map((file, idx) => (
                                    <Fragment key={file.id}>
                                        <DocumentsUploadModalItem
                                            id={file.id}
                                        />
                                        {idx !==
                                            uploadedFiles.list.length - 1 && (
                                            <hr />
                                        )}
                                    </Fragment>
                                ))}
                            </FlexCol>
                        </Modal.Body>
                        <Modal.Footer>
                            <DocumentsUploadModalCTA />
                        </Modal.Footer>
                    </Modal.Content>
                </Modal.Overlay>
            </Modal>
        </UploadedFilesProvider>
    );
};
