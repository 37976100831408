import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { SigmaFunction } from "iconoir-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Supplier } from "../../../../../generated/client/graphql";
import BaseItem from "../../components/dropdowns/items/BaseItem";
import CheckboxItem from "../../components/dropdowns/items/CheckboxItem";
import DropdownItemSearch from "../../components/dropdowns/items/Search";
import DropdownContentLayout from "../../components/dropdowns/layout";
import "./i18n";

const SupplierDropdown = ({
    supplierIds,
    onSupplierIdsChange,
    suppliers,
    ...props
}: {
    supplierIds?: string[] | undefined;
    onSupplierIdsChange: (supplierIds: string[]) => void;
    suppliers: Supplier[] | undefined;
    hidePlus?: boolean;
} & DropdownPrimitive.DropdownMenuContentProps) => {
    const { t } = useTranslation("SupplierDropdown");

    const [searchFilterString, setSearchFilterString] = useState("");

    const onToggle = (id: string) => {
        if (supplierIds?.includes(id)) {
            const newValues = [...supplierIds];
            newValues.splice(supplierIds.indexOf(id), 1);
            onSupplierIdsChange(newValues);
        } else {
            onSupplierIdsChange([...(supplierIds || []), id]);
        }
    };
    return (
        <DropdownContentLayout {...props}>
            <DropdownItemSearch
                searchFilterString={searchFilterString}
                setSearchFilterString={setSearchFilterString}
            />

            <BaseItem
                className="group cursor-pointer"
                IconLeft={SigmaFunction}
                onClick={(e) => {
                    e.preventDefault();
                    onSupplierIdsChange(suppliers?.map(({ id }) => id) || []);
                }}
            >
                {t("select_all")}
            </BaseItem>

            {suppliers
                ?.filter(
                    ({ name }) =>
                        !searchFilterString ||
                        (name &&
                            name
                                .toLowerCase()
                                .includes(searchFilterString.toLowerCase()))
                )
                .map(({ id, name }) => (
                    <CheckboxItem
                        key={id}
                        onSelect={(e) => {
                            e.preventDefault();
                        }}
                        checked={supplierIds?.includes(id)}
                        onCheckedChange={() => onToggle(id)}
                    >
                        {name}
                    </CheckboxItem>
                ))}
        </DropdownContentLayout>
    );
};

export default SupplierDropdown;
