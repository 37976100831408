import { AccessHeadersHolder } from "@app/repositories/AccessHeadersHolder";
import { OrganizationRepository } from "@app/repositories/OrganizationRepository";
import { graphqlClientKoyeb } from "@app/repositories/clients";
import { GetUserOrganisationsDocument, GetUserOrganisationsQueryVariables } from "@generated/client/graphql";

export class GraphQLOrganizationRepository implements OrganizationRepository {
    get = (input: GetUserOrganisationsQueryVariables) => (
        graphqlClientKoyeb.request(
            GetUserOrganisationsDocument,
            input,
            AccessHeadersHolder.getHeaders(),
        )
    );
}
