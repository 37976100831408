import { useRef } from "react";
import { useAtomValue } from "jotai";
import { updateFinalFileAtom } from "@app/store/versionStore";

export function useUpdateDocumentBar() {
    const inputRef = useRef<HTMLInputElement>(null);
    const { mutate: updateFinalFile, isPending: isUploading } =
        useAtomValue(updateFinalFileAtom);

    const handleClick = () => {
        inputRef.current?.click();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        updateFinalFile({ file });
    };
    return {
        handleChange,
        handleClick,
        inputRef,
        isUploading,
    };
}
