import { useTranslation } from "react-i18next";
import { GaugeCircular } from "../../Progress/ProgressCircular/GaugeCircular";
import CardLayout from "../CardLayout";
import "./i18n";
import { Text } from "@design-system/Typography/Text";
export interface GaugeValue {
    name: string;
    value: number;
    color: string;
    callback?: () => void;
}

export interface AssignementCardProps {
    isLoading?: boolean;
    total?: number;
    assigned?: number;
    unassigned?: number;
}

export default function AssignementCard({
    isLoading,
    total,
    assigned,
    unassigned,
}: AssignementCardProps) {
    const { t } = useTranslation("AssignementCard");
    const progress = total && assigned ? (assigned / total) * 100 : 0;
    return (
        <CardLayout
            isLoading={isLoading}
            className="h-[172px] py-4 space-y-2 w-full"
            title={t("title")}
        >
            <div className="flex items-center">
                <div className="flex justify-center w-1/2">
                    <GaugeCircular progress={progress} size={104} width={6}>
                        <Text variant="header1">{progress.toFixed(0)}%</Text>
                    </GaugeCircular>
                </div>
                <div className="w-1/2 space-y-1.5 pr-5 ">
                    <div className="flex justify-between">
                        <div> {t("total")}</div>
                        <div className=" font-bold">{total}</div>
                    </div>
                    <div className=" flex justify-between">
                        <div className="flex space-x-2.5 items-center">
                            <span className="h-2 w-2 bg-green rounded-full" />
                            <span>{t("assigned")}</span>
                        </div>
                        <div className=" font-bold">{assigned}</div>
                    </div>
                    <div className=" flex justify-between">
                        <div className="flex space-x-2.5 items-center">
                            <span className="h-2 w-2 bg-quarternary rounded-full" />
                            <span>{t("unassigned")}</span>
                        </div>
                        <div className=" font-bold">{unassigned}</div>
                    </div>
                </div>
            </div>
        </CardLayout>
    );
}
