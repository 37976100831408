import { forwardRef } from "react";
import { cn, type ClassValue } from "../Utilities";

import { ReactComponent as Add } from "./add.svg";
import { ReactComponent as AngleDown } from "./angle-down.svg";
import { ReactComponent as AngleLeft } from "./angle-left.svg";
import { ReactComponent as AngleRight } from "./angle-right.svg";
import { ReactComponent as AngleUpDown } from "./angle-up-down.svg";
import { ReactComponent as AngleUp } from "./angle-up.svg";
import { ReactComponent as ArrowDown } from "./arrow-down.svg";
import { ReactComponent as ArrowLeft } from "./arrow-left.svg";
import { ReactComponent as ArrowRight } from "./arrow-right.svg";
import { ReactComponent as ArrowUp } from "./arrow-up.svg";
import { ReactComponent as Building } from "./building.svg";
import { ReactComponent as Check } from "./check.svg";
import { ReactComponent as CircleInProgress } from "./circle-in-progress.svg";
import { ReactComponent as CircleLoading } from "./circle-loading.svg";
import { ReactComponent as CircleTodo } from "./circle-todo.svg";
import { ReactComponent as CircleValidated } from "./circle-validated.svg";
import { ReactComponent as Close } from "./close.svg";
import { ReactComponent as CloudWithValidateIcon } from "./cloud-with-validate.svg";
import { ReactComponent as Comment } from "./comment.svg";
import { ReactComponent as Company } from "./company.svg";
import { ReactComponent as DashboardIcon } from "./dashboard.svg";
import { ReactComponent as Document } from "./document.svg";
import { ReactComponent as DoubleCheck } from "./double-check.svg";
import { ReactComponent as Download } from "./download.svg";
import { ReactComponent as Duplicate } from "./duplicate.svg";
import { ReactComponent as Edit } from "./edit.svg";
import { ReactComponent as EyeOff } from "./eye-off.svg";
import { ReactComponent as File } from "./file.svg";
import { ReactComponent as Help } from "./help.svg";
import { ReactComponent as Info } from "./info.svg";
import { ReactComponent as ListCheck } from "./list-check.svg";
import { ReactComponent as ListOrder } from "./list-order.svg";
import { ReactComponent as List } from "./list.svg";
import { ReactComponent as Loader } from "./loader.svg";
import { ReactComponent as Megaphone } from "./megaphone.svg";
import { ReactComponent as More } from "./more.svg";
import { ReactComponent as Palette } from "./palette.svg";
import { ReactComponent as Party } from "./party.svg";
import { ReactComponent as Plus } from "./plus.svg";
import { ReactComponent as Redo } from "./redo.svg";
import { ReactComponent as Ruler } from "./ruler.svg";
import { ReactComponent as Scale } from "./scale.svg";
import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as Table } from "./table.svg";
import { ReactComponent as Target } from "./target.svg";
import { ReactComponent as Trash } from "./trash.svg";
import { ReactComponent as Undo } from "./undo.svg";
import { ReactComponent as UnfoldVertical } from "./unfold-vertical.svg";
import { ReactComponent as Upload } from "./upload.svg";
import { ReactComponent as User } from "./user.svg";
import { ReactComponent as Users } from "./users.svg";
import { ReactComponent as Validate } from "./validate.svg";
import { ReactComponent as Warning } from "./warning.svg";

export const iconMap = {
    add: Add,
    angleDown: AngleDown,
    angleLeft: AngleLeft,
    angleRight: AngleRight,
    angleUpDown: AngleUpDown,
    angleUp: AngleUp,
    arrowDown: ArrowDown,
    arrowLeft: ArrowLeft,
    arrowRight: ArrowRight,
    arrowUp: ArrowUp,
    building: Building,
    check: Check,
    close: Close,
    cloudWithValidate: CloudWithValidateIcon,
    comment: Comment,
    company: Company,
    dashboard: DashboardIcon,
    document: Document,
    doubleCheck: DoubleCheck,
    download: Download,
    duplicate: Duplicate,
    edit: Edit,
    file: File,
    help: Help,
    info: Info,
    list: List,
    listOrder: ListOrder,
    listCheck: ListCheck,
    loader: Loader,
    megaphone: Megaphone,
    palette: Palette,
    party: Party,
    redo: Redo,
    ruler: Ruler,
    scale: Scale,
    search: Search,
    table: Table,
    target: Target,
    trash: Trash,
    unfoldVertical: UnfoldVertical,
    undo: Undo,
    upload: Upload,
    user: User,
    users: Users,
    validate: Validate,
    warning: Warning,
    plus: Plus,
    more: More,
    circleTodo: CircleTodo,
    circleValidated: CircleValidated,
    circleLoading: CircleLoading,
    circleInProgress: CircleInProgress,
    eyeOff: EyeOff,
} as const;

export type IconName = keyof typeof iconMap;

export type IconProps = {
    name: keyof typeof iconMap;
    size?: "xs" | "sm" | "md" | "lg";
    className?: ClassValue;
};

export const Icon = forwardRef<SVGSVGElement, IconProps>(
    ({ name, size, className }, ref) => {
        const Comp = iconMap[name];

        // compute width and height based on size prop
        const sizeTw =
            size === "xs"
                ? "w-3 h-3"
                : size === "sm"
                  ? "w-4 h-4"
                  : size === "lg"
                    ? "w-6 h-6"
                    : "w-5 h-5"; // md is also the default size
        return <Comp ref={ref} className={cn(sizeTw, "shrink-0", className)} />;
    },
);
