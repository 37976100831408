import {
    forwardRef,
    type HTMLAttributes,
    type TdHTMLAttributes,
    type ThHTMLAttributes,
} from "react";
import { cn } from "@design-system/Utilities";
import { Box } from "@design-system/Layout/Box";

export const Table = forwardRef<
    HTMLTableElement,
    HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
    <Box
        className={cn("border border-tertiary", "rounded-lg", "overflow-auto")}
        br="lg"
        w="full"
    >
        <table
            ref={ref}
            className={cn("w-full border-collapse", "text-sm", className)}
            {...props}
        />
    </Box>
));

export const TableHeader = forwardRef<
    HTMLTableSectionElement,
    HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
    <thead
        ref={ref}
        className={cn("border-b border-b-tertiary bg-secondary", className)}
        {...props}
    />
));
export const TableBody = forwardRef<
    HTMLTableSectionElement,
    HTMLAttributes<HTMLTableSectionElement>
>((props, ref) => <tbody ref={ref} {...props} />);

export const TableRow = forwardRef<
    HTMLTableRowElement,
    HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
    <tr
        ref={ref}
        className={cn(
            "border-b border-tertiary last:border-b-0 transition-colors",
            "hover:bg-secondary data-[state=selected]:bg-secondary",
            className,
        )}
        {...props}
    />
));

export const TableHead = forwardRef<
    HTMLTableCellElement,
    ThHTMLAttributes<HTMLTableCellElement>  & { variant?: "regular" | "compact" }
>(({ className, variant = "regular", ...props }, ref) => (
    <th
        ref={ref}
        className={cn(
            "h-[40px] text-left text-sm align-middle [&:has([role=checkbox])]:pr-0",
            variant === 'regular' ? "px-4" : "px-2 py-1.5",
            className,
        )}
        {...props}
    />
));

export const TableCell = forwardRef<
    HTMLTableCellElement,
    TdHTMLAttributes<HTMLTableCellElement> & { variant?: "regular" | "compact" }
>(({ className, variant = "regular", ...props }, ref) => (
    <td
        ref={ref}
        className={cn(
            "align-middle [&:has([role=checkbox])]:pr-0",
            variant === 'regular' ? "px-4 py-3 text-base" : "px-2 py-1 h-5 text-sm", 
            className,
        )}
        {...props}
    />
));
