import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export const Is = <T>(v: T) => {
    if (v === null || v === undefined) {
        return false;
    } else {
        return true;
    }
};

export const orderSort = <T extends { order?: number | null }>(
    arr: T[],
): T[] => {
    return arr?.sort((a, b) => {
        return a.order === null ||
            a.order === undefined ||
            b.order === null ||
            b.order === undefined
            ? 0
            : a.order - b.order;
    });
};
