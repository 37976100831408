import { useQueryClient } from "@tanstack/react-query";
import { Trash } from "iconoir-react";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    GetSustainabilityCenterDocument,
    S3File,
    ScFramework,
    SustainabilityCenter,
    UpdateScFrameworksDocument,
} from "../../../../../../../generated/client/graphql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../../../../QueryClientWithHeaders";
import ButtonLarge from "../../../../../../components/Button/ButtonLarge";
import { ButtonType } from "../../../../../../components/Button/ButtonShared";
import ButtonSmall from "../../../../../../components/Button/ButtonSmall";
import FileUpload from "../../../../../../components/FileUpload";

import { useFrameworks } from "@app/screens/Frameworks/data";
import {
    CenterModal,
    CenterModalContent,
} from "../../../../../../components/Modal/CenterModal";
import ItemsListEditor, {
    selectedIndexAtom,
} from "../../../../../../components/SustainabilityCenter/ItemsLitEditor";
import { useSetToast } from "../../../../../../components/Toast";
import InputSelectFramework from "../../../../../../components/inputs/InputSelect/Framework";
import TextInput from "../../../../../../components/inputs_v2/Text";
import { orderSort } from "../../../../../../lib/utils";
import "./i18n";

export default function EditFrameworksModal({
    sustainabilityCenter,
    open,
    setOpen,
}: {
    sustainabilityCenter: SustainabilityCenter | undefined;
    open: boolean;
    setOpen: (open: boolean) => void;
}) {
    const { t } = useTranslation("SustainabilityCenterEditFrameworksModal");
    const { frameworks: cmsFrameworks } = useFrameworks();
    const { setToastSuccess, setToastError } = useSetToast();
    const client = useQueryClient();
    const { mutate: updateSustainabilityCenter } = useGraphQLMutation(
        UpdateScFrameworksDocument,
        {},
        true,
    );

    const [selectedIdx, setSelectedIdx] = useAtom(selectedIndexAtom);
    const [frameworks, setFrameworks] = useState<ScFramework[]>(
        orderSort(sustainabilityCenter?.scFrameworks || []).map((elt) => {
            return { ...elt, cmsFrameworkId: elt.cmsFrameworkId || "custom" };
        }),
    );
    const [displayedFramework, setDisplayedFramework] = useState<
        ScFramework | undefined
    >(undefined);

    useEffect(() => {
        setFrameworks(
            orderSort(sustainabilityCenter?.scFrameworks || []).map((elt) => {
                return {
                    ...elt,
                    cmsFrameworkId: elt.cmsFrameworkId || "custom",
                };
            }),
        );
        setDisplayedFramework(
            sustainabilityCenter?.scFrameworks
                ? sustainabilityCenter.scFrameworks[selectedIdx]
                : undefined,
        );
    }, [sustainabilityCenter?.scFrameworks]);

    useEffect(() => {
        setDisplayedFramework(frameworks[selectedIdx]);
    }, [frameworks, selectedIdx]);

    const addFramework = () => {
        const cmsLoaded = cmsFrameworks
            ?.map((elt) => elt.cmsId)
            .includes("evs-2023");
        setFrameworks([
            ...frameworks,
            {
                name: "",
                badge: undefined,
                order: frameworks.length,
                cmsFrameworkId: cmsLoaded ? "evs-2023" : "custom",
                isCustom: cmsLoaded ? false : true,
            },
        ] as ScFramework[]);
        setSelectedIdx(frameworks.length);
    };

    const deleteFramework = () => {
        setFrameworks(
            frameworks
                .filter((_, index) => index !== selectedIdx)
                .map((elt, idx) => {
                    return { ...elt, order: idx };
                }),
        );
        setSelectedIdx(0);
    };

    const setName = (name: string | undefined) => {
        setFrameworks(
            frameworks.map((elt, idx) => {
                return idx === selectedIdx ? { ...elt, name: name || "" } : elt;
            }),
        );
    };

    const setBadge = (s3File: S3File | undefined) => {
        setFrameworks(
            frameworks.map((elt, idx) => {
                return idx === selectedIdx ? { ...elt, badge: s3File } : elt;
            }),
        );
    };

    const setCmsFramework = (cmsFrameworkId: string) => {
        setFrameworks(
            frameworks.map((elt, idx) => {
                const isCustom =
                    !cmsFrameworks
                        ?.map((val) => val.cmsId)
                        .includes(cmsFrameworkId) || false;

                return idx === selectedIdx
                    ? {
                          ...elt,
                          cmsFrameworkId: !isCustom ? cmsFrameworkId : null,
                          isCustom: isCustom,
                      }
                    : elt;
            }),
        );
    };

    const handleSave = () => {
        if (!sustainabilityCenter) return;
        const updatePPInput = {
            id: sustainabilityCenter.id,
            inputs:
                frameworks?.map((elt) => {
                    return {
                        name: elt.name,
                        order: elt.order,
                        cmsFrameworkId: elt.isCustom
                            ? ""
                            : elt.cmsFrameworkId || "",
                        badgeFileId: elt.badge?.id || null,
                        isCustom: elt.isCustom || false,
                    };
                }) || [],
        };
        updateSustainabilityCenter(updatePPInput, {
            onSuccess: () => {
                setToastSuccess(t("update_success"));
                client.invalidateQueries({
                    queryKey: useGraphQL.getKey(
                        GetSustainabilityCenterDocument,
                    ),
                });
                setOpen(false);
            },
            onError: () => {
                setToastError(t("update_error"));
            },
        });
    };

    const getTitle = (item: ScFramework | undefined) => {
        return item?.isCustom
            ? t("custom_framework")
            : cmsFrameworks?.find(
                  (cmsFramework) => cmsFramework.cmsId === item?.cmsFrameworkId,
              )?.name || t("custom_framework");
    };

    return (
        <CenterModal open={open} onOpenChange={setOpen} modal={false}>
            <CenterModalContent
                title={t("modal_title")}
                actions={
                    <>
                        <ButtonLarge
                            text={t("save")}
                            variant={ButtonType.ELEVATED}
                            onClick={() => {
                                handleSave();
                                setOpen(false);
                            }}
                        />
                    </>
                }
            >
                <ItemsListEditor
                    items={frameworks}
                    setItems={setFrameworks}
                    addItem={addFramework}
                    itemType={t("frameworks")}
                    getItemTitle={getTitle}
                >
                    <div className="flex min-h-[380px] items-start w-[820px]">
                        {frameworks?.length > 0 && (
                            <div className="flex flex-1 p-5 flex-col items-start gap-6">
                                <div className="flex justify-between items-start self-stretch">
                                    <div className="font-bold text-lg flex-1">
                                        {getTitle(displayedFramework)}
                                    </div>
                                    <ButtonSmall
                                        IconRight={Trash}
                                        variant={ButtonType.OUTLINED}
                                        onClick={deleteFramework}
                                    />
                                </div>
                                <div className="w-full gap-4 flex flex-col">
                                    <InputSelectFramework
                                        value={
                                            (displayedFramework?.cmsFrameworkId as string) ||
                                            "custom"
                                        }
                                        onValueChange={(val) => {
                                            setCmsFramework(val || "");
                                        }}
                                        useCustom={true}
                                    ></InputSelectFramework>
                                    <TextInput
                                        placeholder={t(
                                            "framework_name_input_placeholder",
                                        )}
                                        value={displayedFramework?.name}
                                        setValue={setName}
                                    ></TextInput>
                                </div>
                                <div className="flex flex-col items-start gap-4">
                                    <div className="font-bold test-base">
                                        {t("compliance_badge")}
                                    </div>
                                    <FileUpload
                                        s3File={
                                            displayedFramework?.badge ||
                                            undefined
                                        }
                                        setS3File={(
                                            s3File: S3File | undefined,
                                        ) => setBadge(s3File)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </ItemsListEditor>
            </CenterModalContent>
        </CenterModal>
    );
}
