import { forwardRef } from "react";
import { ReactComponent as Loading } from "../../../assets/loading-small.svg";
import { cn } from "../../../lib/utils";
import { ButtonProps } from "../ButtonShared";

export default forwardRef(function ButtonXSmallBase(
    {
        className,
        textClassName,
        iconClassName,
        onClick,
        IconLeft,
        IconRight,
        onRightClick,
        active,
        ...props
    }: ButtonProps &
        React.ButtonHTMLAttributes<HTMLButtonElement> & {
            buttonClassName?: string;
            textClassName?: string;
            iconClassName?: string;
        },
    ref: React.Ref<HTMLButtonElement>,
) {
    return (
        <button
            ref={ref}
            className={cn(
                "h-[24px] min-w-[24px] relative flex items-center justify-center  cursor-pointer rounded-lg disabled:bg-neutral-150 max-w-full space-x-1 px-1.5 box-content",
                className,
            )}
            onClick={!props.loading ? onClick : undefined}
            {...props}
        >
            {props.loading && (
                <div className="absolute text-white animate-spin px-1 shrink-0">
                    <Loading className={iconClassName} />
                </div>
            )}
            {IconLeft && (
                <div
                    className={`${
                        props.loading && "invisible"
                    } shrink-0 flex items-center justify-center `}
                >
                    <IconLeft className={`w-3 h-3 ${iconClassName}`} />
                </div>
            )}
            {(props.text || props.children) && (
                <div
                    className={`${
                        props.loading && "invisible"
                    } text-xs font-bold whitespace-nowrap disabled:text-disabled truncate px-0.5 ${textClassName}`}
                >
                    {props.text}
                    {props.children}
                </div>
            )}

            {IconRight && (
                <div
                    className={`${
                        props.loading && "invisible"
                    } cursor-pointer items-center justify-center shrink-0`}
                    onPointerDown={(e) => {
                        if (onRightClick) {
                            e.preventDefault();
                            onRightClick(e);
                        }
                    }}
                >
                    <IconRight className={`w-4 h-4 ${iconClassName} `} />
                </div>
            )}
        </button>
    );
});
