import { SingleSelect } from "@design-system/Inputs/SingleSelect";
import { DocumentVersionStatus } from "@generated/client/graphql";
import { type FC } from "react";
import { useTranslation } from "react-i18next";

type VersionStatusSingleSelectProps = {
    onStatusSelect: (status: DocumentVersionStatus) => void;
};

export const VersionStatusSingleSelect: FC<VersionStatusSingleSelectProps> = ({
    onStatusSelect,
}) => {
    const { t } = useTranslation("DocumentsUploadModal");
    return (
        <SingleSelect onValueChange={onStatusSelect}>
            <SingleSelect.Trigger
                placeholder={t("status_select_placeholder")}
                size="sm"
            />
            <SingleSelect.Content>
                <SingleSelect.Option
                    value={DocumentVersionStatus.Draft}
                    label={t("status_select_option_draft")}
                />
                <SingleSelect.Option
                    value={DocumentVersionStatus.PendingBeavrReview}
                    label={t("status_select_option_under_review")}
                />
                <SingleSelect.Option
                    value={DocumentVersionStatus.Approved}
                    label={t("status_select_option_approved")}
                />
            </SingleSelect.Content>
        </SingleSelect>
    );
};
