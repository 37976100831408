import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "ReportingFilters", {
    search_placeholder: "Search",
    to_fill_only: "Indicators with missing data",
    select_columns: "Columns",
    requirements: "Requirements",
    subtheme: "Subtheme",
    assignedTo: "Owner",
    frameworks: "Frameworks",
});

i18next.addResourceBundle("fr", "ReportingFilters", {
    search_placeholder: "Recherche",
    to_fill_only: "Indicateurs avec données manquantes",
    select_columns: "Colonnes",
    requirements: "Contrôles",
    subtheme: "Sous-thème",
    assignedTo: "Propriétaire",
    frameworks: "Référentiels",
});
