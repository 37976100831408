import { EditorMode } from "../../../../screens/Document/Edit/hooks/useEditor";
import "./i18n";
import { SegmentButton } from "@design-system/Inputs/SegmentButton";
import { type FC } from "react";
import { FloatingBadge } from "@design-system/DataDisplay/Badge";
import { Box } from "@design-system/Layout/Box";
import { Icon } from "@design-system/Icons";
import { useSidebarHeader } from "./useSidebarHeader";

const SideBarHeader: FC = () => {
    const {
        editorMode,
        handleValueChange,
        activeThreadsNumber,
        hasActiveThreads,
        showValidationChecklist,
        checkBadgeVariant,
        totalChecks,
        checksRatio,
        t,
        RegularTabs,
        regularModeActiveTab,
        withEditor,
    } = useSidebarHeader();

    return (
        <div>
            {editorMode === EditorMode.REGULAR ? (
                <Box as="header" p="4">
                    <SegmentButton
                        onValueChange={handleValueChange}
                        size="sm"
                        value={regularModeActiveTab}
                    >
                        <FloatingBadge
                            content={`${activeThreadsNumber}`}
                            show={hasActiveThreads}
                            variant="error"
                        >
                            <SegmentButton.Item value={RegularTabs.COMMENTS}>
                                <Icon name="comment" size="sm" />
                            </SegmentButton.Item>
                        </FloatingBadge>
                        {showValidationChecklist ? (
                            <FloatingBadge
                                content={checksRatio}
                                show={totalChecks > 0}
                                variant={checkBadgeVariant}
                            >
                                <SegmentButton.Item value={RegularTabs.CHECKS}>
                                    <Icon name="doubleCheck" size="sm" />
                                </SegmentButton.Item>
                            </FloatingBadge>
                        ) : null}
                        {withEditor && (
                            <>
                                <SegmentButton.Item value={RegularTabs.INFO}>
                                    <Icon name="info" size="sm" />
                                </SegmentButton.Item>
                                <SegmentButton.Item value={RegularTabs.CONTENT}>
                                    <Icon name="listOrder" size="sm" />
                                </SegmentButton.Item>
                            </>
                        )}
                    </SegmentButton>
                </Box>
            ) : (
                <div className="flex px-4 pt-4 pb-2 flex-col items-start gap-4 self-stretch">
                    <div className="font-bold text-xs uppercase">
                        {t("version_history_title")}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SideBarHeader;
