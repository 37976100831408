const Background = () => {
    return (
        <>
            <div className="-z-10 absolute bg-secondary w-full h-full"></div>
            <div className="-z-10 absolute h-[1440px] w-full blur-[102px] bg-white -translate-y-[720px] rounded-full" />
        </>
    );
};

export default Background;
