import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { useTranslation } from "react-i18next";
import { DocumentAcceptance } from "../../../../../generated/client/graphql";
import SingleSelectDropdown from "../../components/dropdowns/SingleSelect";
import "./i18n";

const DocumentModeDropdown = ({
    mode,
    onModeChange,
    ...props
}: {
    mode?: DocumentAcceptance;
    onModeChange: (mode: DocumentAcceptance | undefined) => void;
} & DropdownPrimitive.DropdownMenuContentProps) => {
    const { t } = useTranslation("DocumentModeDropdown");
    return (
        <SingleSelectDropdown
            disableSearch
            value={mode}
            onValueChange={onModeChange}
            values={[
                {
                    value: DocumentAcceptance.Unknown,
                    node: t(DocumentAcceptance.Unknown),
                },
                {
                    value: DocumentAcceptance.NotAllowed,
                    node: t(DocumentAcceptance.NotAllowed),
                },
                {
                    value: DocumentAcceptance.Optional,
                    node: t(DocumentAcceptance.Optional),
                },
                {
                    value: DocumentAcceptance.Required,
                    node: t(DocumentAcceptance.Required),
                },
                {
                    value: DocumentAcceptance.Several,
                    node: t(DocumentAcceptance.Several),
                },
                {
                    value: DocumentAcceptance.SeveralRequired,
                    node: t(DocumentAcceptance.SeveralRequired),
                },
            ]}
            {...props}
        />
    );
};

export default DocumentModeDropdown;
