import { Trash, Upload } from "iconoir-react";
import { useRef } from "react";
import { S3File } from "../../../generated/client/graphql";
import { useCreateS3File } from "../../hooks/createFileHook";
import { ButtonType } from "../Button/ButtonShared";
import ButtonSmall from "../Button/ButtonSmall";

export default function FileUpload({
    s3File,
    setS3File,
}: {
    s3File: S3File | undefined;
    setS3File: (s3File: S3File | undefined) => void;
}) {
    const ref = useRef<HTMLInputElement | null>(null);
    const handleUploadClick = () => {
        ref && ref.current && ref.current.click();
    };
    const { uploadFile } = useCreateS3File();
    const handleNewfile = async (file: File) => {
        const s3FileTemp = await uploadFile(file);
        setS3File(s3FileTemp as S3File);
    };

    const handleDeleteClick = () => {
        setS3File(undefined);
    };

    return (
        <div className="flex">
            {!s3File?.signedUrl && (
                <div className="space-y-4 flex flex-col justify-center items-center px-6 py-5 rounded border border-secondary bg-secondary">
                    {s3File?.signedUrl && (
                        <img className="w-11 h-11" src={s3File?.signedUrl} />
                    )}

                    <div className="text-sm font-regular text-secondary">
                        Image must be 256 * 256px - Max 2mb
                    </div>
                    <ButtonSmall
                        variant={ButtonType.OUTLINED}
                        IconRight={Upload}
                        text="Upload file"
                        onClick={handleUploadClick}
                    />
                    <input
                        ref={ref}
                        type="file"
                        hidden
                        onChange={(e) => {
                            e.target.files && handleNewfile(e.target.files[0]);
                        }}
                    />
                </div>
            )}
            {s3File?.signedUrl && (
                <div>
                    <div className="flex ">
                        <img
                            className="w-[88px] h-[88px] mr-4"
                            src={s3File?.signedUrl}
                        />
                        <div className="flex flex-col justify-center">
                            <ButtonSmall
                                variant={ButtonType.OUTLINED}
                                IconRight={Upload}
                                text="Change file"
                                onClick={handleUploadClick}
                            />
                            <input
                                ref={ref}
                                type="file"
                                hidden
                                onChange={(e) => {
                                    e.target.files &&
                                        handleNewfile(e.target.files[0]);
                                }}
                            />
                            <div className="mt-2">
                                <ButtonSmall
                                    variant={ButtonType.OUTLINED}
                                    IconRight={Trash}
                                    text="Delete"
                                    onClick={handleDeleteClick}
                                ></ButtonSmall>
                            </div>
                        </div>
                    </div>
                    <div className="text-sm font-regular text-secondary mt-2">
                        Image must be 256 * 256px - Max 2mb
                    </div>
                </div>
            )}
        </div>
    );
}
