import { S3FileRepository } from "@app/repositories/S3FileRepository";
import { type VersionRepository } from "@app/repositories/VersionRepository";

import {
    DocumentType,
    DocumentVersionInput,
    DocumentVersionPatch,
    DocumentVersionStatus,
    GetLastVersionByDocumentUrlQuery,
    UserRole,
    type DocumentVersion,
} from "@generated/client/graphql";

import { getFileFormatCategory, type FileFormatCategory } from "./utils";

type GetVersionWhere = {
    documentUrl: string | null;
};

export type S3FileAssociatedDocument = {
    id: string;
    hasAlreadyEntry: boolean;
    lastVersionId?: string;
    name: string;
    status?: DocumentVersionStatus;
    theme?: { name: string; color: string };
};

export type S3FileFromBulkUpload = {
    associatedDocuments?: S3FileAssociatedDocument[];
    /**
     * Actual id as in the stored file in the DB
     */
    id: string;
    name: string;
    fileURL: string;
    format: FileFormatCategory;
};

export const EditValidationType = {
    SendForReview: "SendForReview",
    ApproveDirectly: "ApproveDirectly",
    CsReview: "CsReview",
    PendingCsReview: "PendingCsReview",
    Approved: "Approved",
    Unknown: "Unknown",
};

export type GetVersionQuery = Omit<
    GetLastVersionByDocumentUrlQuery,
    "lastVersion"
> & {
    version?: DocumentVersion | null;
};

export async function getVersion(
    { versionRepository }: { versionRepository: VersionRepository },
    { documentUrl }: GetVersionWhere,
): Promise<GetVersionQuery> {
    if (!documentUrl) throw new Error("Document URL is required");

    const { lastVersion, ...res } =
        await versionRepository.getLastByDocumentURL(documentUrl);

    const version = lastVersion as DocumentVersion | undefined | null;
    return {
        ...res,
        version,
    };
}

export function extractVersionChecks(version?: DocumentVersion | null) {
    return version?.checks ?? [];
}

export function extractEditValidationType(
    version: DocumentVersion | null | undefined,
    role: UserRole | null | undefined,
) {
    const status = version?.status;
    const doctype = version?.document?.type;
    const tier = version?.document?.tier;

    if (
        status === DocumentVersionStatus.Draft ||
        status === DocumentVersionStatus.Rejected
    ) {
        if (
            doctype === DocumentType.MasterPolicy ||
            doctype === DocumentType.Policy ||
            tier === 1
        ) {
            return EditValidationType.SendForReview;
        } else {
            return EditValidationType.ApproveDirectly;
        }
    } else if (status === DocumentVersionStatus.PendingBeavrReview) {
        if (role === UserRole.BeavrAdmin) {
            return EditValidationType.CsReview;
        } else {
            return EditValidationType.PendingCsReview;
        }
    } else if (status === DocumentVersionStatus.Approved) {
        return EditValidationType.Approved;
    } else {
        return EditValidationType.Unknown;
    }
}

export function versionRequiresValidation(version?: DocumentVersion | null) {
    return version?.document?.type === DocumentType.MasterPolicy;
}

export function versionChecksRatio(version?: DocumentVersion | null) {
    const checks = extractVersionChecks(version);
    const totalChecks = checks.length;
    const checkedChecks = checks.reduce(
        (acc, { checked }) => acc + (checked ? 1 : 0),
        0,
    );

    return {
        num: totalChecks === 0 ? 1 : checkedChecks / totalChecks,
        total: totalChecks,
        str: `${checkedChecks}/${totalChecks}`,
    };
}

export async function saveVersionData(
    { versionRepository }: { versionRepository: VersionRepository },
    versionId: string,
) {
    return versionRepository.updateDocumentData({ id: versionId });
}

export async function sendForReview(
    { versionRepository }: { versionRepository: VersionRepository },
    version: DocumentVersion,
) {
    if (version.withEditor)
        await versionRepository.updateDocumentData({ id: version.id });
    return versionRepository.update({
        id: version.id,
        patch: {
            status: DocumentVersionStatus.PendingBeavrReview,
            generatePdf: !!version.withEditor,
        },
    });
}

export async function reject(
    { versionRepository }: { versionRepository: VersionRepository },
    versionId: string,
    { userRole }: { userRole?: UserRole },
) {
    if (userRole !== UserRole.BeavrAdmin) {
        throw new Error("Only admins can reject versions");
    }
    return versionRepository.update({
        id: versionId,
        patch: {
            status: DocumentVersionStatus.Rejected,
        },
    });
}

export async function setToDraft(
    { versionRepository }: { versionRepository: VersionRepository },
    versionId: string,
) {
    return versionRepository.update({
        id: versionId,
        patch: {
            status: DocumentVersionStatus.Draft,
        },
    });
}

export async function approve(
    { versionRepository }: { versionRepository: VersionRepository },
    version: DocumentVersion,
) {
    const shouldGeneratePdf =
        version.withEditor &&
        (version.status === DocumentVersionStatus.Draft ||
            version.status === DocumentVersionStatus.Rejected ||
            version.status === DocumentVersionStatus.PendingBeavrReview);

    return versionRepository.update({
        id: version.id,
        patch: {
            status: DocumentVersionStatus.Approved,
            generatePdf: shouldGeneratePdf,
        },
    });
}

export async function initializeVersionTipTapDoc(
    { versionRepository }: { versionRepository: VersionRepository },
    filter: GetVersionWhere,
) {
    const { version } = await getVersion({ versionRepository }, filter);
    if (!version) throw new Error("Version not found");
    if (version.tipTapDocId) return;

    return versionRepository.addTipTapDoc({ versionId: version?.id });
}

export async function updateFinalFile(
    {
        s3FileRepository,
        versionRepository,
    }: {
        s3FileRepository: S3FileRepository;
        versionRepository: VersionRepository;
    },
    { versionId }: { versionId: string },
    { file }: { file: File },
) {
    const { createEmptyFile: emptyS3File } =
        await s3FileRepository.createOneEmpty(file.name);

    const [updatedVersion] = await Promise.all([
        versionRepository.update({
            id: versionId,
            patch: {
                finalFileId: emptyS3File.id,
            },
        }),
        emptyS3File.putUrl &&
            s3FileRepository.uploadToBucket(file, { url: emptyS3File.putUrl }),
    ]);

    return updatedVersion;
}

export async function bulkFileUploadToS3(
    {
        s3FileRepository,
    }: {
        s3FileRepository: S3FileRepository;
    },
    { files }: { files: File[] },
): Promise<{
    list: S3FileFromBulkUpload[];
    byId: Record<string, S3FileFromBulkUpload>;
}> {
    const nameToFile = files.reduce(
        (acc, file) => {
            acc[file.name] = file;
            return acc;
        },
        {} as Record<string, File>,
    );

    // Create a map filename 2 file, it'll be helpful later to track the files
    const fileNames = Object.keys(nameToFile);
    // create empty files on our DB
    const { createEmptyFiles: s3Files } = await s3FileRepository
        .createManyEmpty(fileNames)
        .catch(() => {
            throw new Error("Failed to create empty files");
        });
    // upload files to S3
    await Promise.all(
        s3Files.map(({ name, putUrl }) => {
            const file = nameToFile[name as keyof typeof nameToFile];
            return (
                putUrl && s3FileRepository.uploadToBucket(file, { url: putUrl })
            );
        }),
    ).catch((e: Error) => {
        throw new Error(
            `Failed to upload files: ${s3Files} to S3 with error : ${e?.name}, ${e?.message}`,
        );
    });

    // Create and return the files from bulk upload;
    const list: S3FileFromBulkUpload[] = s3Files.map((file) => {
        const { id, name } = file;
        const fileURL = name ? URL.createObjectURL(nameToFile[name]) : "";
        const format = getFileFormatCategory(name!.split(".").pop() ?? "");

        return {
            id,
            name: name!,
            format,
            fileURL,
            hasAlreadyEntry: false,
        };
    });
    const byId = list.reduce(
        (acc, file) => {
            acc[file.id] = file;
            return acc;
        },
        {} as Record<string, S3FileFromBulkUpload>,
    );

    return { list, byId };
}

export async function createOrUpdateManyVersionsFromBulkS3FilesUpload(
    {
        versionRepository,
    }: {
        versionRepository: VersionRepository;
    },
    files: S3FileFromBulkUpload[],
) {
    // Get all files that have associated documents
    const filesWithDocuments = files.filter(
        (file) => file.associatedDocuments?.length,
    );
    if (!filesWithDocuments.length) return;

    // Get versions to create and make input for mutation
    const versionsToCreate = filesWithDocuments.reduce((acc, file) => {
        if (!file.associatedDocuments?.length) return acc;

        const inputs =
            file.associatedDocuments
                .filter((doc) => !doc.hasAlreadyEntry) // take only documents without entries
                .map((doc) => ({
                    documentId: doc.id,
                    versionDetails: { s3FileId: file.id, status: doc.status },
                })) ?? [];
        return [...acc, ...inputs];
    }, [] as DocumentVersionInput[]);

    // Get versions to update and make input for mutation
    const versionsToUpdate = filesWithDocuments.reduce(
        (acc, file) => {
            if (!file.associatedDocuments?.length) return acc;

            const input =
                file.associatedDocuments
                    .filter((doc) => doc.hasAlreadyEntry && !!doc.lastVersionId) // take only documents with entries
                    .map((doc) => ({
                        id: doc.lastVersionId!,
                        patch: { finalFileId: file.id, status: doc.status },
                    })) ?? [];
            return [...acc, ...input];
        },
        [] as {
            id: string;
            patch: DocumentVersionPatch;
        }[],
    );

    // Create versions
    const createVersionsPromise = versionsToCreate.length
        ? versionRepository.createMany({
              documentVersions: versionsToCreate,
          })
        : Promise.resolve([]);

    // Update versions
    const updateVersionsPromise = Promise.all(
        versionsToUpdate.map(({ id, patch }) =>
            versionRepository.update({ id, patch }),
        ),
    );

    await Promise.all([createVersionsPromise, updateVersionsPromise]);
}
