export const reportingKeys = {
    indicators: "indicators2",
    datapointGroups: "datapointGroups",
    createDatapointGroup: "createDatapointGroup",
    updateIndicatorEntityConfig: "updateIndicatorEntityConfig",
    sendReminders: "sendReminders",
    updateManyIndicatorEntityConfig: "updateManyIndicatorEntityConfig",
    upsertDatapoint: "upsertDatapointV2",
    exportReporting: "exportReporting",
    exportReportingFiles: "exportReportingFiles",
    reportingContributors: "reportingContributors",
    updateIndicator: "updateIndicator",
} as const;

export const dashboardKeys = {
    dashboardPlanSteps: "dashboardPlanSteps",
    updateDashboardPlanStep: "updateDashboardPlanStep",
} as const;

export const userKeys = {
    users: "users",
    currentUser: "currentUser",
    currentUserNoTenant: "currentUserNoTenant",
    currentUserOrgsAtom: "currentUserOrgsAtom",
} as const;

export const versionKeys = {
    bulkS3FileUploadToS3: "bulkS3FileUploadToS3",
    createManyVersions: "createManyVersions",
    createOrUpdateManyVersionsFromBulkS3FilesUpload:
        "createOrUpdateManyVersionsFromBulkS3FilesUpload",
    initializeVersionTipTapDoc: "initializeVersionTipTapDoc",
    versionByDocumentURL: "versionByDocumentURL",
    updateVersionCheck: "updateVersionCheck",
    requestForApproval: "requestForApproval",
    reject: "reject",
    setToDraft: "setToDraft",
    updateFinalFile: "updateFinalFile",
    deleteS3File: "deleteS3File",
    deleteS3Files: "deleteS3Files",
} as const;

export const themeKeys = {
    themes: "themes",
};

export const tagKeys = {
    tags: "tags",
    createTag: "createTag",
    updateTag: "updateTag",
};

export const documentKeys = {
    GetDocumentsV2: "GetDocumentsV2",
    createCustomDocument: "createCustomDocument",
    documentsForSearch: "documentsForSearch",
    updateManyDocuments: "updateManyDocuments",
} as const;

export const frameworkKeys = {
    frameworks: "frameworks",
} as const;
