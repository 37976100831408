import { TextareaHTMLAttributes, useEffect, useRef, useState } from "react";
import { cn } from "../../../lib/utils";

interface IProps {
    text: string | undefined;
    setText?: (t: string | undefined) => void;
    onBlurCallback?: (text: string | null) => void;
    placeholder?: string;
    hint?: string;
    success?: boolean;
    error?: string;
    containerClassName?: string;
}
const TextAreaInput = ({
    text,
    setText,
    onBlurCallback,
    placeholder,
    hint,
    success,
    error,
    className,
    containerClassName,
    ...props
}: IProps & TextareaHTMLAttributes<HTMLTextAreaElement>) => {
    const [textString, setTextString] = useState(text || "");

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const textAreaAdjust = () => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "1px";
            textAreaRef.current.style.height =
                textAreaRef.current.scrollHeight + "px";
        }
    };

    useEffect(() => {
        textAreaAdjust();
    }, [textString]);

    useEffect(() => {
        setTextString(text || "");
    }, [text]);

    return (
        <div>
            <div
                className={cn(
                    "group px-3 py-1.5 rounded-lg border border-teritiary bg-white focus-within:border-primary",
                    error && "border-[2px] border-danger",
                    success && "border-green",
                    props.disabled && "bg-transparent",
                    containerClassName
                )}
            >
                <label>
                    {placeholder && (
                        <div
                            className={cn(
                                "font-regular text-secondary cursor-text text-sm"
                            )}
                        >
                            {placeholder}
                        </div>
                    )}
                    <textarea
                        ref={textAreaRef}
                        className={cn(
                            "bg-transparent w-full p-0 font-default text-base font-regular leading-[20px] border-0 focus:ring-0 placeholder:text-neutral-500 resize-none disabled:text-neutral-500",
                            className
                        )}
                        value={textString}
                        onChange={(e) => {
                            setTextString(e.target.value);
                            setText && setText(e.target.value || undefined);
                        }}
                        onBlur={() => {
                            onBlurCallback &&
                                onBlurCallback(textString || null);
                        }}
                        {...props}
                    />
                </label>
            </div>
            {error && (
                <div className="text-sm font-regular text-danger">{error}</div>
            )}
        </div>
    );
};

export default TextAreaInput;
