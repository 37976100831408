import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { Outlet, useSearchParams } from "react-router-dom";
import Loading from "../components/Loading";
import SaveBar from "../components/SaveBar";
import Toast from "../components/Toast";
import useHubspotChat from "../lib/hubspot";
import { useTrackingIdentify } from "../lib/tracking/utils";
import useAuth0Wrapper from "./auth0Wrapper";
import { useLogoutIfNotPasswordless } from "./passwordlessMigration";

export const MainLoggedInApp = () => {
    if (import.meta.env.VITE_REACT_APP_AUTH_MODE !== "development") {
        useHubspotChat();
    }
    useTrackingIdentify();
    return (
        <PostHogProvider client={posthog}>
            <SaveBar />
            <Toast />
            <Outlet />
        </PostHogProvider>
    );
};

const LoginRequired = () => {
    const [searchParams, _] = useSearchParams();
    const emailPassword = searchParams.get("emailPassword") === "true";

    const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0Wrapper();
    useLogoutIfNotPasswordless();
    if (isAuthenticated) {
        return <MainLoggedInApp />;
    } else if (isLoading) {
        return <Loading size="large" />;
    } else if (!isLoading && !isAuthenticated) {
        if (searchParams.get("email")) {
            loginWithRedirect({
                authorizationParams: {
                    login_hint: searchParams.get("email") || "",
                    connection: "email",
                },
                appState: {
                    returnTo:
                        window.location.pathname !== "/"
                            ? window.location.pathname
                            : "/o",
                },
            });
        } else {
            loginWithRedirect({
                ...(!emailPassword
                    ? {
                          authorizationParams: {
                              connection: "email",
                          },
                      }
                    : {}),
                appState: {
                    returnTo:
                        window.location.pathname !== "/"
                            ? window.location.pathname
                            : "/o",
                },
            });
        }
        return <></>;
    } else return <div>Error...</div>;
};

export default LoginRequired;
