import {
    AddTipTapDocDocument,
    DocumentVersionPatch,
    GetLastVersionByDocumentUrlDocument,
    UpdateDocumentDataDocument,
    UpdateVersionCheckDocument,
    UpdateVersionDocument,
    type GetLastVersionByDocumentUrlQuery,
    type MutationUpdateDocumentVersionCheckArgs,
    CreateManyVersionsDocument,
    CreateManyVersionsInput,
} from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { type VersionRepository } from "../VersionRepository";
import { graphqlClientKoyeb } from "../clients";

export class GraphQLVersionRepository implements VersionRepository {
    addTipTapDoc({ versionId }: { versionId: string }) {
        return graphqlClientKoyeb.request(
            AddTipTapDocDocument,
            { versionId },
            AccessHeadersHolder.getHeaders(),
        );
    }
    createMany({ documentVersions }: CreateManyVersionsInput) {
        return graphqlClientKoyeb.request(
            CreateManyVersionsDocument,
            { set: { documentVersions } },
            AccessHeadersHolder.getHeaders(),
        );
    }
    getLastByDocumentURL = (
        documentUrl: string,
    ): Promise<GetLastVersionByDocumentUrlQuery> =>
        graphqlClientKoyeb.request(
            GetLastVersionByDocumentUrlDocument,
            { documentUrl },
            AccessHeadersHolder.getHeaders(),
        );
    update({ id, patch }: { id: string; patch: DocumentVersionPatch }) {
        return graphqlClientKoyeb.request(
            UpdateVersionDocument,
            { versionId: id, patch },
            AccessHeadersHolder.getHeaders(),
        );
    }

    updateDocumentData(args: { id: string }) {
        return graphqlClientKoyeb.request(
            UpdateDocumentDataDocument,
            { versionId: args.id },
            AccessHeadersHolder.getHeaders(),
        );
    }

    updateVersionCheck({ id, set }: MutationUpdateDocumentVersionCheckArgs) {
        return graphqlClientKoyeb.request(
            UpdateVersionCheckDocument,
            { id, set },
            AccessHeadersHolder.getHeaders(),
        );
    }
}
