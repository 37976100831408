import { Table } from "@tanstack/react-table";
import Checkbox from "../../../Checkbox";

export default function CheckboxHeader<T>({
    table,
    title,
}: {
    table: Table<T>;
    title?: string;
}) {
    return (
        <div className="flex flex-row space-x-2">
            <Checkbox
                checked={table.getIsAllRowsSelected()}
                onClick={(e) => {
                    e.stopPropagation();
                    table.toggleAllRowsSelected(!table.getIsAllRowsSelected());
                }}
                aria-label="Select all"
            />
            {title && (
                <div className="text-sm font-bold text-neutral-700">
                    {title}
                </div>
            )}
        </div>
    );
}
