import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { useTranslation } from "react-i18next";
import { DocumentExpirationStatus } from "../../../../../generated/client/graphql";
import MultiSelectLayout from "../../components/dropdowns/MultiSelectLayout";
import "./i18n";

const ExpirationStatusDropdown = ({
    status,
    onStatusChange,
    ...props
}: {
    status?: DocumentExpirationStatus[];
    onStatusChange: (status: DocumentExpirationStatus[] | undefined) => void;
} & DropdownPrimitive.DropdownMenuContentProps) => {
    const { t } = useTranslation("ExpirationStatusDropdown");
    return (
        <MultiSelectLayout
            disableSearch
            activeValueIds={status}
            onActiveValueIdsChange={(s: (string | null)[] | undefined) =>
                s
                    ? onStatusChange(s as DocumentExpirationStatus[])
                    : onStatusChange(undefined)
            }
            values={[
                {
                    id: DocumentExpirationStatus.NotStarted,
                    node: t(DocumentExpirationStatus.NotStarted),
                },
                {
                    id: DocumentExpirationStatus.Draft,
                    node: t(DocumentExpirationStatus.Draft),
                },
                {
                    id: DocumentExpirationStatus.PendingBeavrReview,
                    node: t(DocumentExpirationStatus.PendingBeavrReview),
                },
                {
                    id: DocumentExpirationStatus.Rejected,
                    node: t(DocumentExpirationStatus.Rejected),
                },
                {
                    id: DocumentExpirationStatus.ExpiresSoon,
                    node: t(DocumentExpirationStatus.ExpiresSoon),
                },
                {
                    id: DocumentExpirationStatus.Ok,
                    node: t(DocumentExpirationStatus.Ok),
                },
            ]}
            {...props}
        />
    );
};

export default ExpirationStatusDropdown;
