import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "LoadIndicatorTable", {
    uname: "Uname/id col",
    name: "Name col",
    theme: "Theme col",
    subtheme: "Subtheme col",
    unit: "Unit col",
    tags: "Tags col",
    create: "Create {{count}} indicators",
    ready: "Congratulations, you are ready to create indicators",
});

i18next.addResourceBundle("fr", "LoadIndicatorTable", {
    uname: "Col uname/id",
    name: "Col nom",
    theme: "Col thème",
    subtheme: "Col sous-thème",
    unit: "Col unité",
    tags: "Col tags",
    create: "Créer {{count}} indicateurs",
    ready: "Félicitations, vous êtes prêt à créer des indicateurs",
});
