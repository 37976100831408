import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "SubthemeDropdown", {
    placeholder: "Select subtheme",
    setter_text: "{{count}} subthemes selected",
});

i18next.addResourceBundle("fr", "SubthemeDropdown", {
    placeholder: "Sélectionner un sous-thème",
    setter_text: "{{count}} sous-thèmes sélectionnés",
});
