import { useMouse, useWindowScroll } from "@uidotdev/usehooks";
import { atom, useSetAtom } from "jotai";
import { useEffect } from "react";

export const activeGroupIdxAtom = atom<number | undefined>(undefined);

export const useNavigate = (maxIdx: number) => {
    const [{ y }, scrollTo] = useWindowScroll();
    const setActivegroupIdx = useSetAtom(activeGroupIdxAtom);

    const [mouse] = useMouse();
    const scrollToGroup = (idx: number) => {
        const element = document.getElementById(`${idx}`);

        scrollTo({
            left: 0,
            top: (element?.offsetTop || 0) - 140,
            behavior: "smooth",
        });
    };
    useEffect(() => {
        const idx = Array.from({ length: maxIdx }, (_, i) => i).findIndex(
            (idx) => {
                if (y === null) return false;
                const element = document.getElementById(`${idx}`);

                if ((element?.offsetTop || 0) > y + 140) {
                    return true;
                }
                return false;
            }
        );
        idx > -1 ? setActivegroupIdx(idx - 1) : setActivegroupIdx(undefined);
    }, [y, mouse.y]);

    return { scrollToGroup };
};
