import Checkbox from "@app/components/Checkbox";
import { Box } from "@design-system/Layout/Box";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { type FC } from "react";

type CheckedCartProps = {
    id: string;
    title: string;
    description: string;
    disabled?: boolean;
    checked: boolean;
    onCheck: (id: string, checked: boolean) => void;
};

export const CheckCard: FC<CheckedCartProps> = ({
    id,
    title,
    description,
    disabled = false,
    checked,
    onCheck,
}) => {
    const handleClick = () => {
        !disabled && onCheck(id, !checked);
    };
    return (
        <FlexRow
            as="article"
            br="xl"
            className={cn(
                "border border-tertiary bg-secondary",
                !disabled && "cursor-pointer",
            )}
            elevation="b-100"
            onClick={handleClick}
            p="3"
            gap="3"
        >
            <Box p="0.5">
                <Checkbox checked={checked} disabled={disabled} />
            </Box>
            <Box>
                <Text as="h4" variant="body-sm-bold">
                    {title}
                </Text>
                <Text variant="body-sm">{description}</Text>
            </Box>
        </FlexRow>
    );
};
