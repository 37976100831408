import { useAtomValue, useSetAtom } from "jotai";
import { selectAtom } from "jotai/utils";
import { useMemo } from "react";
import Switch from "../../../../components/Switch";
import {
    AvailableSubtheme,
    updateSubthemeInputsAtom,
    updateSubthemeInputsSetterAtom,
} from "../ThemesList";

export function SubthemeItem({ item }: { item: AvailableSubtheme }) {
    const subthemeUpdateAtom = useMemo(
        () =>
            selectAtom(updateSubthemeInputsAtom, (inputs) =>
                inputs.find((i) => i.id === item.id)
            ),
        [item.id]
    );
    const updateValue = useAtomValue(subthemeUpdateAtom);
    const setUpdate = useSetAtom(updateSubthemeInputsSetterAtom);
    const toggled =
        updateValue?.set.active !== undefined &&
        updateValue?.set.active !== null
            ? updateValue?.set.active
            : item.active;
    return (
        <div className="flex space-x-2 items-center">
            <Switch
                key={item.id}
                selected={toggled}
                value={item.id}
                onClick={() => {
                    setUpdate({
                        id: item.id,
                        set: { active: !toggled },
                    });
                }}
            />
            <div className={`${toggled ? "text-primary" : "text-secondary"}`}>
                {item.name}
            </div>
        </div>
    );
}
