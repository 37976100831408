import OrgTypeSelect from "@app/components/Dropdown/dropdowns/OrgType/select";
import { useQueryClient } from "@tanstack/react-query";
import { Cancel, Plus, Trash } from "iconoir-react";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    GetBeavrAdminDocument,
    OrgType,
    UpdateOrgDocument,
} from "../../../../generated/client/graphql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../QueryClientWithHeaders";
import ButtonRegular from "../../../components/Button/ButtonRegular";
import { ButtonType } from "../../../components/Button/ButtonShared";
import InputSimple from "../../../components/InputSimple";
import {
    CenterModal,
    CenterModalContent,
} from "../../../components/Modal/CenterModal";
import { cn } from "../../../lib/utils";
import { orgToUpdateAtom } from "../ctx";
import { userOrganizationsKeys } from "../data";
import "./i18n";

const EditModal = () => {
    const { t } = useTranslation("EditOrgModal");

    const [org, setOrg] = useAtom(orgToUpdateAtom);
    const [name, setName] = useState(org?.tenant?.name);
    const [toDeletePermissions, setToDeletePermissions] = useState<string[]>(
        [],
    );
    const [toAddUserIds, setToAddUserIds] = useState<string[]>([]);
    const [type, setType] = useState<OrgType>();
    const client = useQueryClient();
    const { isPending, mutate } = useGraphQLMutation(
        UpdateOrgDocument,
        {},
        true,
    );
    const { data: beavrAdminData } = useGraphQL(
        GetBeavrAdminDocument,
        undefined,
        undefined,
        true,
    );
    const onSave = () => {
        org &&
            mutate(
                {
                    tenantId: org.tenantId,
                    name: name,
                    addBeavrUserIds: toAddUserIds,
                    removePermissionIds: toDeletePermissions,
                    type: type,
                },
                {
                    onSuccess: () => {
                        client.invalidateQueries({
                            queryKey: userOrganizationsKeys.all,
                        });
                        setOrg(undefined);
                    },
                },
            );
    };

    useEffect(() => {
        setName(org?.tenant?.name);
        setToDeletePermissions([]);
        setToAddUserIds([]);
        setType(org?.type);
    }, [org]);

    const addToDeletePermission = (id: string) => {
        setToDeletePermissions([...toDeletePermissions, id]);
    };
    const removeToDeletePermission = (id: string) => {
        setToDeletePermissions(toDeletePermissions.filter((i) => i !== id));
    };
    const addToAddUserIds = (id: string) => {
        setToAddUserIds([...toAddUserIds, id]);
    };
    const removeToAddUserIds = (id: string) => {
        setToAddUserIds(toAddUserIds.filter((i) => i !== id));
    };
    return (
        <CenterModal
            open={org !== undefined}
            onOpenChange={(open) => !open && setOrg(undefined)}
            modal={false}
        >
            <CenterModalContent
                title={t("title")}
                actions={
                    <ButtonRegular
                        text={t("save")}
                        loading={isPending}
                        onClick={onSave}
                    />
                }
            >
                <InputSimple
                    title={t("name")}
                    value={name || ""}
                    onChange={(e) => setName(e.target.value)}
                />
                <InputSimple
                    title={t("uname")}
                    value={org?.tenant?.uname}
                    disabled
                />
                <OrgTypeSelect type={type} onTypeChange={setType} />

                <div className=" grid grid-cols-2 gap-2">
                    <div>
                        <div className="font-bold"> Existing permisssions</div>
                        {org?.beavrUserPermissions?.map((perm) => {
                            const isToDelete = toDeletePermissions.includes(
                                perm.id,
                            );
                            return (
                                <div
                                    key={perm.id}
                                    className=" flex justify-between items-center"
                                >
                                    <div
                                        className={cn(
                                            "text-sm",
                                            isToDelete && "line-through",
                                        )}
                                    >
                                        {perm.user.firstName}{" "}
                                        {perm.user.lastName}
                                    </div>

                                    {!isToDelete ? (
                                        <ButtonRegular
                                            IconLeft={Trash}
                                            variant={ButtonType.TEXT}
                                            onClick={() =>
                                                addToDeletePermission(perm.id)
                                            }
                                        />
                                    ) : (
                                        <ButtonRegular
                                            IconLeft={Cancel}
                                            variant={ButtonType.TEXT}
                                            onClick={() =>
                                                removeToDeletePermission(
                                                    perm.id,
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <div>
                        <div className=" font-bold">Add permissions</div>
                        {beavrAdminData?.beavrUsers
                            .filter(
                                (u) =>
                                    !org?.userPermissions?.some(
                                        (p) => p.user.id === u.id,
                                    ),
                            )
                            .map((user) => {
                                const isToAdd = toAddUserIds.includes(user.id);
                                return (
                                    <div
                                        key={user.id}
                                        className="flex justify-between items-center"
                                    >
                                        <div
                                            className={cn(
                                                "text-sm",
                                                isToAdd &&
                                                    "font-bold text-green",
                                            )}
                                        >
                                            {user.firstName} {user.lastName}
                                        </div>
                                        {!isToAdd ? (
                                            <ButtonRegular
                                                IconLeft={Plus}
                                                variant={ButtonType.TEXT}
                                                onClick={() =>
                                                    addToAddUserIds(user.id)
                                                }
                                            />
                                        ) : (
                                            <ButtonRegular
                                                IconLeft={Cancel}
                                                variant={ButtonType.TEXT}
                                                onClick={() =>
                                                    removeToAddUserIds(user.id)
                                                }
                                            />
                                        )}
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </CenterModalContent>
        </CenterModal>
    );
};
export default EditModal;
