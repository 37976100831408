import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { Plus } from "iconoir-react";
import {
    GetTagsDocument,
    Tag,
    TagType,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import { ButtonType } from "../../../Button/ButtonShared";
import ButtonXSmall from "../../../Button/ButtonXSmall";
import { DropdownTrigger } from "../../components";
import TagDropdown from "./dropdown";
import "./i18n";

const TagsSetter = ({
    type,
    tagIds,
    onTagIdsChange,
    open,
    setOpen,
}: {
    type: TagType;
    tagIds: string[] | undefined;
    onTagIdsChange: (ids: string[]) => void;
    open?: boolean;
    setOpen?: (open: boolean) => void;
}) => {
    const getTags = useGraphQL(
        GetTagsDocument,
        { type: type },
        undefined,
        true
    );

    return (
        <DropdownMenu open={open} onOpenChange={setOpen}>
            <DropdownTrigger asChild>
                <ButtonXSmall
                    IconLeft={Plus}
                    variant={ButtonType.FLAT_PRIMARY}
                />
            </DropdownTrigger>
            <TagDropdown
                tagIds={tagIds}
                onTagIdsChange={onTagIdsChange}
                tags={getTags.data?.tags as Tag[] | undefined}
                type={type}
            />
        </DropdownMenu>
    );
};

export default TagsSetter;
