import { Link, useNavigate } from "react-router-dom";
import { useCallback } from "react";

/**
 * Makes the given path an absolute URL.
 *
 * @param {string} path The path to make absolute.
 * @return {string} The absolute URL.
 */
export function makeAbsoluteUrl(path: string) {
    return new URL(path, window.location.origin).href;
}

/**
 * Returns a memoized callback function that navigates to the homepage when called.
 *
 * @return {Function} The function that navigates to the homepage.
 */
export function useGoToHomepage() {
    const navigate = useNavigate();

    return useCallback(() => {
        navigate("/o");
    }, [navigate]);
}

/**
 * Checks if the current page is in edit mode.
 *
 * @return {boolean} True if the current page is in edit mode, false otherwise.
 */
export function isEditMode() {
    return !!window?.location.pathname.endsWith("/edit");
}

/**
 * Type of the handle property of a router item with a crumb and page.
 * Used for composing breadcrumb components.
 */
type HandleWithCrumb = {
    crumb: typeof Link;
    page: string;
};

/**
 * Type of the handle property of a router item.
 * It is a union of several types, according on
 * the use case of the handle.
 *
 * So far, it only includes the usecase for the breadcrumb.
 * React-router sets it to `unknown`.
 */
export type Handle = HandleWithCrumb | {};

/**
 * Checks if the given item is a handle with a crumb and page.
 *
 * @param {any} item The item to check.
 * @return {boolean} True if the item is a handle, false otherwise.
 */
export function isHandleWithCrumb(item: any): item is HandleWithCrumb {
    return !!item?.crumb && !!item?.page;
}
