import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import { Framework } from "@generated/client/graphql";
import { FileSpreadsheet } from "lucide-react";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { useFrameworkExport } from "@app/screens/Frameworks/framework/hooks/framework";
import "@app/screens/Frameworks/framework/i18n";
import DocumentModal from "../DocumentModal/Modal";

type ExportSectionProps = {
    framework?: Framework;
};

export const ExportSection: FC<ExportSectionProps> = ({ framework }) => {
    const { t } = useTranslation("Framework");
    const { exportRequirements, isExportingRequirements } = useFrameworkExport(
        framework?.id,
    );

    const handleRequirementsExport = () => {
        if (framework && !isExportingRequirements) exportRequirements();
    };

    return (
        <FlexRow
            justifyContent="end"
            className="border-b border-b-tertiary"
            gap="2"
            py="2"
            px="4"
            w="full"
        >
            <Button onClick={handleRequirementsExport} variant="text">
                <FileSpreadsheet className="text-beavrGreen w-4" />
                {t("export_requirements")}
            </Button>
            {framework && <DocumentModal frameworkId={framework.id} />}
        </FlexRow>
    );
};
