import * as SelectPrimitive from "@radix-ui/react-select";
import * as dayjs from "dayjs";
import { NavArrowDown } from "iconoir-react";
import { useTranslation } from "react-i18next";
import { ReactComponent as FranceFlag } from "../../../../assets/flags/france.svg";
import { ReactComponent as UkFlag } from "../../../../assets/flags/uk.svg";
import SelectItem from "../../../../components/Dropdown/SelectItem";
import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "ChooseLanguage", {
    french: "French",
    english: "English",
});
i18next.addResourceBundle("fr", "ChooseLanguage", {
    french: "Français",
    english: "Anglais",
});

const ChooseLanguage = () => {
    const { i18n, t } = useTranslation("ChooseLanguage");
    const currentLanguage = i18n.language;
    const onChange = (locale: string) => {
        i18next.changeLanguage(locale);
        dayjs.locale(locale);
    };

    return (
        <SelectPrimitive.Root value={currentLanguage} onValueChange={onChange}>
            <SelectPrimitive.Trigger className="flex items-center space-x-1.5">
                {currentLanguage === "fr" ? <FranceFlag /> : <UkFlag />}
                <NavArrowDown className="w-4 h-4" />
            </SelectPrimitive.Trigger>

            <SelectPrimitive.Portal>
                <SelectPrimitive.Content
                    position="popper"
                    sideOffset={5}
                    className="z-10 p-1 rounded-lg bg-white shadow-dropdown min-w-[164px]"
                    align="end"
                >
                    <SelectItem value="fr">{t("french")}</SelectItem>
                    <SelectItem value="en">{t("english")}</SelectItem>
                </SelectPrimitive.Content>
            </SelectPrimitive.Portal>
        </SelectPrimitive.Root>
    );
};

export default ChooseLanguage;
