import { Variant } from "..";
import { ReactComponent as Progress } from "../../../../assets/progress.svg";
import { ReactComponent as Status } from "../../../../assets/Status.svg";

interface TinyStatusTagProps {
    variant: Variant;
    children: React.ReactNode;
}

export default function TinyStatusTag({
    variant,
    children,
}: TinyStatusTagProps) {
    switch (variant) {
        case Variant.Done:
            return (
                <div className="whitespace-nowrap bg-[#EEFFF2] flex justify-center items-center border border-[#01755f33] rounded text-xs font-bold text-neutral-700 px-2 py-1 box-border h-[24px]">
                    <Status className="w-4 mr-[6px]" /> {children}
                </div>
            );
        case Variant.InProgress:
        case Variant.Answered:
            return (
                <div className="whitespace-nowrap bg-[#F2FDFE] flex justify-center items-center border border-[#3b618c33] rounded text-xs font-bold text-neutral-700 px-2 py-1 box-border h-[24px]">
                    <Progress className="w-4 mr-[6px]" /> {children}
                </div>
            );
        case Variant.Base:
            return (
                <div
                    className="whitespace-nowrap bg-white flex justify-center items-center border border-[#D3D3D3] rounded text-xs font-bold text-neutral-700
                        px-2 py-1 box-border h-[24px]"
                >
                    {children}
                </div>
            );
        default:
            return null;
    }
}
