import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

interface ActionItemProps {
    icon: React.FunctionComponent<any>;
    title: string;
}
export default function ActionItemDanger(
    props: ActionItemProps & DropdownMenu.DropdownMenuItemProps
) {
    return (
        <DropdownMenu.Item
            className="cursor-pointer group bg-white w-[280px] flex items-center space-x-2.5 px-2 py-2.5 focus:outline-error-500"
            {...props}
        >
            <props.icon
                className={`text-danger h-4 w-4  ${
                    props.disabled && "text-danger/20"
                }`}
            />
            <div
                className={`text-danger ${props.disabled && "text-danger/20"}`}
            >
                {props.title}
            </div>
        </DropdownMenu.Item>
    );
}
