import * as ToastPrimitive from "@radix-ui/react-toast";
import { Cancel, CheckCircle } from "iconoir-react";
import { atom, useAtom, useSetAtom } from "jotai";
import { cn } from "../../lib/utils";
import "./i18n";

interface ToastState {
    open: boolean;
    success?: string;
    error?: string;
}

const toastStateAtom = atom<ToastState>({
    open: false,
    success: undefined,
    error: undefined,
});

export const setToastSuccessAtom = atom(null, (_, set, success: string) => {
    set(toastStateAtom, {
        open: true,
        success: success,
        error: undefined,
    });
});
export const setToastErrorAtom = atom(null, (_, set, error: string) => {
    set(toastStateAtom, {
        open: true,
        success: undefined,
        error: error,
    });
});

export const useSetToast = () => {
    const setToastSuccess = useSetAtom(setToastSuccessAtom);
    const setToastError = useSetAtom(setToastErrorAtom);

    return { setToastSuccess, setToastError };
};
export default function Toast({ ...props }: ToastPrimitive.ToastProps) {
    const [state, setState] = useAtom(toastStateAtom);

    return (
        <>
            <ToastPrimitive.Root
                duration={3000}
                open={state.open}
                onOpenChange={(o) => setState({ ...state, open: o })}
                {...props}
                className={cn(
                    "rounded-[7px] w-[340px] flex items-center justify-between px-4 py-3 space-x-3 ",
                    state.error ? "bg-red-600" : "bg-neutral-900",
                    "data-[state=open]:animate-[toastSlideIn_0.15s_ease-in-out]",
                    "data-[state=closed]:animate-[fadeOut_0.5s_ease-out]"
                )}
            >
                {state.error ? (
                    <Cancel className="text-white h-5 w-5" />
                ) : (
                    <CheckCircle className="text-green-300 h-5 w-5" />
                )}
                {/* {title && <ToastPrimitive.Title>{title}</ToastPrimitive.Title>} */}
                <ToastPrimitive.Description>
                    {state.success && (
                        <div className="text-white font-bold">
                            {state.success}
                        </div>
                    )}
                    {state.error && (
                        <div className="text-white font-bold">
                            {state.error}
                        </div>
                    )}
                </ToastPrimitive.Description>
                {/* {children && (
                <ToastPrimitive.Action asChild>
                    {children}
                </ToastPrimitive.Action>
            )} */}
                <ToastPrimitive.Close aria-label="Close">
                    <span aria-hidden>×</span>
                </ToastPrimitive.Close>
            </ToastPrimitive.Root>
            <ToastPrimitive.Viewport className="fixed top-0 right-0 p-5 z-[99912345]" />
        </>
    );
}
