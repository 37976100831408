import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "RequirementTable", {
    title: "Requirements",
    requirement: "Requirement",
    subtheme: "Subtheme",
    owner: "Owner",
    framework: "Framework",
    proofs: "Proofs",

    set_owner_text: "Choose owner for {{count}} items",
    set_relevant_text: "Mark as relevant",
    set_unrelevant_text: "Mark as not relevant",
    selection: "Selection ({{count}})",
    set_owner: "Assign owner",
});

i18next.addResourceBundle("fr", "RequirementTable", {
    title: "Requirements",
    requirement: "Requirement",
    owner: "Propriétaire",
    subtheme: "Sous-thème",
    framework: "Framework",
    proofs: "Preuves",

    set_owner_text: "Assigner un propriétaire à {{count}} éléments",
    set_relevant_text: "définir comme non pertinent",
    set_unrelevant_text: "définir comme non pertinent",
    selection: "Sélection ({{count}})",
    set_owner: "Assigner propriétaire",
});
