import FrameworkSection from "@app/screens/Frameworks/framework/components/Section";
import { CsrdEsrs } from "@generated/client/graphql";
import { useAtomValue } from "jotai";
import { filterAtom } from "../../ctx";
import { useEsrs } from "../../data";
import DisclosureRequirement from "../DisclosureRequirement";
import SectionHeader from "./SectionHeader";

const Esrs = ({ cmsId, pillarId }: { cmsId: string; pillarId?: string }) => {
    const filter = useAtomValue(filterAtom);
    const { esrs } = useEsrs(cmsId, pillarId, filter);

    return (
        <FrameworkSection>
            <SectionHeader esrs={esrs as CsrdEsrs | undefined} />
            {esrs?.disclosureRequirements.map(
                (dr, idx) =>
                    !!dr.datapoints?.length && (
                        <DisclosureRequirement
                            key={idx}
                            disclosureRequirement={dr}
                        />
                    ),
            )}
        </FrameworkSection>
    );
};

export default Esrs;
