import { UserScan } from "iconoir-react";
import { useTranslation } from "react-i18next";
import Container from "../../components/Container";
import BodyHeader from "../../shared/components/BodyHeader";
import ProfileSetting from "./components/ProfileSetting";

import "./i18n";

export default function Profile() {
    const { t } = useTranslation("Profile");
    return (
        <Container>
            <BodyHeader
                icon={UserScan}
                title={t("title")}
                subTitle={t("subtitle")}
            />

            <ProfileSetting />
        </Container>
    );
}
