import i18next from "../../../i18n";

i18next.addResourceBundle("en", "ItemsListEditor", {
    no_item: "No item added",
    press_button: "Press the button to add your first item",
});

i18next.addResourceBundle("fr", "ItemsListEditor", {
    no_item: "Aucun item ajouté",
    press_button: "Appuyez sur le bouton pour ajouter un item",
});
