// your-dropdown-menu.jsx
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import React, { ComponentType, Ref, forwardRef } from "react";
import { cn } from "../../lib/utils";

export const ActionDropdownMenu = DropdownMenuPrimitive.Root;
export const ActionDropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

export const ActionDropdownMenuContent = React.forwardRef(
    (
        { children, ...props }: DropdownMenuPrimitive.DropdownMenuContentProps,
        forwardedRef: Ref<HTMLDivElement>
    ) => {
        return (
            <DropdownMenuPrimitive.Portal>
                <DropdownMenuPrimitive.Content
                    ref={forwardedRef}
                    sideOffset={5}
                    align="end"
                    {...props}
                    className={cn(
                        "p-1 rounded-lg bg-white shadow-dropdown",
                        props.className
                    )}
                >
                    {children}
                </DropdownMenuPrimitive.Content>
            </DropdownMenuPrimitive.Portal>
        );
    }
);

interface ActionDropdownMenuItemProps {
    Icon: ComponentType<Partial<{ className: string }>>;
    text: string;
}
export const ActionDropdownMenuItem = forwardRef(
    (
        {
            Icon,
            text,
            ...props
        }: ActionDropdownMenuItemProps & DropdownMenuPrimitive.MenuItemProps,
        ref: Ref<HTMLDivElement>
    ) => {
        return (
            <DropdownMenuPrimitive.Item
                {...props}
                ref={ref}
                className="group flex items-center py-1.5 px-2 space-x-2.5 hover:bg-green-50 focus:outline-none focus:bg-green-50 rounded-lg cursor-pointer"
            >
                <Icon className="w-4 h-4 text-green" />
                <div className="text-secondary group-focus:text-green">
                    {text}
                </div>
            </DropdownMenuPrimitive.Item>
        );
    }
);

export const ActionDropdownMenuItemDanger = forwardRef(
    (
        {
            Icon,
            text,
            ...props
        }: {
            Icon: ComponentType<Partial<{ className: string }>>;
            text: string;
        } & DropdownMenuPrimitive.MenuItemProps,
        forwardedRef: Ref<HTMLDivElement>
    ) => {
        return (
            <DropdownMenuPrimitive.Item
                {...props}
                ref={forwardedRef}
                className={cn(
                    "flex py-1.5 px-2 space-x-2.5 cursor-pointer",
                    props.disabled && "opacity-50 cursor-default"
                )}
            >
                <Icon className="w-5 h-5 text-danger" />
                <div className=" text-sm font-bold text-danger ">{text}</div>
            </DropdownMenuPrimitive.Item>
        );
    }
);

export const ActionDropdownMenuSeparator = () => (
    <DropdownMenuPrimitive.Separator className=" min-w-max h-px mx-2 my-1 bg-tertiary" />
);
