import React, { HTMLProps, ReactNode, Ref } from "react";
import { cn } from "../../../../../lib/utils";

const InnerLayout = React.forwardRef(
    (
        {
            children,
            className,
            ...props
        }: { children: ReactNode } & HTMLProps<HTMLDivElement>,
        ref: Ref<HTMLDivElement>
    ) => {
        return (
            <div ref={ref} className={cn("p-6", className)} {...props}>
                {children}
            </div>
        );
    }
);

export default InnerLayout;
