import i18next from "../../../i18n";

i18next.addResourceBundle("en", "AssignementCard", {
    title: "Assignement",
    total: "Total",
    assigned: "Assigned",
    unassigned: "Unassigned",
});

i18next.addResourceBundle("fr", "AssignementCard", {
    title: "Assignement",
    total: "Total",
    assigned: "Assigné",
    unassigned: "Non assigné",
});
