import { AppState, Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import {
    Navigate,
    Outlet,
    createBrowserRouter,
    useNavigate,
} from "react-router-dom";
import { QueryClientWithHeadersWrapper } from "../QueryClientWithHeaders.tsx";
import Orgs from "../screens/Orgs/index.tsx";

import { Provider as ToastProvider } from "@radix-ui/react-toast";
import { TooltipProvider } from "@radix-ui/react-tooltip";

import { useSetDatadogRumUser } from "@app/datadog.tsx";
import { useFeatureFlagEnabled } from "posthog-js/react";
import LoginRequired from "../authentication/LoginRequired.tsx";
import SupplierContribution from "../screens/Contributor/SupplierContribution/index.tsx";
import SustainabilityCenter from "../screens/SustainabilityCenter/SustainabilityCenter/index.tsx";
import { AdminLayout, adminRoutes } from "./admin.tsx";
import { contributeRoutes } from "./contributor.tsx";

const Root = () => {
    const navigate = useNavigate();
    const onRedirectCallback = (appState?: AppState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };
    useSetDatadogRumUser();

    const rootNode = (
        <div className="relative text-primary scroll-smooth">
            <Auth0Provider
                domain={import.meta.env.VITE_REACT_APP_AUTH0_DOMAIN}
                clientId={import.meta.env.VITE_REACT_APP_AUTH0_CLIENT_ID}
                authorizationParams={{
                    redirect_uri: window.location.origin,
                    audience: import.meta.env.VITE_REACT_APP_AUTH0_AUDIENCE,
                }}
                onRedirectCallback={onRedirectCallback}
            >
                <QueryClientWithHeadersWrapper>
                    <TooltipProvider delayDuration={300}>
                        <ToastProvider duration={2000}>
                            <Outlet />
                        </ToastProvider>
                    </TooltipProvider>
                </QueryClientWithHeadersWrapper>
            </Auth0Provider>
        </div>
    );
    const underMaintenanceNode = (
        <div className="bg-green-100 w-screen h-screen flex justify-center items-center text-lg">
            We are under maintenance. Pleace come back in a few minutes. The
            Beavr team.
        </div>
    );

    const maintenanceFlag = useFeatureFlagEnabled("maintenance");

    return maintenanceFlag ? underMaintenanceNode : rootNode;
};

const Dummy = () => {
    const { isLoading } = useAuth0();

    if (!isLoading) {
        return <Navigate to="o" />;
    }
    return <></>;
};

export const router = createBrowserRouter([
    {
        element: <Root />,
        children: [
            {
                path: "/o",
                element: <LoginRequired />,
                children: [
                    {
                        path: "",
                        element: <Orgs />,
                    },
                    {
                        path: ":org_uname",
                        children: [
                            {
                                path: "admin",
                                children: adminRoutes,
                                element: <AdminLayout />,
                            },
                            {
                                path: "contribute",
                                children: contributeRoutes,
                            },
                            {
                                path: "*",
                                element: <Navigate to="admin" />,
                            },
                            {
                                index: true,
                                element: <Navigate to="admin" />,
                            },
                            {
                                path: "sustainability/edit",
                                element: (
                                    <SustainabilityCenter editMode={true} />
                                ),
                            },
                        ],
                    },
                ],
            },
            {
                path: "/contribute/:org_uname/supplier/:questionnaire_id",
                element: <SupplierContribution />,
            },
            {
                path: "/contribute/supplier/:questionnaire_id", // This needs to be deleted soon
                element: <SupplierContribution />,
            },
            {
                path: "*",
                element: <Navigate to="/o" />,
            },
            {
                path: "/:org_uname",
                element: <SustainabilityCenter editMode={false} />,
            },
            {
                index: true,
                element: <Dummy />,
            },
        ],
    },
]);
