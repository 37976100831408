import { Icon } from "@design-system/Icons";
import { Box } from "@design-system/Layout/Box";
import { Text } from "@design-system/Typography/Text";
import { type ClassValue } from "@design-system/Utilities";
import chroma from "chroma-js";
import { cva } from "class-variance-authority";
import { PropsWithChildren, type FC } from "react";

export type AvaratarVariant = "circle" | "rounded";
export type AvatarSize = "sm" | "md";

export type AvatarProps = PropsWithChildren<{
    variant?: AvaratarVariant;
    size?: AvatarSize;
    className?: ClassValue;
    imageUrl?: string;
    hexColor?: string;
    letter?: string;
}>;

const avatarVariants = cva("flex justify-center items-center overflow-hidden", {
    variants: {
        variant: {
            circle: "rounded-full",
            rounded: "rounded-lg",
        },
        imageUrl: {
            true: "",
        },

        size: {
            sm: "w-5 h-5",
            md: "w-6 h-6",
        },

        hexColor: {
            true: "",
        },
        letter: {
            true: "",
        },
    },
    defaultVariants: {
        variant: "rounded",
        size: "md",
    },
    compoundVariants: [
        {
            hexColor: false,
            imageUrl: false,
            letter: false,
            className: ["bg-beavrGreen"],
        },
        {
            hexColor: false,
            imageUrl: false,
            letter: false,
            className: ["bg-tertiary"],
        },
    ],
});
export const Avatar: FC<AvatarProps> = ({
    className,
    variant,
    size,
    imageUrl,
    hexColor = "#03A365",
    letter = "",
}) => {
    return (
        <Box
            className={avatarVariants({
                variant,
                size,
                imageUrl: !!imageUrl,
                hexColor: !!hexColor,
                letter: !!letter,
                className,
            })}
            style={
                !imageUrl && hexColor
                    ? {
                          backgroundColor: getBgColor(hexColor),
                      }
                    : {}
            }
        >
            {imageUrl ? (
                <img src={imageUrl} />
            ) : letter ? (
                <Text
                    variant="body-sm-bold"
                    className="uppercase text-white text-center"
                    minW="3"
                >
                    {letter}
                </Text>
            ) : (
                <Icon name="user" size={size} className="text-secondary" />
            )}
        </Box>
    );
};

function getBgColor(hexColor: string) {
    if (chroma.valid(hexColor)) return chroma(hexColor).hex();
    return "#03A365";
}
