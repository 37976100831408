import UserSelect from "@app/components/Dropdown/dropdowns/User";
import { useDebouncedStableCallback } from "@app/shared/utils/debounce";
import { StatusTagCircular } from "@design-system/DataDisplay/StatusTagCircular";
import { Icon } from "@design-system/Icons";
import { Button } from "@design-system/Inputs/Button";
import { DropdownMenu } from "@design-system/Inputs/DropdownMenu";
import { SingleSelect } from "@design-system/Inputs/SingleSelect";
import { Switch } from "@design-system/Inputs/Switch";
import { Textarea } from "@design-system/Inputs/Textarea";
import { Textfield } from "@design-system/Inputs/Textfield";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import {
    CsrdDatapointStatus,
    CsrdDatapointType,
} from "@generated/client/graphql";
import { FC, useEffect, useMemo, useState, type FormEvent } from "react";
import {
    Controller,
    ControllerRenderProps,
    useForm,
    useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CsrdDatapoint } from "../types";
import { type FormInput } from "./form";
import "./i18n";

export const DatapointForm: FC<{ datapoint: CsrdDatapoint }> = ({
    datapoint,
}) => {
    const { t } = useTranslation("FrameworkDatapoint");

    const status = useMemo(() => {
        return datapoint?.status === CsrdDatapointStatus.Validated
            ? "validated"
            : datapoint?.status === CsrdDatapointStatus.InProgress
              ? "inProgress"
              : datapoint?.status === CsrdDatapointStatus.NotMaterial
                ? "notMaterial"
                : "todo";
    }, [datapoint]);
    const [isCollapsed, setIsCollapsed] = useState(true);

    const { register, handleSubmit, control, watch } =
        useForm<FormInput<"number">>();

    const charCount = (useWatch({ control, name: "comment" }) || "").length;

    const preventDefault = (e: FormEvent<HTMLFormElement>) =>
        e.preventDefault();
    const makeNoDataSelectHandler =
        (field: ControllerRenderProps<FormInput<"number">, "noData">) => () =>
            field.onChange(!field.value);
    const makeNonMaterialSelectHandler =
        (field: ControllerRenderProps<FormInput<"number">, "nonMaterial">) =>
        () =>
            field.onChange(!field.value);
    const onSubmit = useDebouncedStableCallback((data) => {
        console.log(data);
    }, 300);

    useEffect(() => {
        const subscription = watch(() => handleSubmit(onSubmit)());
        return () => subscription.unsubscribe();
    }, [handleSubmit, onSubmit, watch]);

    return (
        <Box w="full">
            {isCollapsed ? (
                <FlexRow
                    px="4"
                    alignItems="start"
                    gap="4"
                    alignSelf="stretch"
                    h="20"
                    className="border-b border-tertiary"
                >
                    <Box py="4">
                        <StatusTagCircular status={status} size="md" />
                    </Box>
                    <FlexCol
                        w="full"
                        h="full"
                        py="4"
                        className=" cursor-pointer"
                        onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                        <Text variant="body-lg-bold">{datapoint?.name}</Text>
                    </FlexCol>
                    <Box py="4">
                        <DropdownMenu>
                            <DropdownMenu.Trigger>
                                <Icon name="more" />
                            </DropdownMenu.Trigger>
                        </DropdownMenu>
                    </Box>
                </FlexRow>
            ) : (
                <form onSubmit={preventDefault}>
                    <FlexRow
                        gap="4"
                        px="4"
                        alignItems="start"
                        w="full"
                        className="flex-1 border-b border-tertiary"
                    >
                        <Box py="4">
                            <StatusTagCircular status={status} />
                        </Box>

                        <FlexCol gap="4" alignItems="start" w="full" py="4">
                            <FlexCol
                                w="full"
                                justifyContent="start"
                                className="cursor-pointer"
                                onClick={() => setIsCollapsed(!isCollapsed)}
                            >
                                <Text variant="body-lg-bold">
                                    {datapoint?.name}
                                </Text>
                            </FlexCol>
                            <Controller
                                name="user"
                                control={control}
                                render={() => (
                                    <FlexRow
                                        className="h-[28px] gap-[6px]"
                                        alignItems="center"
                                    >
                                        <Text
                                            variant="body-sm"
                                            color="secondary"
                                        >
                                            Ownership :
                                        </Text>
                                        <UserSelect
                                            selectedUserId={
                                                datapoint?.ownerId || undefined
                                            }
                                            onChange={() => {
                                                console.log("changing owner");
                                            }}
                                        />
                                    </FlexRow>
                                )}
                            />
                            {datapoint?.type ===
                                CsrdDatapointType.Reporting && (
                                <Textfield
                                    {...register("value")}
                                    title={t("value")}
                                    value={datapoint?.value?.number}
                                    onChange={() => {
                                        console.log("changing value");
                                    }}
                                    id="firstName"
                                    type="number"
                                />
                            )}
                            {datapoint?.type ===
                                CsrdDatapointType.Seminarrative && (
                                <SingleSelect
                                    value={datapoint?.value?.boolean}
                                    onValueChange={() =>
                                        console.log(
                                            "changing semi narrative datapoint value",
                                        )
                                    }
                                >
                                    <SingleSelect.Trigger>
                                        <Button
                                            variant="tonal"
                                            className="w-[200px]"
                                        >
                                            <FlexRow
                                                justifyContent="between"
                                                w="full"
                                                px="2"
                                            >
                                                <Text variant="body-sm">
                                                    {t("yes_no")}
                                                </Text>
                                                <Icon name="angleDown" />
                                            </FlexRow>
                                        </Button>
                                    </SingleSelect.Trigger>
                                    <SingleSelect.Content>
                                        <SingleSelect.Option
                                            value={"true"}
                                            label={t("yes")}
                                        ></SingleSelect.Option>
                                        <SingleSelect.Option
                                            value={"false"}
                                            label={t("no")}
                                        ></SingleSelect.Option>
                                    </SingleSelect.Content>
                                </SingleSelect>
                            )}
                            <Textarea
                                {...register("comment")}
                                placeholder={t("comment", {
                                    charCount,
                                })}
                                value={datapoint?.comment || ""}
                                onChange={() => {
                                    console.log("changing comment");
                                }}
                                className="w-full"
                            />
                            <Controller
                                control={control}
                                name="validated"
                                render={() => (
                                    <Switch
                                        checked={
                                            datapoint?.status ===
                                            CsrdDatapointStatus.Validated
                                        }
                                        onChange={() =>
                                            console.log("changing validated")
                                        }
                                        label={t("validate")}
                                        labelPosition="right"
                                    />
                                )}
                            />
                        </FlexCol>
                        <Box py="4">
                            <DropdownMenu>
                                <DropdownMenu.Trigger>
                                    <Icon name="more" />
                                </DropdownMenu.Trigger>
                                <DropdownMenu.Content>
                                    <Controller
                                        name="noData"
                                        control={control}
                                        render={({ field }) => (
                                            <DropdownMenu.Item
                                                onSelect={makeNoDataSelectHandler(
                                                    field,
                                                )}
                                            >
                                                <DropdownMenu.ItemIcon name="edit" />
                                            </DropdownMenu.Item>
                                        )}
                                    />
                                    <Controller
                                        name="nonMaterial"
                                        control={control}
                                        render={({ field }) => (
                                            <DropdownMenu.Item
                                                onSelect={makeNonMaterialSelectHandler(
                                                    field,
                                                )}
                                            >
                                                <DropdownMenu.ItemIcon name="eyeOff" />
                                            </DropdownMenu.Item>
                                        )}
                                    />
                                </DropdownMenu.Content>
                            </DropdownMenu>
                        </Box>
                    </FlexRow>
                </form>
            )}
        </Box>
    );
};
