// your-tooltip.jsx
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { ReactNode } from "react";
import { cn } from "../../lib/utils";

interface TooltipProps {
    children: ReactNode;
    tooltipContent: ReactNode;
    className?: string;
    delayDuration?: number;
    triggerAsChild?: boolean;
    side?: string;
    arrow?: boolean;
}

/**
 * @deprecated 
 */
export function Tooltip({
    children,
    tooltipContent,
    className,
    delayDuration,
    triggerAsChild,
    ...props
}: TooltipProps & TooltipPrimitive.TooltipContentProps) {
    if (!tooltipContent) {
        return <>{children}</>;
    } else {
        return (
            <TooltipPrimitive.Root delayDuration={delayDuration}>
                <TooltipPrimitive.Trigger asChild={triggerAsChild}>
                    {children}
                </TooltipPrimitive.Trigger>
                <TooltipPrimitive.Portal>
                    <TooltipPrimitive.Content
                        side={props.side || "bottom"}
                        align="center"
                        className={cn(
                            "z-50 bg-neutral-800 text-white p-2 rounded-lg",
                            className
                        )}
                        {...props}
                    >
                        {tooltipContent}
                        {props.arrow && (
                            <TooltipPrimitive.Arrow className="fill-neutral-800" />
                        )}
                    </TooltipPrimitive.Content>
                </TooltipPrimitive.Portal>
            </TooltipPrimitive.Root>
        );
    }
}
