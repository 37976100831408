import { Flex } from "@design-system/Layout/Flex";
import { ValidationButton } from "@app/pages/Editor/ValidationButton";
import { cn } from "@design-system/Utilities";
import { barHeightClassName } from "../utils";

const TopRightBlock = () => (
    <Flex
        alignItems="center"
        px="4"
        className={cn("border-b border-b-tertiary", barHeightClassName)}
    >
        <ValidationButton />
    </Flex>
);

export default TopRightBlock;
