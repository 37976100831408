import i18next from "../../../i18n";

i18next.addResourceBundle("en", "SustainabilityCenterDashboard", {
    title: "Sustainability center",
    subtitle:
        "Upload, store and share all the documents associated with your CSR or impact strategy.",
    open_page: "Open my sustainability center",
    copy_link: "Copy link",
    edit_page: "Edit page",
    publish: "Publish",
    unpublish: "Unpublish",
    create_page: "Create sustainability center",
    create_success: "Successfully creates sustainability center",
    create_error: "Error creating sustainability center",
    publish_success: "Successfully published sustainability center",
    unpublish_success: "Successfully unpublished sustainability center",
    publish_error: "Error publishing sustainability center",
    unpublish_error: "Error unpublishing sustainability center",
    unique_visitors: "Unique visitors",
    downloaded_docs: "Downloaded docs",
    no_data: "- -",
    monthly_visitors: "Monthly visitors",
    not_enough_data: "Not enough data",
    copied_to_clipboard: "Link copied to clipboard",
    language: "language",
    english: "english",
    french: "french",
    update_success: "Successfully updated sustainability center",
    update_error: "Error updating sustainability center",
});

i18next.addResourceBundle("fr", "SustainabilityCenterDashboard", {
    title: "Centre de durabilité",
    subtitle:
        "Uploadez, enregistrez et partagez tous les documents associés à votre stratégie d'impact RSE.",
    edit_page: "Modifier la page",
    copy_link: "Copier le lien",
    open_page: "Ouvrir mon centre de conformité",
    publish: "Publier",
    unpublish: "Dépublier",
    create_page: "Créer le centre de conformité",
    create_success: "Centre de conformité créé avec succes",
    create_error: "Erreur lors de la création du centre de conformité",
    publish_success: "Centre de conformité publié avec succès",
    unpublish_success: "Centre de conformité dépublié avec succès",
    publish_error: "Erreur lors de la publication du centre de conformité",
    unpublish_error: "Erreur lors de la dépublication du centre de conformité",
    unique_visitors: "Visiteurs uniques",
    downloaded_docs: "Documents téléchargés",
    no_data: "- -",
    monthly_visitors: "Vues mensuelles",
    not_enough_data: "Pas assez de données",
    copied_to_clipboard: "Lien copié",
    language: "langue",
    english: "anglais",
    french: "français",
    update_success: "Centre de conformité mix à jour avec succès",
    update_error: "Erreur lors de la mise à jour du centre de conformité",
});
