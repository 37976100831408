import { cn } from "../../lib/utils";

interface InputSimpleProps {
    title: string;
    error?: string;
    iconLeft?: React.ReactNode;
}

export default function InputSimple({
    title,
    error,
    iconLeft,
    className,
    ...props
}: InputSimpleProps & React.InputHTMLAttributes<HTMLInputElement>) {
    return (
        <div className="relative">
            <input
                type="text"
                id={props.id || `floating_filled_${title}`}
                className={cn(
                    "`block rounded-lg border border-secondary bg-white px-3 pt-[22px] pb-1.5 w-full text-base font-regular text-primary focus:outline-none focus:ring-0 focus:border-primary peer disabled:bg-secondary placeholder-transparent",
                    error && "border-danger",
                    iconLeft && "pl-8",
                    className
                )}
                {...props}
                placeholder={`${props.value}`}
            />
            {iconLeft && (
                <div className="absolute left-3 top-4">{iconLeft}</div>
            )}
            <label
                htmlFor={props.id || `floating_filled_${title}`}
                className={cn(
                    "absolute text-base text-secondary font-regular duration-300 transform -translate-y-2.5 scale-75 top-4 origin-top-left left-3 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-2.5 peer-focus:scale-75 peer-focus:text-primary ",
                    error && "text-danger",
                    iconLeft && "pl-8"
                )}
            >
                {title}
            </label>
            {error && <div className="text-sm text-danger">{error}</div>}
        </div>
    );
}
