import chroma from "chroma-js";
import { useTranslation } from "react-i18next";
import {
    SupplierQuestionnaire,
    SupplierQuestionnaireStatus,
} from "../../../../generated/client/graphql";
import ButtonRegular from "../../../components/Button/ButtonRegular";
import ActionWithConfirmation from "../../../components/Modal/ActionWithConfirmation";
import { useUpdateSupplierQuestionnaire } from "./hooks/updateSupplierQuestionnaire";

const CloseQuestionnaireButton = ({
    supplierQuestionnaire,
    color,
}: {
    supplierQuestionnaire?: SupplierQuestionnaire;
    color: chroma.Color;
}) => {
    const { t } = useTranslation("SupplierContribution");

    const { updateQuestionnaire, isPending } = useUpdateSupplierQuestionnaire(
        supplierQuestionnaire?.id
    );
    const showClose =
        supplierQuestionnaire?.status ===
            SupplierQuestionnaireStatus.InProgress ||
        supplierQuestionnaire?.status ===
            SupplierQuestionnaireStatus.NotStarted;
    return showClose ? (
        <ActionWithConfirmation
            TriggerButton={({ onClick }) => (
                <ButtonRegular
                    loading={isPending}
                    text={t("close_questionnaire_button")}
                    style={{ backgroundColor: color.css() }}
                    onClick={onClick}
                />
            )}
            confirmButton={
                <ButtonRegular
                    loading={isPending}
                    text={t("close_questionnaire_confirm")}
                    style={{ backgroundColor: color.css() }}
                    onClick={() =>
                        updateQuestionnaire({
                            status: SupplierQuestionnaireStatus.Closed,
                        })
                    }
                />
            }
            title={t("close_questionnaire_title")}
        >
            {t("close_questionnaire_content")}
        </ActionWithConfirmation>
    ) : (
        <></>
    );
};

export default CloseQuestionnaireButton;
