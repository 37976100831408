import chroma from "chroma-js";
import { Pin } from "iconoir-react";
import {
    DataRequestPatch,
    DataRequestStatus,
} from "../../../../../generated/client/graphql";
import BackgroundBars from "../../../../components/BackgroundBars";
import ColorTag from "../../../../components/Tags/ColorTag";
import { Variant } from "../../../../components/Tags/StatusTag";
import TinyStatusTag from "../../../../components/Tags/StatusTag/Tiny";
import { RequestSubthemeEntityGroup } from "../../utils/group";
import { useRequestStats } from "../../utils/stats";
import RequestBlock from "../RequestBlock";

interface GroupBlockProps {
    idx: number;
    color: chroma.Color;
    group: RequestSubthemeEntityGroup;
    updateRequest: (requestId: string, patch: DataRequestPatch) => void;
    hideWrongContributor?: boolean;
    hideAnswered?: boolean;
}
const GroupBlock = ({
    idx,
    color,
    group,
    updateRequest,
    hideWrongContributor,
    hideAnswered,
}: GroupBlockProps) => {
    const stats = useRequestStats(group.dataRequests);

    return (
        <div id={`${idx}`}>
            <div className="-z-10 relative">
                <div className="absolute -left-4 -top-4">
                    <BackgroundBars color={color} />
                </div>
            </div>
            <div className="rounded-lg border border-tertiary p-6 bg-primary space-y-4">
                <div className="flex justify-between">
                    <div className="flex space-x-2">
                        <ColorTag
                            color={group.theme?.color || ""}
                            text={group.theme?.name || ""}
                        />
                        {group.subtheme && (
                            <ColorTag
                                color={group.theme?.color || ""}
                                text={group.subtheme?.name || ""}
                            />
                        )}
                        {group.entity && (
                            <div className="flex space-x-1 items-center">
                                <Pin className="w-4 h-4" />
                                <div className="font-medium">
                                    {group.entity.name}{" "}
                                </div>
                            </div>
                        )}
                    </div>

                    {stats.answered + stats.transfered === stats.total ? (
                        <TinyStatusTag variant={Variant.Done}>
                            {`${stats.answered + stats.transfered}/${
                                stats.total
                            }`}
                        </TinyStatusTag>
                    ) : (
                        <ColorTag
                            color=""
                            text={`${stats.answered + stats.transfered}/${
                                stats.total
                            }`}
                        />
                    )}
                </div>
                {group.dataRequests
                    .filter(
                        (dr) =>
                            !hideAnswered ||
                            dr.status !== DataRequestStatus.Answered,
                    )
                    .map((request, idx) => (
                        <RequestBlock
                            key={idx}
                            request={request}
                            updateRequest={updateRequest}
                            hideWrongContributor={hideWrongContributor}
                        />
                    ))}
            </div>
        </div>
    );
};

export default GroupBlock;
