import { DocumentExpirationStatus } from "@generated/client/graphql";
import { PaginationState } from "@tanstack/react-table";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export type DocPageFilterState = {
    searchFilter: string | undefined;
    unrelevantSearchFilter: string | undefined;
    themeIdFilter: string | undefined;
    subthemeIdsFilter: string[] | undefined;
    tier1Filter?: boolean;
    statusFilter?: DocumentExpirationStatus[] | undefined;
    pagination: PaginationState;
};

const defaultState: DocPageFilterState = {
    searchFilter: undefined,
    unrelevantSearchFilter: undefined,
    themeIdFilter: undefined,
    subthemeIdsFilter: undefined,
    tier1Filter: false,
    statusFilter: undefined,
    pagination: { pageIndex: 0, pageSize: 10 },
};
export const DocumentPageFilterStateAtom = atomWithStorage<DocPageFilterState>(
    "documentPageFilterStateAtom",
    defaultState,
);

export const useDocumentPageState = () => {
    const [state, setState] = useAtom(DocumentPageFilterStateAtom);
    const setPartialState = (partialState: Partial<DocPageFilterState>) => {
        setState((state) => {
            const newState = { ...state, ...partialState };
            if (
                partialState.searchFilter ||
                partialState.unrelevantSearchFilter ||
                partialState.themeIdFilter ||
                partialState.subthemeIdsFilter ||
                partialState.tier1Filter !== undefined ||
                partialState.statusFilter
            ) {
                newState.pagination.pageIndex = 0;
            }
            return newState;
        });
    };

    const resetDefaultState = () => setState(defaultState);

    return { state, setState: setPartialState, resetDefaultState };
};
