import { useState } from "react";
import CollapsedAccordion from "../CollapsedAccordion";

interface AccordionProps {
    title: string;
    color: string;
    tags: React.ReactNode;
    children: React.ReactNode;
}

export default function Accordion({
    title,
    color,
    tags,
    children,
}: AccordionProps) {
    const [collapsed, setCollapsed] = useState(true);
    return (
        <div className="">
            <div className="bg-white flex flex-col divide-y justify-center items-start w-full">
                <CollapsedAccordion
                    collapsed={collapsed}
                    onClick={() => setCollapsed(!collapsed)}
                    title={title}
                    color={color}
                    tags={tags}
                />
                {!collapsed && children}
            </div>
        </div>
    );
}
