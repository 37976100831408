import { clsx, type ClassValue as ClsxClassValue } from "clsx";
import { useEffect, useState, type RefObject } from "react";
import { twMerge } from "tailwind-merge";

export type ClassValue = ClsxClassValue;

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

/**
 * Check if an element is overflown
 * @param element The element to check
 * @returns True if the element is overflown
 * @example
 * ```tsx
 * const element = document.getElementById('my-element');
 * if (isOverflown(element)) {
 *  console.log('Element is overflown');
 * }
 * ```
 */
export function isOverflown(element?: HTMLElement) {
    if (!element) return false;
    return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
    );
}

/**
 * React hook to check if an element is overflown
 * It observes element resize and updates the state
 * if the element is overflown.
 * @param ref The ref to the element to check
 * @param watch A value to watch for changes, since the ref is doesn't trigger a re-render
 * @returns True if the element is overflown
 * @example
 * ```tsx
 * const ref = useRef(null);
 * const isOverflown = useIsOverflown(ref);
 * if (isOverflown) {
 *  console.log('Element is overflown');
 * }
 * ```
 */
export function useIsOverflown(ref: RefObject<HTMLElement>, watch?: unknown) {
    const [isOverflownState, setIsOverflownState] = useState(false);

    useEffect(() => {
        const element = ref.current;
        if (!element) return;

        const resizeObserver = new ResizeObserver(() => {
            setIsOverflownState(isOverflown(element));
        });

        resizeObserver.observe(element);

        // Clean up function
        return () => {
            resizeObserver.unobserve(element);
        };
    }, [ref, isOverflownState, watch]);

    return isOverflownState;
}

export function getRandomBgColorForWhiteText() {
    const colors = [
        "#03A365", // green
        "#1081E9", // azzurro
        "#E36DC2", // pink
        "#EC825A", // orange
        "#F2C94C", // yellow
        "#9254E3", // purple
        "#2CA5BF", // teal
    ];
    return colors[Math.floor(Math.random() * colors.length)];
}