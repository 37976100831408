import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "RequestBlock", {
    no_data: "I do not have the data",
    not_the_right_contributor: "I am not the right contributor",
    no_data_comment_error:
        "You must provide a comment explaining why data is missing",
    not_the_right_contributor_comment_error:
        "You must provide a comment specifying who the request should be transfered to",
    reassign_to: "Reassign to",
    document_required: "A document is required for this indicator",
    value_in: "Value in",
    comment: "Comment",
});

i18next.addResourceBundle("fr", "RequestBlock", {
    no_data: "Je n'ai pas l'information",
    not_the_right_contributor: "Je ne suis pas le bon contributeur",
    no_data_comment_error:
        "Vous devez fournir un commentaire expliquant pourquoi les données sont manquantes",
    not_the_right_contributor_comment_error:
        "Vous devez fournir un commentaire précisant à qui la demande doit être transférée",
    reassign_to: "Réassigner à",
    document_required: "Un document est demandé pour cet indicateur",
    value_in: "Valeur en",
    comment: "Commentaire",
});
