import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { useState } from "react";

import {
    GetAllUsersDocument,
    User,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import FilterTrigger from "../../components/triggers/Filter";
import UserDropdown from "./dropdown";
import "./i18n";

export default function UserTableFilter({
    selectedUserIds,
    setSelectedUserIds,
    placeholder,
}: {
    selectedUserIds?: (string | null)[] | undefined;
    setSelectedUserIds: (
        selectedUserIds: (string | null)[] | undefined
    ) => void;
    placeholder: string;
}) {
    const { data } = useGraphQL(GetAllUsersDocument, {}, {}, true);
    const [selectOpen, setSelectOpen] = useState(false);
    const onUserClick = (userId: string | undefined) => {
        if (selectedUserIds?.includes(userId || null)) {
            const selection = selectedUserIds.filter(
                (id) => id !== (userId || null)
            );
            setSelectedUserIds(selection || undefined);
        } else {
            setSelectedUserIds(
                selectedUserIds
                    ? [...selectedUserIds, userId || null]
                    : [userId || null]
            );
        }
    };
    return (
        <>
            <DropdownPrimitive.Root
                open={selectOpen}
                onOpenChange={setSelectOpen}
            >
                <DropdownPrimitive.Trigger asChild>
                    <FilterTrigger
                        unsetCallback={() => setSelectedUserIds(undefined)}
                        active={selectedUserIds !== undefined}
                    >
                        {placeholder}
                    </FilterTrigger>
                </DropdownPrimitive.Trigger>
                <UserDropdown
                    users={(data?.users || []) as User[]}
                    selectedUserIds={selectedUserIds}
                    onUserIdChange={onUserClick}
                    preventDefault
                />
            </DropdownPrimitive.Root>
        </>
    );
}
