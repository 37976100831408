import {
    SortingState,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import { useState } from "react";
import { columns } from "./columns";

import { Entity } from "../../../../../../generated/client/graphql";
import DataTable from "../../../../../components/Table/DataTable";

import UpdateSiteModal from "../UpdateSiteModal";
import "./i18n";

export default function SitesTable({ sites }: { sites: Entity[] }) {
    const [sorting, setSorting] = useState<SortingState>([]);

    const table = useReactTable<Entity>({
        data: sites,
        columns,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
        },
    });

    return (
        <>
            <DataTable table={table} />
            <UpdateSiteModal />
        </>
    );
}
