import i18next from "../../../i18n";

i18next.addResourceBundle("en", "ConfigureOrgModal", {
    title: "Configure organization",
    button_action: "Update cms actions",
    button_theme: "Update cms themes",
    button_policy: "Update cms policies",
    actions_update_success: "{{count}} actions created or updated",
    themes_update_success: "{{count}} themes created or updated",
    policies_update_success: "{{count}} policies created or updated",
    tenant_initialization_success:
        "Tenant initialization started. Will take a few minutes.",
    button_init_all: "Initialize tenant",
});

i18next.addResourceBundle("fr", "ConfigureOrgModal", {
    title: "Configurer l'organisation",
    button_action: "Mettre à jour les actions avec le CMS",
    button_theme: "Mettre à jour les thèmes avec le CMS",
    button_policy: "Mettre à jour les politiques avec le CMS",
    actions_update_success: "{{count}} actions créées ou mis à jour",
    themes_update_success: "{{count}} thèmes créés ou mis à jour",
    policies_update_success: "{{count}} politiques créées ou mis à jour",
    tenant_initialization_success:
        "L'initialisation du compte est démarée. Elle va prendre quelques minutes.",
    button_init_all: "Initialiser le tenant",
});
