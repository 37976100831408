import { Icon } from "@design-system/Icons";
import { Button } from "@design-system/Inputs/Button";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { type FC } from "react";
import { DocumentsDropdownMultiselect } from "./DocumentsDropdownMutiselect";
import { VersionStatusSingleSelect } from "./VersionStatusSingleSelect";
import { useDocumentUploadModalItem } from "./useDocumentsUploadModalItem";

export const DocumentsUploadModalItem: FC<{ id: string }> = ({ id }) => {
    const {
        buttonIconName,
        currentFile: { name, fileURL },
        errorMsg,
        handleButtonClick,
        handleStatusChange,
        Illustration,
    } = useDocumentUploadModalItem(id);

    return (
        <FlexRow h="fit" w="full" max-w="full" gap="3">
            <Box display="flex" alignItems="center" w="fit">
                <Illustration />
            </Box>
            <Box
                className="max-w-[410px]"
                h="full"
                w="full"
                direction="col"
                display="flex"
                gap="2"
            >
                <a
                    className="cursor-pointer"
                    href={fileURL}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <Text
                        as="h5"
                        variant="body-bold"
                        color="brand"
                        className="truncate"
                    >
                        {name}
                    </Text>
                </a>
                <FlexCol gap="1">
                    <FlexRow gap="3">
                        <DocumentsDropdownMultiselect fileId={id} />
                        <VersionStatusSingleSelect
                            onStatusSelect={handleStatusChange}
                        />
                    </FlexRow>
                    {!!errorMsg && (
                        <Text
                            as="p"
                            color="danger"
                            variant="body-sm"
                            className="inline-flex gap-1.5"
                        >
                            <Icon name="warning" size="sm" />
                            {errorMsg}
                        </Text>
                    )}
                </FlexCol>
            </Box>
            <Box w="fit">
                <Button
                    onClick={handleButtonClick}
                    size="sm"
                    variant="outlined"
                >
                    <Button.Icon name={buttonIconName} />
                </Button>
            </Box>
        </FlexRow>
    );
};
