import { Editor } from "@tiptap/react";

import {
    AlignCenter,
    AlignJustify,
    AlignLeft,
    AlignRight,
    Bold,
    Hash,
    Heading,
    Heading1,
    Heading2,
    Heading3,
    Highlighter,
    Image,
    Italic,
    ListOrdered,
    ListPlus,
    Redo,
    Strikethrough,
    Table,
    Underline,
    Undo,
} from "lucide-react";
import { cn } from "../../../../lib/utils.ts";
import { DropdownTrigger } from "../../../Dropdown/components/index.tsx";
import {
    DropdownContentLayout,
    DropdownRoot,
} from "../../../Dropdown/index.tsx";
import Avatar from "../../../User/Avatar/index.tsx";
import EditorButton from "../BlockEditor/components/EditorButton.tsx";
import EditorDropdownItem from "../BlockEditor/components/EditorDropdownItem.tsx";
import { EditorUser } from "../BlockEditor/types.tsx";

interface MenuButton {
    Icon: any;
    title?: string;
    children?: MenuButton[];
    action?: () => void;
    isActive?: () => boolean;
}
export default ({ editor, users }: { editor: Editor; users: EditorUser[] }) => {
    const group1: MenuButton[][] = [
        [
            {
                Icon: Undo,
                title: "Redo",
                action: () => editor.chain().focus().undo().run(),
            },
            {
                Icon: Redo,
                title: "Redo",
                action: () => editor.chain().focus().redo().run(),
            },
        ],
    ];

    const group2: MenuButton[][] = [
        [
            {
                Icon: Heading,
                children: [
                    {
                        Icon: Hash,
                        title: "Paragraph",
                        action: () =>
                            editor.chain().focus().setParagraph().run(),
                        isActive: () => editor.isActive("paragraph"),
                    },
                    {
                        Icon: Heading1,
                        title: "Heading 1",
                        action: () =>
                            editor
                                .chain()
                                .focus()
                                .toggleHeading({ level: 1 })
                                .run(),
                        isActive: () =>
                            editor.isActive("heading", { level: 1 }),
                    },
                    {
                        Icon: Heading2,
                        title: "Heading 2",
                        action: () =>
                            editor
                                .chain()
                                .focus()
                                .toggleHeading({ level: 2 })
                                .run(),
                        isActive: () =>
                            editor.isActive("heading", { level: 2 }),
                    },
                    {
                        Icon: Heading3,
                        title: "Heading 3",
                        action: () =>
                            editor
                                .chain()
                                .focus()
                                .toggleHeading({ level: 3 })
                                .run(),
                        isActive: () =>
                            editor.isActive("heading", { level: 3 }),
                    },
                ],
            },
        ],
        [
            {
                Icon: ListPlus,
                children: [
                    {
                        Icon: ListPlus,
                        title: "Bullet List",
                        action: () =>
                            editor.chain().focus().toggleBulletList().run(),
                        isActive: () => editor.isActive("bulletList"),
                    },
                    {
                        Icon: ListOrdered,
                        title: "Ordered List",
                        action: () =>
                            editor.chain().focus().toggleOrderedList().run(),
                        isActive: () => editor.isActive("orderedList"),
                    },
                    // {
                    //     Icon: ListChecks,
                    //     title: "Task List",
                    //     action: () =>
                    //         editor.chain().focus().toggleTaskList().run(),
                    //     isActive: () => editor.isActive("taskList"),
                    // },
                ],
            },
            {
                Icon: editor.isActive({ textAlign: "left" })
                    ? AlignLeft
                    : editor.isActive({ textAlign: "center" })
                      ? AlignCenter
                      : editor.isActive({ textAlign: "right" })
                        ? AlignRight
                        : AlignJustify,
                children: [
                    {
                        Icon: AlignJustify,
                        title: "Justify",
                        action: () =>
                            editor
                                .chain()
                                .focus()
                                .setTextAlign("justify")
                                .run(),
                    },
                    {
                        Icon: AlignLeft,
                        title: "Left",
                        action: () =>
                            editor.chain().focus().setTextAlign("left").run(),
                    },
                    {
                        Icon: AlignCenter,
                        title: "Center",
                        action: () =>
                            editor.chain().focus().setTextAlign("center").run(),
                    },
                    {
                        Icon: AlignRight,
                        title: "Right",
                        action: () =>
                            editor.chain().focus().setTextAlign("right").run(),
                    },
                ],
            },
        ],
        [
            {
                Icon: Bold,
                title: "Bold",
                action: () => editor.chain().focus().toggleBold().run(),
                isActive: () => editor.isActive("bold"),
            },
            {
                Icon: Italic,
                title: "Italic",
                action: () => editor.chain().focus().toggleItalic().run(),
                isActive: () => editor.isActive("italic"),
            },
            {
                Icon: Strikethrough,
                title: "Strike",
                action: () => editor.chain().focus().toggleStrike().run(),
                isActive: () => editor.isActive("strike"),
            },
            {
                Icon: Underline,
                title: "Underline",
                action: () => editor.chain().focus().toggleUnderline().run(),
                isActive: () => editor.isActive("underline"),
            },
            {
                Icon: Highlighter,
                title: "Highlight",
                action: () => editor.chain().focus().toggleHighlight().run(),
                isActive: () => editor.isActive("highlight"),
            },
        ],
        [
            {
                Icon: Table,
                title: "Insert table",
                action: () =>
                    editor
                        .chain()
                        .focus()
                        .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                        .run(),
            },
            {
                Icon: Image,
                title: "Insert an image",
                action: () => editor.chain().focus().setImageUpload().run(),
            },
        ],
    ];

    return (
        <div className="flex flex-shrink-0 items-center space-x-5 px-5 justify-between h-[61px] border-b border-b-tertiary">
            {[group1, group2].map((group, idx) => (
                <div
                    key={idx}
                    className={cn(
                        "flex justify-center space-x-[20px]",
                        idx === 1 && "flex-grow",
                    )}
                >
                    {group.map((subgroup, jdx) => (
                        <div key={jdx} className="flex space-x-1">
                            {subgroup.map((item, kdx) =>
                                !item.children ? (
                                    <EditorButton
                                        key={kdx}
                                        Icon={item.Icon}
                                        active={item.isActive?.()}
                                        onClick={item.action}
                                    >
                                        {item.title}
                                    </EditorButton>
                                ) : (
                                    <DropdownRoot key={kdx}>
                                        <DropdownTrigger asChild>
                                            <EditorButton
                                                Icon={item.Icon}
                                                active={false}
                                                arrow
                                            />
                                        </DropdownTrigger>
                                        <DropdownContentLayout>
                                            {item.children.map((child, ldx) => (
                                                <EditorDropdownItem
                                                    key={ldx}
                                                    Icon={child.Icon}
                                                    text={child.title}
                                                    onClick={() =>
                                                        child.action?.()
                                                    }
                                                />
                                            ))}
                                        </DropdownContentLayout>
                                    </DropdownRoot>
                                ),
                            )}
                        </div>
                    ))}
                </div>
            ))}
            <div className="flex items-center">
                {users.map((user: any, idx) => (
                    <Avatar key={idx} size="medium" initials={user.initials} />
                ))}
            </div>
        </div>
    );
};
