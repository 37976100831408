import {
    GetSustainabilityCenterDocument,
    SustainabilityCenter,
} from "../../../../../generated/client/graphql";
import { useGraphQL, useRest } from "../../../../QueryClientWithHeaders";

export const useSustainabilityCenter = (
    isPublic: boolean,
    orgUname: string | undefined
) => {
    if (isPublic) {
        const { data, isLoading } = useRest(`sc/${orgUname}`);

        return {
            sustainabilityCenter:
                data?.sustainabilityCenter as SustainabilityCenter,
            isLoading: isLoading,
        };
    } else {
        const { data, isLoading } = useGraphQL(
            GetSustainabilityCenterDocument,
            undefined,
            { refetchOnWindowFocus: false },
            true
        );
        return {
            sustainabilityCenter: data?.sustainabilityCenter,
            isLoading: isLoading,
        };
    }
};
