import { cn } from "@app/lib/utils";
import SearchBar from "@design-system/Inputs/SearchBar";
import { ClassValue } from "@design-system/Utilities";
import * as RadixSelect from "@radix-ui/react-select";
import { ComponentProps, PropsWithChildren, type FC } from "react";

type SelectComponent = FC<ComponentProps<typeof RadixSelect.Root>> & {
    Trigger: typeof Trigger;
    Content: typeof Content;
    Item: typeof Item;
    Icon: typeof Icon;
    Search: typeof Search;
};

const Search: FC<
    PropsWithChildren<{
        searchString: string | undefined;
        setSearchString: (value: string | undefined) => void;
        placeholder?: string;
        className?: ClassValue;
    }>
> = (props) => {
    return <SearchBar {...props} />;
};

const Trigger: FC<RadixSelect.SelectTriggerProps> = (props) => {
    return (
        <RadixSelect.Trigger
            className={cn("w-[140px] outline-none", props?.className)}
        >
            {props?.children}
        </RadixSelect.Trigger>
    );
};
const Content: FC<RadixSelect.PopperContentProps> = (props) => {
    return (
        <RadixSelect.Content
            position="popper"
            sideOffset={4}
            className={cn(
                "border w-[220px] bg-white shadow-dropdown rounded-xl p-1 z-[999]",
                props?.className,
            )}
        >
            {props?.children}
        </RadixSelect.Content>
    );
};
const Item: FC<RadixSelect.SelectItemProps> = (props) => {
    return (
        <RadixSelect.Item
            value={props?.value}
            className={cn(
                "w-[210px] h-5 flex items-center gap-3 flex-1 px-2 py-1.5 rounded-lg bg-white focus:outline-none cursor-pointer hover:bg-tertiary group",
                props?.className,
            )}
        >
            {props?.children}
        </RadixSelect.Item>
    );
};
const Icon = RadixSelect.Icon;

const Select: SelectComponent = (props) => {
    return <RadixSelect.Root {...props}></RadixSelect.Root>;
};

Select.Trigger = Trigger;
Select.Content = Content;
Select.Item = Item;
Select.Icon = Icon;
Select.Search = Search;

export default Select;
