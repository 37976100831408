// Centralized Modal component

import * as DialogPrimitive from "@radix-ui/react-alert-dialog";

import { Cancel } from "iconoir-react";
import React from "react";
import i18next from "../../../i18n";
import { cn } from "../../../lib/utils";
import ButtonRegular from "../../Button/ButtonRegular";
import { ButtonType } from "../../Button/ButtonShared";

i18next.addResourceBundle("en", "AlertModal", {
    cancel: "Cancel",
});

i18next.addResourceBundle("fr", "AlertModal", {
    cancel: "Annuler",
});

interface AlertModalContentProps {
    title: string;
    subtitle?: string;
    actions?: React.ReactNode;
    children: React.ReactNode;
}
export const AlertModalContent = React.forwardRef(
    (
        {
            title,
            subtitle,
            children,
            actions,
            className,
            ...props
        }: AlertModalContentProps & DialogPrimitive.DialogContentProps,
        forwardedRef: React.Ref<HTMLDivElement>
    ) => (
        <DialogPrimitive.Portal>
            <DialogPrimitive.Overlay className="fixed inset-0 bg-black/50" />
            <DialogPrimitive.Content
                className={cn(
                    "min-w-[444px] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 shadow-dropdown rounded-lg bg-white",
                    className
                )}
                {...props}
                ref={forwardedRef}
            >
                <div className="absolute right-[14px] top-[14px]">
                    <DialogPrimitive.Cancel aria-label="Close" asChild>
                        <ButtonRegular
                            IconLeft={Cancel}
                            variant={ButtonType.TEXT}
                        />
                    </DialogPrimitive.Cancel>
                </div>
                <DialogPrimitive.Title asChild>
                    <div className="p-5 pb-3 space-y-2">
                        <h3>{title}</h3>
                        <div className="text-secondary text-base font-regular">
                            {subtitle}
                        </div>
                    </div>
                </DialogPrimitive.Title>

                <div className="px-5 py-3 space-y-3">{children}</div>
                {actions && (
                    <div className="p-5 flex justify-end space-x-2">
                        <DialogPrimitive.Cancel aria-label="Close" asChild>
                            <ButtonRegular
                                text="Cancel"
                                variant={ButtonType.TEXT}
                            />
                        </DialogPrimitive.Cancel>
                        {actions}
                    </div>
                )}
            </DialogPrimitive.Content>
        </DialogPrimitive.Portal>
    )
);

export const AlertModal = DialogPrimitive.Root;
export const AlertModalTrigger = DialogPrimitive.Trigger;
