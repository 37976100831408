import * as Select from "@radix-ui/react-select";
import { Ref, forwardRef } from "react";

import { NavArrowDown } from "iconoir-react";
import { cn } from "../../../lib/utils";

interface SelectTriggerProps {
    disabled?: boolean;
    success?: boolean;
    stringValue: string | undefined;
    placeholder?: string;
}
export const InnerSelectTrigger = ({
    success,
    disabled,
    stringValue,
    placeholder,
    className,
}: SelectTriggerProps & { className?: string }) => {
    return (
        <div
            className={cn(
                "group",
                "border border-tertiary rounded-lg bg-white",
                "h-[52px] flex items-center text-base font-regular text-secondary",
                "data-[state=open]:border-primary",
                "focus:outline-none focus:border-primary",
                success && "border-green",
                disabled && "bg-secondary",
                className
            )}
        >
            <div className="flex-grow px-3 py-1.5 text-start">
                <div
                    className={cn(
                        "text-secondary font-regular",
                        stringValue !== undefined
                            ? "text-sm"
                            : " text-base group-focus:text-primary group-data-[state=open]:text-primary"
                    )}
                >
                    {placeholder}
                </div>
                {stringValue !== undefined && (
                    <div className="text-primary font-regular">
                        {stringValue}
                    </div>
                )}
            </div>
            <div className="px-3">
                <NavArrowDown className="text-primary w-4 h-4" />
            </div>
        </div>
    );
};
const InputSelectTrigger = forwardRef(
    (
        {
            success,
            stringValue,
            placeholder,
            className,
            ...props
        }: SelectTriggerProps & Select.SelectTriggerProps,
        ref: Ref<HTMLButtonElement>
    ) => {
        return (
            <Select.Trigger {...props} ref={ref} className="w-full">
                <InnerSelectTrigger
                    stringValue={stringValue}
                    placeholder={placeholder}
                    className={className}
                    success={success}
                    disabled={props.disabled}
                />
            </Select.Trigger>
        );
    }
);

export default InputSelectTrigger;
