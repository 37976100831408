import i18next from "../../../../../i18n";

i18next.addResources("en", "CreateDocumentOption", {
    upload_title: "Upload final version directly",
    upload_desc:
        "Please upload the final version of your Document directly from your computer.",
    upload_button: "Upload",

    start_from_scratch_title: "Start from scratch",
    start_from_scratch_desc:
        "Write your Document directly in the editor. Start from scratch.",
    start_from_scratch_button: "Start",

    start_from_previous_title: "Start from current version ",
    start_from_previous_desc:
        "Update and optimize the latest version of your policy. ",
    start_from_previous_button: "Edit previous",

    start_from_template_title: "Start with a template",
    start_from_template_desc:
        "Build your policy using a Beavr template and ensure you follow a perfect structure.",
    start_from_template_button: "Start from template",
    choose_template_locale: "Choose template language",
    en: "English",
    fr: "French",
});
i18next.addResources("fr", "CreateDocumentOption", {
    upload_title: "Télécharger un fichier",
    upload_desc:
        "Veuillez télécharger la version finale de votre politique directement depuis votre ordinateur.",
    upload_button: "Télécharger",

    start_from_scratch_title: "Partir d'une feuille blanche",
    start_from_scratch_desc:
        "Écrivez votre politique directement dans l'éditeur. Commencez à partir de zéro.",
    start_from_scratch_button: "Commencer",

    start_from_previous_title: "Partir de la dernière version",
    start_from_previous_desc:
        "Mettez à jour et optimisez la dernière version de votre politique. ",
    start_from_previous_button: "Modifier les précédentes",

    start_from_template_title: "Partir d’un template",
    start_from_template_desc:
        "Construisez votre politique à partir d’un template Beavr et assurez-vous de suivre une structure parfaite.",
    start_from_template_button: "Commencer à partir d'un modèle",
    choose_template_locale: "Choisir la langue du modèle",
    en: "Anglais",
    fr: "Français",
});
