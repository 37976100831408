import i18next from "../../../../i18n";

i18next.addResources("en", "DocumentInfoBlock", {
    description: "Description",
    recurrence_title: "Review frequency",
    recurrence: "Renew annually",
    comment_placeholder: "Add additional context",
});
i18next.addResources("fr", "DocumentInfoBlock", {
    description: "Description",
    recurrence_title: "Fréquence de revue",
    recurrence: "A renouveler annuellement",
    comment_placeholder: "Ajouter un commentaire",
});
