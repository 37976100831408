import {
    Header,
    HeaderFullSkeleton,
} from "@app/screens/Frameworks/framework/components/Header";
import { Gauge } from "@design-system/DataDisplay/Gauge";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import chroma from "chroma-js";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCsrdPillar, useCsrdPillarStats } from "../../data";
import { RESOURCE_NAME, keys } from "../../i18n";

export const PillarHeader: FC<{ pillarId?: string }> = ({ pillarId }) => {
    const { pillar } = useCsrdPillar(pillarId);
    const { stats } = useCsrdPillarStats(pillarId);
    const { t } = useTranslation(RESOURCE_NAME);

    const list = useMemo(() => {
        if (!stats) return [];
        return stats.categories.map((cat) => ({
            ...cat,
            label: t(`stats_${cat.label}`, { count: cat.value }),
        }));
    }, [stats]);

    return pillar && stats ? (
        <Header bgColor={pillar.color} className="rounded-lg">
            <Header.LeftColumn>
                <Header.Title>{pillar.name}</Header.Title>
                <Header.Description>{pillar.description}</Header.Description>
            </Header.LeftColumn>
            <Header.RightColumn>
                <FlexCol h="full" w="full" justifyContent="center" p="5">
                    <Text color="white" variant="header1">
                        {stats.percentage}%
                    </Text>
                    <Gauge>
                        <Gauge.List>
                            {list.map(({ value, color, label }) => (
                                <Gauge.Item
                                    key={label}
                                    value={value}
                                    color={chroma.hex(color)}
                                    label={label}
                                />
                            ))}
                        </Gauge.List>
                    </Gauge>
                    <FlexRow justifyContent="between">
                        {typeof stats.completed === "number" && (
                            <Text color="white">
                                {t(keys.stats_completed, {
                                    count: stats.completed,
                                })}
                            </Text>
                        )}
                        {typeof stats.total === "number" && (
                            <Text color="white">
                                {t(keys.stats_total, {
                                    count: stats.total,
                                })}
                            </Text>
                        )}
                    </FlexRow>
                </FlexCol>
            </Header.RightColumn>
        </Header>
    ) : (
        <HeaderFullSkeleton />
    );
};
