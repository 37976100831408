import { Modal } from "@design-system/Modal";
import { useTranslation } from "react-i18next";
import { useModalActions } from "./useModalActions";

export const DocumentsUploadModalCTA = () => {
    const { t } = useTranslation("DocumentsUploadModal");
    const { handleCreateVersions, isCreateVersionLoading, uploadedFiles } = useModalActions();
    return (
        <>
            <Modal.CancelCTA loading={isCreateVersionLoading}>{t("cancel")}</Modal.CancelCTA>
            <Modal.ConfirmCTA loading={isCreateVersionLoading} onClick={handleCreateVersions} preventClose>
                {t("confirm", {
                    count: uploadedFiles.list.reduce(
                        (acc, file) =>
                            acc + (file.associatedDocuments?.length ?? 0),
                        0,
                    ),
                })}
            </Modal.ConfirmCTA>
        </>
    );
};
