import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
    GetAllUsersDocument,
    User,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import ButtonRegular from "../../../Button/ButtonRegular";
import { AlertModal, AlertModalContent } from "../../../Modal/AlertModal";
import AssignButton from "./AssignButton";
import UserDropdown from "./dropdown";
import "./i18n";

interface UserSelectProps {
    selectedUserId?: string;
    selectedUserIds?: string[];
    onChange: (userId: string | undefined) => void;
    userIdsToInclude?: string[];
    title?: string;
    size?: "small" | "regular";
}

export default function UserSelect({
    selectedUserId,
    selectedUserIds,
    onChange,
    userIdsToInclude,
}: UserSelectProps) {
    const { data } = useGraphQL(GetAllUsersDocument, {}, {}, true);
    const selectedUser =
        (selectedUserId &&
            data?.users.find(({ id }) => id === selectedUserId)) ||
        ((selectedUserIds &&
            selectedUserIds.length === 1 &&
            data?.users.find(({ id }) => id === selectedUserIds[0])) as
            | User
            | undefined);

    const { t } = useTranslation("UserDropdown");

    const [selectOpen, setSelectOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [toConfirmUser, setToConfirmUser] = useState<User | undefined>();
    const onUserClick = (newUserId: string | undefined) => {
        if (selectedUserIds && selectedUserIds.length > 1) {
            setSelectOpen(false);
            setAlertOpen(true);
            setToConfirmUser(
                data?.users.find(({ id }) => id === newUserId) as
                    | User
                    | undefined
            );
        } else {
            onChange(newUserId);
        }
    };
    return (
        <>
            <DropdownPrimitive.Root
                open={selectOpen}
                onOpenChange={setSelectOpen}
            >
                <DropdownPrimitive.Trigger asChild>
                    <AssignButton
                        size="small"
                        userName={
                            selectedUser &&
                            `${selectedUser?.firstName} ${selectedUser.lastName}`
                        }
                    />
                </DropdownPrimitive.Trigger>
                <UserDropdown
                    users={
                        (data?.users.filter(
                            (u) =>
                                userIdsToInclude === undefined ||
                                userIdsToInclude.includes(u.id)
                        ) || []) as User[]
                    }
                    onUserIdChange={onUserClick}
                />
            </DropdownPrimitive.Root>
            <AlertModal
                open={alertOpen}
                onOpenChange={(o) => {
                    setAlertOpen(o);
                    setSelectOpen(o);
                }}
            >
                <AlertModalContent
                    title={t("Replace users")}
                    actions={
                        <ButtonRegular
                            text={t("replace")}
                            onClick={() => {
                                onChange(toConfirmUser?.id);
                                setAlertOpen(false);
                                setSelectOpen(false);
                            }}
                        />
                    }
                >
                    {t("replace_confirm", {
                        count: selectedUserIds?.length,
                        user: `${toConfirmUser?.firstName} ${toConfirmUser?.lastName}`,
                    })}
                </AlertModalContent>
            </AlertModal>
        </>
    );
}
