import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { ClassValue, cn } from "@design-system/Utilities";
import * as Progress from "@radix-ui/react-progress";
import { type FC } from "react";

export type ProgressBarProps = {
    total: number;
    completed: number;
    noDataPlaceholder: string;
    className?: ClassValue;
    color?: string;
};

export const ProgressBar: FC<ProgressBarProps> = ({
    className,
    color,
    completed,
    noDataPlaceholder,
    total,
}) => {
    const progress = (completed / total) * 100;
    return (
        <FlexRow alignItems="center" gap="2">
            {total === 0 ? (
                <Text>{noDataPlaceholder}</Text>
            ) : (
                <Progress.Root
                    className={cn(
                        "relative overflow-hidden bg-tertiary rounded-full w-[90px] h-[8px]",
                        className,
                    )}
                    style={{
                        transform: "translateZ(0)",
                    }}
                    value={progress}
                >
                    <Progress.Indicator
                        className={cn(
                            "w-full h-full transition-transform duration-[660ms] ease-[cubic-bezier(0.65, 0, 0.35, 1)]",
                            color ? "" : "bg-beavrGreen ",
                        )}
                        style={{
                            ...(color ? { backgroundColor: color } : {}),
                            transform: `translateX(-${100 - progress}%)`,
                        }}
                    />
                </Progress.Root>
            )}
        </FlexRow>
    );
};
