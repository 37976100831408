import { Column } from "@tanstack/react-table";
import { ChevronDown, ChevronUp, ChevronsUpDown } from "lucide-react";

interface HeaderProps<TData> {
    title?: string;
    children?: React.ReactNode;
    column: Column<TData, unknown>;
    disableSort?: boolean;
}

export default function Header<TData>({
    children,
    column,
    title,
    disableSort,
}: HeaderProps<TData>) {
    return (
        <button
            className="flex items-center space-x-2 text-sm font-bold"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
            {children}
            <span>{title}</span>
            {!disableSort && column.getCanSort() && !column.getIsSorted() && (
                <ChevronsUpDown className="h-3 w-3" />
            )}
            {!disableSort && column.getIsSorted() === "desc" && (
                <ChevronDown className="h-3 w-3" />
            )}
            {!disableSort && column.getIsSorted() === "asc" && (
                <ChevronUp className="h-3 w-3" />
            )}
        </button>
    );
}
