import { forwardRef } from "react";
import { FlexCol, type OrientedFlexProps } from "../Flex";
import { cn } from "@design-system/Utilities";

export const PageContainer = forwardRef<HTMLDivElement, OrientedFlexProps>(
    ({ className, ...props }, ref) => (
        <FlexCol
            as="section"
            br="xl"
            className={cn(
                "flex-1 w-full bg-white border border-neutral-150",
                className,
            )}
            elevation="b-200"
            ref={ref}
            w="full"
            {...props}
        />
    ),
);
