import { useAtomValue } from "jotai";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { bearerTokenIsSetAtom } from "@app/store/authStore";
import { currentUserIsBeavrAtom } from "@app/store/userStore";
import { CompactDisc } from "iconoir-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Container from "../../components/Container";
import Loading from "../../components/Loading";
import i18next from "../../i18n";
import BodyHeader from "../../shared/components/BodyHeader";
import ConfigureModal from "./ConfigureModal";
import CreateModal from "./CreateModal";
import { useOrganizations } from "./data";
import EditModal from "./EditModal";
import { UserPermissionTable } from "./table";

i18next.addResourceBundle("en", "Orgs", {
    title: "Organizations",
    subtitle: "Select an organization to continue",
});
i18next.addResourceBundle("fr", "Orgs", {
    title: "Organisations",
    subtitle: "Gérer vos organisations",
});

const RedirectToUserOrg = () => {
    const { data, isLoading } = useOrganizations({ skip: 0, take: 2 });
    const navigate = useNavigate();
    const { t } = useTranslation("Orgs");
    const { isBeavrUser } = useAtomValue(currentUserIsBeavrAtom);

    useEffect(() => {
        if (data?.count === 1 && data.organizations[0].tenant) {
            navigate(`${data.organizations[0].tenant.uname}/admin`);
        }
    }, [data]);

    return (
        <Container>
            <BodyHeader
                icon={CompactDisc}
                title={t("title")}
                subTitle={t("subtitle")}
                action={<CreateModal />}
            />
            <div className="p-4">
                {isLoading ? <Loading size="large" /> : <UserPermissionTable />}
            </div>
            {isBeavrUser && (
                <>
                    <EditModal />
                    <ConfigureModal />
                </>
            )}
        </Container>
    );
};
function Orgs() {
    const bearerTokenIsSet = useAtomValue(bearerTokenIsSetAtom);
    const { org_uname } = useParams();

    if (!bearerTokenIsSet) {
        return <Loading size="large" />;
    }
    if (org_uname) {
        return <Outlet />;
    } else {
        return <RedirectToUserOrg />;
    }
}

export default Orgs;
