import { InputHTMLAttributes, useEffect, useRef, useState } from "react";
import { cn } from "../../../lib/utils";

const useFloat = (value: string) => {
    const float = parseFloat(value);
    if (isNaN(float)) {
        return null;
    } else {
        return float;
    }
};
interface IProps {
    value: number | undefined;
    onBlurCallback?: (value: number | null) => void;
    placeholder?: string;
    hint?: string;
    success?: boolean;
    error?: string;
}
const FloatInput = ({
    value,
    onBlurCallback,
    placeholder,
    hint,
    success,
    error,
    className,
    ...props
}: IProps & InputHTMLAttributes<HTMLInputElement>) => {
    const [valueString, setValueString] = useState(
        value !== undefined ? `${value}` : ""
    );
    const valueFloat = useFloat(valueString);

    const inputRef = useRef<HTMLInputElement>(null);

    const textAreaAdjust = () => {
        if (inputRef.current) {
            inputRef.current.style.height = "1px";
            inputRef.current.style.height =
                inputRef.current.scrollHeight + "px";
        }
    };

    useEffect(() => {
        textAreaAdjust();
    }, [valueString]);

    useEffect(() => {
        setValueString(value !== undefined ? `${value}` : "");
    }, [value]);

    return (
        <div>
            <div
                className={cn(
                    "group h-[52px] flex items-center group px-3 py-1.5 rounded-lg border border-teritiary bg-white focus-within:border-primary",
                    success && "border-green",
                    props.disabled && "bg-secondary",
                    error && "border-[2px] border-danger"
                )}
            >
                <label className="w-full">
                    <div
                        className={cn(
                            " font-regular text-secondary cursor-text transition translate-y-2.5 group-focus-within:translate-y-0",
                            valueString
                                ? " text-sm translate-y-0"
                                : " text-base group-focus-within:text-primary "
                        )}
                    >
                        {placeholder}
                    </div>

                    <input
                        ref={inputRef}
                        type="numeric"
                        className={cn(
                            "w-full bg-transparent p-0 font-default text-base font-regular border-0 focus:ring-0 placeholder:text-neutral-500 resize-none disabled:text-neutral-500 min-h-[20px] focus:outline-none",
                            className
                        )}
                        value={valueString}
                        onChange={(e) => {
                            setValueString(e.target.value);
                        }}
                        onBlur={() => {
                            onBlurCallback &&
                                (value || null) !== valueFloat &&
                                onBlurCallback(valueFloat);
                        }}
                        {...props}
                    />
                </label>
            </div>
            {error && (
                <div className="text-sm font-regular text-danger">{error}</div>
            )}
        </div>
    );
};

export default FloatInput;
