import {
    ColumnFiltersState,
    SortingState,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { SigmaFunction } from "iconoir-react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Supplier } from "../../../../../../generated/client/graphql";

import { useNavigate } from "react-router-dom";
import { filterTagIdsAtom } from "../../../../../components/Dropdown/dropdowns/Tags/filter";
import { searchFilterStringAtom } from "../../../../../components/Filters/Search";
import DataTable from "../../../../../components/Table/DataTable";
import EmptyTable from "../../../../../components/Table/EmptyTable";
import LoadingTable from "../../../../../components/Table/LoadingTable";
import { newSupplierModalOpenAtom } from "../NewSupplierModal";
import columns from "./columns";
import ActionBar from "./components/Savebar";
import "./i18n";

const SupplierInnerTable = ({ suppliers }: { suppliers: Supplier[] }) => {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [rowSelection, setRowSelection] = useState({});
    const [searchFilterString, setSearchFilterString] = useAtom(
        searchFilterStringAtom
    );
    const filterTagIds = useAtomValue(filterTagIdsAtom);
    useEffect(() => {
        table.getColumn("tags")!.setFilterValue(filterTagIds);
    }, [filterTagIds]);

    const navigate = useNavigate();
    const table = useReactTable<Supplier>({
        data: suppliers,
        columns,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onRowSelectionChange: setRowSelection,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setSearchFilterString,
        state: {
            sorting,
            columnFilters,
            rowSelection,
            globalFilter: searchFilterString,
        },
        autoResetPageIndex: false,
    });

    return (
        <>
            <DataTable
                table={table}
                onRowClick={({ original }) => {
                    navigate(`../${original.id}`);
                }}
            />

            <ActionBar
                suppliers={table
                    .getSelectedRowModel()
                    .flatRows.map((r) => r.original)}
                unset={table.resetRowSelection}
            />
        </>
    );
};

const SupplierTable = ({
    suppliers,
    isLoading,
}: {
    suppliers: Supplier[];
    isLoading: boolean;
}) => {
    const { t } = useTranslation("SupplierTable");
    const setNewSupplierModalOpen = useSetAtom(newSupplierModalOpenAtom);

    return !suppliers && isLoading ? (
        <LoadingTable />
    ) : suppliers.length === 0 ? (
        <EmptyTable
            Icon={SigmaFunction}
            smallText={t("empty_table_small_text")}
            buttonText={t("empty_table_button_text")}
            onClick={() => setNewSupplierModalOpen(true)}
        />
    ) : suppliers ? (
        <SupplierInnerTable suppliers={suppliers} />
    ) : (
        <div>Error, refresh ...</div>
    );
};

export default SupplierTable;
