import { cn } from "../../../lib/utils";

const NumberBadge = ({
    value,
    selected,
    color,
}: {
    value: number;
    color?: chroma.Color;
    selected?: boolean;
}) => {
    return (
        <div
            className={cn(
                "roudned rounded-xl text-xs font-bold text-white px-1",
                selected ? "bg-green-800" : "bg-neutral-700"
            )}
            style={selected ? { backgroundColor: color?.css() } : {}}
        >
            {value}
        </div>
    );
};

export default NumberBadge;
