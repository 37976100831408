import { ReactComponent as BarLogo } from "./BarLogo.svg";
import { ReactComponent as TextLogo } from "./TextLogo.svg";

export const Logo = () => {
    return (
        <div className="w-[150px] flex items-center space-x-[32px]">
            <BarLogo className="text-green" />
            <TextLogo className="text-black" />
        </div>
    );
};
