import { Plus } from "iconoir-react";
import { atom, useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { HiArrowSmallUp } from "react-icons/hi2";
import { cn, orderSort } from "../../../lib/utils";
import { ButtonType } from "../../Button/ButtonShared";
import ButtonSmall from "../../Button/ButtonSmall";
import "./i18n";

export const selectedIndexAtom = atom<number>(0);

export default function ItemsListEditor({
    children,
    items,
    setItems,
    addItem,
    itemType,
    getItemTitle,
}: {
    children: React.ReactNode;
    items: any[];
    setItems: (items: any[]) => void;
    addItem: () => void;
    itemType: string;
    getItemTitle: (item: any) => string;
}) {
    const [selectedIdx, setSelectedIdx] = useAtom(selectedIndexAtom);
    const { t } = useTranslation("ItemsListEditor");
    const updateOrder = () => {
        setItems(
            items.map((elt, idx) => {
                return idx === selectedIdx - 1
                    ? { ...elt, order: selectedIdx }
                    : idx === selectedIdx
                    ? { ...elt, order: selectedIdx - 1 }
                    : elt;
            })
        );
        setSelectedIdx(selectedIdx - 1);
    };

    return (
        <div className="flex min-h-[380px] items-start w-[820px]">
            <div className="w-[260px] flex flex-col gap-5 p-5 items-start self-stretch border-r border-tertiary">
                <div className="flex justify-between self-stretch">
                    <div className="font-bold text-lg text-primary">
                        {itemType}
                    </div>
                    <ButtonSmall
                        IconRight={Plus}
                        variant={ButtonType.DEFAULT}
                        onClick={addItem}
                    />
                </div>
                <div className="flex flex-col items-start self-stretch w-[220px]">
                    {orderSort(items)?.map((item, idx) => (
                        <div className="flex items-start self-stretch">
                            <div
                                className={cn(
                                    "flex p-1.5 items-center flex-1 justify-between rounded-lg cursor-pointer overflow-hidden gap-4",
                                    idx === selectedIdx
                                        ? "text-brand bg-secondary"
                                        : ""
                                )}
                            >
                                <div
                                    className={cn(
                                        "whitespace-nowrap overflow-hidden text-ellipsis",
                                        idx === selectedIdx ? "text-brand" : ""
                                    )}
                                    onClick={() => {
                                        setSelectedIdx(idx);
                                    }}
                                >
                                    {getItemTitle(item)}
                                </div>

                                {idx === selectedIdx && selectedIdx > 0 && (
                                    <div
                                        className="text-black hover:text-brand"
                                        onClick={updateOrder}
                                    >
                                        <HiArrowSmallUp />
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {items?.length == 0 && (
                <div className="flex flex-1 p-5 flex-col items-start gap-6">
                    <div className="flex px-6 py-10 flex-col justify-center items-center gap-2 self-stretch rounded-xl border border-neutral-150 bg-neutral-50">
                        <div className="text-neutral-800 font-bold text-sm">
                            {t("no_item")}
                        </div>
                        <div className="text-neutral-700 text-sm">
                            {t("press_button")}
                        </div>
                    </div>
                </div>
            )}
            {items?.length > 0 && children}
        </div>
    );
}
