import chroma from "chroma-js";

export default function BackgroundBars({ color }: { color?: chroma.Color }) {
    if (color === undefined) {
        color = chroma("#03A365");
    }
    return (
        <svg
            width="131"
            height="68"
            viewBox="0 0 131 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="125.779"
                y="4.14258"
                width="1.55351"
                height="10.5639"
                transform="rotate(45 125.779 4.14258)"
                fill={color?.css()}
            />
            <rect
                width="1.55351"
                height="10.5639"
                transform="matrix(0 1 1 0 100.754 7.65039)"
                fill={color?.css()}
            />
            <rect
                width="1.55351"
                height="10.5639"
                transform="matrix(-0.707107 0.707107 0.707107 0.707107 86.4746 4.14258)"
                fill={color?.css()}
            />
            <rect
                width="1.55351"
                height="10.5639"
                transform="matrix(-1 0 0 1 74.002 3.14453)"
                fill={color?.css()}
            />
            <rect
                x="6.70898"
                y="3.10547"
                width="1.55351"
                height="10.5639"
                fill={color?.css()}
            />
            <rect
                x="53.7012"
                y="12.6992"
                width="1.55351"
                height="10.5639"
                transform="rotate(-135 53.7012 12.6992)"
                fill={color?.css()}
            />
            <rect
                x="35.1445"
                y="9.21289"
                width="1.55351"
                height="10.5639"
                transform="rotate(-90 35.1445 9.21289)"
                fill={color?.css()}
            />
            <rect
                x="19.6328"
                y="5.24023"
                width="1.55351"
                height="10.5639"
                transform="rotate(-45 19.6328 5.24023)"
                fill={color?.css()}
            />
            <rect
                x="2.1543"
                y="42.8027"
                width="1.55351"
                height="10.5639"
                transform="rotate(-90 2.1543 42.8027)"
                fill={color?.css()}
            />
            <rect
                x="3.13867"
                y="22.0371"
                width="1.55351"
                height="10.5639"
                transform="rotate(-45 3.13867 22.0371)"
                fill={color?.css()}
            />
            <rect
                x="4.36719"
                y="63.0859"
                width="1.55351"
                height="10.5639"
                transform="rotate(-135 4.36719 63.0859)"
                fill={color?.css()}
            />
        </svg>
    );
}
