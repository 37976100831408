import React from "react";

const OptionLayout = ({
    title,
    subtitle,
    Icon,
    button,
}: {
    title: string;
    subtitle: string;
    Icon: React.FC<Partial<{ className: string }>>;
    button: React.ReactNode;
}) => {
    return (
        <div className="p-4 flex space-x-4 border-b last:border-none border-tertiary">
            <Icon className="w-4 h-4 text-green" />
            <div className="flex-grow space-y-1">
                <div className="text-base font-bold">{title}</div>
                <div className="text-sm font-regular">{subtitle}</div>
            </div>
            <div className=" self-center">{button}</div>
        </div>
    );
};

export default OptionLayout;
