import { DocumentVersionStatus } from "@generated/client/graphql";
import MenuBar from "../../../../components/TipTap/components/menus/MenuBar";
import { UpdateDocumentBar } from "@app/pages/Editor/UpdateDocumentBar";
import { useEditorContext } from "../hooks/useEditor";
import { useTranslation } from "react-i18next";

const TopLeftBlock = () => {
    const { editor, users, version } = useEditorContext();
    const showEditorMenuBar = !!version?.withEditor && editor;
    const showUpdateDocument =
        !version?.withEditor &&
        version?.status !== DocumentVersionStatus.Approved;

    const { t } = useTranslation("EditDocument");

    if (!version && !showEditorMenuBar && !showUpdateDocument) {
        return null;
    }

    if (showEditorMenuBar) {
        return editor ? <MenuBar editor={editor} users={users} /> : null;
    }

    if (showUpdateDocument) {
        return (
            <UpdateDocumentBar
                buttonLabel={t("update_bar_button_label")}
                description={t("update_bar_description")}
            />
        );
    }
};

export default TopLeftBlock;
