import { GraphQLThemeRepository } from "@app/repositories/GraphQLRepositories/ThemeRepository";
import { atom } from "jotai";
import { atomWithQuery } from "jotai-tanstack-query";
import { themeKeys } from "./queryKeys";

const themeRepository = new GraphQLThemeRepository();

export const activeReportingEntityIdAtom = atom<string>("");

export const themesAtom = atomWithQuery(() => {
    return {
        queryKey: [themeKeys.themes],
        queryFn: async () => themeRepository.get(),
    };
});
