import React, { HTMLProps, ReactNode, Ref } from "react";
import { cn } from "../../../../lib/utils";

const FullFrameBodyLayout = React.forwardRef(
    (
        {
            children,
            className,
            ...props
        }: { children: ReactNode } & HTMLProps<HTMLDivElement>,
        ref: Ref<HTMLDivElement>,
    ) => {
        return (
            <div
                ref={ref}
                className={cn(
                    "flex-grow py-8 bg-transparent flex flex-col items-center ",
                    className,
                )}
                {...props}
            >
                <div className="w-[680px] space-y-6 ">{children}</div>
            </div>
        );
    },
);

export default FullFrameBodyLayout;
