import { Icon as IconBase, type IconProps } from "@design-system/Icons";
import { cn } from "@design-system/Utilities";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import {
    createContext,
    forwardRef,
    useContext,
    type ForwardRefExoticComponent,
    type RefAttributes,
} from "react";
import "./index.css";

const buttonVariants = cva(
    "inline-flex items-center justify-center font-bold box-border border border-transparent whitespace-nowrap transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:text-disabled disabled:bg-tertiary disabled:border-tertiary rounded-lg h-fit",
    {
        variants: {
            variant: {
                flatPrimary: [
                    "bg-green border-brand_secondary text-white shadow-b-100",
                    "hover:bg-brand_secondary",
                    "active:bg-brand_tertiary active:shadow-none",
                ],
                flatDanger: [
                    "bg-danger border-danger text-white shadow-b-100",
                    "hover:bg-danger_secondary hover:border-error-400",
                    "active:bg-error-600 active:border-error-600 active:shadow-none",
                ],

                tonal: [
                    "bg-secondary border-secondary text-primary shadow-b-100",
                    "hover:bg-primary",
                    "active:bg-tertiary active:shadow-none",
                ],
                outlined: [
                    "text-primary border-secondary bg-white shadow-b-100",
                    "hover:bg-secondary",
                    "active:bg-tertiary active:border-secondary active:shadow-none",
                ],
                outlinedDanger: [
                    "text-primary border-error-500 bg-white",
                    "hover:bg-secondary",
                    "active:bg-tertiary active:shadow-none",
                ],
                text: [
                    "text-primary shadow-none",
                    "hover:bg-secondary hover:shadow-b-100",
                    "active:bg-tertiary active:shadow-none",
                ],
                plain: [
                    "text-secondary shadow-none",
                    "hover:bg-secondary hover:shadow-b-100 hover:text-primary",
                    "active:bg-tertiary active:shadow-none",
                ],
            },

            size: {
                xs: "px-1.5 py-1 gap-0.5 text-xs min-h-6",
                sm: "px-1.5 py-1 gap-1 text-sm min-h-[28px]",
                md: "p-2 gap-1 text-base min-h-[36px]",
                lg: "p-2.5 gap-1.5 text-lg min-h-[44px]",
            },

            active: {
                true: "",
            },

            loading: {
                true: "cursor-wait pointer-events-none",
            },
        },
        /*
         * loading props must be flagged with "!" as a loading button
         * is also disabled and the disabled selector is more specific
         */
        compoundVariants: [
            {
                variant: "flatPrimary",
                loading: true,
                className: "!bg-brand_tertiary !text-white",
            },
            {
                variant: ["flatPrimary", "outlinedDanger"],
                active: true,
                className:
                    "bg-primary border-green Beavr_btn__inner_border text-primary",
            },
            {
                variant: "flatDanger",
                loading: true,
                className: "!bg-error-600 !border-error-600 !text-white",
            },
            {
                variant: ["flatDanger", "tonal", "text", "plain"],
                active: true,
                className: "bg-secondary text-primary",
            },
            {
                variant: ["tonal", "outlined"],
                loading: true,
                className: "!bg-secondary !border-tertiary !text-green",
            },
            {
                variant: "outlined",
                active: true,
                className:
                    "bg-tertiary border-green Beavr_btn__inner_border text-primary",
            },
            {
                variant: "outlinedDanger",
                loading: true,
                className: "!text-error-500 !bg-secondary !text-error-500",
            },
            {
                variant: ["text", "plain"],
                loading: true,
                className: "!bg-secondary !text-green !shadow-b-100",
            },
            {
                variant: ["text", "plain"],
                active: true,
                className: "bg-secondary shadow-b-100 text-primary",
            },
        ],
        defaultVariants: {
            variant: "flatPrimary",
            size: "md",
        },
    },
);

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean;
}
type ButtonContextType = VariantProps<typeof buttonVariants>;

const buttonContext = createContext<ButtonContextType | null>(null);

const ButtonIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
    const ctx = useContext(buttonContext);

    if (!ctx) {
        throw new Error("Button.Icon must be used within a Button");
    }

    const size = props.size ?? ctx.size ?? "md";

    return <IconBase {...props} size={size} ref={ref} />;
});

type ButtonComponentType = ForwardRefExoticComponent<
    ButtonProps & RefAttributes<HTMLButtonElement>
> & {
    Icon: typeof ButtonIcon;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            active,
            className,
            loading,
            variant,
            size,
            asChild = false,
            children,
            ...props
        },
        ref,
    ) => {
        const Comp = asChild ? Slot : "button";
        return (
            <buttonContext.Provider value={{ active, size, variant }}>
                <Comp
                    className={cn(
                        buttonVariants({
                            variant,
                            size,
                            active,
                            loading,
                            className,
                        }),
                    )}
                    ref={ref}
                    {...props}
                    disabled={loading || props.disabled}
                >
                    {loading ? (
                        <ButtonIcon name="loader" className="animate-spin" />
                    ) : (
                        children
                    )}
                </Comp>
            </buttonContext.Provider>
        );
    },
) as ButtonComponentType;

Button.Icon = ButtonIcon;

export { Button, buttonVariants };
