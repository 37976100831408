import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "Documents2Table", {
    done: "Approved",
    draft: "Draft",
    ready_for_approval: "Ready for approval",
    start: "Get started",
    status: "Status",
    document: "Document",
    not_started: "Not started",
    pending_bv_review: "Pending review",
    rejected: "Needs improvement",
    master_policy: "Master policy",
    text_document: "text document",
    text_tier_1:
        "This document is essential to your CSR management system. \n Make sure to prioritize it !",
    rocket_header_tooltip: "Filter only essential documents",
});

i18next.addResourceBundle("fr", "Documents2Table", {
    done: "Validé",
    draft: "Brouillon",
    ready_for_approval: "Prêt pour approbation",
    start: "Commencer",
    status: "Statut",
    document: "Document",
    not_started: "A faire",
    pending_bv_review: "revue en cours",
    rejected: "Améliorations nécessaires",
    master_policy: "Politique principale",
    text_document: "Document textuel",
    text_tier_1:
        "Ce document est essentiel à votre système de management RSE. \n Assurez-vous de le construire en priorité !",
    rocket_header_tooltip: "Filtrer uniquement les documents essentiels",
});
