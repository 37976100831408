import {
    SortingState,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import { ImportType } from "..";
import DataTable from "../../../../components/Table/DataTable";
import SaveIndicators from "./SaveIndicators";
import { IndicatorImport, useColumns } from "./columns";

export default function IndicatorImportTable({
    newIndicators,
    importType,
}: {
    newIndicators: IndicatorImport[];
    importType?: ImportType;
}) {
    const [sorting, setSorting] = useState<SortingState>([]);

    const columns = useColumns(importType);

    const table = useReactTable<IndicatorImport>({
        data: newIndicators,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
        },
    });

    const selectedRows = table.getSelectedRowModel().flatRows;
    const toCreateRows = selectedRows.filter(
        (row) => row.original.can_be_created
    );

    return (
        <>
            <DataTable table={table} />
            <SaveIndicators
                toCreateIndicators={toCreateRows.flatMap((row) => row.original)}
                importType={importType}
            />
        </>
    );
}
