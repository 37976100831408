import { useEffect } from "react";
import { useSetAtom } from "jotai";
import { documentURLAtom } from "@app/store/documentStore";
import { useParams } from "react-router-dom";

export function useSyncDocumentURL() {
    const { document_id } = useParams();
    const setDocumentURL = useSetAtom(documentURLAtom);

    useEffect(() => {
        setDocumentURL(document_id ?? null);
    }, [document_id]);
}
