import { OpenSelectHandGesture } from "iconoir-react";
import { useTranslation } from "react-i18next";
import Container from "../../../components/Container";
import BodyHeader from "../../../shared/components/BodyHeader";

import { useAtom, useAtomValue } from "jotai";
import {
    Datapoint,
    GetSupplierDatapointsDocument,
} from "../../../../generated/client/graphql";
import { useGraphQL } from "../../../QueryClientWithHeaders";
import SupplierFilter, {
    filterSupplierIdsAtom,
} from "../../../components/Dropdown/dropdowns/Supplier/filter";
import SearchFilter, {
    searchFilterStringAtom,
} from "../../../components/Filters/Search";
import { useCapturePageView } from "../../../lib/tracking/utils";
import Export from "./Export/Export";
import SupplierDataTable from "./Table";
import "./i18n";

export default function SupplierDatapoints() {
    useCapturePageView("supplier");

    const { t } = useTranslation("SupplierDatapoints");

    const filterSupplierIds = useAtomValue(filterSupplierIdsAtom);

    const [searchString, setSearchString] = useAtom(searchFilterStringAtom);
    const getSupplierDatapointsQuery = useGraphQL(
        GetSupplierDatapointsDocument,
        filterSupplierIds && filterSupplierIds?.length > 0
            ? { supplierIds: filterSupplierIds }
            : undefined,
        {},
        true,
    );
    return (
        <>
            <Container>
                <BodyHeader
                    icon={OpenSelectHandGesture}
                    title={t("title")}
                    subTitle={t("subtitle")}
                    // action={<Export  />}
                />
                {/* <div className="p-4 space-y-4">
                    <SearchFilter />
                </div> */}
                <div className="p-4 space-y-4">
                    <div className="flex items-center space-x-2">
                        <SupplierFilter />
                        <SearchFilter
                            searchString={searchString}
                            setSearchString={setSearchString}
                        />
                        <Export />
                    </div>
                    <SupplierDataTable
                        isLoading={getSupplierDatapointsQuery.isLoading}
                        datapoints={
                            getSupplierDatapointsQuery.data
                                ?.datapointsV2 as Datapoint[]
                        }
                    />
                </div>
            </Container>
        </>
    );
}
