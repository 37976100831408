import {
    createContext,
    useContext,
    type FC,
    type PropsWithChildren,
} from "react";

import { Skeleton } from "@design-system/DataDisplay/Skeleton";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Ellipsis } from "@design-system/Typography/Ellipsis";
import { Text } from "@design-system/Typography/Text";
import { cn, type ClassValue } from "../../Utilities";

type NavigationContext = {
    selectedId?: string;
    onSelectedIdChange?: (id: string) => void;
};

export const NavigationContext = createContext<NavigationContext | null>(null);

const Divider: FC<{ className?: ClassValue }> = ({ className }) => {
    return (
        <hr
            className={cn("h-[1px] w-full border-t border-gray-300", className)}
        />
    );
};

const Content: FC<PropsWithChildren<{ className?: ClassValue }>> = ({
    children,
    className,
}) => {
    return (
        <FlexCol
            alignItems="start"
            w="full"
            gap="4"
            className={cn("flex-1 overflow-y-auto scrollbar-hide", className)}
        >
            {children}
        </FlexCol>
    );
};

const Group: FC<
    PropsWithChildren<{
        className?: ClassValue;
    }>
> = ({ className, children }) => {
    return (
        <FlexCol w="full" gap="4" className={cn(className)}>
            {children}
        </FlexCol>
    );
};

const GroupTitle: FC<{
    title: string;
    className?: ClassValue;
    onClick?: () => void;
}> = ({ title, className, onClick }) => {
    return (
        <FlexRow className={cn(className)} onClick={onClick}>
            <FlexRow
                className="h-[24px] cursor-pointer"
                px="1"
                alignItems="center"
                gap="1"
                alignSelf="stretch"
                w="full"
            >
                <Text
                    variant="body-xs-bold"
                    className="uppercase text-secondary"
                >
                    {title}
                </Text>
            </FlexRow>
        </FlexRow>
    );
};

const NavigationItem: FC<{
    className?: ClassValue;
    id: string;
    children: FC<{ isSelected: boolean }>;
}> = (props) => {
    const ctx = useContext(NavigationContext);
    // create a context to know if button is focused/hovered or active

    if (!ctx) throw new Error("Context must not null");
    const { selectedId, onSelectedIdChange } = ctx;
    const isSelected = props.id === selectedId;

    const handleClick = () => {
        onSelectedIdChange?.(props.id);
    };

    return (
        <FlexRow
            w="full"
            alignItems="center"
            className={cn("h-[36px] cursor-pointer", props.className)}
            onClick={handleClick}
        >
            {props.children({ isSelected })}
        </FlexRow>
    );
};

const ItemLabel: FC<{ title: string; className?: ClassValue }> = ({
    title,
    className,
}) => {
    return (
        <Ellipsis hasTooltip asChild>
            <Text className={cn(className)}>{title}</Text>
        </Ellipsis>
    );
};

const ItemBox: FC<PropsWithChildren<{ className?: ClassValue }>> = ({
    children,
    className,
}) => {
    return <Box className={cn(className)}>{children}</Box>;
};

export type NavigationComponentType = FC<
    PropsWithChildren<{
        selectedId?: string;
        onSelectedIdChange?: (id: string) => void;
        className?: ClassValue;
        isLoading?: boolean;
    }>
> & {
    Content: typeof Content;
    Group: typeof Group;
    GroupTitle: typeof GroupTitle;
    Item: typeof NavigationItem;
    ItemLabel: typeof ItemLabel;
    ItemBox: typeof ItemBox;
    Divider: typeof Divider;
};

export const LoadingSideBar: FC<{ className?: ClassValue }> = ({
    className,
}) => {
    return (
        <FlexCol
            className={cn("h-screen w-[232px]", className)}
            py="4"
            pr="4"
            gap="5"
        >
            <Skeleton h="2" w="full" br="lg" />
            <FlexCol gap="7">
                <FlexCol gap="3">
                    <Skeleton h="2" className="w-[180px]" br="lg" />
                    <Skeleton h="2" w="full" br="lg" />
                    <Skeleton h="2" className="w-[120px]" br="lg" />
                </FlexCol>
                <FlexCol gap="3">
                    <Skeleton h="2" className="w-[120px]" br="lg" />
                    <Skeleton h="2" w="full" br="lg" />
                    <Skeleton h="2" className="w-[120px]" br="lg" />
                </FlexCol>
                <FlexCol gap="3">
                    <Skeleton h="2" className="w-[120px]" br="lg" />
                    <Skeleton h="2" w="full" br="lg" />
                    <Skeleton h="2" className="w-[150px]" br="lg" />
                    <Skeleton h="2" w="full" br="lg" />
                </FlexCol>
                <FlexCol gap="3">
                    <Skeleton h="2" w="full" br="lg" />
                    <Skeleton h="2" className="w-[120px]" br="lg" />
                    <Skeleton h="2" className="w-[120px]" br="lg" />
                </FlexCol>
            </FlexCol>
        </FlexCol>
    );
};

export const Sidebar: NavigationComponentType = ({
    children,
    className,
    selectedId,
    onSelectedIdChange,
    isLoading,
}) => {
    return (
        <NavigationContext.Provider value={{ selectedId, onSelectedIdChange }}>
            {isLoading ? (
                <LoadingSideBar className={className} />
            ) : (
                <nav
                    className={cn(
                        "min-w-0 w-[232px] shrink-0 flex flex-col bg-primary h-fit",
                        className,
                    )}
                >
                    {children}
                </nav>
            )}
        </NavigationContext.Provider>
    );
};

Sidebar.Content = Content;
Sidebar.Group = Group;
Sidebar.GroupTitle = GroupTitle;
Sidebar.Item = NavigationItem;
Sidebar.Divider = Divider;
Sidebar.ItemLabel = ItemLabel;
Sidebar.ItemBox = ItemBox;
