import { GraphQLCsrdRepository } from "@app/repositories/GraphQLRepositories/csrd";
import { CsrdDatapointsFilter } from "@generated/client/graphql";
import { skipToken, useQuery } from "@tanstack/react-query";
import { csrdKeys } from "../data";
import {
    filterPillarData,
    makeCompletionStatOption,
    makeDrCompletionStats,
} from "../services";

import { GetCsrdPillarDataQuery } from "@generated/client/graphql";
import { useMemo } from "react";

export type DisclosureRequirement =
    GetCsrdPillarDataQuery["pillar"]["esrss"][number]["disclosureRequirements"][number];
export type Esrs = GetCsrdPillarDataQuery["pillar"]["esrss"][number];

const csrdRepository = new GraphQLCsrdRepository();

const csrdPillarKeys = {
    all: [...csrdKeys.all, "pillar"] as const,
    details: () => [...csrdPillarKeys.all, "details"] as const,
    detail: (pillarId?: string, filter?: CsrdDatapointsFilter) =>
        [...csrdPillarKeys.details(), pillarId, filter] as const,
};

export function useCsrdPillar(csmId?: string, filter?: CsrdDatapointsFilter) {
    const { data, ...query } = useQuery({
        queryKey: csrdPillarKeys.detail(csmId, filter),
        queryFn:
            csmId && filter !== null
                ? () => csrdRepository.getPillar(csmId, filter)
                : skipToken,
    });

    const filteredPillar = data?.pillar
        ? filterPillarData(data?.pillar)
        : undefined;
    return { ...query, pillar: data?.pillar, filteredPillar };
}

export function useCsrdPillarStats(
    csmId?: string,
    filter?: CsrdDatapointsFilter,
) {
    const { pillar, ...data } = useCsrdPillar(csmId, filter);

    return {
        stats: pillar?.stats
            ? makeCompletionStatOption(pillar.stats)
            : undefined,
        ...data,
        disclosureRequirementStats: pillar?.esrss
            ? makeDrCompletionStats(pillar.esrss)
            : undefined,
    };
}

export function useEsrs(
    csmId?: string,
    pillarId?: string,
    filter?: CsrdDatapointsFilter,
) {
    const { pillar, isLoading } = useCsrdPillar(pillarId, filter);

    const esrs = useMemo(
        () => pillar?.esrss.find((esrs) => esrs.cmsId === csmId),
        [pillar, csmId],
    );

    return { esrs, isLoading };
}
