import { forwardRef, type PropsWithChildren } from "react";
import { cn, type ClassValue } from "@design-system/Utilities";

type DashboardCardProps = {
    as?: "article" | "div" | "section";
    className?: ClassValue;
    elevation?: 0 | 100 | 200;
    hoverBorder?: boolean;
    onClick?: () => void;
};

export const DashboardCard = forwardRef<
    HTMLDivElement,
    PropsWithChildren<DashboardCardProps>
>(
    (
        {
            as: Comp = "div",
            children,
            className,
            elevation = 100,
            hoverBorder = false,
            onClick,
        },
        ref,
    ) => {
        const boxShadow: ClassValue =
            elevation === 100 ? "shadow-b-100" : "shadow-b-200";
        return (
            <Comp
                className={cn(
                    "w-full p-4 rounded-xl border border-tertiary bg-white",
                    hoverBorder &&
                        " hover:outline hover:outline-green hover:outline-1 hover:border-green",
                    !!elevation && boxShadow,
                    className,
                )}
                onClick={onClick}
                ref={ref}
            >
                {children}
            </Comp>
        );
    },
);
