import * as Progress from "@radix-ui/react-progress";

export default function ProgressBar({ progress }: { progress: number }) {
    return (
        <Progress.Root
            className={`relative overflow-hidden w-full max-w-[170px] h-1.5 rounded-full bg-gray-200`}
            value={progress}
            style={{ transform: "translateZ(0)" }}
        >
            <Progress.Indicator
                className={`${
                    progress === 100 ? "bg-green" : "bg-accent-700"
                } w-full h-full transition-transform duration-[660ms] ease-[cubic-bezier(0.65,0,0.35,1)]`}
                style={{
                    transform: `translateX(-${100 - progress}%)`,
                }}
            />
        </Progress.Root>
    );
}
