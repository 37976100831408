import React, { FunctionComponent } from "react";
import { ButtonProps } from "./ButtonShared";

const ButtonPlain = React.forwardRef(
    (
        {
            Base,
            className,
            ...props
        }: {
            Base: FunctionComponent<
                ButtonProps & {
                    buttonClassName?: string;
                    textClassName?: string;
                    iconClassName?: string;
                } & React.ButtonHTMLAttributes<HTMLButtonElement> &
                    React.RefAttributes<HTMLButtonElement>
            >;
        } & ButtonProps &
            React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>,
    ) => {
        return (
            <Base
                ref={forwardedRef}
                className={`  ${
                    props.active
                        ? " bg-secondary"
                        : "bg-white hover:bg-secondary active:bg-tertiary"
                } ${className}`}
                textClassName={
                    props.disabled ? "text-disabled" : "text-secondary"
                }
                iconClassName="text-primary"
                {...props}
            />
        );
    },
);

export default ButtonPlain;
