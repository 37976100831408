import { InputHTMLAttributes, useEffect, useState } from "react";
import { cn } from "../../../lib/utils";

interface IProps {
    value: string | undefined;
    setValue?: (v: string | undefined) => void;
    onBlurCallback?: (value: string | null) => void;
    placeholder?: string;
    hint?: string;
    success?: boolean;
    error?: string;
}
const TextInput = ({
    value,
    setValue,
    onBlurCallback,
    placeholder,
    hint,
    success,
    error,
    className,
    ...props
}: IProps & InputHTMLAttributes<HTMLInputElement>) => {
    const [valueString, setValueString] = useState(value || "");

    useEffect(() => {
        setValueString(value || "");
    }, [value]);

    return (
        <div>
            <div
                className={cn(
                    "h-[52px] group px-3 py-1.5 rounded-lg border border-teritiary bg-white focus-within:border-primary",
                    success && "border-green",
                    props.disabled && "bg-transparent",
                    error && "border-[2px] border-danger"
                )}
            >
                <label>
                    {placeholder && (
                        <div
                            className={cn(
                                "font-regular text-secondary cursor-text transition translate-y-2.5 group-focus-within:translate-y-0",
                                valueString
                                    ? " text-sm translate-y-0"
                                    : " text-base group-focus-within:text-primary "
                            )}
                        >
                            {placeholder}
                        </div>
                    )}
                    <input
                        type="text"
                        className={cn(
                            "bg-transparent w-full p-0 font-default text-base font-regular leading-[20px] border-0 focus:ring-0 placeholder:text-neutral-500 resize-none disabled:text-neutral-500",
                            className
                        )}
                        value={valueString}
                        onChange={(e) => {
                            setValueString(e.target.value);
                            setValue && setValue(e.target.value || undefined);
                        }}
                        onBlur={() => {
                            onBlurCallback &&
                                onBlurCallback(valueString || null);
                        }}
                        {...props}
                    />
                </label>
            </div>
            {error && (
                <div className="text-sm font-regular text-danger">{error}</div>
            )}
        </div>
    );
};

export default TextInput;
