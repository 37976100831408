import { Box, type BoxProps } from "@design-system/Layout/Box";
import { cn } from "@design-system/Utilities";
import { forwardRef } from "react";

export const Skeleton = forwardRef<HTMLDivElement, BoxProps>(
    ({ className, ...props }, ref) => {
        return (
            <Box
                className={cn(
                    "animate-pulse bg-gradient-to-r from-neutral-150 to-[rgba(230, 230, 230, 0.30)]",
                    className,
                )}
                {...props}
                ref={ref}
            />
        );
    },
);
