import React, { ReactNode } from "react";
import { ReactComponent as BeavrFullLogo } from "../../../../assets/beavr-full-logo.svg";

const HeaderLayout = ({
    title,
    children,
    saveAndClose,
}: {
    title: string;
    children?: ReactNode;
    saveAndClose?: ReactNode;
}) => {
    return (
        <div className="flex-shrink-0 w-full h-[60px] flex items-center bg-white border-b border-teritiary px-5 space-x-5">
            <div className="flex-1">
                <BeavrFullLogo />
            </div>
            <div className="text-lg font-bold text-primary">{title}</div>
            <div className="flex-1 flex justify-end space-x-5 items-center">
                {React.Children.toArray(children).map((child, idx) => (
                    <div key={idx} className="group flex space-x-5">
                        {child}
                        <div className="h-6 border-r border-tertiary group-last:hidden" />
                    </div>
                ))}

                {saveAndClose ? saveAndClose : <></>}
            </div>
        </div>
    );
};
export default HeaderLayout;
