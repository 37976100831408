import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import React, { FC } from "react";
import { cn } from "../../../../../lib/utils";

const EditorDropdownItem = React.forwardRef<
    HTMLDivElement,
    {
        Icon: FC<Partial<{ className: string }>>;
        active?: boolean;
        text?: string;
        onClick: () => void;
    }
>(({ Icon, active, text, onClick }, ref) => {
    return (
        <DropdownPrimitive.Item
            className={cn(
                "px-2 py-1 space-x-3 flex items-center cursor-pointer"
            )}
            onClick={onClick}
            ref={ref}
        >
            <Icon className="w-4 h-4 text-green" />
            <div className={cn(active && "text-green")}>{text}</div>
        </DropdownPrimitive.Item>
    );
});
export default EditorDropdownItem;
