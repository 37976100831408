import { Box } from "@design-system/Layout/Box";
import { Text } from "@design-system/Typography/Text";
import { CsrdEsrs } from "@generated/client/graphql";

const SectionHeader = ({ esrs }: { esrs?: CsrdEsrs }) => {
    return (
        <Box
            className="border-t-4 border-t-beavrGreen bg-tertiary h-[84px] box-border"
            p="4"
            id={esrs?.cmsId}
        >
            <Text variant="hero6">{esrs?.name}</Text>
        </Box>
    );
};

export default SectionHeader;
