import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreateOrgDocument } from "../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../QueryClientWithHeaders";
import ButtonRegular from "../../../components/Button/ButtonRegular";
import InputSimple from "../../../components/InputSimple";
import {
    CenterModal,
    CenterModalContent,
    CenterModalTrigger,
} from "../../../components/Modal/CenterModal";
import { useSetToast } from "../../../components/Toast";

const CreateModal = () => {
    const [name, setName] = useState("");
    const [uname, setUname] = useState("");

    const { mutate } = useGraphQLMutation(CreateOrgDocument, {}, true);
    const navigate = useNavigate();
    const { setToastSuccess } = useSetToast();
    const onSave = () => {
        mutate(
            {
                uname: uname,
                name: name.trim(),
            },
            {
                onSuccess: () => {
                    setToastSuccess(
                        "Org created successfully. It is being initialised..."
                    );
                    navigate(`${uname}/settings/users`);
                },
            }
        );
    };
    const containsSpace = uname.includes(" ");
    const containsUpperCase = /[A-Z]/.test(uname);

    const canSave = name && uname && !containsSpace && !containsUpperCase;

    return (
        <CenterModal>
            <CenterModalTrigger asChild>
                <ButtonRegular text="Create Org" />
            </CenterModalTrigger>
            <CenterModalContent
                title="Create Org"
                actions={
                    <ButtonRegular
                        text="Create"
                        disabled={!canSave}
                        onClick={onSave}
                    />
                }
            >
                <InputSimple
                    title="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <InputSimple
                    title="Uname"
                    value={uname}
                    onChange={(e) => setUname(e.target.value)}
                />
                {containsSpace && (
                    <div className="text-error-500">
                        Remove that space in uname !
                    </div>
                )}
                {containsUpperCase && (
                    <div className="text-error-500">
                        Remove that upper case in uname !
                    </div>
                )}
            </CenterModalContent>
        </CenterModal>
    );
};

export default CreateModal;
