import { ArrowLeft, Cancel } from "iconoir-react";
import { ReactNode } from "react";
import { RightModalClose } from ".";

interface RightModalHeaderProps {
    title: string;
    action?: ReactNode;
    backCallback?: () => void;
}

export default function RightModalHeader({
    title,
    action,
    backCallback,
}: RightModalHeaderProps) {
    return (
        <div className="w-full border-b flex justify-between items-center px-4 py-5">
            {backCallback ? (
                <button onClick={backCallback} className="p-2">
                    <ArrowLeft className="w-4 h-4 text-primary" />{" "}
                </button>
            ) : (
                <div />
            )}
            <h4>{title}</h4>
            {action ? (
                action
            ) : (
                <RightModalClose aria-label="Close" asChild>
                    <button className="right-0 cursor-pointer">
                        <Cancel className="h-6 w-6" />
                    </button>
                </RightModalClose>
            )}
        </div>
    );
}
