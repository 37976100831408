import { OpenSelectHandGesture } from "iconoir-react";
import { useTranslation } from "react-i18next";
import {
    GetSupplierIndicatorsDocument,
    Indicator,
    IndicatorType,
} from "../../../../generated/client/graphql";
import { useGraphQL } from "../../../QueryClientWithHeaders";
import ButtonRegular from "../../../components/Button/ButtonRegular";
import Container from "../../../components/Container";
import SearchFilter, {
    searchFilterStringAtom,
} from "../../../components/Filters/Search";
import BodyHeader from "../../../shared/components/BodyHeader";

import { useAtom } from "jotai";
import IndicatorTable from "../../../components/IndicatorTable";
import { useCapturePageView } from "../../../lib/tracking/utils";
import "./i18n";

export default function Questionnaires() {
    useCapturePageView("supplier");

    const { t } = useTranslation("SupplierQuestionnaires");
    const { data, isLoading, refetch } = useGraphQL(
        GetSupplierIndicatorsDocument,
        undefined,
        {},
        true,
    );
    const [searchString, setSearchString] = useAtom(searchFilterStringAtom);
    return (
        <Container>
            <BodyHeader
                icon={OpenSelectHandGesture}
                title={t("title")}
                subTitle={t("subtitle")}
                action={
                    <>
                        <ButtonRegular
                            text={t("create_indicator_button")}
                            onClick={() => {}}
                            disabled={true}
                        />
                    </>
                }
            />
            <div className="p-4 space-y-4">
                <SearchFilter
                    searchString={searchString}
                    setSearchString={setSearchString}
                />
                <IndicatorTable
                    indicators={
                        data?.indicators?.sort(
                            (i1, i2) => i1.order - i2.order,
                        ) as Indicator[]
                    }
                    isLoading={isLoading}
                    type={IndicatorType.Supplier}
                    refetch={refetch}
                />
            </div>
        </Container>
    );
}
