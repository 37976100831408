import { TableOfContentDataItem } from "@tiptap-pro/extension-table-of-contents";
import { useState } from "react";
import { cn } from "../../../../lib/utils";
import { useEditorContext } from "../../../../screens/Document/Edit/hooks/useEditor";
function romanize(num: number) {
    if (isNaN(num)) return NaN;
    var digits = String(+num).split(""),
        key = [
            "",
            "C",
            "CC",
            "CCC",
            "CD",
            "D",
            "DC",
            "DCC",
            "DCCC",
            "CM",
            "",
            "X",
            "XX",
            "XXX",
            "XL",
            "L",
            "LX",
            "LXX",
            "LXXX",
            "XC",
            "",
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
        ],
        roman = "",
        i = 3;
    while (i--) roman = (key[+digits.pop()! + i * 10] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
}

export const ToCItem = ({
    position,
    item,
    onItemClick,
    activeIdx,
}: {
    position: number;
    item: TableOfContentDataItem;
    onItemClick: (
        e: React.MouseEvent<HTMLAnchorElement>,
        item: TableOfContentDataItem,
        position: number
    ) => void;
    activeIdx: number | undefined;
}) => {
    return (
        <div
            className={cn(
                "px-2 py-1.5 hover:bg-secondary w-full flex",
                item.level === 1 && position !== 0 && "mt-3",
                item.level === 2 && "pl-6",
                item.level > 2 && "pl-8",
                position === activeIdx && "bg-tertiary rounded-lg"
            )}
        >
            <a
                className={cn(
                    "hover:no-underline flex overflow-hidden self-stretch flex-1 text-ellipsis"
                )}
                href={`#${item.id}`}
                onClick={(e) => onItemClick(e, item, position)}
            >
                <div className="font-bold text-sm hover:no-underline cursor-pointer text-nowrap gap-2 flex items-center self-stretch flex-1 overflow-hidden">
                    {item.level === 1 ? (
                        <span className="">{romanize(item.itemIndex)}. </span>
                    ) : item.level === 2 ? (
                        <span>{item.itemIndex}. </span>
                    ) : (
                        <span>
                            {String.fromCharCode(96 + item.itemIndex)}.{" "}
                        </span>
                    )}
                    <span className="overflow-hidden text-ellipsis">
                        {item.textContent}
                    </span>
                </div>
            </a>
        </div>
    );
};

const TableOfContentTab = ({}: {}) => {
    const { tableOfContentsItems, editor } = useEditorContext();
    const [activeIdx, setActiveIdx] = useState<number | undefined>(undefined);
    const onItemClick = (
        e: React.MouseEvent<HTMLAnchorElement>,
        item: TableOfContentDataItem,
        position: number
    ) => {
        e.preventDefault();
        setActiveIdx(position);
        if (editor) {
            const element = editor.view.dom.querySelector(
                `[data-toc-id="${item.id}"`
            );

            element?.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start",
            });
        }
    };

    return (
        <div className="flex px-4 flex-col items-start gap-3 self-stretch overflow-hidden text-ellipsis">
            <div className="flex flex-col items-start self-stretch">
                {tableOfContentsItems.map((item, idx) => (
                    <ToCItem
                        position={idx}
                        item={item}
                        onItemClick={onItemClick}
                        activeIdx={activeIdx}
                    />
                ))}
            </div>
        </div>
    );
};

export default TableOfContentTab;
