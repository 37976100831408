import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "CreateIndicatorsModal", {
    title: "Add custom indicators",
    import_csv: "Import from CSV",
    import_csv_tooltip:
        "Your csv should have those EXACT columns (with not spaces): uname, name, description, unit-bv-id, theme-bv-id, subtheme-bv-id",
});

i18next.addResourceBundle("fr", "CreateIndicatorsModal", {
    title: "Ajouter des indicateurs personnalisés",
    import_csv: "Importer depuis un CSV",
    import_csv_tooltip:
        "Votre CSV doit avoir ces colonnes EXACTEMENT: uname, name, description, unit-bv-id, theme-bv-id, subtheme-bv-id",
});
