import i18next from "../../../../../../i18n";

i18next.addResourceBundle("en", "DocumentTableSavebar", {
    more: "More",
    apply: "Apply",

    set_owner_button: "Set owner",
    set_owner_text: "Choose owner for {{count}} items",

    set_relevance_button: "Set relevance",
    set_relevance_text: "Set relevance for {{count}} items",
    relevant: "Relevant",
    not_relevant: "Not relevant",
    mark_not_relevant: "Mark as not relevant",
    success_message: "Document updated successfully",
});

i18next.addResourceBundle("fr", "DocumentTableSavebar", {
    more: "Plus",
    apply: "Enregistrer",

    set_owner_button: "Assigner",
    set_tags_text: "Assigner {{count}} éléments",

    set_relevance_button: "Set relevance",
    set_relevance_text: "Set relevance for {{count}} items",
    mark_not_relevant: "Définir comme non pertinent",
    success_message: "Document mis à jour avec succès",
    set_owner_text: "Assigner un propriétaire à {{count}} éléments",
});
