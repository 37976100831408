import { CsrdDatapointStatus } from "@generated/client/graphql";
import { atom } from "jotai";
import { atomWithHash } from "jotai-location";
import {} from "react-router-dom";

//NavBar
export const activeDisclosureRequirementIdAtom = atomWithHash<
    string | undefined
>("drId", undefined);

// Search
export const searchStringAtom = atomWithHash<string | undefined>(
    "search",
    undefined,
);
export const ownerIdsFilterAtom = atom<(string | null)[] | undefined>(
    undefined,
);
export const statusesFilterAtom = atom<CsrdDatapointStatus[] | undefined>(
    undefined,
);

export const filterAtom = atom((get) => {
    return {
        search: get(searchStringAtom),
        ownerIds: !!get(ownerIdsFilterAtom)?.length
            ? get(ownerIdsFilterAtom)
            : undefined,
        statuses: !!get(statusesFilterAtom)?.length
            ? get(statusesFilterAtom)
            : undefined,
    };
});
export const userMultiSelectSearchAtom = atom<string | undefined>(undefined);
