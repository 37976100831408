import { HandCard } from "iconoir-react";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { InitializeTenantDocument } from "../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../QueryClientWithHeaders";
import ButtonRegular from "../../../components/Button/ButtonRegular";
import { ButtonType } from "../../../components/Button/ButtonShared";
import {
    CenterModal,
    CenterModalContent,
} from "../../../components/Modal/CenterModal";
import { useSetToast } from "../../../components/Toast";
import { orgToConfigureAtom } from "../ctx";
import "./i18n";

const ConfigureModal = () => {
    const { t } = useTranslation("ConfigureOrgModal");

    const [org, setOrg] = useAtom(orgToConfigureAtom);

    const { setToastSuccess } = useSetToast();

    const initializeTenantMutation = useGraphQLMutation(
        InitializeTenantDocument,
        {},
        true,
    );
    const onInitialiseTenant = () => {
        org &&
            initializeTenantMutation.mutate(
                { tenantId: org.tenantId },
                {
                    onSuccess: () => {
                        setToastSuccess(t("tenant_initialization_success"));
                        setOrg(undefined);
                    },
                },
            );
    };
    return (
        <CenterModal
            open={org !== undefined}
            onOpenChange={(open) => !open && setOrg(undefined)}
            modal={false}
        >
            <CenterModalContent title={t("title")}>
                <ButtonRegular
                    text={t("button_init_all")}
                    variant={ButtonType.DEFAULT}
                    IconRight={HandCard}
                    onClick={onInitialiseTenant}
                    loading={initializeTenantMutation.isPending}
                />
            </CenterModalContent>
        </CenterModal>
    );
};
export default ConfigureModal;
