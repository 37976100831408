import { FlexCol } from "@design-system/Layout/Flex";
import { FC } from "react";
import { useCsrdPillar } from "../../data";
import Esrs from "../Esrs";

export const PillarBody: FC<{ pillarId?: string }> = ({ pillarId }) => {
    const { filteredPillar } = useCsrdPillar(pillarId);

    return filteredPillar ? (
        <FlexCol w="full" className="min-w-0" gap="4">
            {filteredPillar.esrss.map((esrs, idx) => (
                <Esrs key={idx} cmsId={esrs.cmsId} pillarId={pillarId} />
            ))}
        </FlexCol>
    ) : (
        <div>Todo: Add loader </div>
    );
};

export default PillarBody;
