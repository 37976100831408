interface IProps {
    selected?: boolean;
}

const Switch = ({
    selected,
    ...props
}: IProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    return (
        <button {...props} className={`py-px`}>
            {selected ? (
                <div className="h-[18px] w-[32px] rounded-[35px] bg-green flex items-center justify-end pr-0.5">
                    <div className="rounded-full w-[14px] h-[14px] bg-white" />
                </div>
            ) : (
                <div className="h-[18px] w-[32px] rounded-[35px] border border-neutral-500 bg-white flex items-center pl-1">
                    <div className="rounded-full w-[10px] h-[10px] bg-neutral-500" />
                </div>
            )}
        </button>
    );
};

export default Switch;
