import { atom } from "jotai";

export const searchFilterAtom = atom<string | undefined>(undefined);
export const unrelevantModalSearchFilterAtom = atom<string | undefined>(
    undefined,
);
export const themeIdFilterAtom = atom<string | undefined>(undefined);
export const subthemeIdsFilterAtom = atom<string[] | undefined>(undefined);
export const ownerIdsFilterAtom = atom<(string | null)[] | undefined>(
    undefined,
);
export const paginationAtom = atom<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
});

import { PaginationState } from "@tanstack/react-table";

export const filterAtom = atom((get) => {
    return {
        themeId: get(themeIdFilterAtom),
        subthemeIds: get(subthemeIdsFilterAtom),
        ownerIds: get(ownerIdsFilterAtom),
        search: get(searchFilterAtom),
    };
});
export const resetContextAtom = atom(null, (_get, set) => {
    set(searchFilterAtom, undefined);
    set(unrelevantModalSearchFilterAtom, undefined);
    set(themeIdFilterAtom, undefined);
    set(subthemeIdsFilterAtom, undefined);
    set(ownerIdsFilterAtom, undefined);
});
