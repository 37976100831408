import { useTranslation } from "react-i18next";
import { InputSelect } from "..";

import { useFrameworks } from "@app/screens/Frameworks/data";
import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "SelectInput", {
    select_framework: "Framework",
    custom_framework_name: "Custom framework",
});

i18next.addResourceBundle("fr", "SelectInput", {
    select_framework: "Référentiel",
    custom_framework_name: "Référentiel custom",
});

export interface InputSelectFrameworkProps {
    value: string | undefined;
    onValueChange: (value: string | undefined) => void;
    useCustom: boolean | undefined;
}

export default function InputSelectFramework(props: InputSelectFrameworkProps) {
    const { t } = useTranslation("SelectInput");
    const { frameworks } = useFrameworks();

    return (
        <>
            {frameworks && frameworks.length > 0 && (
                <InputSelect
                    {...props}
                    placeholder={t("select_framework")}
                    values={
                        props.useCustom
                            ? [
                                  ...frameworks
                                      ?.filter((elt) => elt.name)
                                      .map((elt) => {
                                          return {
                                              name: elt.name || "",
                                              id: elt.cmsId,
                                          };
                                      }),
                                  {
                                      name: t("custom_framework_name"),
                                      id: "custom",
                                  },
                              ]
                            : [
                                  ...frameworks
                                      ?.filter((elt) => elt.name)
                                      .map((elt) => {
                                          return {
                                              name: elt.name || "",
                                              id: elt.cmsId,
                                          };
                                      }),
                              ]
                    }
                />
            )}
        </>
    );
}
