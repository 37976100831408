import i18next from "../../../i18n";

i18next.addResourceBundle("en", "Suppliers", {
    title: "Suppliers",
    subtitle:
        "Manage your suppliers. Multi select suppliers to perform bulk actions.",
    create_indicator_button: "Add Suppliers",
});

i18next.addResourceBundle("fr", "Suppliers", {
    title: "Mes fournisseurs",
    subtitle:
        "Gérez vos fournisseurs. Sélectionnez plusieurs fournisseurs pour effectuer des actions groupées.",
    create_indicator_button: "Ajouter des fournisseurs",
});
