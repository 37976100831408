import React from "react";
import { default as ButtonElevated } from "../ButtonElevated";
import ButtonFlatDanger from "../ButtonFlatDanger";
import ButtonFlatPrimary from "../ButtonFlatPrimary";
import ButtonOutline from "../ButtonOutline";
import { ButtonProps, ButtonType } from "../ButtonShared";
import ButtonText from "../ButtonText";
import ButtonTonal from "../ButtonTonal";
import ButtonXLargeBase from "./ButtoXLargeBase";

const ButtonXLargeElevated = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonElevated
                ref={forwardedRef}
                Base={ButtonXLargeBase}
                {...props}
            />
        );
    }
);
const ButtonXLargeFlatPrimary = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonFlatPrimary
                ref={forwardedRef}
                Base={ButtonXLargeBase}
                {...props}
            />
        );
    }
);

const ButtonXLargeFlatDanger = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonFlatDanger
                ref={forwardedRef}
                Base={ButtonXLargeBase}
                {...props}
            />
        );
    }
);

const ButtonXLargeTonal = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonTonal
                ref={forwardedRef}
                Base={ButtonXLargeBase}
                {...props}
            />
        );
    }
);

const ButtonXLargeOutlined = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonOutline
                Base={ButtonXLargeBase}
                {...props}
                ref={forwardedRef}
            />
        );
    }
);

const ButtonXLargeText = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonText Base={ButtonXLargeBase} {...props} ref={forwardedRef} />
        );
    }
);

/**
 * @deprecated
 */
const ButtonXLarge = React.forwardRef(
    (
        {
            variant,
            ...props
        }: { variant?: ButtonType } & ButtonProps &
            React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        if (!variant) {
            return <ButtonXLargeElevated {...props} ref={forwardedRef} />;
        }
        switch (variant) {
            case ButtonType.ELEVATED:
                return <ButtonXLargeElevated {...props} ref={forwardedRef} />;
            case ButtonType.FLAT_PRIMARY:
                return (
                    <ButtonXLargeFlatPrimary {...props} ref={forwardedRef} />
                );
            case ButtonType.FLAT_DANGER:
                return <ButtonXLargeFlatDanger {...props} ref={forwardedRef} />;
            case ButtonType.TONAL:
                return <ButtonXLargeTonal {...props} ref={forwardedRef} />;
            case ButtonType.OUTLINED:
                return <ButtonXLargeOutlined {...props} ref={forwardedRef} />;
            case ButtonType.TEXT:
                return <ButtonXLargeText {...props} ref={forwardedRef} />;
        }
    }
);

export default ButtonXLarge;
