import i18next from "../../../i18n";

i18next.addResourceBundle("en", "ProgressCard", {
    title: "Completion",
    ok_requirements: "{{count}} completed requirements",
    total: "{{count}} total",
});

i18next.addResourceBundle("fr", "ProgressCard", {
    title: "Completion",
    ok_requirements: "{{count}} contrôles complétés",
    total: "Total {{count}}",
});
