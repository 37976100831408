import React from "react";
import { default as ButtonElevated } from "../ButtonElevated";
import ButtonFlatDanger from "../ButtonFlatDanger";
import ButtonFlatPrimary from "../ButtonFlatPrimary";
import ButtonOutline from "../ButtonOutline";
import { ButtonProps, ButtonType } from "../ButtonShared";
import ButtonText from "../ButtonText";
import ButtonTonal from "../ButtonTonal";
import ButtonSmallBase from "./ButtonSmallBase";
import ButtonPlain from "../ButtonPlain";

const ButtonSmallElevated = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>,
    ) => {
        return (
            <ButtonElevated
                ref={forwardedRef}
                Base={ButtonSmallBase}
                {...props}
            />
        );
    },
);
const ButtonSmallFlatPrimary = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>,
    ) => {
        return (
            <ButtonFlatPrimary
                ref={forwardedRef}
                Base={ButtonSmallBase}
                {...props}
            />
        );
    },
);

const ButtonSmallFlatDanger = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>,
    ) => {
        return (
            <ButtonFlatDanger
                ref={forwardedRef}
                Base={ButtonSmallBase}
                {...props}
            />
        );
    },
);

const ButtonSmallTonal = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>,
    ) => {
        return (
            <ButtonTonal ref={forwardedRef} Base={ButtonSmallBase} {...props} />
        );
    },
);

const ButtonSmallOutlined = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>,
    ) => {
        return (
            <ButtonOutline
                Base={ButtonSmallBase}
                {...props}
                ref={forwardedRef}
            />
        );
    },
);

const ButtonSmallText = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>,
    ) => {
        return (
            <ButtonText Base={ButtonSmallBase} {...props} ref={forwardedRef} />
        );
    },
);

const ButtonSmallPlain = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>,
    ) => {
        return (
            <ButtonPlain Base={ButtonSmallBase} {...props} ref={forwardedRef} />
        );
    },
);

/**
 * @deprecated
 */
const ButtonSmall = React.forwardRef(
    (
        {
            variant,
            ...props
        }: { variant?: ButtonType } & ButtonProps &
            React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>,
    ) => {
        if (!variant) {
            return <ButtonSmallElevated {...props} ref={forwardedRef} />;
        }
        switch (variant) {
            case ButtonType.ELEVATED:
                return <ButtonSmallElevated {...props} ref={forwardedRef} />;
            case ButtonType.FLAT_PRIMARY:
                return <ButtonSmallFlatPrimary {...props} ref={forwardedRef} />;
            case ButtonType.FLAT_DANGER:
                return <ButtonSmallFlatDanger {...props} ref={forwardedRef} />;
            case ButtonType.TONAL:
                return <ButtonSmallTonal {...props} ref={forwardedRef} />;
            case ButtonType.OUTLINED:
                return <ButtonSmallOutlined {...props} ref={forwardedRef} />;
            case ButtonType.TEXT:
                return <ButtonSmallText {...props} ref={forwardedRef} />;
            case ButtonType.PLAIN:
                return <ButtonSmallPlain {...props} ref={forwardedRef} />;
        }
    },
);

export default ButtonSmall;
