import BackgroundBars from "../BackgroundBars";
import BackgroundEllipse from "../BackgroundEllipse";

export default function Background(props: {
    topBar: React.ReactNode;
    children: React.ReactNode;
    hasBgBars?: boolean;
}) {
    return (
        <div className="w-full h-screen">
            <BackgroundEllipse />
            <div className="h-screen flex flex-col z-10 px-6 pb-20 pt-3 overflow-scroll scrollbar-hide">
                {props.topBar}
                <div className="h-2 shrink-0" />
                {props.hasBgBars ? (
                    <div className="-z-10 relative">
                        <div className="absolute -left-4 -top-4">
                            <BackgroundBars />
                        </div>
                    </div>
                ) : null}
                {props.children}
            </div>
        </div>
    );
}
