import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "SupplierQuestionnaireTemplatesDropdown", {
    placeholder: "Questionnaire",
    setter_text: "{{count}} questionnaires",
    choose_template: "Choose template",
    create_template: "Create new template",
});

i18next.addResourceBundle("fr", "SupplierQuestionnaireTemplatesDropdown", {
    placeholder: "Questionnaire",
    setter_text: "{{count}} questionnaires",
    choose_template: "Choisir le modèle",
    create_template: "Créer un nouveau modèle",
});
