import i18next from "../../i18n";

i18next.addResourceBundle("en", "Profile", {
    title: "Profile",
    subtitle: "Set up your user profile here !",
    language: "Language",
    french: "French",
    english: "English",
    firstName: "First name",
    lastName: "Last name",
    email: "Email",
    change_password: "Change password",
    logout: "Log out",
    buttonSaveTitle: "Save",
    message: "Warning, there are still unsaved changes !",
    update_password_modal: {
        title: "Update your password",
        subtitle: "Enter your new password here",
        new_password: "New password",
        confirm_new_password: "Confirm new password",
        passwords_no_match: "Passwords do not match",
        has_special_character: "Password must at least one special character",
        has_upper_case: "Password must at least one upper case",
        has_lower_case: "Password must at least one lower case",
        has_number: "Password must at least one number",
        has_length_greater_8: "Password must be at least 8 characters long",
    },
});

i18next.addResourceBundle("fr", "Profile", {
    title: "Mon profil",
    subtitle: "Gérer votre profil ici !",
    language: "Langue",
    french: "Français",
    english: "Anglais",
    firstName: "Prénom",
    lastName: "Nom",
    email: "Email",
    change_password: "Changer le mot de passe",
    logout: "Se déconnecter",
    buttonSaveTitle: "Sauvegarder",
    message: "Attention, sauvegardez vos modifications!",
    update_password_modal: {
        title: "Mettre à jour votre mot de passe",
        subtitle: "Entrez votre nouveau mot de passe ici",
        new_password: "Nouveau mot de passe",
        confirm_new_password: "Confirmer le nouveau mot de passe",
        passwords_no_match: "Les mots de passe ne correspondent pas",
        has_special_character:
            "Le mot de passe doit contenir un caractère spécial",
        has_upper_case: "Le mot de passe doit contenir une majuscule",
        has_lower_case: "Le mot de passe doit contenir une minuscule",
        has_number: "Le mot de passe doit contenir un chiffre",
        has_length_greater_8:
            "Le mot de passe doit contenir au moins 8 caractères",
    },
});
