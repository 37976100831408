import { cn } from "../../../lib/utils";
import { Text } from "@design-system/Typography/Text";

const CardLayout = ({
    title,
    isLoading,
    children,
    className,
}: {
    title?: string;
    isLoading?: boolean;
    children: React.ReactNode;
    className?: string;
}) => {
    return (
        <div
            className={cn(
                "p-4 border border-tertiary rounded-lg flex flex-col justify-between",
                className
            )}
        >
            {title && <Text variant="header2">{title}</Text>}
            {isLoading ? (
                <>
                    <div className="flex items-center h-[20px] w-full">
                        <div className="rounded-full w-3/4 h-2 bg-gradient-to-r from-neutral-150 to-white" />
                    </div>
                    <div className="flex items-center h-[28px] w-full">
                        <div className="rounded-full w-1/3 h-2 bg-gradient-to-r from-neutral-150 to-white" />
                    </div>
                </>
            ) : (
                children
            )}
        </div>
    );
};

export default CardLayout;
