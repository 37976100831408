import { GetFrameworksQuery } from "@generated/client/graphql";
import { FrameworkItem, type ReleaseStatus } from "./types";

export const frameworksColorsBycmsId: Record<
    string,
    { primary: string; secondary: string }
> = {
    "evs-2023": {
        primary: "#03A365",
        secondary: "#FFC700",
    },
    csrd: {
        primary: "#0051AF",
        secondary: "#87C6AE",
    },
};

export const releaseStatus: Record<string, ReleaseStatus> = {
    released: "released",
    beta: "beta",
    comingSoon: "coming_soon",
    backlog: "backlog",
};

const releaseStatusOrder = {
    [releaseStatus.released]: 1,
    [releaseStatus.beta]: 2,
    [releaseStatus.comingSoon]: 3,
    [releaseStatus.backlog]: 4,
};

export const sortByReleaseStatusCb = (a: FrameworkItem, b: FrameworkItem) => {
    return (
        releaseStatusOrder[a.releaseStatus] -
        releaseStatusOrder[b.releaseStatus]
    );
};

export const betaFrameworks = ["csrd"];
export const comingSoonFrameworks = ["iso-14001", "iso-45001", "iso-50001"];

export function getReleaseStatus(released: boolean, cmsId: string) {
    if (released) return releaseStatus.released;
    if (betaFrameworks.includes(cmsId)) return releaseStatus.beta;
    if (comingSoonFrameworks.includes(cmsId)) return releaseStatus.comingSoon;
    return releaseStatus.backlog;
}

export function getFrameworks(
    gqlFrameworks?: GetFrameworksQuery["frameworks"],
): FrameworkItem[] | undefined {
    if (!gqlFrameworks) return undefined;

    const frameworks = gqlFrameworks.map(({ released, ...framework }) => {
        const color = frameworksColorsBycmsId[framework.cmsId]
            ? frameworksColorsBycmsId[framework.cmsId]
            : undefined;

        return {
            ...framework,
            color,
            releaseStatus: getReleaseStatus(!!released, framework.cmsId),
        };
    });

    const orderedFrameworks = frameworks
        .sort((a, b) => {
            // order by order
            return a.order === null ||
                a.order === undefined ||
                b.order === null ||
                b.order === undefined
                ? 0
                : a.order - b.order;
        })
        .sort(sortByReleaseStatusCb); // show active ones first, then beta, then coming soon

    return orderedFrameworks;
}
