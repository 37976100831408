import {
    FragmentType,
    useFragment,
} from "../../../../generated/client/fragment-masking";
import {
    DataRequestStatus,
    Entity,
    IndicatorToFillFieldsFragmentDoc,
    RequestBlockFieldsFragment,
    RequestBlockFieldsFragmentDoc,
    SubTheme,
    Theme,
} from "../../../../generated/client/graphql";

export interface RequestSubthemeEntityGroup {
    theme: Theme | null | undefined;
    subtheme: SubTheme | null | undefined;
    entity: Entity | null | undefined;
    dataRequests: RequestBlockFieldsFragment[];
}

export const groupDataRequestsKeepOrder = (
    dataRequestsFragment?: FragmentType<typeof RequestBlockFieldsFragmentDoc>[],
) => {
    const dataRequests = useFragment(
        RequestBlockFieldsFragmentDoc,
        dataRequestsFragment,
    );
    var nbSubmitted = 0;
    const groups = dataRequests?.reduce((groups, dataRequest) => {
        const indicator = useFragment(
            IndicatorToFillFieldsFragmentDoc,
            dataRequest.indicator,
        );
        if (dataRequest.status === DataRequestStatus.Submitted) {
            nbSubmitted += 1;
            return groups;
        }
        const lastGroup =
            groups.length > 0 ? groups[groups.length - 1] : undefined;
        if (
            lastGroup &&
            indicator?.subtheme?.id === lastGroup.subtheme?.id &&
            indicator?.theme?.id === lastGroup.theme?.id
        ) {
            lastGroup.dataRequests.push(dataRequest);
            return groups;
        } else {
            groups.push({
                theme: indicator?.theme as Theme | undefined,
                subtheme: indicator?.subtheme as SubTheme | undefined,
                entity: dataRequest.entity as Entity | undefined,
                dataRequests: [dataRequest],
            });
            return groups;
        }
    }, [] as RequestSubthemeEntityGroup[]);

    return { nbSubmitted, groups };
};
