import "./i18n";

import { Table } from "@tanstack/react-table";
import { Requirement } from "../../../../../generated/client/graphql";

import { useSetAtom } from "jotai";
import DataTable from "../../../../components/Table/DataTable";

import { useFilteredRelevantdRequirements } from "../../hooks/data";
import { modalRequirementIdAtom } from "../modal/modal-hooks";
import RequirementSavebar from "./saveBar";

export default function RequirementTable({
    table,
}: {
    table: Table<Requirement>;
}) {
    const setRequirementId = useSetAtom(modalRequirementIdAtom);

    const { isFetching } = useFilteredRelevantdRequirements();

    return (
        <>
            <DataTable
                table={table}
                isLoading={isFetching}
                rowClassName={() => "h-[96px] cursor-pointer"}
                onRowClick={(row) => setRequirementId(row.original.id)}
            />
            <RequirementSavebar
                requirements={table
                    .getSelectedRowModel()
                    .flatRows.map((row) => row.original)}
                unset={() => table.toggleAllRowsSelected(false)}
            />
        </>
    );
}

export function NonRelevantRequirementTable({
    table,
}: {
    table: Table<Requirement>;
}) {
    return (
        <>
            <DataTable
                table={table}
                rowClassName={() => "h-[96px] min-w-[700px]"}
            />
        </>
    );
}
