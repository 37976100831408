type Maybe<T> = T | undefined | null;
type BeavrHeaders = Headers | string[][] | Record<string, string>;

let _bearer: Maybe<string> = undefined;
let _org_uname: Maybe<string> = undefined;
let _headers: BeavrHeaders = {};

export class AccessHeadersHolder {
    static presetHeaders(arg: Headers | string[][] | Record<string, string>) {
        _headers = arg;
    }

    static setBearer(bearer: Maybe<string>) {
        _bearer = bearer;
    }

    static setOrgUname(org_uname: Maybe<string>) {
        _org_uname = org_uname;
    }

    static getHeaders(): BeavrHeaders {
        let headers: BeavrHeaders = { ..._headers };

        if (window === undefined) {
            return headers;
        }

        headers = { ...headers, pathname: window.location.pathname };

        if (_bearer) {
            headers = headers = {
                ...headers,
                authorization: `Bearer ${_bearer}`,
            };
        }

        if (_org_uname) {
            headers = { ...headers, "x-org-uname": _org_uname };
        }

        return headers;
    }
}
