import i18next from "../../../i18n";

i18next.addResourceBundle("en", "EditOrgModal", {
    name: "Name",
    uname: "Uname",
    title: "Update org",
    save: "Save",
});

i18next.addResourceBundle("fr", "EditOrgModal", {
    name: "Nom",
    uname: "Uname",
    title: "Modifier l'organisation",
    save: "Enregistrer",
});
