import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
    TEMPLATE_EN_UNAME,
    TEMPLATE_FR_UNAME,
} from "../../../screens/Document/Edit/components/Header";
import { ButtonType } from "../../Button/ButtonShared";
import ButtonSmall from "../../Button/ButtonSmall";
import "./i18n";

interface ChooseLocaleProps {
    availableLocales?: string[];
    locale?: string;
    setLocale: (locale: string) => void;
}
const ChooseLocale = ({
    availableLocales,
    locale,
    setLocale,
}: ChooseLocaleProps) => {
    const { org_uname } = useParams();
    const possibleLocales =
        org_uname === TEMPLATE_FR_UNAME
            ? ["fr"]
            : org_uname === TEMPLATE_EN_UNAME
            ? ["en"]
            : ["en", "fr"];

    const { t } = useTranslation("ChooseLocale");

    return (
        <div className="flex space-x-2">
            {possibleLocales
                .filter((loc) => availableLocales?.includes(loc))
                .map((loc, idx) => (
                    <ButtonSmall
                        key={idx}
                        onClick={() => setLocale(loc)}
                        variant={ButtonType.OUTLINED}
                        className="rounded-full"
                        active={loc === locale}
                    >
                        {t(loc)}
                    </ButtonSmall>
                ))}
        </div>
    );
};

export default ChooseLocale;
