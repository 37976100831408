import i18next from "../../../i18n";

i18next.addResourceBundle("en", "Units", {
    title: "Units",
    subtitle: " Create, edit, and delete units",
    add_unit: "Create new",
});

i18next.addResourceBundle("fr", "Units", {
    title: "Unités",
    subtitle: "Créer, modifier et supprimer des unités",
    add_unit: "Créer",
});
