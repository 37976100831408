import i18next from "../../../../../i18n";

i18next.addResourceBundle("en", "NewSupplierModal", {
    trigger_text: "Add suppliers",
    title: "Add suppliers",
    subtitle: "You'll then be able to send them questionnaires.",
    save_button: "Create",
    supplier_name: "Supplier",
    supplier_email: "Email",
    firstname: "Firstname",
    lastname: "Lastname",
    add_one: "Add one",
    import_csv: "Import from CSV",
    import_csv_tooltip:
        "Your csv should have those exact columlns : name, email, firstname, lastname",
    toast_success: "Suppliers added successfully",
    toast_error: "An error occured while adding suppliers",
});

i18next.addResourceBundle("fr", "NewSupplierModal", {
    trigger_text: "Ajouter des fournisseurs",
    title: "Ajouter des fournisseurs",
    subtitle: "Vous pourrez ensuite leur envoyer des questionnaires.",
    save_button: "Créer",
    supplier_name: "Nom du fournisseur",
    supplier_email: "Email du fournisseur",
    firstname: "Prénom",
    lastname: "Nom",
    add_one: "Ajouter un fournisseur",
    import_csv: "Importer depuis un CSV",
    import_csv_tooltip:
        "Votre CSV doit avoir ces colonnes : name, email, firstname, lastname",
    toast_success: "Fournisseurs ajoutés avec succès",
    toast_error: "Une erreur est survenue lors de l'ajout des fournisseurs",
});
