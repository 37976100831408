import {
    SortingState,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import { useState } from "react";
import { columns } from "./columns";

import { Ruler } from "iconoir-react";
import { useTranslation } from "react-i18next";
import {
    GetAllUnitsDocument,
    Unit,
} from "../../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../../QueryClientWithHeaders";
import DataTable from "../../../../../components/Table/DataTable";
import EmptyTable from "../../../../../components/Table/EmptyTable";
import LoadingTable from "../../../../../components/Table/LoadingTable";
import "./i18n";

function UnitsTableInner({ units }: { units: Unit[] }) {
    const [sorting, setSorting] = useState<SortingState>([]);

    const table = useReactTable<Unit>({
        data: units,
        columns,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
        },
    });

    return (
        <>
            <DataTable table={table} />
        </>
    );
}

const UnitsTable = () => {
    const { data, isLoading } = useGraphQL(
        GetAllUnitsDocument,
        undefined,
        undefined,
        true,
    );
    const { t } = useTranslation("UnitsTable");

    return !data && isLoading ? (
        <LoadingTable />
    ) : data?.units?.length === 0 ? (
        <EmptyTable
            Icon={Ruler}
            smallText={t("empty_table_small_text")}
            buttonText={t("empty_table_button_text")}
        />
    ) : data?.units ? (
        <UnitsTableInner units={data.units} />
    ) : (
        <div>Error, refresh ...</div>
    );
};

export default UnitsTable;
