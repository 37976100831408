import i18next from "../../../i18n";

i18next.addResourceBundle("en", "InputSelect", {
    placeholder: "Choose an option",
    unset: "Unset",
});

i18next.addResourceBundle("fr", "InputSelect", {
    placeholder: "Choisir une option",
    unset: "Réinitialiser",
});
