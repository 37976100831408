import * as Progress from "@radix-ui/react-progress";

import { useTranslation } from "react-i18next";
import CardLayout from "../CardLayout";
import "./i18n";
export interface GaugeValue {
    name: string;
    value: number;
    color: string;
    callback?: () => void;
}

export interface ProgressCardProps {
    isLoading?: boolean;
    total?: number;
    ok?: number;
}

export default function ProgressCard({
    isLoading,
    total,
    ok,
}: ProgressCardProps) {
    const { t } = useTranslation("ProgressCard");
    const progress = total && ok ? (ok / total) * 100 : 0;

    return (
        <CardLayout
            isLoading={isLoading}
            className="h-[172px] py-4 space-y-2 w-full"
            title={t("title")}
        >
            <div className="flex flex-col justify-center space-y-2">
                <h1>{progress.toFixed(0)}%</h1>
                <Progress.Root
                    className={`w-full h-1.5 rounded-full bg-gray-200 overflow-hidden`}
                    value={progress}
                    style={{ transform: "translateZ(0)" }}
                >
                    <Progress.Indicator
                        className={` bg-green h-full transition-transform duration-[660ms] ease-[cubic-bezier(0.65,0,0.35,1)]`}
                        style={{
                            transform: `translateX(-${100 - progress}%)`,
                        }}
                    />
                </Progress.Root>
                <div className="flex justify-between">
                    <div>{t("ok_requirements", { count: ok })}</div>
                    <div>{t("total", { count: total })}</div>
                </div>
            </div>
        </CardLayout>
    );
}
