import i18next from "../../i18n";

i18next.addResourceBundle("en", "Themes", {
    title: "Material topics",
    subtitle:
        "Activate or deactivate the sustainability topics that are material to your organizations to automatically update your sustainability management system.",
});

i18next.addResourceBundle("fr", "Themes", {
    title: "Enjeux matériels",
    subtitle:
        "Activez ou désactivez les enjeux de durabilité qui sont matériels pour votre organisation afin de mettre à jour automatiquement votre système de management de durabilité.",
});
