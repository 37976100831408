// inspired by https://medium.com/swlh/using-a-debounced-callback-in-react-ade57d31ca6b
import { useRef, useCallback } from "react";

/**
 * Returns a memoized function that will only call the passed function when it hasn't been called for the wait period
 * @param func The function to be called
 * @param wait Wait period after function hasn't been called for, in ms
 * @returns A memoized function that is debounced. This memoized function is stable and will not change between renders, unless the wait param changes
 */
export function useDebouncedStableCallback<T extends (...args: any[]) => any>(
    func: T,
    wait: number,
): T {
    // Use a ref to store the function between renders
    const funcRef = useRef(func);
    funcRef.current = func;
    // Use a ref to store the timeout between renders
    // and prevent changes to it from causing re-renders
    const timeout = useRef<NodeJS.Timeout | null>(null);

    return useCallback(
        (...args: Parameters<T>) => {
            const fn = funcRef.current;
            const later = () => {
                if (timeout.current) {
                    clearTimeout(timeout.current);
                }
                fn(...args);
            };

            if (timeout.current) {
                clearTimeout(timeout.current);
            }
            timeout.current = setTimeout(later, wait);
        },
        [funcRef, wait],
    ) as T;
}
