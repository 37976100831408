import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { GetDocumentByUrlDocument } from "../../../generated/client/graphql";
import { useGraphQL } from "../../QueryClientWithHeaders";

export const useDocument = () => {
    const { document_id: url } = useParams();

    const getDocumentQuery = useGraphQL(
        GetDocumentByUrlDocument,
        {
            documentUrl: url!,
        },
        { enabled: !!url },
        true,
    );
    const document = getDocumentQuery.data?.documentByUrl;

    const key = useGraphQL.getKey(GetDocumentByUrlDocument, {
        documentUrl: url!,
    });
    const client = useQueryClient();

    const invalidateQuery = () => client.invalidateQueries({ queryKey: key });
    return { document, getDocumentQuery, invalidateQuery };
};
