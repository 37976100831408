import { ArrowRightCircle, Glasses, X } from "lucide-react";
import {
    DocumentExpirationStatus,
    DocumentType,
    Proof,
} from "../../../../../generated/client/graphql";

import { ReactComponent as Status } from "../../../../assets/Status.svg";
import { ReactComponent as Progress } from "../../../../assets/progress.svg";
import UserSelect from "../../../../components/Dropdown/dropdowns/User";
import { useNavigation } from "../../../../lib/navigation";
import { useUpdateProofDocumentOwner } from "./hooks";

const ProofTable = ({
    requirementId,
    proofs,
}: {
    requirementId: string;
    proofs: Proof[];
}) => {
    const { navigateFromHome } = useNavigation();
    const { updateOwner } = useUpdateProofDocumentOwner(requirementId);
    const handleClick = (proof: Proof) => {
        if (proof.document?.type === DocumentType.Procedure) {
            navigateFromHome(`measures/${proof.document?.uniqueUrl}`);
        } else if (
            proof.document?.type === DocumentType.Policy ||
            proof.document?.type === DocumentType.MasterPolicy
        ) {
            navigateFromHome(`policies/${proof.document?.uniqueUrl}`);
        } else if (proof.document?.type === DocumentType.Misc) {
            navigateFromHome(`measures/${proof.document?.uniqueUrl}`);
        } else if (proof.document?.type === DocumentType.CustomDocument) {
            navigateFromHome(`custom_documents/${proof.document?.uniqueUrl}`);
        }
    };
    return (
        <div className="rounded-lg border border-tertiary overflow-hidden">
            <div className="p-3 bg-secondary border-b border-tertiary font-bold"></div>
            {proofs.map((proof, index) => (
                <div
                    key={index}
                    className="p-3 border-b border-tertiary last:border-none flex justify-between items-center"
                >
                    <div className="flex items-center space-x-3 ">
                        <div className="w-4 h-4">
                            {proof.document?.status ===
                                DocumentExpirationStatus.Draft && <Progress />}
                            {proof.document?.status ===
                                DocumentExpirationStatus.Ok && <Status />}
                            {proof.document?.status ===
                                DocumentExpirationStatus.NotStarted && (
                                <ArrowRightCircle className="w-4 h-4" />
                            )}
                            {proof.document?.status ===
                                DocumentExpirationStatus.PendingBeavrReview && (
                                <Glasses className="w-4 h-4" />
                            )}
                            {proof.document?.status ===
                                DocumentExpirationStatus.Rejected && (
                                <X className="w-4 h-4" />
                            )}
                        </div>
                        <a
                            onClick={() => handleClick(proof)}
                            className="text-green font-bold cursor-pointer"
                        >
                            {proof.document?.name}
                        </a>
                    </div>
                    <UserSelect
                        selectedUserId={
                            proof.document?.assignedToId || undefined
                        }
                        onChange={(ownerId) =>
                            updateOwner(proof, ownerId || null)
                        }
                    />
                </div>
            ))}
        </div>
    );
};

export default ProofTable;
