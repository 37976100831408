import React from "react";
import { useTranslation } from "react-i18next";
import {
    SustainabilityCenter,
    Theme,
} from "../../../../../generated/client/graphql";
import {
    TabContent,
    TabList,
    TabRoot,
    TabTrigger,
} from "../../../../components/Tab";
import { useApprovedRequirements } from "../hooks/useApprovedRequirements";
import "../i18n";
import SustainabilityCenterControlsTab from "./SustainabilityCenterControlsTab";
import SustainabilityCenterHeader from "./SustainabilityCenterHeader";
import SustainabilityCenterOverviewTab from "./SustainabilityCenterOverviewTab";

export default function SustainabilityCenterContent({
    sustainabilityCenter,
    editMode,
    orgUname,
}: {
    sustainabilityCenter: SustainabilityCenter;
    editMode: boolean;
    orgUname: string | undefined;
}) {
    const { t } = useTranslation("SustainabilityCenter", {
        lng: sustainabilityCenter?.locale || "fr",
    });
    const [value, setValue] = React.useState("overview");
    const { themes } = useApprovedRequirements(orgUname);
    return (
        <div className="flex flex-col justify-center items-start bg-primary rounded-xxl p-5 border-tertiary shadow-md">
            <SustainabilityCenterHeader
                sustainabilityCenter={sustainabilityCenter}
                editMode={editMode}
            ></SustainabilityCenterHeader>
            <div className="flex w-[1120px] items-start">
                <TabRoot
                    value={value}
                    onValueChange={setValue}
                    orientation="horizontal"
                >
                    <TabList>
                        <TabTrigger
                            text={t("overview_tab_title")}
                            value="overview"
                            color={sustainabilityCenter?.hexColor || undefined}
                        />
                        <TabTrigger
                            text={t("controls_tab_title")}
                            value="controls"
                            color={sustainabilityCenter?.hexColor || undefined}
                        />
                    </TabList>
                    <TabContent value="overview">
                        <SustainabilityCenterOverviewTab
                            sustainabilityCenter={
                                sustainabilityCenter as SustainabilityCenter
                            }
                            themes={themes?.publicSCThemes as Theme[]}
                            editMode={editMode}
                            setTabValue={setValue}
                        />
                    </TabContent>
                    <TabContent value="controls">
                        <SustainabilityCenterControlsTab
                            themes={
                                (
                                    (themes?.publicSCThemes || []) as Theme[]
                                ).filter(
                                    (theme) =>
                                        theme.approvedRequirements &&
                                        theme.approvedRequirements.length > 0
                                ) as Theme[]
                            }
                            locale={sustainabilityCenter?.locale || "fr"}
                        ></SustainabilityCenterControlsTab>
                    </TabContent>
                </TabRoot>
            </div>
        </div>
    );
}
