import i18next from "../../../../../i18n";

i18next.addResources("en", "ChooseRequirementTableModal", {
    title: "Requirements",
    subtitle: "Select the requirements you want to link to the document.",
    open_button: "Edit requirements",
    header_attached: "Attached requirements",
    header_not_attached: "Add more",
    set_requirements: "Set requirements",
    warning:
        "⚠️ Editing the requirements associated with your document can impact your compliance. We recommend using the documents suggested by Beavr and the requirements automatically associated with them.",
});
i18next.addResources("fr", "ChooseRequirementTableModal", {
    title: "Contrôles",
    subtitle: "Sélectionnez les contrôles que vous souhaitez lier au document.",
    open_button: "Modifier les contrôles",
    header_attached: "Contrôles liés",
    header_not_attached: "Ajouter",
    set_requirements: "Définir les contrôles",
    warning:
        "⚠️ Modifier les éléments de contrôle associés à votre document peut impacter votre mise en conformité. Nous vous recommandons d'utiliser les documents suggérés par Beavr et les contrôles qui y sont automatiquement associés.",
});
