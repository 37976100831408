import { EditPencil, Plus } from "iconoir-react";
import { useTranslation } from "react-i18next";
import {
    ScFaq,
    SustainabilityCenter,
} from "../../../../generated/client/graphql";
import { ButtonType } from "../../Button/ButtonShared";
import ButtonSmall from "../../Button/ButtonSmall";

import { ChevronDown, ChevronUp } from "lucide-react";
import { useEffect, useState } from "react";
import { cn, orderSort } from "../../../lib/utils";
import "./i18n";

export default function FaqsBlock({
    sustainabilityCenter,
    editMode,
    setOpenModal,
}: {
    sustainabilityCenter: SustainabilityCenter;
    editMode: boolean;
    setOpenModal: (open: boolean) => void;
}) {
    const { t } = useTranslation("SustainabilityCenterFaqsBlock");

    const [faqs, setFaqs] = useState<ScFaq[]>(
        orderSort(sustainabilityCenter?.scFaqs || [])
    );

    const [openFaqIdx, setOpenFaqIdx] = useState<number | undefined>(undefined);

    useEffect(() => {
        setFaqs(orderSort(sustainabilityCenter?.scFaqs || []));
    }, [sustainabilityCenter]);

    const isEmpty = !faqs || faqs.length == 0;

    const toggleOpen = (idx: number) => {
        if (idx === openFaqIdx) {
            setOpenFaqIdx(undefined);
        } else {
            setOpenFaqIdx(idx);
        }
    };

    return (
        <div className="flex flex-col gap-4 self-stretch items-start">
            <div className="flex h-6.5 justify-between flex-start self-stretch">
                <div className="text-primary font-bold text-lg">
                    {t("title")}
                </div>
                {editMode && (
                    <ButtonSmall
                        variant={ButtonType.OUTLINED}
                        IconLeft={EditPencil}
                        onClick={() => setOpenModal(true)}
                    ></ButtonSmall>
                )}
            </div>

            {isEmpty && (
                <div className="w-full gap-3 py-7 text-primary rounded-xl border border-secondary flex flex-col justify-center align-middle">
                    <div className="text-primary text-sm text-center">
                        {t("empty_text")}
                    </div>
                    <div className="flex justify-center">
                        {editMode && (
                            <ButtonSmall
                                text={t("add_faq_text")}
                                IconRight={Plus}
                                variant={ButtonType.OUTLINED}
                                onClick={() => setOpenModal(true)}
                            ></ButtonSmall>
                        )}
                    </div>
                </div>
            )}
            {!isEmpty && (
                <div className="flex flex-col items-start self-stretch border border-secondary rounded-xl">
                    {faqs.map((faq, idx) => (
                        <div
                            className={cn(
                                "p-3 flex flex-col items-start self-stretch gap-2 justify-between cursor-pointer",
                                idx === faqs.length - 1
                                    ? ""
                                    : "border-b border-secondary",
                                openFaqIdx === idx ? "bg-secondary" : ""
                            )}
                            onClick={() => toggleOpen(idx)}
                        >
                            <div className="flex items-start self-stretch justify-between w-full">
                                <div className="font-bold text-primary text-sm">
                                    {faq.question}
                                </div>

                                {openFaqIdx === idx ? (
                                    <ChevronUp className="w-5 h-5" />
                                ) : (
                                    <ChevronDown className="w-5 h-5" />
                                )}
                            </div>
                            {openFaqIdx === idx && (
                                <div className="text-primary text-sm">
                                    {faq.answer}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
