import { DefinedQueryObserverResult } from "@tanstack/react-query";
import { Upload } from "iconoir-react";
import { ChangeEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
    CreateWithUploadDocument,
    GetDocumentByUrlQuery,
} from "../../../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../../../QueryClientWithHeaders";
import ButtonRegular from "../../../../../components/Button/ButtonRegular";
import { useCreateS3File } from "../../../../../hooks/createFileHook";
import OptionLayout from "./OptionLayout";
import "./i18n";

const OptionUploadFile = ({
    getDocumentQuery,
}: {
    getDocumentQuery: DefinedQueryObserverResult<
        GetDocumentByUrlQuery,
        unknown
    >;
}) => {
    const { t } = useTranslation("CreateDocumentOption");

    const { isLoading, uploadFile } = useCreateS3File();

    const createNewVersionMutation = useGraphQLMutation(
        CreateWithUploadDocument,
        {},
        true,
    );

    const onFileChange = useCallback(
        async (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.files) {
                const s3File = await uploadFile(e.target.files[0]);
                if (!s3File?.id) {
                    return;
                }
                createNewVersionMutation.mutate(
                    {
                        id: getDocumentQuery.data.documentByUrl?.id!,
                        s3FileId: s3File.id,
                    },
                    { onSuccess: () => getDocumentQuery.refetch() },
                );
            }
        },
        [uploadFile],
    );
    const uploadFileButton = (
        <ButtonRegular
            loading={
                isLoading ||
                createNewVersionMutation.isPending ||
                getDocumentQuery.isRefetching
            }
            className="min-w-[110px]"
        >
            <label className="cursor-pointer">
                <input
                    type="file"
                    hidden
                    onChange={onFileChange}
                    accept={
                        getDocumentQuery?.data?.documentByUrl?.extType ===
                        "OTHER"
                            ? "application/pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                            : "application/pdf"
                    }
                />
                {t("upload_button")}
            </label>
        </ButtonRegular>
    );

    return (
        <OptionLayout
            Icon={Upload}
            button={uploadFileButton}
            title={t("upload_title")}
            subtitle={t("upload_desc")}
        />
    );
};

export default OptionUploadFile;
