import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    GetSuppliersForFilterDocument,
    Supplier,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import FilterTrigger from "../../components/triggers/Filter";
import SupplierDropdown from "./dropdown";
import "./i18n";

export const filterSupplierIdsAtom = atom<string[] | undefined>(undefined);
const SupplierFilter = () => {
    const [supplierIds, setSupplierIds] = useAtom(filterSupplierIdsAtom);
    const { t } = useTranslation("SupplierDropdown");

    const getSuppliers = useGraphQL(
        GetSuppliersForFilterDocument,
        undefined,
        {},
        true
    );
    useEffect(() => {
        setSupplierIds(undefined);
    }, []);
    return (
        <DropdownMenu>
            <FilterTrigger
                unsetCallback={
                    supplierIds ? () => setSupplierIds(undefined) : undefined
                }
                active={supplierIds !== undefined}
            >
                {t("placeholder")}
            </FilterTrigger>
            <SupplierDropdown
                supplierIds={supplierIds}
                onSupplierIdsChange={(ids: string[]) =>
                    setSupplierIds(ids.length > 0 ? ids : undefined)
                }
                suppliers={
                    getSuppliers.data?.suppliers as Supplier[] | undefined
                }
            />
        </DropdownMenu>
    );
};

export default SupplierFilter;
