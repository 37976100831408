import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "UnitInput", {
    placeholder: "Unit",
    unset: "Unset",
});

i18next.addResourceBundle("fr", "UnitInput", {
    placeholder: "Unité",
    unset: "Réinitialiser",
});
