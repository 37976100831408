import DropZone from "@design-system/Inputs/DropZone";
import { Text } from "@design-system/Typography/Text";
import { Trans, useTranslation } from "react-i18next";
import "./i18n";
import { useModalActions } from "./useModalActions";

export const DocumentsUploadModalDropzone = () => {
    const { t } = useTranslation("DocumentsUploadModal");
    const { handleDropFiles, isUploading, uploadedFiles: { list: files} } =
        useModalActions();
    return (
        <DropZone
            buttonText={t("dropzone_button")}
            multiple
            onChange={handleDropFiles}
            size={!!files.length || isUploading ? "md" : "lg"}
        >
            <Text as='span'>
                <Trans t={t} i18nKey={"dropzone_title"} />
            </Text>
        </DropZone>
    );
};
