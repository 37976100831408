import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { ChevronDown } from "lucide-react";
import { useTranslation } from "react-i18next";
import { ReactComponent as FranceFlag } from "../../../../assets/flags/france.svg";
import { ReactComponent as UkFlag } from "../../../../assets/flags/uk.svg";
import ButtonRegular from "../../../Button/ButtonRegular";
import { ButtonType } from "../../../Button/ButtonShared";
import LocaleDropdown from "./dropdown";
import "./i18n";

interface LocaleSelectProps {
    locale?: string;
    setLocale: (locale: string | undefined) => void;
    title?: string;
}

export default function LocaleSelect({ locale, setLocale }: LocaleSelectProps) {
    const { t } = useTranslation("LocaleDropdown");

    return (
        <>
            <DropdownMenu>
                <DropdownPrimitive.Trigger>
                    <ButtonRegular
                        text={locale ? t(locale) : t("placeholder")}
                        IconLeft={locale === "fr" ? FranceFlag : UkFlag}
                        IconRight={ChevronDown}
                        variant={ButtonType.OUTLINED}
                        className="w-[120px]"
                    />
                </DropdownPrimitive.Trigger>
                <LocaleDropdown locale={locale} onLocaleChange={setLocale} />
            </DropdownMenu>
        </>
    );
}
