import { currentUserAtom } from "@app/store/userStore";
import { useAuth0 } from "@auth0/auth0-react";
import { useAtomValue } from "jotai";
import { useEffect } from "react";

export const useLogoutIfNotPasswordless = () => {
    const { error } = useAtomValue(currentUserAtom);
    const { user } = useAuth0();
    const { logout } = useAuth0();

    useEffect(() => {
        if (user?.sub?.includes("auth0") && error) {
            logout();
        }
    }, [error, user?.sub]);
};
