import i18next from "../../../../../i18n";

i18next.addResourceBundle("en", "CreateIndicatorModal", {
    title: "Create Indicator",
    subtitle:
        "Choose the id, name, description and unit type of your indicator",
    uname: "Id",
    order: "Order",
    name: "Name",
    description: "Description",
    options: "Options: Go to line between each option.",
    create: "Create",
    success: "Indicator created successfully",
    error: "Could not create indicator",
});

i18next.addResourceBundle("fr", "CreateIndicatorModal", {
    title: "Créer un indicateur",
    subtitle:
        "Choisissez l'id, le nom, la description et le type d'unité de votre indicateur. ",
    uname: "Id",
    order: "Ordre",
    name: "Nom",
    description: "Description",
    options: "Options: Aller à la ligne entre chaque option.",
    create: "Créer",
    success: "Indicateur créé avec succès",
    error: "Impossible de créer l'indicateur",
});
