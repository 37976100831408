import { type OrganizationRepository } from "@app/repositories/OrganizationRepository";
import { type Organization } from "./types";
import { GetUserOrganisationsQueryVariables } from "@generated/client/graphql";
export async function getOrgs(
    {
        organizationRepository,
    }: { organizationRepository: OrganizationRepository },
    input: GetUserOrganisationsQueryVariables,
) {
    const data = await organizationRepository.get(input);
    const organizations: Organization[] = data.orgs?.data ?? [];
    const count = data.orgs?.count ?? 0;
    return { organizations, count };
}

export type { Organization };
