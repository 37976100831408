import { DocumentVersionCheckType } from "@generated/client/graphql";
import { keys } from "./i18n";

export const checkListCards = [
    {
        id: "",
        checked: false,
        checkType: DocumentVersionCheckType.Issues,
        descriptionKey: keys.card_sustainability_issues_description,
        titleKey: keys.card_sustainability_issues_title,
    },
    {
        id: "",
        checked: false,
        checkType: DocumentVersionCheckType.Scope,
        descriptionKey: keys.card_scope_description,
        titleKey: keys.card_scope_title,
    },
    {
        id: "",
        checked: false,
        checkType: DocumentVersionCheckType.Objectives,
        descriptionKey: keys.card_objectives_description,
        titleKey: keys.card_objectives_title,
    },
    {
        id: "",
        checked: false,
        checkType: DocumentVersionCheckType.Governance,
        descriptionKey: keys.card_governance_description,
        titleKey: keys.card_governance_title,
    },
    {
        id: "",
        checked: false,
        checkType: DocumentVersionCheckType.Revision,
        descriptionKey: keys.card_revision_description,
        titleKey: keys.card_revision_title,
    },
];
