import { GraphQLEntityRepository } from "@app/repositories/GraphQLRepositories/EntityRepository";
import { atomWithQuery } from "jotai-tanstack-query";

// init
const entityRepository = new GraphQLEntityRepository();

// queries
export const entitiesAtom = atomWithQuery(() => {
    return {
        queryKey: ["entities", {}],
        queryFn: entityRepository.get,
    };
});
