import React from "react";
import { default as ButtonElevated } from "../ButtonElevated";
import ButtonFlatDanger from "../ButtonFlatDanger";
import ButtonFlatPrimary from "../ButtonFlatPrimary";
import ButtonOutline from "../ButtonOutline";
import { ButtonProps, ButtonType } from "../ButtonShared";
import ButtonText from "../ButtonText";
import ButtonTonal from "../ButtonTonal";
import ButtonXSmallBase from "./ButtonXSmallBase";

const ButtonXSmallElevated = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonElevated
                ref={forwardedRef}
                Base={ButtonXSmallBase}
                {...props}
            />
        );
    }
);
const ButtonXSmallFlatPrimary = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonFlatPrimary
                ref={forwardedRef}
                Base={ButtonXSmallBase}
                {...props}
            />
        );
    }
);

const ButtonXSmallFlatDanger = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonFlatDanger
                ref={forwardedRef}
                Base={ButtonXSmallBase}
                {...props}
            />
        );
    }
);

const ButtonXSmallTonal = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonTonal
                ref={forwardedRef}
                Base={ButtonXSmallBase}
                {...props}
            />
        );
    }
);

const ButtonXSmallOutlined = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonOutline
                Base={ButtonXSmallBase}
                {...props}
                ref={forwardedRef}
            />
        );
    }
);

const ButtonXSmallText = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonText Base={ButtonXSmallBase} {...props} ref={forwardedRef} />
        );
    }
);

/**
 * @deprecated
 */
const ButtonXSmall = React.forwardRef(
    (
        {
            variant,
            ...props
        }: { variant?: ButtonType } & ButtonProps &
            React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        if (!variant) {
            return <ButtonXSmallElevated {...props} ref={forwardedRef} />;
        }
        switch (variant) {
            case ButtonType.ELEVATED:
                return <ButtonXSmallElevated {...props} ref={forwardedRef} />;
            case ButtonType.FLAT_PRIMARY:
                return (
                    <ButtonXSmallFlatPrimary {...props} ref={forwardedRef} />
                );
            case ButtonType.FLAT_DANGER:
                return <ButtonXSmallFlatDanger {...props} ref={forwardedRef} />;
            case ButtonType.TONAL:
                return <ButtonXSmallTonal {...props} ref={forwardedRef} />;
            case ButtonType.OUTLINED:
                return <ButtonXSmallOutlined {...props} ref={forwardedRef} />;
            case ButtonType.TEXT:
                return <ButtonXSmallText {...props} ref={forwardedRef} />;
        }
    }
);

export default ButtonXSmall;
