import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { useTranslation } from "react-i18next";
import { ReactComponent as FranceFlag } from "../../../../assets/flags/france.svg";
import { ReactComponent as UkFlag } from "../../../../assets/flags/uk.svg";
import SingleSelectDropdown from "../../components/dropdowns/SingleSelect";
import "./i18n";

const LocaleDropdown = ({
    locale,
    onLocaleChange,
    ...props
}: {
    locale?: string;
    onLocaleChange: (locale: string | undefined) => void;
} & DropdownPrimitive.DropdownMenuContentProps) => {
    const { t } = useTranslation("LocaleDropdown");
    return (
        <SingleSelectDropdown
            disableSearch
            value={locale}
            onValueChange={onLocaleChange}
            values={[
                {
                    value: "fr",
                    node: (
                        <div className="flex items-center justify-between">
                            <div>{t("fr")}</div>
                            <FranceFlag className="w-6 h-6" />
                        </div>
                    ),
                },
                {
                    value: "en",
                    node: (
                        <div className="flex items-center justify-between">
                            <div>{t("en")}</div>
                            <UkFlag className="w-6 h-6" />
                        </div>
                    ),
                },
            ]}
            className="w-[20px]"
            {...props}
        />
    );
};

export default LocaleDropdown;
