import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import * as Popover from "@radix-ui/react-popover";
import { Cancel, Plus } from "iconoir-react";
import { ComponentType, useState } from "react";

const PlusDropDownItem = ({
    title,
    children,
    SaveButton,
}: {
    title: string;
    children: React.ReactNode;
    SaveButton: ComponentType<{ onClick: () => void }>;
}) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Popover.Root open={open} onOpenChange={setOpen} modal={true}>
                <Popover.Trigger className="w-full">
                    <DropdownPrimitive.Item
                        onClick={(e) => {
                            setOpen(true);
                            e.preventDefault();
                        }}
                        className="flex-grow px-2 py-1.5 focus:bg-green-50 focus:outline-none space-x-2.5 flex items-center"
                    >
                        <Plus className="w-4 h-4 text-green" />
                        <div className="text-sm font-regular">{title}</div>
                    </DropdownPrimitive.Item>
                </Popover.Trigger>
                <Popover.Content
                    className="py-2 px-4 bg-white shadow-dropdown rounded-lg space-y-2"
                    sideOffset={10}
                    side="left"
                >
                    <div className="relative w-full">
                        <Popover.Close className="absolute -right-2">
                            <Cancel className="w-4 h-4" />
                        </Popover.Close>
                    </div>
                    {children}
                    <SaveButton onClick={() => setOpen(false)} />
                </Popover.Content>
            </Popover.Root>
        </>
    );
};

export default PlusDropDownItem;
