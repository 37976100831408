import { useTranslation } from "react-i18next";
import { InnerSelectTrigger } from "../SelectTrigger";
import "./i18n";

import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { useState } from "react";

import { cn } from "../../../lib/utils";
import Checkbox from "../../Checkbox";
import "./i18n";

interface MultiSelectInputProps {
    success?: boolean;
    disabled?: boolean;
    optionIds: string[] | undefined;
    onOptionIdsChange: (v: string[] | null) => void;
    options?: {
        id: string;
        name: string;
    }[];
}
const MultiSelectInput = ({
    success,
    disabled,
    optionIds,
    onOptionIdsChange,
    options,
}: MultiSelectInputProps) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation("MultiSelectInput");

    const onCheckChanged = (optionId: string, checked: boolean) => {
        if (checked) {
            const newValues = [...(optionIds || [])];
            newValues.push(optionId);
            onOptionIdsChange(newValues);
        } else {
            const newValues = optionIds?.filter((id) => id !== optionId);
            onOptionIdsChange(newValues || null);
        }
    };
    return (
        <DropdownPrimitive.Root open={open} onOpenChange={setOpen}>
            <DropdownPrimitive.Trigger className="w-full" disabled={disabled}>
                <InnerSelectTrigger
                    success={success}
                    disabled={disabled}
                    stringValue={
                        optionIds && optionIds?.length > 0
                            ? t("selected_value", { count: optionIds.length })
                            : undefined
                    }
                    placeholder={t("placeholder")}
                    className="w-full"
                />
            </DropdownPrimitive.Trigger>
            <DropdownPrimitive.Portal>
                <DropdownPrimitive.Content
                    align="start"
                    sideOffset={4}
                    className="border w-[220px] bg-white shadow-dropdown rounded-xl p-1"
                    style={{
                        width: "var(--radix-dropdown-menu-trigger-width)",
                    }}
                >
                    {options?.map(({ id, name }) => (
                        <DropdownPrimitive.CheckboxItem
                            checked={optionIds?.includes(id) || false}
                            onSelect={(e) => e.preventDefault()}
                            className="px-2 py-1.5 flex space-x-2.5"
                            onCheckedChange={(checked) =>
                                onCheckChanged(id, checked)
                            }
                        >
                            <Checkbox
                                checked={optionIds?.includes(id) || false}
                                onCheckedChange={(checked) =>
                                    onCheckChanged(id, checked)
                                }
                            />
                            <div
                                className={cn(
                                    "text-sm",
                                    optionIds?.includes(id)
                                        ? "font-bold"
                                        : "font-regular"
                                )}
                            >
                                {name}
                            </div>
                        </DropdownPrimitive.CheckboxItem>
                    ))}
                </DropdownPrimitive.Content>
            </DropdownPrimitive.Portal>
        </DropdownPrimitive.Root>
    );
};

export default MultiSelectInput;
