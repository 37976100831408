import React from "react";
import { default as ButtonElevated } from "../ButtonElevated";
import ButtonFlatDanger from "../ButtonFlatDanger";
import ButtonFlatPrimary from "../ButtonFlatPrimary";
import ButtonOutline from "../ButtonOutline";
import { ButtonProps, ButtonType } from "../ButtonShared";
import ButtonText from "../ButtonText";
import ButtonTonal from "../ButtonTonal";
import ButtonLargeBase from "./ButtoLargeBase";

const ButtonLargeElevated = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonElevated
                ref={forwardedRef}
                Base={ButtonLargeBase}
                {...props}
            />
        );
    }
);
const ButtonLargeFlatPrimary = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonFlatPrimary
                ref={forwardedRef}
                Base={ButtonLargeBase}
                {...props}
            />
        );
    }
);

const ButtonLargeFlatDanger = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonFlatDanger
                ref={forwardedRef}
                Base={ButtonLargeBase}
                {...props}
            />
        );
    }
);

const ButtonLargeTonal = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonTonal ref={forwardedRef} Base={ButtonLargeBase} {...props} />
        );
    }
);

const ButtonLargeOutlined = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonOutline
                Base={ButtonLargeBase}
                {...props}
                ref={forwardedRef}
            />
        );
    }
);

const ButtonLargeText = React.forwardRef(
    (
        {
            ...props
        }: ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <ButtonText Base={ButtonLargeBase} {...props} ref={forwardedRef} />
        );
    }
);

/**
 * @deprecated
 */
const ButtonLarge = React.forwardRef(
    (
        {
            variant,
            ...props
        }: { variant?: ButtonType } & ButtonProps &
            React.ButtonHTMLAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        if (!variant) {
            return <ButtonLargeElevated {...props} ref={forwardedRef} />;
        }
        switch (variant) {
            case ButtonType.ELEVATED:
                return <ButtonLargeElevated {...props} ref={forwardedRef} />;
            case ButtonType.FLAT_PRIMARY:
                return <ButtonLargeFlatPrimary {...props} ref={forwardedRef} />;
            case ButtonType.FLAT_DANGER:
                return <ButtonLargeFlatDanger {...props} ref={forwardedRef} />;
            case ButtonType.TONAL:
                return <ButtonLargeTonal {...props} ref={forwardedRef} />;
            case ButtonType.OUTLINED:
                return <ButtonLargeOutlined {...props} ref={forwardedRef} />;
            case ButtonType.TEXT:
                return <ButtonLargeText {...props} ref={forwardedRef} />;
        }
    }
);

export default ButtonLarge;
