import * as dayjs from "dayjs";
import "dayjs/locale/fr";

import { atom, useSetAtom } from "jotai";
import { useEffect } from "react";
import { HiChevronDown } from "react-icons/hi2";
import { useNavigate, useParams } from "react-router-dom";
import {
    GetCurrentUserQuery,
    User,
} from "../../../../generated/client/graphql";
import i18next from "../../../i18n";
import Avatar from "../../User/Avatar";

export const currentUserAtom = atom<User | undefined>(undefined);
export default function TopBarProfil({
    currentUser,
}: {
    currentUser?: GetCurrentUserQuery["currentUser"];
}) {
    const navigate = useNavigate();
    const params = useParams();
    const setCurrentUser = useSetAtom(currentUserAtom);
    useEffect(() => {
        const locale = currentUser?.locale;
        i18next.changeLanguage(locale);
        dayjs.locale(locale);
    }, [currentUser?.locale]);

    useEffect(() => {
        setCurrentUser(currentUser as User);
    }, [currentUser]);

    return (
        <button
            className="flex items-center space-x-4 rounded-full border border-neutral-150 p-1 pr-3"
            onClick={() => navigate(`/o/${params.org_uname}/admin/profile`)}
        >
            {currentUser && (
                <Avatar
                    avatarUrl={currentUser.avatar?.signedUrl}
                    initials={currentUser.initials}
                    size="large"
                />
            )}
            <HiChevronDown className="w-4 h-4 text-neutral-700" />
        </button>
    );
}
