import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    CreateSupplierQuestionnaireTemplateDocument,
    GetAllSupplierQuestionnaireTemplatesDocument,
    SupplierQuestionnaireTemplate,
} from "../../../../../generated/client/graphql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../../QueryClientWithHeaders";
import ButtonSmall from "../../../Button/ButtonSmall";
import TextInput from "../../../inputs_v2/Text";
import PlusDropDownItem from "../../MultiSelect/PlusDropdownItem";
import CheckboxItem from "../../components/dropdowns/items/CheckboxItem";
import DropdownItemSearch from "../../components/dropdowns/items/Search";
import DropdownContentLayout from "../../components/dropdowns/layout";

const SupplierQuestionnaireTemplatesDropdownMultiSelect = ({
    templateIds,
    onTemplateIdsChange,
    templates,
    hidePlus,
    ...props
}: {
    templateIds?: string[] | undefined;
    onTemplateIdsChange: (templateIds: string[]) => void;
    templates: SupplierQuestionnaireTemplate[] | undefined;
    hidePlus?: boolean;
} & DropdownPrimitive.DropdownMenuContentProps) => {
    const { t } = useTranslation("SupplierQuestionnaireTemplatesDropdown");
    const [newName, setNewName] = useState<string>();
    const [searchFilterString, setSearchFilterString] = useState("");

    const { mutate } = useGraphQLMutation(
        CreateSupplierQuestionnaireTemplateDocument,
        {},
        true
    );
    const client = useQueryClient();
    const onSave = () => {
        newName &&
            mutate(
                { name: newName },
                {
                    onSuccess: () => {
                        client.invalidateQueries({
                            queryKey: useGraphQL.getKey(
                                GetAllSupplierQuestionnaireTemplatesDocument
                            ),
                        });
                    },
                }
            );
    };
    const onToggle = (id: string) => {
        if (templateIds?.includes(id)) {
            const newValues = [...templateIds];
            newValues.splice(templateIds.indexOf(id), 1);
            onTemplateIdsChange(newValues);
        } else {
            onTemplateIdsChange([...(templateIds || []), id]);
        }
    };
    return (
        <DropdownContentLayout {...props}>
            <DropdownItemSearch
                searchFilterString={searchFilterString}
                setSearchFilterString={setSearchFilterString}
            />
            {!hidePlus && (
                <PlusDropDownItem
                    title={t("create_template")}
                    SaveButton={({ onClick }) => (
                        <ButtonSmall
                            disabled={!newName}
                            onClick={() => {
                                onClick();
                                onSave();
                            }}
                        >
                            {t("create_template")}
                        </ButtonSmall>
                    )}
                >
                    <div className="text-sm font-bold">
                        {t("create_template")}
                    </div>
                    <TextInput value={newName} setValue={setNewName} />
                </PlusDropDownItem>
            )}
            {templates
                ?.filter(
                    ({ name }) =>
                        !searchFilterString ||
                        (name &&
                            name
                                .toLowerCase()
                                .includes(searchFilterString.toLowerCase()))
                )
                .map(({ id, name }) => (
                    <CheckboxItem
                        key={id}
                        onSelect={(e) => {
                            e.preventDefault();
                        }}
                        checked={templateIds?.includes(id)}
                        onCheckedChange={() => onToggle(id)}
                    >
                        {name}
                    </CheckboxItem>
                ))}
        </DropdownContentLayout>
    );
};

export default SupplierQuestionnaireTemplatesDropdownMultiSelect;
