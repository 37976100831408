import i18next from "@app/i18n";

i18next.addResourceBundle("en", "FrameworkDatapoint", {
    comment: "Comment ({{charCount}} characters)",
    ownership: "Ownership",
    validate: "Validate datapoint",
    value: "Value {{ symbol }}",
    yes_no: "Yes/No",
    yes: "Yes",
    no: "No",
});

i18next.addResourceBundle("fr", "FrameworkDatapoint", {
    comment: "Commentaire ({{charCount}} caractères)",
    ownership: "Assigné à:",
    validate: "Valider le datapoint",
    value: "Valeur {{ symbol }}",
    yes_no: "Oui/Non",
    yes: "Oui",
    no: "Non",
});
