import {
    CreateTagDocument,
    CreateTagMutation,
    GetTagsDocument,
    GetTagsQuery,
    TagType,
    UpdateTagDocument,
    UpdateTagMutation,
} from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { TagRepository } from "../TagRepository";
import { graphqlClientKoyeb } from "../clients";

export class GraphQLTagRepository implements TagRepository {
    get = (type: TagType): Promise<GetTagsQuery> => {
        return graphqlClientKoyeb.request(
            GetTagsDocument,
            { type },
            AccessHeadersHolder.getHeaders(),
        );
    };

    update = (id: string, name: string): Promise<UpdateTagMutation> => {
        return graphqlClientKoyeb.request(
            UpdateTagDocument,
            { id, name },
            AccessHeadersHolder.getHeaders(),
        );
    };

    create = (type: TagType, name: string): Promise<CreateTagMutation> => {
        return graphqlClientKoyeb.request(
            CreateTagDocument,
            { type, name },
            AccessHeadersHolder.getHeaders(),
        );
    };
}
