export default function SustainabilityCenterBackground({
    color,
    children,
}: {
    color: String | undefined | null;
    children: React.ReactNode;
}) {
    return (
        <div className="w-full h-full flex flex-col">
            <div className="flex flex-col absolute top-0 left-0 h-full w-full -z-10">
                <div
                    className="h-[224px]"
                    style={{ backgroundColor: `${color || "#03A365"}` }}
                ></div>
                <div className="bg-secondary flex-1"></div>
            </div>
            {children}
        </div>
    );
}
