import i18next from "../../../i18n";

i18next.addResourceBundle("en", "MultiSelectInput", {
    placeholder: "Choose one or more options",
    selected_value_one: "{{count}} selected value",
    selected_value_other: "{{count}} selected values",
});

i18next.addResourceBundle("fr", "MultiSelectInput", {
    placeholder: "Choisir une ou plusieurs options",
    selected_value_one: "{{count}} valeur sélectionnée",
    selected_value_other: "{{count}} valeurs sélectionnées",
});
