import {
    CreateDatapointGroupDocument,
    CreateDatapointGroupInput,
    CreateDatapointGroupMutation,
    GetAllDatapointGroupsDocument,
    GetAllDatapointGroupsQuery,
} from "../../../generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { DatapointGroupRepository } from "../DatapointGroupRepository";
import { graphqlClientKoyeb } from "../clients";
export class GraphQLDatapointGroupRepository
    implements DatapointGroupRepository
{
    get = (): Promise<GetAllDatapointGroupsQuery> => {
        return graphqlClientKoyeb.request(
            GetAllDatapointGroupsDocument,
            undefined,
            AccessHeadersHolder.getHeaders(),
        );
    };

    create = (
        input: CreateDatapointGroupInput,
    ): Promise<CreateDatapointGroupMutation> => {
        return graphqlClientKoyeb.request(
            CreateDatapointGroupDocument,
            input,
            AccessHeadersHolder.getHeaders(),
        );
    };
}
