import { Copy } from "iconoir-react";
import { useTranslation } from "react-i18next";
import { Datapoint } from "../../../../../generated/client/graphql";
import { Is } from "../../../../lib/utils";

const CopyValue = ({
    lastDatapoint,

    onFloatChange,
    onStringChange,
    onBooleanChange,
    onCommentChange,
}: {
    lastDatapoint: Datapoint;

    onFloatChange: (valueFloat: number | null) => void;
    onStringChange: (valueString: string | null) => void;
    onBooleanChange: (valueBool: boolean | null) => void;
    onCommentChange: (comment: string | null) => void;
}) => {
    const { t } = useTranslation("RequestBlock");

    return (
        <>
            <div className="flex space-x-1">
                <div className="text-sm font-regular text-secondary">
                    {t("value_in")} {lastDatapoint.period?.year}:
                </div>
                <div className="text-sm font-regular">
                    {lastDatapoint.stringifyValue}
                </div>
                {(Is(lastDatapoint.valueFloat) ||
                    Is(lastDatapoint.valueString) ||
                    Is(lastDatapoint.valueBool)) && (
                    <button
                        onClick={() => {
                            if (Is(lastDatapoint.valueFloat)) {
                                onFloatChange(lastDatapoint.valueFloat!);
                                return;
                            } else if (Is(lastDatapoint.valueString)) {
                                onStringChange(lastDatapoint.valueString!);
                                return;
                            } else if (Is(lastDatapoint.valueBool)) {
                                onBooleanChange(lastDatapoint.valueBool!);
                                return;
                            }
                        }}
                    >
                        <Copy className="w-4 h-4 text-green" />
                    </button>
                )}
            </div>
            {lastDatapoint.comment && (
                <div>
                    <div className="flex space-x-1">
                        <div className="text-sm font-regular text-secondary">
                            {t("comment")}
                        </div>
                        <button
                            onClick={() =>
                                onCommentChange(lastDatapoint.comment!)
                            }
                        >
                            <Copy className="w-4 h-4 text-green" />
                        </button>
                    </div>
                    <div className="text-sm font-regular">
                        {lastDatapoint.comment}
                    </div>
                </div>
            )}
        </>
    );
};

export default CopyValue;
