import * as RadixSelect from "@radix-ui/react-select";
import { Check } from "iconoir-react";
import { ReactNode } from "react";
import { cn } from "../../../lib/utils";
import Item from "../Item";

export default function SelectItem({
    children,
    ...props
}: {
    children: ReactNode;
} & RadixSelect.SelectItemProps) {
    return (
        <Item
            {...props}
            className="group"
            IconLeft={({ className }) => (
                <Check
                    className={cn(
                        className,
                        "invisible group-data-[state=checked]:visible text-green "
                    )}
                />
            )}
        >
            {children}
        </Item>
    );
}
