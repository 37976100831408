import {
    ColumnFiltersState,
    SortingState,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { Document } from "../../../../../generated/client/graphql";
import { searchFilterStringAtom } from "../../../../components/Filters/Search";
import { DocumentColumns } from "./columns";

export const useDocumentsTable = (documents: Document[]) => {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnFilters, setColumnFilters] =
        React.useState<ColumnFiltersState>([]);
    const [rowSelection, setRowSelection] = React.useState({});
    const [searchFilterString, setSearchFilterString] = useAtom(
        searchFilterStringAtom
    );

    const table = useReactTable<Document>({
        data: documents,
        columns: DocumentColumns,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onGlobalFilterChange: setSearchFilterString,
        onRowSelectionChange: setRowSelection,
        state: {
            sorting,
            columnFilters,
            rowSelection,
            globalFilter: searchFilterString,
            columnVisibility: { subtheme: false, theme: false },
        },
    });

    return { table };
};
