import { Color } from "chroma-js";

interface ProgressProps {
    value: number;
    total: number;
    color?: Color;
}
const Progress = ({ value, total, color }: ProgressProps) => {
    return (
        <div className="flex w-full space-x-0.5">
            {Array.from(Array(total).keys()).map((_, idx) => (
                <div
                    key={idx}
                    className="h-1 flex-grow bg-quarternary "
                    style={{
                        ...(idx < value
                            ? {
                                  backgroundColor:
                                      color?.css() ||
                                      "var(--color-green-DEFAULT)",
                              }
                            : undefined),
                    }}
                ></div>
            ))}
        </div>
    );
};

export default Progress;
