import { ColumnDef } from "@tanstack/react-table";
import { atom, useAtom } from "jotai";
import { useTranslation } from "react-i18next";

import { useMemo } from "react";
import { ImportType } from "..";
import { DocumentAcceptance } from "../../../../../generated/client/graphql";
import Checkbox from "../../../../components/Checkbox";
import Header from "../../../../components/Table/Header";
import TextInput from "../../../../components/inputs_v2/Text";
import { cn } from "../../../../lib/utils";
import "./i18n";

const columnMapRawAtom = atom(
    new Map<string, string>([
        ["order", "order"],
        ["uname", "uname"],
        ["name", "name"],
        ["type", "type"],
        ["theme", "theme"],
        ["subtheme", "subtheme"],
        ["unit", "unit"],
        ["description", "description"],
        ["options", "options"],
        ["document_acceptance", "document"],
        ["tags", "tags"],
    ])
);

export const columnMapAtom = atom(
    (get) => get(columnMapRawAtom),
    (get, set, pair: Array<string>) => {
        const map = new Map(get(columnMapRawAtom));
        map.set(pair[0], pair[1]);
        set(columnMapRawAtom, map);
    }
);

export interface IndicatorImportCSV {
    uname?: string;
    name?: string;
    type?: string;
    theme?: string;
    subtheme?: string;
    unit?: string;
    description?: string;
    order?: string;
    options?: string;
    document_acceptance?: DocumentAcceptance;
    tags?: string;
}
export interface IndicatorImport {
    csv: IndicatorImportCSV;
    indicator_id?: string;
    uname?: string;
    name?: string;
    description?: string;
    value?: string;
    theme?: string;
    theme_id?: string;
    subtheme?: string;
    subtheme_id?: string;
    unit?: string;
    cms_unit_id?: string;
    already_exists: boolean;
    can_be_created: boolean;
    order?: number;
    options?: string[];
    tags: string[];
    tagsIds?: string[];
    status?: string;
    document_acceptance?: DocumentAcceptance;
}

export const useColumns = (
    importType?: ImportType
): ColumnDef<IndicatorImport>[] =>
    useMemo<ColumnDef<IndicatorImport>[]>(
        () => [
            {
                id: "select",
                meta: {
                    headerClassName: "w-[34px]",
                },
                header: ({ table }) => {
                    return (
                        <div className="flex flex-row space-x-2">
                            <Checkbox
                                checked={table.getIsAllRowsSelected()}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    table.toggleAllRowsSelected(
                                        !table.getIsAllRowsSelected()
                                    );
                                }}
                                aria-label="Select all"
                            />
                        </div>
                    );
                },
                cell: ({ row }) => {
                    return (
                        <div className="flex flex-row space-x-2">
                            <Checkbox
                                checked={row.getIsSelected()}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    row.toggleSelected();
                                }}
                                aria-label="Select all"
                            />
                        </div>
                    );
                },
            },
            {
                id: "status",
                accessorKey: "status",
                header: ({ column }) => (
                    <Header title={"Status"} column={column} />
                ),
                cell: ({ row }) => {
                    return (
                        <div
                            className={cn(
                                "flex flex-row space-x-2",
                                row.original.already_exists &&
                                    "text-warning-500",
                                !row.original.can_be_created && "text-error-500"
                            )}
                        >
                            {row.original.already_exists
                                ? "already exists"
                                : row.original.can_be_created
                                ? "can be created"
                                : "Warning, indicator cannot be created"}
                        </div>
                    );
                },
            },
            {
                accessorKey: "order",
                header: ({}) => {
                    const { t } = useTranslation("LoadIndicatorTable");
                    const [map, setMap] = useAtom(columnMapAtom);
                    return (
                        <div className="py-1">
                            <TextInput
                                placeholder={t("order")}
                                value={map.get("order")}
                                setValue={(v) => setMap(["order", v || ""])}
                            />
                        </div>
                    );
                },
            },
            {
                accessorKey: "uname",
                header: ({}) => {
                    const { t } = useTranslation("LoadIndicatorTable");
                    const [map, setMap] = useAtom(columnMapAtom);
                    return (
                        <div className="py-1">
                            <TextInput
                                placeholder={t("uname")}
                                value={map.get("uname")}
                                setValue={(v) => setMap(["uname", v || ""])}
                            />
                        </div>
                    );
                },
            },
            {
                accessorKey: "name",
                header: ({}) => {
                    const { t } = useTranslation("LoadIndicatorTable");
                    const [map, setMap] = useAtom(columnMapAtom);
                    return (
                        <div className="py-1">
                            <TextInput
                                placeholder={t("name")}
                                value={map.get("name")}
                                setValue={(v) => setMap(["name", v || ""])}
                            />
                        </div>
                    );
                },
            },
            {
                accessorKey: "description",
                header: ({}) => {
                    const { t } = useTranslation("LoadIndicatorTable");
                    const [map, setMap] = useAtom(columnMapAtom);
                    return (
                        <div className="py-1">
                            <TextInput
                                placeholder={t("description")}
                                value={map.get("description")}
                                setValue={(v) =>
                                    setMap(["description", v || ""])
                                }
                            />
                        </div>
                    );
                },
            },
            {
                accessorKey: "theme",
                header: ({}) => {
                    const { t } = useTranslation("LoadIndicatorTable");
                    const [map, setMap] = useAtom(columnMapAtom);
                    return (
                        <div className="py-1">
                            <TextInput
                                placeholder={t("theme")}
                                value={map.get("theme")}
                                setValue={(v) => setMap(["theme", v || ""])}
                            />
                        </div>
                    );
                },
            },
            {
                accessorKey: "subtheme",
                header: ({}) => {
                    const { t } = useTranslation("LoadIndicatorTable");
                    const [map, setMap] = useAtom(columnMapAtom);

                    return (
                        <div className="py-1">
                            <TextInput
                                placeholder={t("subtheme")}
                                value={map.get("subtheme")}
                                setValue={(v) => setMap(["subtheme", v || ""])}
                            />
                        </div>
                    );
                },
            },
            {
                accessorKey: "unit",
                header: ({}) => {
                    const { t } = useTranslation("LoadIndicatorTable");
                    const [map, setMap] = useAtom(columnMapAtom);
                    return (
                        <div className="py-1">
                            <TextInput
                                placeholder={t("unit")}
                                value={map.get("unit")}
                                setValue={(v) => setMap(["unit", v || ""])}
                            />
                        </div>
                    );
                },
            },
            {
                accessorKey: "options",
                header: ({}) => {
                    const { t } = useTranslation("LoadIndicatorTable");
                    const [map, setMap] = useAtom(columnMapAtom);
                    return (
                        <div className="py-1">
                            <TextInput
                                placeholder={t("options")}
                                value={map.get("options")}
                                setValue={(v) => setMap(["options", v || ""])}
                            />
                        </div>
                    );
                },
            },
            {
                accessorKey: "document_acceptance",
                header: ({}) => {
                    const { t } = useTranslation("LoadIndicatorTable");
                    const [map, setMap] = useAtom(columnMapAtom);
                    return (
                        <div className="py-1">
                            <TextInput
                                placeholder={t("document")}
                                value={map.get("document")}
                                setValue={(v) =>
                                    setMap(["document_acceptance", v || ""])
                                }
                            />
                        </div>
                    );
                },
            },
            {
                accessorKey: "tags",
                accessorFn: (row) => row.tags?.join(", ") || "",
                header: ({}) => {
                    const { t } = useTranslation("LoadIndicatorTable");
                    const [map, setMap] = useAtom(columnMapAtom);
                    return (
                        <div className="py-1">
                            <TextInput
                                placeholder={t("tags")}
                                value={map.get("tags")}
                                setValue={(v) => setMap(["tags", v || ""])}
                            />
                        </div>
                    );
                },
            },
        ],
        [importType]
    );
