import i18next from "../../../../../../i18n";

i18next.addResourceBundle("en", "SustainabilityCenterEditFrameworksModal", {
    update_success: "Successfully updated sustainability center",
    update_error: "Error updating sustainability center",
    custom_framework: "Custom framework",
    modal_title: "Edit compliance frameworks",
    save: "Save",
    frameworks: "Frameworks",
    framework_name_input_placeholder: "Framework public name",
    compliance_badge: "Compliance badge",
});

i18next.addResourceBundle("fr", "SustainabilityCenterEditFrameworksModal", {
    update_success: "Centre de confirmité mis à jour avec succès",
    update_error: "Erreur lors de la mise à jour du centre de confirmité",
    custom_framework: "Référentiel personnalisé",
    modal_title: "Modifier les référentiels de conformité",
    save: "Enregistrer",
    frameworks: "Référentiels",
    framework_name_input_placeholder: "Nom public du référentiel",
    compliance_badge: "Badge de conformité",
});
