import i18next from "../../../../../i18n";

i18next.addResourceBundle("en", "NewSiteModal", {
    open_modal: "Create new entity",
    title: "Add new entity",
    subtitle: "New entity will be created",
});

i18next.addResourceBundle("fr", "NewSiteModal", {
    open_modal: "Créer une nouvelle entité",
    title: "Ajouter une nouvelle entité",
    subtitle: "une nouvelle entité sera créée",
});
