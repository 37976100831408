import i18next from "../../../../../../i18n";

i18next.addResourceBundle("en", "SustainabilityCenterEditOverviewModal", {
    update_success: "Successfully updated sustainability center",
    update_error: "Error updating sustainability center",
    save: "Save",
    company_name: "Company name",
    description: "Description",
    contact: "Contact email",
    branding: "Branding",
    color: "Color",
    edit_overview_title: "Edit overview",
});

i18next.addResourceBundle("fr", "SustainabilityCenterEditOverviewModal", {
    update_success: "Centre de conformité mis à jour avec succès",
    update_error: "Erreur lors de la mise à jour du centre de conformité",
    save: "Enregistrer",
    company_name: "Nom de l'entreprise",
    description: "Description",
    contact: "email de contact",
    branding: "Branding",
    color: "Color",
    edit_overview_title: "Modifier la vue d'ensemble",
});
