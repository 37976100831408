import { cn } from "@design-system/Utilities";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { headerBgSvgDataUrl } from "./utils";
import { type FrameworkItem } from "@app/screens/Frameworks/types";
import { releaseStatus } from "@app/screens/Frameworks/services";

export type FrameworkCardProps = {
    framework: FrameworkItem;
};

export function useFrameworkCard({ framework }: FrameworkCardProps) {
    const { t } = useTranslation("Frameworks");

    const betaOrReleased = [
        releaseStatus.released,
        releaseStatus.beta,
    ].includes(framework.releaseStatus);

    const progress = useMemo(() => {
        return framework.stats.reqTotal > 0
            ? (100 * framework.stats.reqOk) / framework.stats.reqTotal
            : 0;
    }, [framework.stats.reqOk, framework.stats.reqTotal]);

    const wrapperClass = cn(
        framework.releaseStatus === releaseStatus.released
            ? "no-underline cursor-pointer"
            : "cursor-not-allowed",
        framework.releaseStatus === releaseStatus.beta && "opacity-50",
        "border border-secondary",
        "overflow-hidden",
    );

    const headerBgClassColor = framework.color?.primary || "bg-accent-900";
    const headerClass = betaOrReleased ? headerBgClassColor : "bg-tertiary";
    const headerStyle =
        betaOrReleased && framework.color
            ? {
                  backgroundImage: `url(${headerBgSvgDataUrl}), linear-gradient(${framework.color.primary}, ${framework.color.primary})`,
                  backgroundSize: "auto",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "right",
              }
            : undefined;

    const titleClass = betaOrReleased ? "text-neutral-25" : "text-secondary";

    const subtitleClass = betaOrReleased
        ? "text-neutral-25 opacity-50"
        : "text-tertiary";

    return {
        betaOrReleased,
        headerClass,
        headerStyle,
        progress,
        subtitleClass,
        t,
        titleClass,
        wrapperClass,
    };
}
