import { useTranslation } from "react-i18next";
import { ReactComponent as BeavrLogo } from "../../../../assets/beavr-h10.svg";
import { ReactComponent as TopLogo } from "../../../../assets/top-logo.svg";

import { MailIcon } from "lucide-react";
import "../i18n";

export default function SustainabilityCenterFooter({
    locale,
}: {
    locale: string;
}) {
    const { t } = useTranslation("SustainabilityCenter", {
        lng: locale,
    });

    return (
        <div className="mt-5 flex justify-center items-center self-stretch pt-11 pb-30 bg-secondary border-t border-secondary">
            <div className="flex w-[1120px] items-start gap-8">
                <div className="flex w-[300px] flex-col items-start shrink-0">
                    <a
                        className="flex flex-col items-end cursor-pointer no-underline"
                        href="https://www.beavr.tech/"
                        target="_blank"
                    >
                        <div className="text-xs bg-clip-text text-brand font-bold">
                            {t("powered_by")}
                        </div>
                        <div className="flex items-center w-[150px] justify-end">
                            <TopLogo className="h-7 text-black mr-2" />
                            <BeavrLogo className="h-7 text-green" />
                        </div>
                    </a>
                </div>
                <div className="flex flex-col items-start gap-5">
                    <div className="text-sm"> {t("about")}</div>
                    <div className="flex font-bold text-green gap-2 items-center">
                        <MailIcon className="w-5" />
                        <a
                            className="text-green font-bold text-base"
                            href="https://www.beavr.tech/contact"
                            target="_blank"
                        >
                            {t("beavr_contact")}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
