import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { modalIsMountedAtom, themeIdAtom } from "../..";
import { SubTheme } from "../../../../../generated/client/graphql";
import { ButtonType } from "../../../../components/Button/ButtonShared";
import ButtonSmall from "../../../../components/Button/ButtonSmall";
import { SubthemeItem } from "../SubthemeItem";
import { AvailableSubtheme } from "../ThemesList";
import "./i18n";

interface SubthemesProps {
    subthemes: SubTheme[];
    themeId: string | undefined | null;
}

export default function Subthemes({ subthemes, themeId }: SubthemesProps) {
    const { t } = useTranslation("ThemeSharedSubthemes");
    const [showModal, setShowModal] = useAtom(modalIsMountedAtom);
    const [, setThemeId] = useAtom(themeIdAtom);

    return (
        <div className="flex flex-1 flex-col grow px-5 py-4">
            <div className="flex justify-between space-x-7">
                <div>
                    <div className="font-medium text-lg text-neutral-700">
                        {t("title")}
                    </div>
                    <div className="text-xs text-neutral-500">
                        {t("subtitle")}
                    </div>
                </div>
                <div>
                    <ButtonSmall
                        variant={ButtonType.TONAL}
                        text={t("add_subtheme")}
                        onClick={() => {
                            setShowModal(!showModal);
                            setThemeId(themeId || "");
                        }}
                    />
                </div>
            </div>

            <div className="mt-5 flex flex-col space-y-2">
                {subthemes &&
                    subthemes.map((item: AvailableSubtheme) => {
                        return <SubthemeItem item={item} />;
                    })}
            </div>
        </div>
    );
}
