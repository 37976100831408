import { ColumnDef } from "@tanstack/react-table";
import { Attachment, ChatLines, LongArrowRightDown } from "iconoir-react";
import { useTranslation } from "react-i18next";
import { Datapoint } from "../../../../../generated/client/graphql";
import { ReactComponent as Status } from "../../../../assets/Status.svg";
import SubthemeFilter from "../../../../components/Filters/MultiFilter/Subtheme";
import Header from "../../../../components/Table/Header";
import ColorTag from "../../../../components/Tags/ColorTag";
import TextWithTooltip from "../../../../components/TextWithTooltip";
import { Tooltip } from "../../../../components/Tooltip";
import "./i18n";

function getStatusIcon(status: string) {
    switch (status) {
        case "CREATED":
            return <LongArrowRightDown id="status" className="w-3 h-3" />;
        case "VALIDATED":
            return <Status id="status" className="w-3 h-3" />;
    }
}

export const columns: ColumnDef<Datapoint>[] = [
    {
        accessorKey: "indicator.order",
        meta: {
            colWidth: "5%",
        },
        header: ({ column }) => {
            const { t } = useTranslation("SupplierDatapointTable");
            return <Header title={t("order")} column={column} />;
        },
        cell: ({ row }) => {
            return (
                <div className="flex space-x-2 items-center text-sm">
                    {row.original.indicator?.order}
                </div>
            );
        },
    },
    {
        id: "subtheme",
        filterFn: "arrIncludesSome",
        accessorKey: "indicator.subtheme.id",
        header: () => <SubthemeFilter />,
        cell: ({ row }) => (
            <div className=" max-w-fit">
                <ColorTag
                    color={row.original.indicator?.theme?.color || "green"}
                    text={
                        row.original.indicator?.subtheme?.name ||
                        row.original.indicator?.theme?.name ||
                        ""
                    }
                />
            </div>
        ),
    },
    {
        id: "indicator",
        filterFn: "arrIncludesSome",
        accessorKey: "indicator.name",
        meta: {
            colWidth: "30%",
        },
        header: () => {
            const { t } = useTranslation("SupplierDatapointTable");

            return t("indicator");
        },
        cell: ({ row }) => {
            const { t } = useTranslation("SupplierDatapointTable");
            return (
                <div className="flex space-x-2 items-center">
                    <div>
                        <Tooltip tooltipContent={`${t(row.original.status)}`}>
                            <button>
                                {getStatusIcon(row.original.status)}
                            </button>
                        </Tooltip>
                    </div>
                    <div className="text-sm font-bold">
                        {row.original.indicator?.name}
                    </div>
                </div>
            );
        },
    },
    {
        id: "supplier",
        filterFn: "arrIncludesSome",
        accessorKey: "supplier.id",
        header: () => {
            const { t } = useTranslation("SupplierDatapointTable");

            return t("supplier");
        },
        cell: ({ row }) => {
            return (
                <div className="flex items-center">
                    <div className="text-sm">{row.original.supplier?.name}</div>
                </div>
            );
        },
    },
    {
        id: "value",
        accessorKey: "stringifyValue",
        header: ({ column }) => {
            const { t } = useTranslation("SupplierDatapointTable");
            return <Header title={t("value")} column={column} />;
        },
        cell: ({ row }) => {
            return (
                <TextWithTooltip id={`value-${row.id}`} className="text-sm">
                    {row.original?.stringifyValue as string}
                </TextWithTooltip>
            );
        },
    },
    {
        id: "year",
        accessorKey: "year",
        header: ({ column }) => {
            const { t } = useTranslation("SupplierDatapointTable");
            return <Header title={t("year")} column={column} />;
        },
        cell: ({ row }) => {
            return (
                <div className="flex items-center text-sm">
                    {row.original.year}
                </div>
            );
        },
    },
    {
        id: "actions",
        cell: ({ row }) => {
            return (
                <div className="flex space-x-2 items-center justify-end pr-4">
                    {row.original.comment && (
                        <Tooltip
                            tooltipContent={`${row.original.comment}`}
                            className="max-w-sm"
                        >
                            <button>
                                <ChatLines className="w-[14px]" />
                            </button>
                        </Tooltip>
                    )}
                    {row.original.proofs && (
                        <div className="flex space-x-2">
                            {row.original.proofs.map((proof) => (
                                <Attachment
                                    className="w-[14px] cursor-pointer"
                                    onClick={() =>
                                        proof.signedUrl &&
                                        window.open(proof.signedUrl, "_blank")
                                    }
                                />
                            ))}
                        </div>
                    )}
                </div>
            );
        },
    },
];
