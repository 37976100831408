import i18next from "../../../../../../../i18n";

i18next.addResourceBundle("en", "SupplierTableSavebar", {
    more: "More",
    apply: "Apply",

    set_tags_button: "Set tags",
    set_tags_text: "Set tags to {{count}} suppliers",

    start_questionnaire_button: "Start questionnaire",
    start_questionnaire_text: "Start questionnaires for {{count}} suppliers",

    close_questionnaire_button: "Close questionnaire",
    close_questionnaire_text: "Close ongoing questionnaires",

    send_email: "Send form by email",
});

i18next.addResourceBundle("fr", "SupplierTableSavebar", {
    more: "Plus",
    apply: "Enregistrer",

    set_tags_button: "Choisir les tags",
    set_tags_text: "Définir des tags pour {{count}} fournisseurs",

    start_questionnaire_button: "Lancer des questionnaires",
    start_questionnaire_text:
        "Lancer des questionnaires pour {{count}} fournisseurs",

    close_questionnaire_button: "Fermer les questionnaires",
    close_questionnaire_text: "Cloturer les questionnaires en cours",

    send_email: "Envoyer par email",
});
