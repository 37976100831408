import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    GetThemesOnlyDocument,
    Theme,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import FilterTrigger from "../../components/triggers/Filter";
import ThemeDropdown from "./dropdown";
import "./i18n";

export const filterThemeIdsAtom = atom<string[] | undefined>(undefined);
const ThemesFilter = () => {
    const [themeIds, setThemeIds] = useAtom(filterThemeIdsAtom);
    const { t } = useTranslation("ThemeDropdown");

    const getThemes = useGraphQL(GetThemesOnlyDocument, undefined, {}, true);
    useEffect(() => {
        setThemeIds(undefined);
    }, []);
    return (
        <DropdownMenu>
            <FilterTrigger
                unsetCallback={
                    themeIds ? () => setThemeIds(undefined) : undefined
                }
                active={themeIds !== undefined}
            >
                {t("placeholder")}
            </FilterTrigger>
            <ThemeDropdown
                themeIds={themeIds}
                onThemeIdsChange={(ids: string[]) =>
                    setThemeIds(ids.length > 0 ? ids : undefined)
                }
                themes={getThemes.data?.themes as Theme[] | undefined}
                hidePlus
            />
        </DropdownMenu>
    );
};

export default ThemesFilter;
