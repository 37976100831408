import React, { ReactNode } from "react";

const RequirementLayout = ({ children }: { children: ReactNode }) => {
    return (
        <div className="border rounded-lg border-tertiary bg-tertiary truncate ">
            {React.Children.toArray(children).map((child, index) => (
                <div
                    className="w-full p-4 border-b border-tertiary last:border-none"
                    key={index}
                >
                    {child}
                </div>
            ))}
        </div>
    );
};

export default RequirementLayout;
