import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpRightSquare, Check } from "lucide-react";
import { Document, Requirement } from "../../../../../generated/client/graphql";
import { ButtonType } from "../../../../components/Button/ButtonShared";
import ButtonSmall from "../../../../components/Button/ButtonSmall";
import Checkbox from "../../../../components/Checkbox";
import Header from "../../../../components/Table/Header";
import "./i18n";

const selectCol: ColumnDef<Document> = {
    id: "select",
    meta: {
        headerClassName: "w-[34px]",
        colWidth: "10%",
    },
    header: ({ table }) => {
        return (
            <div className="flex flex-row space-x-2">
                <Checkbox
                    checked={table.getIsAllRowsSelected()}
                    onClick={(e) => {
                        e.stopPropagation();
                        table.toggleAllRowsSelected(
                            !table.getIsAllRowsSelected()
                        );
                    }}
                    aria-label="Select all"
                />
            </div>
        );
    },
    cell: ({ row }) => {
        return (
            <div className="flex flex-row space-x-2">
                <Checkbox
                    checked={row.getIsSelected()}
                    onClick={(e) => {
                        e.stopPropagation();
                        row.toggleSelected();
                    }}
                    aria-label="Select"
                />
            </div>
        );
    },
};

const nameCol: ColumnDef<Document> = {
    id: "name",
    accessorKey: "name",
    meta: {
        colWidth: "80%",
    },
    header: ({ column }) => {
        return <Header title="Document" column={column} />;
    },
    cell: ({ row }) => {
        return (
            <div
                className="flex space-x-2 items-center"
                onClick={(e) => {
                    e.stopPropagation();
                    row.toggleSelected();
                }}
            >
                <div className="text-sm font-bold ">{row.original.name}</div>
            </div>
        );
    },
};

const actionCol: ColumnDef<Document> = {
    id: "action",
    accessorKey: "action",
    meta: {
        colWidth: "10%",
    },
    header: ({}) => {
        return <div />;
    },
    cell: ({ row }) => {
        return (
            <div className="flex space-x-2 items-center">
                <ButtonSmall
                    variant={ButtonType.DEFAULT}
                    IconLeft={ArrowUpRightSquare}
                    onClick={(e) => {
                        const signedUrl =
                            row.original.lastVersion?.finalFile?.signedUrl;
                        signedUrl && window.open(signedUrl, "_blank")?.focus();

                        e.stopPropagation();
                    }}
                />
            </div>
        );
    },
};

export const DocumentColumns = [
    selectCol,
    nameCol,
    actionCol,
] as ColumnDef<Document>[];

export const useSubthemeRequirementsColumns = (
    subthemeName: string
): ColumnDef<Requirement>[] => {
    const requirementNameCol: ColumnDef<Document> = {
        id: "name",
        accessorKey: "name",
        meta: {
            colWidth: "90%",
        },
        header: ({ column }) => {
            column.getCanSort = () => {
                return false;
            };
            return <Header title={subthemeName} column={column} />;
        },
        cell: ({ row }) => {
            return (
                <div className="flex flex-col items-start">
                    <div className="text-base font-bold ">
                        {row.original.name}
                    </div>
                    <div className="text-sm text-primary">
                        {row.original.description}
                    </div>
                </div>
            );
        },
    };

    const iconCol: ColumnDef<Document> = {
        id: "icon",
        accessorKey: "icon",
        meta: {
            colWidth: "10%",
        },
        header: ({}) => {
            return <div />;
        },
        cell: ({}) => {
            return (
                <div className=" flex justify-center items-center h-5 w-5 text-white bg-beavrGreen rounded-[20px]">
                    <Check />
                </div>
            );
        },
    };

    return [requirementNameCol, iconCol] as ColumnDef<Requirement>[];
};
