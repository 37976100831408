import { ReactComponent as LoaderBars } from "../../assets/loader-bars.svg";
export default function Loading({
    size,
}: {
    size: "small" | "medium" | "large";
}) {
    return (
        <div className="w-full h-full flex justify-center items-center p-8">
            <LoaderBars
                className={`text-green ${
                    size === "small"
                        ? "w-4 h-4"
                        : size === "medium"
                        ? "w-8 h-8"
                        : "w-20 h-20"
                }`}
            />
        </div>
    );
}
