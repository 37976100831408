import i18next from "../../../i18n";

i18next.addResourceBundle("en", "Framework", {
    owner: "Owner",
    export_requirements: "Export Requirements",
    export_proofs: "Export Proofs",
    table_header_name: "Name",
    open_in_new_tab: "Open in new tab",
    download: "Download",
    export_documents_zero: "Export",
    export_documents_one: "Export 1 document",
    export_documents_other: "Export {{count}} documents",
});

i18next.addResourceBundle("fr", "Framework", {
    owner: "Assigné à",
    export_requirements: "Exporter les contrôles",
    export_proofs: "Exporter les preuves",
    table_header_name: "Nom",
    open_in_new_tab: "Ouvrir dans un nouvel onglet",
    download: "Télécharger",
    export_documents_zero: "Exporter",
    export_documents_one: "Exporter 1 document",
    export_documents_other: "Exporter {{count}} documents",
});
