import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "UpdateDocumentModal", {
    title: "Update document",
    subtitle: "Change name and description.",
    update: "Update document",
    document_name: "Document name",
    document_description: "Document description",
    owner: "Owner",
    document_update_success: "Document updated successfully",
    document_update_error: "Document update error",
});

i18next.addResourceBundle("fr", "UpdateDocumentModal", {
    title: "Modifier le document",
    subtitle: "Changer le nom et la description.",
    update: "Modifier le document",
    document_name: "Nom du document",
    document_description: "Description du document",
    owner: "Propriétaire",
    document_update_success: "Document mis à jour avec succès",
    document_update_error: "Erreur de mise à jour du document",
});
