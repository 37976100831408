import { Tooltip } from "@app/components/Tooltip";
import { Info } from "lucide-react";
import { type FC } from "react";
import { Ellipsis } from "@design-system/Typography/Ellipsis";

export const VersionInfoTooltip: FC<{ tooltip: string }> = ({ tooltip }) => {
    return (
        <Tooltip
            arrow
            className="py-1 max-w-[250px] text-sm"
            side="top"
            sideOffset={3}
            tooltipContent={
                <Ellipsis className="text-white">
                    {tooltip}
                </Ellipsis>
            }
            triggerAsChild={true}
        >
            <Info className="text-secondary w-3 h-3" />
        </Tooltip>
    );
};
