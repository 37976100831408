import { Color } from "chroma-js";
import { FC } from "react";
import { AlertProps } from ".";
import { cn } from "../../lib/utils";

interface BaseProps {
    mainColor: Color;
    bgColor: Color;
    Icon: FC<any>;
}
export default function Base({
    mainColor,
    bgColor,
    Icon,
    ...props
}: BaseProps & AlertProps) {
    return (
        <div
            className={cn("rounded-lg border p-3", "flex space-x-3")}
            style={{
                borderColor: mainColor.hex(),
                backgroundColor: bgColor.hex(),
            }}
        >
            <div className="p-1">
                <Icon
                    className={cn("w-4 h-4")}
                    style={{ color: mainColor.hex() }}
                />
            </div>
            <div className="space-y-0.5">
                <div className="text-sm font-bold">{props.title}</div>
                <div className="text-sm font-regular">{props.subtitle} </div>
            </div>
        </div>
    );
}
