import Checkbox from "@app/components/Checkbox";
import { MultiSelect } from "@design-system/Inputs/MultiSelect";
import { FlexRow, FlexCol } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { cn } from "@design-system/Utilities";
import { type FC } from "react";
import { useTranslation } from "react-i18next";

type ItemProps = {
    hasEntry?: boolean;
    id: string;
    name: string;
    theme?: {
        name: string;
        color: string;
    };
};

export const Item: FC<ItemProps> = ({ hasEntry, id, name, theme }) => {
    const { t } = useTranslation("DocumentsUploadModal");
    return (
        <MultiSelect.Item value={id}>
            {({ isSelected }) => (
                <FlexRow
                    alignSelf="stretch"
                    p="2"
                    gap="3"
                    className={cn(
                        "flex-1 hover:bg-secondary",
                        isSelected && "bg-secondary",
                    )}
                >
                    <Checkbox checked={isSelected} />
                    <FlexCol gap="1" w="full">
                        <Text as="p" variant="body">
                            {name}
                        </Text>
                        <FlexRow
                            alignItems="center"
                            justifyContent="between"
                            w="full"
                        >
                            <Text
                                as="p"
                                style={{
                                    color: theme?.color,
                                }}
                                variant="body-xs-bold"
                            >
                                {theme?.name ||
                                    t("multiselect_custom_document")}
                            </Text>
                            {hasEntry ? (
                                <Text
                                    as="p"
                                    className="self-end"
                                    color="danger"
                                    variant="body-xs"
                                >
                                    {t("multiselect_item_has_entry")}
                                </Text>
                            ) : null}
                        </FlexRow>
                    </FlexCol>
                </FlexRow>
            )}
        </MultiSelect.Item>
    );
};
