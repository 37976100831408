import i18next from "../../../i18n";

const enResources = {
    info_box:
        "Before validating your policy, make sure it contains all the expected elements and complies with the expectations of evaluators and certifiers",
    card_sustainability_issues_title: "Sustainability issues:",
    card_sustainability_issues_description:
        "Attached documentation clearly lays out principles and commitments on the topics addressed",
    card_scope_title: "Scope:",
    card_scope_description:
        "Scope of application, i.e. sites and activities of the organization covered by the policy, is clearly defined in the policy",
    card_objectives_title: "Objectives:",
    card_objectives_description:
        "Policy specifies quantitative objectives associated with dates for the designated topics",
    card_governance_title: "Governance:",
    card_governance_description:
        "Policy details the distribution of roles and responsibilities across the organization relative to the designated topic, as well as the policy implementation process",
    card_revision_title: "Revision:",
    card_revision_description:
        "Policy specifies the distribution and revision mechanisms, including the frequency of revision",
} as const;

type ResourcesKeys = keyof typeof enResources;

// hack to use have some sort of typesafty outside this file
// needs further digging into i18next typing
export const keys = Object.fromEntries(
    Object.keys(enResources).map((k) => [k, k]),
) as Record<ResourcesKeys, ResourcesKeys>;

i18next.addResources("en", "Checklist", enResources);
i18next.addResources("fr", "Checklist", {
    info_box:
        "Avant de valider votre politique, assurez-vous qu’elle contient tous les éléments attendus et est conforme aux attentes des évaluateurs et certificateurs",
    card_sustainability_issues_title: "Enjeux de durabilité:",
    card_sustainability_issues_description:
        "La politique couvre clairement les enjeux clés de durabilité identifiés pour l’entreprise",
    card_scope_title: "Périmètre:",
    card_scope_description:
        "Le périmètre, c'est-à-dire les sites et les activités de l'organisation couverts par la politique, est clairement défini dans la politique",
    card_objectives_title: "Objectifs:",
    card_objectives_description:
        "La politique spécifie des objectifs quantitatifs associés à des dates pour le sujet désigné",
    card_governance_title: "Gouvernance:",
    card_governance_description:
        "La politique précise la répartition des rôles et des responsabilités au sein de l'organisation par rapport au sujet désigné, ainsi que le processus de mise en œuvre de la politique",
    card_revision_title: "Révision:",
    card_revision_description:
        "La politique spécifie les mécanismes de distribution et de révision, y compris la fréquence de révision",
});
