import CardLayout from "@app/components/BodyAnalytics/CardLayout";
import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { Gauge } from "@design-system/DataDisplay/Gauge";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import chroma from "chroma-js";
import "./i18n";
import { keys } from "./i18n";

type Option = {
    value: number;
    color: string;
    label: string;
};

type DatapointsCompletionCardProps = {
    isLoading?: boolean;
    options: Option[];
    total?: number;
    completed?: number;
    label?: string;
};

export const DatapointsCompletionCard: FC<DatapointsCompletionCardProps> = ({
    isLoading,
    options,
    total,
    completed,
    label,
}) => {
    const { t } = useTranslation("DatapointsCompletionCard");

    return (
        <CardLayout
            className="gap-4 justify-normal h-full w-full"
            isLoading={isLoading}
            title={t(keys.card_datapoints_completion)}
        >
            <FlexCol gap="2" pt="3">
                {!!label && <Text variant="header1">{label}</Text>}
                <Gauge>
                    <Gauge.List>
                        {options.map(({ value, color, label }) => {
                            return (
                                <Gauge.Item
                                    value={value}
                                    color={chroma.hex(color)}
                                    label={label}
                                />
                            );
                        })}
                    </Gauge.List>
                </Gauge>
                <FlexRow justifyContent="between">
                    {typeof completed === "number" && (
                        <Text>{t(keys.complteted, { count: completed })}</Text>
                    )}
                    {typeof total === "number" && (
                        <Text>{t(keys.total, { count: total })}</Text>
                    )}
                </FlexRow>
            </FlexCol>
        </CardLayout>
    );
};
