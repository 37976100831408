import CheckboxItem from "@app/components/Dropdown/components/dropdowns/items/CheckboxItem";
import { updateTagAtom } from "@app/store/tagStore";
import { Icon } from "@design-system/Icons";
import { Button } from "@design-system/Inputs/Button";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useAtomValue } from "jotai";
import { FC, useEffect, useRef, useState } from "react";

const TagDropdownItem: FC<{
    tagId: string;
    tagName: string;
    checked: boolean | undefined;
    onToggle: (id: string) => void;
}> = ({ tagId, tagName, checked, onToggle }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newTagName, setNewTagName] = useState<string>(tagName);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const { mutate } = useAtomValue(updateTagAtom);
    const handleNewTagNameChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        setNewTagName(e.target.value?.trimStart());
        textareaRef.current?.focus();
    };

    const handleSaveNewTagName = () => {
        if (!newTagName || newTagName === tagName) return;
        mutate(
            { id: tagId, name: newTagName },
            { onSettled: () => setIsEditing(false) },
        );
    };

    const onEditClick = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsEditing(true);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter") {
            textareaRef.current?.blur();
            handleSaveNewTagName();
        }
        if (e.key === "Escape") {
            e.preventDefault();
            setIsEditing(false);
        }
    };

    useEffect(() => {
        if (isEditing) {
            textareaRef.current?.focus();
        }
    }, [isEditing]);

    return (
        <>
            {isEditing ? (
                <FlexRow
                    h="9"
                    px="0"
                    py="1.5"
                    alignItems="center"
                    justifyContent="center"
                    className="relative rounded-lg focus:outline-none"
                >
                    <textarea
                        value={newTagName}
                        onChange={handleNewTagNameChange}
                        onKeyDown={handleKeyDown}
                        ref={textareaRef}
                        className="h-full w-full resize-none rounded-lg border-secondary focus:border-secondary focus:ring-0"
                    ></textarea>
                    <Button
                        variant="flatPrimary"
                        size="xs"
                        className="absolute right-2"
                        disabled={newTagName === tagName}
                        onClick={handleSaveNewTagName}
                    >
                        <Icon name="arrowRight" size="xs" />
                    </Button>
                </FlexRow>
            ) : (
                <CheckboxItem
                    onSelect={(e) => {
                        e.preventDefault();
                    }}
                    checked={checked}
                    onCheckedChange={() => onToggle(tagId)}
                >
                    <FlexRow justifyContent="between">
                        <Text variant="body-sm">{tagName}</Text>
                        <Button variant="text" size="xs" onClick={onEditClick}>
                            <Icon name="edit" size="xs" />
                        </Button>
                    </FlexRow>
                </CheckboxItem>
            )}
        </>
    );
};

export default TagDropdownItem;
