import { useTranslation } from "react-i18next";
import { OrgType } from "../../../../../generated/client/graphql";
import { DropdownMenu } from "../../components";
import BaseTrigger from "../../components/triggers/Base";
import OrgTypeDropdown from "./dropdown";

const OrgTypeSelect = ({
    type,
    onTypeChange,
}: {
    type?: OrgType;
    onTypeChange: (type: OrgType | undefined) => void;
}) => {
    const { t } = useTranslation("OrgTypeDropdown");

    return (
        <DropdownMenu modal={false}>
            <BaseTrigger>{type ? t(type) : t("placeholder")}</BaseTrigger>
            <OrgTypeDropdown type={type} onTypeChange={onTypeChange} />
        </DropdownMenu>
    );
};

export default OrgTypeSelect;
