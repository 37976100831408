import {
    EditorMode,
    useEditorContext,
} from "../../../../screens/Document/Edit/hooks/useEditor";

import { Editor } from "@tiptap/react";
import { useState } from "react";
import { DocumentVersionCheckpoint } from "../../../../../generated/client/graphql";
import VersionCheckpoint from "./VersionCheckpoint";
import "./i18n";

const VersionHistoryTab = ({}: {}) => {
    const { editor, version, setEditorMode } = useEditorContext();
    const [activeDocIdx, setActiveDocIdx] = useState<number>(0);
    const toggleActive = (idx: number) => {
        setActiveDocIdx(idx);
    };

    const onRestore = (checkpoint: DocumentVersionCheckpoint) => {
        setEditorMode(EditorMode.REGULAR);
        if (!editor) return;
        editor.commands.setContent(checkpoint?.data || "{}");
        editor.setOptions({ editable: true });
    };

    const onCheckpointClick = (
        idx: number,
        checkpoint: DocumentVersionCheckpoint,
        editor: Editor | null
    ) => {
        toggleActive(idx);
        if (!editor) return;
        editor.commands.setContent(checkpoint?.data || "{}");
    };
    return (
        <>
            {version?.checkpoints &&
                version?.checkpoints?.length &&
                version?.checkpoints?.map((checkpoint, idx) => (
                    <VersionCheckpoint
                        checkpoint={checkpoint}
                        isActive={activeDocIdx === idx}
                        isFirst={idx === 0}
                        isLast={idx === (version?.checkpoints?.length || 0) - 1}
                        onRestore={onRestore}
                        onClick={() =>
                            onCheckpointClick(idx, checkpoint, editor)
                        }
                    />
                ))}
        </>
    );
};

export default VersionHistoryTab;
