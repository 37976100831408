import { forwardRef, type PropsWithChildren } from "react";
import { Box, type BoxProps } from "../Box";

export type FlexProps = Omit<BoxProps, "display">;
export type OrientedFlexProps = Omit<FlexProps, "direction">;

/**Component for creating flex layouts. */
export const Flex = forwardRef<HTMLDivElement, PropsWithChildren<FlexProps>>(
    (props, ref) => {
        return <Box display="flex" {...props} ref={ref} />;
    },
);

/**Flex container with horizontal layout.
 */
export const FlexRow = forwardRef<
    HTMLDivElement,
    PropsWithChildren<OrientedFlexProps>
>((props, ref) => {
    return <Flex direction="row" {...props} ref={ref} />;
});

/**Flex container with vertical layout.
 */
export const FlexCol = forwardRef<
    HTMLDivElement,
    PropsWithChildren<OrientedFlexProps>
>((props, ref) => {
    return <Flex direction="col" {...props} ref={ref} />;
});
