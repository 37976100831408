import i18next from "../../../../../../i18n";

i18next.addResourceBundle("en", "SustainabilityCenterEditFaqsModal", {
    update_success: "Successfully updated sustainability center",
    update_error: "Error updating sustainability center",
    title: "Edit FAQ",
    save: "Save",
    faq: "Questions",
    faq_question_input_placeholder: "Question",
    faq_answer_input_placeholder: "Answer",
});

i18next.addResourceBundle("fr", "SustainabilityCenterEditFaqsModal", {
    update_success: "Centre de confirmité mis à jour avec succès",
    update_error: "Erreur lors de la mise à jour du centre de confirmité",
    title: "Editer les FAQs",
    save: "Enregistrer",
    faq: "Questions",
    faq_question_input_placeholder: "Question",
    faq_answer_input_placeholder: "Réponse",
});
