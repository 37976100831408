import {
    FrameworkGroup,
    FrameworkSection,
} from "@generated/client/graphql";

import SectionBlock from "./SectionBlock";

const GroupBlocks = ({ group }: { group: FrameworkGroup }) => {
    return group?.subgroups?.flatMap((subgroup) => [
        subgroup?.sections
            .filter((s) => !!s.requirements.length)
            .map((section) => (
                <SectionBlock
                    key={section.id}
                    anchor={subgroup.name}
                    groupName={group.name}
                    subgroupName={subgroup.name}
                    section={section as FrameworkSection}
                />
            )),
    ]);
};

export default GroupBlocks;
