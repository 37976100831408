import { Icon } from "@design-system/Icons";
import { Box } from "@design-system/Layout/Box";
import { cn } from "@design-system/Utilities";
import { ClassValue } from "clsx";
import { type FC } from "react";

export const tagStatus = {
    todo: "todo",
    inProgress: "inProgress",
    validated: "validated",
    notMaterial: "notMaterial",
    checked: "checked",
    loading: "loading",
} as const;

type TagStatus = keyof typeof tagStatus;

export const StatusTagCircular: FC<{
    status: TagStatus;
    className?: ClassValue;
    size?: "xs" | "sm" | "md" | "lg";
}> = ({ status, className, size }) => {
    const iconName = (function () {
        switch (status) {
            case tagStatus.todo:
                return "circleTodo";
            case tagStatus.inProgress:
                return "circleInProgress";
            case tagStatus.validated:
                return "circleValidated";
            case tagStatus.notMaterial:
                return "eyeOff";
            case tagStatus.checked:
                return "check";
            case tagStatus.loading:
                return "circleLoading";
        }
    })();

    return (
        <Box
            alignItems="center"
            className={cn(
                className,

                status === tagStatus.notMaterial &&
                    "rounded-[720px] bg-neutral-150",
            )}
            justifyContent="center"
        >
            <Icon name={iconName} size={size || "sm"} />
        </Box>
    );
};
