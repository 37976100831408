import chroma from "chroma-js";
import { CheckCircle } from "iconoir-react";
import { FC, ReactNode } from "react";
import Base from "./Base";

export enum AlertType {
    SUCCESS,
    WARNING,
    DANGER,
    INFO,
}

export interface AlertProps {
    variant?: AlertType;
    Icon?: FC<any>;
    title: string | ReactNode;
    subtitle: string;
}

export default function Alert({ variant, Icon, title, subtitle }: AlertProps) {
    switch (variant) {
        case AlertType.WARNING:
        case AlertType.DANGER:
        case AlertType.INFO:
            return <></>;
        default:
            const mainColor = chroma("#03A365");
            const bgColor = chroma("#EEFFF2");
            return (
                <Base
                    Icon={Icon || CheckCircle}
                    bgColor={bgColor}
                    mainColor={mainColor}
                    title={title}
                    subtitle={subtitle}
                />
            );
    }
}
