import React, { Children, HTMLProps, ReactNode, Ref } from "react";
import { cn } from "../../../../lib/utils";

const FullFrameBlock = React.forwardRef(
    (
        {
            children,
            className,
            ...props
        }: { children: ReactNode } & HTMLProps<HTMLDivElement>,
        ref: Ref<HTMLDivElement>
    ) => {
        return (
            <div
                ref={ref}
                className={cn(
                    "w-full rounded-xl border border-tertiary bg-white shadow-container",
                    className
                )}
                {...props}
            >
                {Children.toArray(children).map((child, idx) => (
                    <div
                        key={idx}
                        className="border-b border-teritary last:border-none"
                    >
                        {child}
                    </div>
                ))}
            </div>
        );
    }
);

export default FullFrameBlock;
