import i18next from "../../../../../i18n";

i18next.addResourceBundle("en", "UnitsTable", {
    unit: "Nom",
    shortName: "Nom court",
    type: "Type",
    edit_unit: "Modifier",
    delete_unit: "Supprimer",

    empty_table_small_text: "No units yet",
    empty_table_button_text: "Add a unit",
});

i18next.addResourceBundle("fr", "UnitsTable", {
    unit: "Nom",
    shortName: "Nom court",
    type: "Type",
    edit_unit: "Modifier",
    delete_unit: "Supprimer",

    empty_table_small_text: "Vous n'avez pas encore d'unité",
    empty_table_button_text: "Créer une unité",
});
