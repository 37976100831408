import i18next from "../../../i18n";

i18next.addResourceBundle("en", "SustainabilityCenter", {
    overview_tab_title: "Overview",
    controls_tab_title: "Controls",
    add_description: "add a description",
    edit_overview_title: "Edit overview",
    save: "save",
    impact_strategy: "Impact strategy",
    show_strategy: "Show strategy",
    add_strategy: "Add strategy",
    compliance: "Compliance",
    show_frameworks: "Show compliance frameworks",
    add_frameworks: "Add frameworks",
    documents: "Documents",
    show_documents: "Show documents",
    add_documents: "Add documents",
    powered_by: "POWERED BY",
    beavr_contact: "Contact us to request a page",
    about: `This CSR report is powered by Beavr. Beavr is the fast, trusted solution for managing CSR compliance. 
    Beavr helps you strengthen your CSR management system (policies, actions, reporting) and prepare for CSR labels and certifications so that you can win you stakeholders' trust. 
    Dozens of companies already use Beavr to build stakeholders' trust and boost their brand while cutting costs, time and efforts.`,
    search_controls: "Search controls",
});

i18next.addResourceBundle("fr", "SustainabilityCenter", {
    overview_tab_title: "Aperçu",
    controls_tab_title: "Contrôles",
    add_description: "ajoutez une description",
    edit_overview_title: "Modifier l'aperçu",
    save: "enregistrer",
    impact_strategy: "Stratégie d'impact",
    show_strategy: "Monter la stratégie",
    add_strategy: "Ajouter une stratégie",
    compliance: "Conformité",
    show_frameworks: "Montrer les référentiels de conformité",
    add_frameworks: "Ajouter des référentiels",
    documents: "Documents",
    show_documents: "Montrer les documents",
    add_documents: "Ajouter des documents",
    powered_by: "POWERED BY",
    beavr_contact: "Prenez contact avec nous pour avoir votre page",
    about: `Ce rapport RSE est généré par Beavr. Beavr est la solution rapide et fiable pour gérer la conformité RSE.
    Beavr vous aide à renforcer votre système de management RSE (politiques, actions, reporting) et à vous préparer aux labels et certifications RSE pour gagner la confiance de vos parties prenantes.
    Des dizaines d'entreprises utilisent déjà Beavr pour renforcer la confiance de leurs parties prenantes et booster leur marque tout en réduisant les coûts, le temps et les efforts.`,
    search_controls: "Rechercher des contrôles",
});
