export type OnboardingProgress = {
    discoverBeavr: boolean;
    addUsers: boolean;
    configureSustainabilityProfile: boolean;
    assignResponsibles: boolean;
    /**
     * This is temporary and will be removed once the feature
     * is available for all users. So far, only Beavr Admins
     * can upload documents in batches.
     */
    uploadDocuments: boolean | null;
};

export const defaultProgress: OnboardingProgress = {
    discoverBeavr: false,
    addUsers: false,
    configureSustainabilityProfile: false,
    assignResponsibles: false,
    uploadDocuments: null,
};

export type OnboardingProgressRepository = {
    getOnboardingProgress: () => OnboardingProgress;
    setOnboardingProgress: (progress?: OnboardingProgress) => void;
    getIsOnboardingSectionCollapsed: () => boolean;
    setIsOnboardingSectionCollapsed: (isCollapsed: boolean) => void;
};
