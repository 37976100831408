import i18next from "../../i18n";

i18next.addResourceBundle("en", "Documents", {
    title: "Documents",
    subtitle:
        "Upload, store and share all the documents associated with your CSR or impact strategy.",
    add_document: "Add document",
    add: "Add custom document",

    POLICY_title: "Policies",
    POLICY_subtitle:
        "Policies are documents that define the company's quantitative commitments and improvement objectives covering all material subjects per theme.",

    PROCEDURE_title: "Measures",
    PROCEDURE_subtitle:
        "Measures are documents that outline the procedures, projects or proofs in place to achieve your company's CSR objectives.",

    CUSTOM_DOCUMENT_title: "Custom documents",
    CUSTOM_DOCUMENT_subtitle:
        "Custom documents go beyond the policy and measure documents provided by Beavr.",
    see_not_relevant: "See {{count}} not relevant items",
});

i18next.addResourceBundle("fr", "Documents", {
    title: "Documents",
    subtitle:
        "Téléchargez, stockez et partagez tous les documents associés à votre stratégie RSE ou d'impact.",
    add_document: "Ajouter un document",
    add: "Ajouter un document personnalisé",

    POLICY_title: "Politiques",
    POLICY_subtitle:
        "Les politiques définissent les engagements et objectifs de l'entreprise couvrant tous les sujets matériels par thème.",
    PROCEDURE_title: "Mesures",
    PROCEDURE_subtitle:
        "Les mesures sont des procédures ou autres documents qui décrivent les actions concretes mises en place pour atteindre vos objectifs RSE.",
    CUSTOM_DOCUMENT_title: "Documents spécifiques",
    CUSTOM_DOCUMENT_subtitle:
        "Les documents spécifiques vont au-delà des documents de politique et de mesure fournis par Beavr.",
    see_not_relevant: "Voir {{count}} éléments non pertinent",
});
