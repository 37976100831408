import i18next from "@app/i18n";

i18next.addResourceBundle("en", "DocumentsUploadModal", {
    dropzone_button: "Browse",
    dropzone_title:
        "Drag & drop your documents. <br /> We acccept documents PDF, JPG, PNG under 30 MB.",

    title: "Import your documents",
    subtitle: "Upload your documents all at once.",
    cancel: "Cancel",
    confirm: "Add matched ({{count}})",

    multiselect_content_placeholder: "Search for matching correspondences",
    multiselect_search_placeholder: "Search by keyword",
    multiselect_create_custom: "Create custom document",
    multiselect_create_custom_input_placeholder: "Document name",
    multiselect_create_custom_CTA: "Create",
    multiselect_results_title: "Results",
    multiselect_close_custom: "Close",
    multiselect_matched_documents: "Matched documents",
    multiselect_search_for_more: "Search for more",
    multiselect_no_results_placeholder: "No results",
    multiselect_item_has_entry: "This document already has an entry",
    multiselect_custom_document: "Custom document",

    status_select_placeholder: "Select status",
    status_select_option_draft: "Draft",
    status_select_option_approved: "Approved",
    status_select_option_under_review: "Under review",

    match_documents_dropdown_trigger: "Matched document ({{count}})",

    error_already_an_entry:
        "Some documents already have an entry that will be overwritten.",
});

i18next.addResourceBundle("fr", "DocumentsUploadModal", {
    dropzone_button: "Parcourir",
    dropzone_title:
        "Glissez-déposez vos documents. <br /> Nous acceptons les documents PDF, JPG, PNG de moins de 30 Mo.",

    title: "Importer vos documents",
    subtitle: "Importer vos documents en une seule fois.",
    cancel: "Annuler",
    confirm: "Ajouter correspondances ({{count}})",

    multiselect_search_placeholder: "Rechercher par mots-clés",
    multiselect_content_placeholder: "Rechercher des correspondances",
    multiselect_create_custom: "Créer un document spécifique",
    multiselect_create_custom_input_placeholder: "Nom du document",
    multiselect_create_custom_CTA: "Créer document",
    multiselect_results_title: "Résultats",
    multiselect_close_custom: "Fermer",
    multiselect_matched_documents: "Documents correspondants",
    multiselect_search_for_more: "Rechercher plus",
    multiselect_no_results_placeholder: "Aucun résultat",
    multiselect_item_has_entry: "Ce document a deja un fichier attaché",
    multiselect_custom_document: "Document spécifique",

    status_select_placeholder: "Sélectionner un statut",
    status_select_option_draft: "Brouillon",
    status_select_option_approved: "Appouvé",
    status_select_option_under_review: "En cours de révision",




    match_documents_dropdown_trigger: "Documents correspondants ({{count}})",

    error_already_an_entry: "Certains documents ont déjà un fichier qui sera remplacé.",
});
