import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { useAtomValue } from "jotai";
import { Circle, History, MoreHorizontal } from "lucide-react";
import { useTranslation } from "react-i18next";
import { DocumentVersionCheckpoint } from "../../../../../generated/client/graphql";
import BaseItem from "../../../Dropdown/components/dropdowns/items/BaseItem";
import DropdownContentLayout from "../../../Dropdown/components/dropdowns/layout";
import { currentUserAtom } from "../../../TopBar/TopBarProfil";
import { cn } from "../../lib/utils";

import "./i18n";
const VersionCheckpoint = ({
    checkpoint,
    isActive,
    isFirst,
    isLast,
    onRestore,
    onClick,
}: {
    checkpoint: DocumentVersionCheckpoint;
    isActive: boolean;
    isFirst: boolean;
    isLast: boolean;
    onRestore: (checkpoint: DocumentVersionCheckpoint) => void;
    onClick: () => void;
}) => {
    const { t } = useTranslation("DocumentEditorSideBar");

    const currentUser = useAtomValue(currentUserAtom);

    const getDisplayTimestamp = (timestamp: string) => {
        const now = new Date();
        const docDate = new Date(timestamp);
        const dateTimeFormapOptions = new Intl.DateTimeFormat(
            currentUser?.locale === "en" ? "fr-FR" : "en-EN",
            now.getDay() === docDate.getDay()
                ? { hour: "numeric", minute: "numeric" }
                : {
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                  }
        );
        return dateTimeFormapOptions.format(docDate);
    };

    return (
        <div className="flex items-start self-stretch">
            <div
                className={cn(
                    "flex pl-1 pr-3 gap-2 flex-1 rounded-lg cursor-pointer",
                    isActive && "bg-tertiary"
                )}
            >
                <div
                    className="flex w-5 h-[44px] flex-col items-center"
                    onClick={onClick}
                >
                    <div
                        className={cn(
                            "w-[1px] flex-1 h-[18px]",
                            !isFirst && "border-r border-secondary"
                        )}
                    ></div>
                    <div className="w-2 h-2 flex-shrink-0">
                        <Circle
                            className="h-2 w-2"
                            color={isFirst ? "#03A365" : "#000000"}
                        />
                    </div>
                    <div
                        className={cn(
                            "w-[1px] flex-1",
                            !isLast && "border-r border-secondary"
                        )}
                    ></div>
                </div>
                <div
                    className="flex flex-col justify-center items-start flex-1 self-stretch"
                    onClick={onClick}
                >
                    <div
                        className={cn(
                            "font-bold text-sm",
                            isFirst && "text-beavrGreen"
                        )}
                    >
                        {isFirst
                            ? t("current_version")
                            : getDisplayTimestamp(checkpoint.timestamp)}
                    </div>
                </div>
                {isActive && !isFirst && (
                    <DropdownMenu modal={false}>
                        <DropdownPrimitive.Trigger>
                            <div className="flex py-3 items-start gap-2">
                                <MoreHorizontal className="w-4 h-4" />
                            </div>
                        </DropdownPrimitive.Trigger>
                        <DropdownContentLayout
                            align="end"
                            className="w-[240px]"
                        >
                            <BaseItem
                                IconLeft={History}
                                onClick={() => onRestore(checkpoint)}
                            >
                                {t("restore_version")}
                            </BaseItem>
                        </DropdownContentLayout>
                    </DropdownMenu>
                )}
            </div>
        </div>
    );
};

export default VersionCheckpoint;
