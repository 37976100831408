import {
    height,
    mapPropsToClasses,
    margin,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    padding,
    textColor,
    textLayout,
    textVariant,
    width,
} from "@design-system/Theme";
import { cn } from "@design-system/Utilities";
import { forwardRef, useMemo, type PropsWithChildren } from "react";

export type TextProps = React.HTMLAttributes<HTMLParagraphElement> &
    PropsWithChildren<{
        as?: "span" | "p" | "div" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
        align?: keyof (typeof textLayout)["align"];
        verticalAlign?: keyof (typeof textLayout)["verticalAlign"];
        whitespace?: keyof (typeof textLayout)["whitespace"];
        variant?: keyof typeof textVariant;
        color?: keyof typeof textColor;
        className?: string;
        w?: keyof typeof width;
        maxW?: keyof typeof maxWidth;
        minW?: keyof typeof minWidth;
        h?: keyof typeof height;
        maxH?: keyof typeof maxHeight;
        minH?: keyof typeof minHeight;
        p?: keyof (typeof padding)["p"];
        px?: keyof (typeof padding)["px"];
        py?: keyof (typeof padding)["py"];
        pt?: keyof (typeof padding)["pt"];
        pr?: keyof (typeof padding)["pr"];
        pb?: keyof (typeof padding)["pb"];
        pl?: keyof (typeof padding)["pl"];
        m?: keyof (typeof margin)["m"];
        mx?: keyof (typeof margin)["mx"];
        my?: keyof (typeof margin)["my"];
        mt?: keyof (typeof margin)["mt"];
        ml?: keyof (typeof margin)["ml"];
        mr?: keyof (typeof margin)["mr"];
        mb?: keyof (typeof margin)["mb"];
        truncate?: boolean;
    }>;

export const Text = forwardRef<HTMLParagraphElement, TextProps>(
    (
        {
            as: Comp = "p",
            variant: _variant,
            truncate,
            children,
            className,
            color: textColor,
            ...props
        },
        ref,
    ) => {
        const classes = useMemo(
            () => mapPropsToClasses({ ...props, textColor }),
            [props, textColor],
        );
        const variant = Comp !== "span" ? _variant ?? "body" : _variant;
        return (
            <Comp
                {...props}
                className={cn(
                    variant && textVariant[variant],
                    !!truncate && "truncate max-w-full",
                    ...classes,
                    className,
                )}
                ref={ref}
            >
                {children}
            </Comp>
        );
    },
);
