import { atom, useSetAtom } from "jotai";
import { useNavigation } from "../../../../lib/navigation";

export const modalRequirementIdAtom = atom<string | undefined>(undefined);

export const useNavigateToOpenRequirement = () => {
    const setRequirementId = useSetAtom(modalRequirementIdAtom);

    const { navigateFromHome } = useNavigation();
    return (requirementId: string) => {
        navigateFromHome(`requirements`);
        setRequirementId(requirementId);
    };
};
