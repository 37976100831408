import { atom } from "jotai";

export const Tab = {
    overview: "overview",
    datapoints: "datapoints",
} as const;

export type TabType = keyof typeof Tab;

export const activeTabAtom = atom<TabType>(Tab.overview);

export const datapointSearchStrAtom = atom<string | undefined>(undefined);
