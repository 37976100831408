import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "UnrelevantDocumentsModal", {
    title: "Documents marked as not relevant",
    set_relevant: "Mark {{count}} as relevant",
    success_message: "Documents updated successfully",
});

i18next.addResourceBundle("fr", "UnrelevantDocumentsModal", {
    title: "Documents définis comme non pertinents",
    set_relevant: "Définir {{count}} comme pertinent",
    success_message: "Documents mis à jour avec succès",
});
