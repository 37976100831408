import Checkbox from "@app/components/Checkbox";
import { Box } from "@design-system/Layout/Box";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { ArrowUp } from "iconoir-react";
import { useAtomValue } from "jotai";
import { XCircle } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UserRole } from "../../../../../generated/client/graphql";
import { useThreads } from "../../../../screens/Document/Edit/hooks/useComments";
import {
    CommentsTabs,
    useEditorContext,
} from "../../../../screens/Document/Edit/hooks/useEditor";
import ButtonSmall from "../../../Button/ButtonSmall";
import { useNotifications } from "../../../Notifications/useNotifications";

import { currentUserAtom, currentUserIsBeavrAtom } from "@app/store/userStore";
import TextAreaInput from "../../../inputs_v2/TextArea";
import CommentBox from "./CommentBox";
import "./i18n";

const CommentsTab = ({}: {}) => {
    const { t } = useTranslation("DocumentEditorSideBar");
    const { createThread, activeThreads, resolvedThreads } = useThreads();
    const currentUser = useAtomValue(currentUserAtom);
    const { isBeavrUser } = useAtomValue(currentUserIsBeavrAtom);
    const { commentModeActiveTab, version, setCommentModeActiveTab } =
        useEditorContext();
    const [displayAddCommentInput, setDisplayAddCommentInput] =
        useState<boolean>(false);
    const [commentText, setCommentText] = useState<string | undefined>(
        undefined,
    );

    const displayedThreads =
        commentModeActiveTab === CommentsTabs.OPEN
            ? activeThreads
            : resolvedThreads;

    const { notifyNewCommentOnDoc } = useNotifications();

    const postThread = () => {
        createThread(commentText);
        if (version?.document?.id) {
            notifyNewCommentOnDoc({
                documentId: version?.document?.id,
            });
        }
        setCommentText(undefined);
        setDisplayAddCommentInput(false);
    };

    const closeCommentInput = () => setDisplayAddCommentInput(false);
    const openCommentInput = () => setDisplayAddCommentInput(true);
    const toggleResolved = () =>
        setCommentModeActiveTab(
            commentModeActiveTab === CommentsTabs.OPEN
                ? CommentsTabs.RESOLVED
                : CommentsTabs.OPEN,
        );

    return (
        <>
            <FlexRow gap="2" px="4" pt="1" pb="3">
                <Checkbox
                    checked={commentModeActiveTab === CommentsTabs.RESOLVED}
                    onCheckedChange={toggleResolved}
                />
                {t("show_resolved", { count: resolvedThreads.length })}
            </FlexRow>

            <div className="flex flex-col px-4 gap-3 items-start self-stretch overflow-scroll scrollbar-hide">
                {displayedThreads.length === 0 && (
                    <Box
                        br="lg"
                        p="2.5"
                        className="bg-accent-100 border border-accent-700"
                        w="full"
                    >
                        <Text variant="body-sm-bold">
                            {t(
                                commentModeActiveTab === CommentsTabs.OPEN
                                    ? "no_active_comments"
                                    : "no_resolved_comments",
                            )}
                        </Text>
                    </Box>
                )}
                {displayedThreads.map((thread) => (
                    <div className="w-full" key={thread.id}>
                        {!!thread.comments.length && (
                            <CommentBox thread={thread} />
                        )}
                    </div>
                ))}
                {commentModeActiveTab === CommentsTabs.OPEN &&
                    displayAddCommentInput && (
                        <div className="relative">
                            <TextAreaInput
                                placeholder={t("add_comment")}
                                text={commentText}
                                setText={setCommentText}
                                containerClassName="w-[288px] bg-secondary focus-within:border-tertiary shadow-sm"
                                className="min-h-[50px] mb-[20px]"
                            />
                            <XCircle
                                className="absolute right-[8px] top-[8px] cursor-pointer w-4 h-4 text-secondary hover:text-primary"
                                onClick={closeCommentInput}
                            />
                            <ButtonSmall
                                className="flex flex-col items-start absolute right-[10px] bottom-[12px]"
                                IconLeft={ArrowUp}
                                onClick={postThread}
                            />
                        </div>
                    )}
                {commentModeActiveTab === CommentsTabs.OPEN &&
                    !displayAddCommentInput &&
                    (isBeavrUser ||
                        currentUser?.data?.currentUser?.permission?.role ===
                            UserRole.BeavrAdmin) && (
                        <ButtonSmall
                            text={t("add_comment")}
                            onClick={openCommentInput}
                        />
                    )}
            </div>
        </>
    );
};

export default CommentsTab;
