import { type FC } from "react";
import { Skeleton } from "@design-system/DataDisplay/Skeleton";
import { FlexCol } from "@design-system/Layout/Flex";

export const FrameworkCardSkeleton: FC = () => (
    <FlexCol
        as="article"
        br="lg"
        elevation="b-200"
        h="full"
    >
        {/* Header */}
        <Skeleton
            as="header"
            h="full"
            br="lg"
            py="6"
            px="4"
            display="flex"
            direction="col"
            gap="2"
        >
            <Skeleton h="6" w="24" br="lg"/>
            <Skeleton h="4" w="40"  br="lg"/>
        </Skeleton>

        {/* Body */}
        <FlexCol p="4" gap="2">
            <Skeleton h="6" w="16" br="lg" />
            <Skeleton h="3" w="full" br="sm" />
            <Skeleton h="8" w="full" br="lg" />
        </FlexCol>
    </FlexCol>
);
