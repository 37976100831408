import {
    ColumnFiltersState,
    SortingState,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { Requirement, Theme } from "../../../../../generated/client/graphql";
import { orderSort } from "../../../../lib/utils";
import { scControlsTabSearchStringAtom } from "../components/SustainabilityCenterControlsTab";
import { useSubthemeRequirementsColumns } from "./columns";

export const useApprovedRequirementsTable = (themes: Theme[]) => {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnFilters, setColumnFilters] =
        React.useState<ColumnFiltersState>([]);
    const [rowSelection, setRowSelection] = React.useState({});
    const [searchFilterString, setSearchFilterString] = useAtom(
        scControlsTabSearchStringAtom,
    );

    const tables = orderSort(themes).map((theme) => {
        const subthemes = theme.subthemes
            .filter((elt) => !!elt?.approvedRequirements?.length && elt.active)
            .map((elt) => ({
                ...elt,
                table: useReactTable<Requirement>({
                    data: elt.approvedRequirements as Requirement[],
                    columns: useSubthemeRequirementsColumns(elt.name),
                    initialState: {
                        pagination: {
                            pageSize: 100,
                        },
                    },
                    getCoreRowModel: getCoreRowModel(),
                    onSortingChange: setSorting,
                    onColumnFiltersChange: setColumnFilters,
                    getPaginationRowModel: getPaginationRowModel(),
                    getSortedRowModel: getSortedRowModel(),
                    getFilteredRowModel: getFilteredRowModel(),
                    onGlobalFilterChange: setSearchFilterString,
                    onRowSelectionChange: setRowSelection,

                    state: {
                        sorting,
                        columnFilters,
                        rowSelection,
                        globalFilter: searchFilterString,
                    },
                }),
            }));

        return { name: theme.name, subthemes: subthemes };
    });
    return { tables };
};
