import { CheckList } from "@app/pages/Editor/CheckList";
import {
    EditorMode,
    RegularTabs,
    useEditorContext,
} from "../../../../screens/Document/Edit/hooks/useEditor";
import CommentsTab from "./CommentsTab";
import InfoTab from "./InfoTab";
import TableOfContentTab from "./TableOfContentTab";
import VersionHistoryTab from "./VersionHistoryTab";
import "./i18n";
const SideBarBody = ({}: {}) => {
    const {
        editorMode,
        version,
        regularModeActiveTab,
        showValidationChecklist,
    } = useEditorContext();

    return (
        <div className="h-full flex flex-col items-start self-stretch bg-primary overflow-y-scroll scrollbar-hide pb-[70px]">
            {editorMode === EditorMode.REGULAR ? (
                <div className="w-full">
                    {regularModeActiveTab === RegularTabs.CONTENT && (
                        <TableOfContentTab />
                    )}
                    {regularModeActiveTab === RegularTabs.INFO && (
                        <InfoTab documentType={version?.document?.type} />
                    )}
                    {regularModeActiveTab === RegularTabs.CHECKS &&
                        showValidationChecklist && <CheckList />}
                    {regularModeActiveTab === RegularTabs.COMMENTS && (
                        <CommentsTab />
                    )}
                </div>
            ) : (
                <VersionHistoryTab />
            )}
        </div>
    );
};

export default SideBarBody;
