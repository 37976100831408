import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "LocaleDropdown", {
    placeholder: "Select language",
    fr: "French",
    en: "English",
});

i18next.addResourceBundle("fr", "LocaleDropdown", {
    placeholder: "Sélectionner la langue",
    fr: "Français",
    en: "Anglais",
});
