import {
    GetThemesAndSubthemesDocument,
    GetThemesAndSubthemesQuery,
} from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { ThemeRepository } from "../ThemeRepository";
import { graphqlClientKoyeb } from "../clients";

export class GraphQLThemeRepository implements ThemeRepository {
    get = (): Promise<GetThemesAndSubthemesQuery> => {
        return graphqlClientKoyeb.request(
            GetThemesAndSubthemesDocument,
            undefined,
            AccessHeadersHolder.getHeaders()
        );
    };
}
