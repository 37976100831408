import { useSetToast } from "@app/components/Toast";
import { AddUser } from "iconoir-react";
import { atom, useAtom } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    CreateSupplierInput,
    CreateSuppliersDocument,
    ResponseStatus,
    UserRole,
} from "../../../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../../../QueryClientWithHeaders";
import ButtonLarge from "../../../../../components/Button/ButtonLarge";
import ButtonRegular from "../../../../../components/Button/ButtonRegular";
import { ButtonType } from "../../../../../components/Button/ButtonShared";
import {
    CenterModal,
    CenterModalContent,
    CenterModalTrigger,
} from "../../../../../components/Modal/CenterModal";
import ImportCSVButton from "./ImportCsvButton";
import NewSupplierRow from "./NewSupplierRow";
import "./i18n";

export const newSupplierModalOpenAtom = atom(false);
const NewSupplierModal = ({ refetch }: { refetch: () => void }) => {
    const { t } = useTranslation("NewSupplierModal");
    const [open, setOpen] = useAtom(newSupplierModalOpenAtom);
    const createSuppliers = useGraphQLMutation(
        CreateSuppliersDocument,
        {},
        true,
    );

    const { setToastSuccess, setToastError } = useSetToast();

    const [newSuppliers, setNewSuppliers] = useState<CreateSupplierInput[]>([
        {
            name: "",
            pointOfContact: {
                email: "",
                firstName: "",
                lastName: "",
                role: UserRole.Contributor,
            },
        },
    ]);

    const canSave =
        newSuppliers.length > 0 &&
        newSuppliers.every(
            (i) =>
                i.name.length > 0 &&
                i.pointOfContact.email.length > 0 &&
                i.pointOfContact.firstName.length > 0 &&
                i.pointOfContact.lastName.length > 0,
        );

    const onAdd = (additionalSuppliers: CreateSupplierInput[]) => {
        setNewSuppliers([
            ...newSuppliers.filter(
                (s) =>
                    s.name !== "" ||
                    s.pointOfContact.email !== "" ||
                    s.pointOfContact.firstName !== "" ||
                    s.pointOfContact.lastName !== "",
            ),
            ...additionalSuppliers,
        ]);
    };
    const onAddEmpty = () => {
        setNewSuppliers([
            ...newSuppliers,
            {
                name: "",
                pointOfContact: {
                    email: "",
                    firstName: "",
                    lastName: "",
                    role: UserRole.Supplier,
                },
            },
        ]);
    };
    const onDelete = (index: number) => {
        setNewSuppliers([...newSuppliers].filter((_, i) => i !== index));
    };
    const onInputChange = (
        index: number,
        input: Partial<CreateSupplierInput>,
    ) => {
        setNewSuppliers([
            ...newSuppliers.map((supplier, idx) =>
                idx === index ? { ...supplier, ...input } : supplier,
            ),
        ]);
    };

    const onSave = () => {
        createSuppliers.mutate(
            {
                inputs: newSuppliers,
            },
            {
                onSuccess: ({ createSuppliers }) => {
                    const { status } = createSuppliers;
                    if (status === ResponseStatus.Success) {
                        setToastSuccess(t("toast_success"));
                    } else {
                        setToastError(t("toast_error"));
                    }
                    refetch();
                    setNewSuppliers([]);
                    setOpen(false);
                },
            },
        );
    };

    return (
        <CenterModal open={open} onOpenChange={setOpen}>
            <CenterModalTrigger asChild>
                <ButtonRegular text={t("trigger_text")} />
            </CenterModalTrigger>
            <CenterModalContent
                title={t("title")}
                subtitle={t("subtitle")}
                actions={
                    <ButtonLarge
                        onClick={onSave}
                        disabled={!canSave || createSuppliers.isPending}
                        loading={createSuppliers.isPending}
                    >
                        {t("save_button")}
                    </ButtonLarge>
                }
            >
                {newSuppliers.map((input, i) => (
                    <NewSupplierRow
                        input={input}
                        onInputChange={(input) => onInputChange(i, input)}
                        onDelete={() => onDelete(i)}
                    />
                ))}
                <div className="flex space-x-3">
                    <ButtonLarge
                        variant={ButtonType.TONAL}
                        IconRight={AddUser}
                        onClick={onAddEmpty}
                    >
                        {t("add_one")}
                    </ButtonLarge>
                    <ImportCSVButton onAddSuppliers={onAdd} />
                </div>
            </CenterModalContent>
        </CenterModal>
    );
};

export default NewSupplierModal;
