import { allowNestedClickWithCallback } from "@app/shared/utils/allowNestedClickWithCallback";
import { Button } from "@design-system/Inputs/Button";
import { FileStack } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Document } from "@generated/client/graphql";
import ButtonRegular from "@app/components/Button/ButtonRegular";
import SearchFilter from "@app/components/Filters/Search";
import {
    CenterModal,
    CenterModalContent,
} from "@app/components/Modal/CenterModal";
import LoadingTable from "@app/components/Table/LoadingTable";
import { useFrameworkDocument } from "@app/screens/Frameworks/framework/hooks/framework";
import Table from "./Table";

const DocumentModal = ({ frameworkId }: { frameworkId: string }) => {
    const [open, setOpen] = useState<boolean>(false);
    const { t } = useTranslation("Framework");
    const [searchString, setSearchString] = useState<string | undefined>();
    const { documents, isLoading, exportDocuments, exportDocumentsLoading } =
        useFrameworkDocument(frameworkId);
    const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>(
        [],
    );

    const openModal = allowNestedClickWithCallback(() => setOpen(true));

    return (
        <>
            <Button onClick={openModal} variant="text">
                <FileStack className="text-beavrGreen w-4" />
                {t("export_proofs")}
            </Button>
            <CenterModal open={open} onOpenChange={setOpen}>
                <CenterModalContent
                    title={t("export_proofs")}
                    actions={
                        <ButtonRegular
                            disabled={!selectedDocumentIds.length}
                            loading={exportDocumentsLoading}
                            onClick={() => exportDocuments(selectedDocumentIds)}
                        >
                            {t("export_documents", {
                                count: selectedDocumentIds.length,
                            })}
                        </ButtonRegular>
                    }
                >
                    {isLoading && <LoadingTable />}
                    {!isLoading && !!documents?.length && (
                        <>
                            <SearchFilter
                                className=" w-auto"
                                searchString={searchString}
                                setSearchString={setSearchString}
                            />
                            <Table
                                searchString={searchString}
                                documents={documents as Document[]}
                                onSelectedDocumentIdsChange={
                                    setSelectedDocumentIds
                                }
                            />
                        </>
                    )}
                </CenterModalContent>
            </CenterModal>
        </>
    );
};

export default DocumentModal;
