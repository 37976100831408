import { useAuth0 } from '@auth0/auth0-react';

export const useAuth0Wrapper = () => {
    if (import.meta.env.VITE_REACT_APP_AUTH_MODE === "development") {
        return {
            isAuthenticated: true,
            loginWithRedirect: () => { },
            isLoading: false,
        }
    }
    return useAuth0();
};

export default useAuth0Wrapper;
