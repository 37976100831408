import i18next from "../../../i18n";

i18next.addResourceBundle("en", "Supplier", {
    subtitle: "Manage your supplier. Add and delete user for this supplier.",
    add_user: "Add user",
});

i18next.addResourceBundle("fr", "Supplier", {
    subtitle:
        "Gérez votre fournisseur. Ajoutez et supprimez des utilisateurs pour ce fournisseur.",
    add_user: "Ajouter un utilisateur",
});
