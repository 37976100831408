// your-select.jsx
import * as SelectPrimitive from "@radix-ui/react-select";
import { Check, NavArrowDown } from "iconoir-react";
import { cn } from "../../../lib/utils";

export interface InputSelectProps {
    value: string | undefined;
    onValueChange: (value: string | undefined) => void;
}
export interface InputSelectRootProps extends SelectPrimitive.SelectProps {
    placeholder?: string;
    values: { id: string; name?: string; node?: React.ReactNode }[] | undefined;
}

export const InputSelect = ({
    placeholder,
    values,
    ...props
}: InputSelectRootProps & SelectPrimitive.SelectProps) => {
    const selectedValue = values?.find((v) => v.id === props.value);
    return (
        <SelectPrimitive.Root {...props}>
            <SelectPrimitive.Trigger
                className={` w-full h-[52px] flex rounded border border-secondary  data-[state=open]:border-primary items-center bg-white focus:outline-none focus:border-primary`}
            >
                <div className="h-full flex-grow px-3 flex flex-col justify-center">
                    <div
                        className={cn(
                            "text-left font-regular text-secondary",
                            props.value ? " text-sm" : "text-base"
                        )}
                    >
                        {placeholder}
                    </div>
                    {selectedValue && (
                        <div className="font-regular text-left">
                            {selectedValue.name}
                        </div>
                    )}
                </div>
                <div className="px-3">
                    <NavArrowDown className="h-4 w-4" />
                </div>
            </SelectPrimitive.Trigger>
            <SelectPrimitive.Portal>
                <SelectPrimitive.Content
                    position="popper"
                    sideOffset={4}
                    alignOffset={-4}
                    className="border w-[220px] bg-white shadow-dropdown rounded-xl p-1 max-h-[300px] overflow-y-auto "
                >
                    <SelectPrimitive.Viewport>
                        {values?.map(
                            (value) =>
                                value.node || (
                                    <SelectPrimitive.Item
                                        value={value.id}
                                        className={cn(
                                            "group flex items-center px-2 py-2.5 space-x-2.5 rounded-lg cursor-pointer ",
                                            "focus:outline-none focus:bg-green-50"
                                        )}
                                    >
                                        <Check className="w-4 h-4 text-green group-data-[state=unchecked]:invisible" />
                                        <SelectPrimitive.ItemText className="text-primary text-base font-bold truncate group-hover:text-green">
                                            {value.name}
                                        </SelectPrimitive.ItemText>
                                    </SelectPrimitive.Item>
                                )
                        )}
                    </SelectPrimitive.Viewport>
                </SelectPrimitive.Content>
            </SelectPrimitive.Portal>
        </SelectPrimitive.Root>
    );
};
