import { useTranslation } from "react-i18next";
import { DocumentAcceptance } from "../../../../../generated/client/graphql";
import { DropdownMenu } from "../../components";
import BaseTrigger from "../../components/triggers/Base";
import DocumentModeDropdown from "./dropdown";

const DocumentModeSelect = ({
    mode,
    onModeChange,
}: {
    mode?: DocumentAcceptance;
    onModeChange: (mode: DocumentAcceptance | undefined) => void;
}) => {
    const { t } = useTranslation("DocumentModeDropdown");

    return (
        <DropdownMenu>
            <BaseTrigger>{mode ? t(mode) : t("placeholder")}</BaseTrigger>
            <DocumentModeDropdown mode={mode} onModeChange={onModeChange} />
        </DropdownMenu>
    );
};

export default DocumentModeSelect;
