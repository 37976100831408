import i18next from "i18next";

i18next.addResourceBundle("en", "ReportingContributorsPage", {
    page_title: "Contributors",
    page_subtitle: "Monitor the advancement of all contributors",
    contributor_column_title: "Contributors",
    search_placeholder: "Search",
    progress_bar_no_data: "No data",
});

i18next.addResourceBundle("fr", "ReportingContributorsPage", {
    page_title: "Contributeurs",
    page_subtitle: "Suivez l'avancement de tous les contributeurs",
    contributor_column_title: "Contributeurs",
    search_placeholder: "Rechercher",
    progress_bar_no_data: "Pas de donnée",
});
