import i18next from "@app/i18n";

i18next.addResourceBundle("en", "NewDashboard", {
    configuration_hi: "Hi, {{name}} 👋",
    configuration_welcome: "Welcome to the dashboard",
    configuration_complete: "Complete the tutorials below",
    configuration_almost_there: "We're almost there ! ({{percentage}}%)",
    configuration_cards_discover_beavr_title: "Discover the Beavr platform",
    configuration_cards_discover_beavr_description:
        "The Beavr platform allows you to build and strengthen your CSR management system.",
    configuration_cards_discover_beavr_reading_time: "5mn",
    configuration_cards_add_users_title: "Add users",
    configuration_cards_add_users_description:
        "Add your team members working on CSR topics at your company.",
    configuration_cards_add_users_reading_time: "5mn",
    configuration_cards_configure_sustainability_profile_title:
        "Discover your Beavr support",
    configuration_cards_configure_sustainability_profile_description:
        "Need help? Let our coaches help you out.",
    configuration_cards_configure_sustainability_profile_reading_time: "5mn",
    configuration_cards_assign_responsibles_title: "Assign responsibilities",
    configuration_cards_assign_responsibles_description:
        "Assign co-workers to each work item to increase efficiency.",
        configuration_cards_assign_responsibles_reading_time: "5mn",
    configuration_cards_upload_documents_title: "Upload documents",
    configuration_cards_upload_documents_description:
        "Upload your documents all at once.",
    configuration_cards_upload_documents_reading_time: "5mn",
    agenda_title: "Your company's work plan  🚀",
    agenda_cards_reporting_title: "Reporting",
    agenda_cards_reporting_description:
        "Reporting allows you to collect numerical data on all your activated sustainability issues. Starting with reporting allows you to assess your current maturity and then set relevant improvement objectives.",
    agenda_cards_engagements_title: "Commitments and Targets",
    agenda_cards_engagements_description:
        "Commitments are the strategic principles and values implemented in your company to respond to sustainability issues. Objectives are quantified and dated targets guiding the implementation of the commitments you’ve made. Your commitments and objectives are the core element of your sustainability policies.",
    agenda_cards_policies_title: "Policies",
    agenda_cards_policies_description:
        "Policies are the foundation of your CSR management system. They must respect a formalised structure and include commitments and quantitative objectives. Use the Beavr editor to build polices that meet the expectations of certifiers.",
    agenda_cards_measures_title: "Measures",
    agenda_cards_measures_description:
        "Measures are all the actions and projects implemented in your company to achieve your CSR objectives. These actions and projects must be proven using documents. Upload your existing valid documents and use the Beavr templates to strengthen your proof system.",
    agenda_cards_followup_title: "Follow up and assessment",
    agenda_cards_followup_description:
        "Your Beavr expert supports you throughout your work through coaching and 5/7 chat support. Use the ‘Frameworks’ tab to track your preparation level. Once your proof system is ready, your expert will help you submit your assessment.",
    learn_more: "Learn more",
    due_date: "Due date: {{- date}}",
    overdue: "Overdue",
    deadline_in_one: "Deadline in {{count}} week",
    deadline_in: "Deadline in {{count}} weeks",
});

i18next.addResourceBundle("fr", "NewDashboard", {
    configuration_hi: "Bonjour, {{name}} 👋",
    configuration_welcome: "Bienvenue sur votre tableau de bord",
    configuration_complete: "Complétez ces guides",
    configuration_almost_there: "On y est presque ! ({{percentage}}%)",
    configuration_cards_discover_beavr_title: "Découvrir la plateforme Beavr",
    configuration_cards_discover_beavr_description:
        "La plateforme Beavr vous permet de construire ou renforcer votre système de management RSE.",
    configuration_cards_discover_beavr_reading_time: "5mn",
    configuration_cards_add_users_title: "Ajouter des utilisateurs",
    configuration_cards_add_users_description:
        "Ajoutez les personnes qui composent votre équipe de travail au sein de votre entreprise.",
    configuration_cards_add_users_reading_time: "5mn",
    configuration_cards_configure_sustainability_profile_title:
        "Participez à nos Webinaires",
    configuration_cards_configure_sustainability_profile_description:
        "Assistez à des sessions hebdomadaires avec des questions-réponses en direct.",
    configuration_cards_configure_sustainability_profile_reading_time: "5mn",
    configuration_cards_assign_responsibles_title: "Assigner des responsables",
    configuration_cards_assign_responsibles_description:
        "Assignez des responsables sur chaque élément de travail afin de gagner en efficacité.",
    configuration_cards_assign_responsibles_reading_time: "5mn",
    configuration_cards_upload_documents_title: "Uploader des documents",
    configuration_cards_upload_documents_description:
        "Uploadez tous vos documents en une seule fois.",
    configuration_cards_upload_documents_reading_time: "5mn",
    agenda_title: "Le parcours de travail de votre entreprise  🚀",
    agenda_cards_reporting_title: "Reporting",
    agenda_cards_reporting_description:
        "Le reporting vous permet de récolter des données chiffrées sur l’ensemble de vos enjeux durables activés. Commencer par le reporting vous permet d’évaluer votre situation actuelle et ensuite fixer des objectifs d’amélioration pertinents.",
    agenda_cards_engagements_title: "Engagements et objectifs",
    agenda_cards_engagements_description:
        "Les engagements sont les axes stratégiques mis en place dans votre entreprise pour répondre à un enjeu de durabilité. Les objectifs sont des objectifs chiffrés et datés permettant d’atteindre vos engagements et de les intégrer dans votre modèle d’affaires. Vos engagements et objectifs seront au coeur de vos politiques RSE.",
    agenda_cards_policies_title: "Politiques",
    agenda_cards_policies_description:
        "Les politiques constituent les fondations de votre système de management RSE. Elles doivent respecter une structure et inclure vos engagements et objectifs quantitatifs. Utilisez l’éditeur Beavr pour construire des politiques conformes aux attentes des certificateurs.",
    agenda_cards_measures_title: "Mesures",
    agenda_cards_measures_description:
        "Les mesures sont toutes les actions mises en place dans votre entreprise pour atteindre vos objectifs RSE. Ces actions doivent être prouvées à l’aide de documents. Chargez vos document déjà validés et utilisez les modèles Beavr pour renforcer votre système de preuve.",
    agenda_cards_followup_title: "Suivi et évaluation",
    agenda_cards_followup_description:
        "Votre expert Beavr vous accompagne tout au long de votre travail à travers des coachings et un support 5/7 via le chat. Utilisez l’onglet “Référentiels” pour suivre votre niveau de préparation. Une fois votre système de management prêt, votre expert vous aidera à soumettre votre évaluation.",
    learn_more: "En savoir plus",
    due_date: "Date limite: {{- date}}",
    overdue: "En retard",
    deadline_in_one: "Delai de {{ count }} semaine",
    deadline_in: "Delai de {{ count }} semaines",
});
