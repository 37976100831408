import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "UnrelevantRequirements", {
    title: "Controls marked as not relevant",
    set_relevant: "Mark selection ({{count}}) as relevant",
});

i18next.addResourceBundle("fr", "UnrelevantRequirements", {
    title: "Contrôles définis comme non pertinents",
    set_relevant: "Définir la sélection ({{count}}) comme pertinent",
});
