export function browserDownload(url: string, newTab?: boolean) {
    const a = document.createElement("a");
    a.href = url;
    a.download = "export.csv";
    document.body.appendChild(a);
    if (newTab) {
        a.target = "_blank";
        a.rel = "noopener noreferrer";
    }
    a.click();
    document.body.removeChild(a);
}
