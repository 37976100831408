import { ColumnDef } from "@tanstack/react-table";
import { EditPencil, Trash } from "iconoir-react";
import { useTranslation } from "react-i18next";

import { currentUserIsBeavrAtom } from "@app/store/userStore";
import { type Organization } from "@app/usecases/OrganizationUseCases";
import { Tooltip } from "@design-system/DataDisplay/Tooltip";
import { useAtomValue, useSetAtom } from "jotai";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ActionDropdownMenuItem } from "../../../components/ActionDropdown";
import ActionDropdown from "../../../components/Table/ActionDropdown";
import ActionItemDanger from "../../../components/Table/ActionItemDanger";
import Avatar from "../../../components/User/Avatar";
import { orgToConfigureAtom, orgToUpdateAtom } from "../ctx";
import "./i18n";

export const columns: ColumnDef<Organization>[] = [
    {
        accessorKey: "name",
        header: () => {
            const { t } = useTranslation("PermissionTable");
            return t("name");
        },
        cell: ({ row }) => {
            const navigate = useNavigate();
            const handleClick = () =>
                row.original.tenant &&
                navigate(row.original.tenant.uname + "/admin");
            return (
                <button
                    className="text-left text-sm font-bold"
                    onClick={handleClick}
                >
                    {row.original.tenant?.name}
                </button>
            );
        },
        enableSorting: true,
    },

    {
        accessorKey: "uname",
        header: () => {
            const { t } = useTranslation("PermissionTable");
            return t("uname");
        },
        enableSorting: true,
        cell: ({ row }) => row.original.tenant?.uname,
    },
    {
        id: "tenant.userPermissions.length",
        header: () => {
            const { t } = useTranslation("PermissionTable");
            return t("beavr_user");
        },
        enableSorting: false,
        cell: ({ row }) => {
            return (
                <div className="flex flex-wrap -p-0.5">
                    {row.original.userPermissions
                        ?.map((u) => u.user)
                        .map((user) => (
                            <div key={user.id} className="p-0.5">
                                <Tooltip>
                                    <Tooltip.Trigger>
                                        <Avatar
                                            size="medium"
                                            initials={user.initials}
                                            avatarUrl={user.avatar?.signedUrl}
                                        />
                                    </Tooltip.Trigger>
                                    <Tooltip.Content>
                                        {`${user.firstName} ${user.lastName}`}
                                    </Tooltip.Content>
                                </Tooltip>
                            </div>
                        ))}
                </div>
            );
        },
    },
    {
        accessorKey: "type",
        header: () => {
            const { t } = useTranslation("PermissionTable");
            return t("type");
        },
        enableSorting: true,
    },
];

const actionColumn: ColumnDef<Organization> = {
    id: "actions",
    meta: {
        colWidth: "50px",
    },
    enableSorting: false,
    cell: ({ row }) => {
        const { t } = useTranslation("PermissionTable");

        const setOrgToUpdate = useSetAtom(orgToUpdateAtom);
        const setOrgToConfigure = useSetAtom(orgToConfigureAtom);
        const handleOrgToUpdateClick = () => setOrgToUpdate(row.original);
        const handleOrgToConfigureClick = () => setOrgToConfigure(row.original);

        return (
            <ActionDropdown>
                <ActionDropdownMenuItem
                    Icon={({ className }) => (
                        <EditPencil className={className} />
                    )}
                    text={t("edit")}
                    onClick={handleOrgToUpdateClick}
                />
                <ActionDropdownMenuItem
                    Icon={({ className }) => (
                        <EditPencil className={className} />
                    )}
                    text={t("configure")}
                    onClick={handleOrgToConfigureClick}
                />
                <ActionItemDanger icon={Trash} title={t("delete")} disabled />
            </ActionDropdown>
        );
    },
};

export const useColumns = (): ColumnDef<Organization>[] => {
    const { isBeavrUser } = useAtomValue(currentUserIsBeavrAtom);

    return useMemo(
        () => [...columns, ...(isBeavrUser ? [actionColumn] : [])],
        [isBeavrUser],
    );
};
