import i18next from "../../../../i18n";

i18next.addResources("en", "DocumentRequirementTable", {
    framework: "Framework",
    section: "Section",
    name: "Requirement",
});
i18next.addResources("fr", "DocumentRequirementTable", {
    framework: "Framework",
    section: "Section",
    name: "Contrôle",
});
