import { Ruler } from "iconoir-react";

import { useTranslation } from "react-i18next";
import Container from "../../../components/Container";
import { useCapturePageView } from "../../../lib/tracking/utils";
import BodyHeader from "../../../shared/components/BodyHeader";
import UnitsTable from "./components/UnitsTable";
import "./i18n";

export default function Units() {
    useCapturePageView("settings");

    const { t } = useTranslation("Units");

    return (
        <>
            <Container>
                <BodyHeader
                    icon={Ruler}
                    title={t("title")}
                    subTitle={t("subtitle")}
                />
                <div className="p-4">
                    <UnitsTable />
                </div>
            </Container>
        </>
    );
}
