import i18next from "../../i18n";

i18next.addResourceBundle("en", "ReminderTextEditor", {
    title: "Reminder",
    subtitle:
        "Customize the reminder email that will be sent to your contributors.",
    reporting_default_message: `
    <p>
    Hi {{first_name}}, 
    </p>
    <p>
    To monitor and improve our CSR performance, you are invited to fill in a data collection campaign. Please follow this link to access your personal page: {{personal_link}}. 
    </p>
    <p>
    Thanks
    </p>
    `,
    supplier_default_message: `
    <p>
    Dear {{supplier}} team, 
    </p>
    <p>
    To monitor and improve our CSR performance, you are invited to fill in a data collection campaign. Please follow this link to access your personal page: {{personal_link}}. 
    </p>
    <p>
    Thank you,
    </p>
    `,

    variables: {
        first_name: "Firstname",
        last_name: "Lastname",
        personal_link: "Personnal link",
        n_requests: "Number of requests",
        supplier: "Supplier",
    },
});

i18next.addResourceBundle("fr", "ReminderTextEditor", {
    title: "Rappel",
    subtitle:
        "Personnalisez l'e-mail de rappel qui sera envoyé à vos contributeurs.",
    reporting_default_message: `
    <p>
    Bonjour {{first_name}}, 
    </p>
    <p>
    Pour suivre et améliorer notre performance RSE, vous êtes invité à remplir une campagne de collecte de données. Veuillez suivre ce lien pour accéder à votre page personnelle: {{personal_link}}.
    </p>
    <p>
    Merci
    </p>
    `,

    supplier_default_message: `
    <p>
    Chère équipe de {{supplier}},
    </p>
    <p>
    Pour suivre et améliorer notre performance RSE, vous êtes invité à remplir une campagne de collecte de données. Veuillez suivre ce lien pour accéder à votre page personnelle: {{personal_link}}.
    </p>
    <p>
    Merci,
    </p>
    `,

    variables: {
        first_name: "Prénom",
        last_name: "Nom",
        personal_link: "Lien personnel",
        n_requests: "Nombre de demandes",
        supplier: "Fournisseur",
    },
});
