import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { BlockThread, InlineThread } from "@tiptap-pro/extension-comments";
import CharacterCount from "@tiptap/extension-character-count";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TextStyle from "@tiptap/extension-text-style";
import Typography from "@tiptap/extension-typography";
import Underline from "@tiptap/extension-underline";
import { generateText } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import {
    ArrowRight,
    Download,
    MinusIcon,
    MoreHorizontal,
    PlusIcon,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Document,
    DocumentVersionStatus,
} from "../../../../../generated/client/graphql";
import { useNavigation } from "../../../../lib/navigation";
import BaseItem from "../../../Dropdown/components/dropdowns/items/BaseItem";
import DropdownContentLayout from "../../../Dropdown/components/dropdowns/layout";
import { Variant } from "../../../Tags/StatusTag";
import Status from "../../../Tags/StatusTag/Status";
import { ImageBlock } from "../../extensions/ImageBlock";
import { ImageUpload } from "../../extensions/ImageUpload";
import { Table } from "../../extensions/Table";
import { TableCell } from "../../extensions/Table/Cell";
import { cn } from "../../lib/utils";
import { DocumentRelationType, Relations } from "./InfoTab";
import "./i18n";

const RelatedDocumentListElement = ({
    isActive,
    relatedDocument,
    isFirst,
    isLast,
    relationType,
    insertDocument,
    toggleActive,
}: {
    isActive: boolean;
    relatedDocument: Document;
    isFirst: boolean;
    isLast: boolean;
    relationType: DocumentRelationType;
    insertDocument: (relatedDocument: Document) => void;
    toggleActive: () => void;
}) => {
    const { t } = useTranslation("DocumentEditorSideBar");
    const { navigateFromHome } = useNavigation();
    const [expandedText, setExpandedText] = useState<string>(
        t("document_not_started")
    );
    useEffect(() => {
        const documentText = !relatedDocument?.lastVersion
            ? ""
            : generateText(
                  relatedDocument?.lastVersion?.tiptapData || "{}",
                  [
                      StarterKit,
                      Highlight,
                      TableHeader,
                      TableRow,
                      TableCell,
                      Table,
                      ImageBlock,
                      ImageUpload,
                      TextStyle,
                      Underline,
                      Superscript,
                      Link,
                      Subscript,
                      CharacterCount,
                      Typography,
                      InlineThread,
                      BlockThread,
                  ],
                  {
                      blockSeparator: "\n\n",
                  }
              );
        setExpandedText(
            !relatedDocument?.lastVersion
                ? t("document_not_started")
                : documentText
                ? documentText
                : relatedDocument.lastVersion.finalFile?.signedUrl
                ? t("document_is_not_text")
                : t("no_data_yet")
        );
    }, [relatedDocument]);

    return (
        <div
            className={cn(
                "flex pl-1 pr-3 gap-2 items-start self-stretch rounded-sm overflow-hidden",
                isActive &&
                    relationType === Relations.CHILDREN &&
                    "bg-tertiary",
                relationType === Relations.CHILDREN && "cursor-pointer"
            )}
        >
            <div
                className="flex w-5 flex-col items-center self-stretch"
                onClick={toggleActive}
            >
                <div
                    className={cn(
                        "w-[1px] h-[14px]",
                        !isFirst && "border-l border-secondary"
                    )}
                ></div>
                <div className="flex p-1 items-center rounded-lg border border-secondary">
                    {relationType === Relations.PARENTS ? (
                        <ArrowRight className="w-2 h-2" />
                    ) : isActive ? (
                        <MinusIcon className="w-2 h-2" />
                    ) : (
                        <PlusIcon className="w-2 h-2" />
                    )}
                </div>
                <div
                    className={cn(
                        "w-[1px] flex-1",
                        !isLast && "border-l border-secondary"
                    )}
                ></div>
            </div>
            <div
                className="flex py-3 flex-col items-start justify-center self-stretch gap-2 flex-1 overflow-hidden"
                onClick={toggleActive}
            >
                <div className="flex items-start gap-2 self-stretch">
                    <div className="flex pt-[3px] items-start w-4">
                        {!relatedDocument?.lastVersion?.status ? (
                            <Status variant={Variant.Nothing}>{""}</Status>
                        ) : relatedDocument?.lastVersion?.status ===
                          DocumentVersionStatus.Approved ? (
                            <Status variant={Variant.Done}>{""}</Status>
                        ) : (
                            <Status variant={Variant.InProgress}>{""}</Status>
                        )}
                    </div>
                    <div className="font-bold text-sm flex-1">
                        {relatedDocument.name}
                    </div>
                </div>
                {isActive && relationType === Relations.CHILDREN && (
                    <p className="text-sm overflow-hidden whitespace-pre-wrap break-all line-clamp-[20]">
                        {expandedText}
                    </p>
                )}
            </div>
            {(relationType === Relations.PARENTS || isActive) && (
                <DropdownMenu modal={false}>
                    <DropdownPrimitive.Trigger>
                        <div className="flex py-3 items-start gap-2">
                            <MoreHorizontal className="w-4 h-4" />
                        </div>
                    </DropdownPrimitive.Trigger>
                    <DropdownContentLayout align="end" className="w-[240px]">
                        <BaseItem
                            IconLeft={ArrowRight}
                            onClick={() =>
                                navigateFromHome(
                                    `policies/${relatedDocument.uniqueUrl}`
                                )
                            }
                        >
                            {t("go_to_doc")}
                        </BaseItem>
                        {relationType === Relations.CHILDREN &&
                            relatedDocument?.lastVersion &&
                            relatedDocument?.lastVersion?.tiptapData &&
                            relatedDocument?.lastVersion?.tiptapData["content"]
                                .length > 0 && (
                                <BaseItem
                                    IconLeft={Download}
                                    onClick={() =>
                                        insertDocument(relatedDocument)
                                    }
                                >
                                    {t("insert_doc")}
                                </BaseItem>
                            )}
                    </DropdownContentLayout>
                </DropdownMenu>
            )}
        </div>
    );
};

export default RelatedDocumentListElement;
