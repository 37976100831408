import { queryClient } from "@app/QueryClientWithHeaders";
import { GraphQLS3FileRepository } from "@app/repositories/GraphQLRepositories/S3FileRepository";
import { GraphQLVersionRepository } from "@app/repositories/GraphQLRepositories/VersionRepository";
import {
    S3FileFromBulkUpload,
    approve,
    bulkFileUploadToS3,
    createOrUpdateManyVersionsFromBulkS3FilesUpload,
    extractEditValidationType,
    extractVersionChecks,
    getVersion,
    initializeVersionTipTapDoc,
    reject,
    saveVersionData,
    sendForReview,
    setToDraft,
    updateFinalFile,
    versionChecksRatio,
    versionRequiresValidation,
    type GetVersionQuery,
} from "@app/usecases/VersionUseCases";
import {
    type CreateManyVersionsInput,
    type DocumentVersion,
    type MutationUpdateDocumentVersionCheckArgs,
    type UpdateVersionCheckMutation,
    type UpdateVersionMutation,
} from "@generated/client/graphql";
import { atom } from "jotai";
import { atomWithMutation, atomWithQuery } from "jotai-tanstack-query";
import { documentURLAtom } from "./documentStore";
import { documentKeys, versionKeys } from "./queryKeys";
import { currentUserAtom, currentUserRoleAtom } from "./userStore";

// init
const versionRepository = new GraphQLVersionRepository();
const s3FileRepository = new GraphQLS3FileRepository();

// queries
export const versionAtom = atomWithQuery((get) => ({
    queryKey: [
        versionKeys.versionByDocumentURL,
        { documentUrl: get(documentURLAtom) },
    ],
    queryFn: () =>
        getVersion(
            { versionRepository },
            { documentUrl: get(documentURLAtom) },
        ),
}));

export const checklistValidationRequiredAtom = atom((get) => {
    const { data, ...res } = get(versionAtom);
    const showValidation = versionRequiresValidation(data?.version);
    return { ...res, data: { showValidation } };
});

export const versionChecksAtom = atom((get) => {
    const { data, ...res } = get(versionAtom);
    const versionChecks = extractVersionChecks(data?.version);
    return { ...res, data: { versionChecks } };
});

export const editValidationTypeAtom = atom((get) => {
    const { data } = get(versionAtom);
    const { role } = get(currentUserRoleAtom);
    const validationType = extractEditValidationType(data?.version, role);
    return validationType;
});

export const versionStatusAtom = atom((get) => {
    const { data, ...res } = get(versionAtom);
    const status = data?.version?.status;
    return { ...res, data: { status } };
});

export const versionChecksRatioAtom = atom((get) => {
    const { data, ...res } = get(versionAtom);
    const ratio = versionChecksRatio(data?.version);
    return { ...res, data: { ratio } };
});

// mutations
export const updateVersionCheckAtom = atomWithMutation<
    UpdateVersionCheckMutation,
    MutationUpdateDocumentVersionCheckArgs,
    Error,
    { previousQuery: GetVersionQuery }
>((get) => ({
    mutationKey: [versionKeys.updateVersionCheck],
    mutationFn: async (input: MutationUpdateDocumentVersionCheckArgs) => {
        return versionRepository.updateVersionCheck(input);
    },
    // We are running an optimistic update
    onMutate: async (input: MutationUpdateDocumentVersionCheckArgs) => {
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries({
            queryKey: [
                versionKeys.versionByDocumentURL,
                { documentUrl: get(documentURLAtom) },
            ],
        });

        // Snapshot the previous value
        const previousQuery: GetVersionQuery | undefined =
            queryClient.getQueryData([
                versionKeys.versionByDocumentURL,
                { documentUrl: get(documentURLAtom) },
            ]);

        if (!previousQuery) {
            throw new Error("No previous query found");
        }

        const previousVersion = previousQuery?.version as DocumentVersion;

        if (!previousVersion) {
            throw new Error("No previous version found");
        }

        // Optimistically update to the new value
        const updatedVersion = {
            ...previousVersion,
            checks: previousVersion.checks?.map((check) =>
                check.id === input.id ? { ...check, ...input.set } : check,
            ),
        };
        const updatedQuery: GetVersionQuery = {
            ...previousQuery,
            version: updatedVersion,
        };
        queryClient.setQueryData(
            [
                versionKeys.versionByDocumentURL,
                { documentUrl: get(documentURLAtom) },
            ],
            updatedQuery,
        );

        // Return a context with the previous query, for error hanlding
        return { previousQuery };
    },
    // If the mutation fails, roll back to the previous version
    onError: (_, __, context) => {
        console.log("onError", context);
        queryClient.setQueryData(
            [
                versionKeys.versionByDocumentURL,
                { documentUrl: get(documentURLAtom) },
            ],
            context?.previousQuery,
        );
    },
}));

export const sendForReviewAtom = atomWithMutation((get) => {
    const version = get(versionAtom).data?.version;

    return {
        mutationKey: [versionKeys.requestForApproval],
        mutationFn: async () => {
            if (!version) {
                throw new Error("No version found");
            }
            return sendForReview({ versionRepository }, version);
        },
        onSettled() {
            queryClient.invalidateQueries({
                queryKey: [
                    versionKeys.versionByDocumentURL,
                    { documentUrl: get(documentURLAtom) },
                ],
            });
        },
    };
});

export const rejectDocumentVersionAtom = atomWithMutation((get) => {
    const version = get(versionAtom).data?.version;
    const user = get(currentUserAtom);

    return {
        mutationKey: [versionKeys.reject],
        mutationFn: async () => {
            if (!version) {
                throw new Error("No version found");
            }
            if (!user) {
                throw new Error("No user found");
            }
            return reject({ versionRepository }, version.id, {
                userRole: user?.data?.currentUser.permission?.role ?? undefined,
            });
        },
        onSettled() {
            queryClient.invalidateQueries({
                queryKey: [
                    versionKeys.versionByDocumentURL,
                    { documentUrl: get(documentURLAtom) },
                ],
            });
        },
    };
});

export const setToDraftAtom = atomWithMutation<
    UpdateVersionMutation,
    void,
    Error
>((get) => {
    const version = get(versionAtom).data?.version;

    return {
        mutationKey: [versionKeys.setToDraft],
        mutationFn: async () => {
            if (!version) {
                throw new Error("No version found");
            }
            return setToDraft({ versionRepository }, version.id);
        },
        onSettled() {
            queryClient.invalidateQueries({
                queryKey: [
                    versionKeys.versionByDocumentURL,
                    { documentUrl: get(documentURLAtom) },
                ],
            });
        },
    };
});

export const approveAtom = atomWithMutation<UpdateVersionMutation, void, Error>(
    (get) => {
        const version = get(versionAtom).data?.version;

        return {
            mutationKey: [versionKeys.setToDraft],
            mutationFn: async () => {
                if (!version) {
                    throw new Error("No version found");
                }
                return approve({ versionRepository }, version);
            },
            onSettled() {
                queryClient.invalidateQueries({
                    queryKey: [
                        versionKeys.versionByDocumentURL,
                        { documentUrl: get(documentURLAtom) },
                    ],
                });
            },
        };
    },
);

export const saveVersionDataAtom = atomWithMutation((get) => {
    const version = get(versionAtom).data?.version;

    return {
        mutationKey: [versionKeys.updateVersionCheck],
        mutationFn: async () => {
            if (!version) {
                throw new Error("No version found");
            }
            return saveVersionData({ versionRepository }, version.id);
        },
        onSettled() {
            queryClient.invalidateQueries({
                queryKey: [
                    versionKeys.versionByDocumentURL,
                    { documentUrl: get(documentURLAtom) },
                ],
            });
        },
    };
});

/**
 * This atom will initialize the version with a tiptap doc id
 * if it doesn't have one; it will check if the version has a
 * tiptap doc id, no need to do it here or in the component
 */
export const addVersionTipTapDocAtom = atomWithMutation((get) => {
    return {
        mutationKey: [versionKeys.initializeVersionTipTapDoc],
        mutationFn() {
            return initializeVersionTipTapDoc(
                { versionRepository },
                { documentUrl: get(documentURLAtom) },
            );
        },
        onSettled() {
            queryClient.invalidateQueries({
                queryKey: [
                    versionKeys.versionByDocumentURL,
                    { documentUrl: get(documentURLAtom) },
                ],
            });
        },
    };
});

export const updateFinalFileAtom = atomWithMutation((get) => ({
    mutationKey: [versionKeys.updateFinalFile],
    mutationFn: async (input: { file: File }) => {
        const versionId = get(versionAtom).data?.version?.id;

        if (typeof versionId !== "string") {
            throw new Error("No version found");
        }

        return updateFinalFile(
            {
                s3FileRepository,
                versionRepository,
            },
            { versionId },
            { file: input.file },
        );
    },

    onSettled() {
        queryClient.invalidateQueries({
            queryKey: [
                versionKeys.versionByDocumentURL,
                { documentUrl: get(documentURLAtom) },
            ],
        });
    },
}));

export const bulkS3FileUploadToS3MutationAtom = atomWithMutation(() => {
    return {
        mutationKey: [versionKeys.bulkS3FileUploadToS3],
        mutationFn: async (input: { files: File[] }) => {
            return bulkFileUploadToS3({ s3FileRepository }, input);
        },
    };
});

export const createManyVersionsMutationAtom = atomWithMutation(() => ({
    mutationKey: [versionKeys.createManyVersions],
    mutationFn: async (input: CreateManyVersionsInput) => {
        return versionRepository.createMany(input);
    },
}));

export const createOrUpdateManyVersionsFromBulkS3FilesUploadMutationAtom =
    atomWithMutation(() => ({
        mutationKey: [
            versionKeys.createOrUpdateManyVersionsFromBulkS3FilesUpload,
        ],
        mutationFn: async (input: S3FileFromBulkUpload[]) => {
            return createOrUpdateManyVersionsFromBulkS3FilesUpload(
                { versionRepository },
                input,
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: [documentKeys.documentsForSearch],
            });
        }
    }));
export const deleteS3FileMutationAtom = atomWithMutation(() => {
    return {
        mutationKey: [versionKeys.deleteS3File],
        mutationFn: async (id: string) => {
            return s3FileRepository.deleteOne(id);
        },
    };
})

export const deleteS3FilesMutationAtom = atomWithMutation(() => {
    return {
        mutationKey: [versionKeys.deleteS3Files],
        mutationFn: async (ids: string[]) => {
            return s3FileRepository.deleteMany(ids);
        }
    };
});
