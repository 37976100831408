import { ReactComponent as BeavrFullLogo } from "../../../../assets/beavr-full-logo.svg";

const FooterLayout = ({}: {}) => {
    return (
        <div className="w-full flex justify-end p-5 border-t border-secondary space-x-8 text-secondary text-sm bg-secondary">
            <span>Copyright © Beavr 2024</span>
            <BeavrFullLogo />
        </div>
    );
};
export default FooterLayout;
