import i18next from "@app/i18n";

const enResources = {
    status: "Status",
    not_started: "Not started",
    in_progress: "In progress",
    validated: "Validated",
    not_material: "Not material",
} as const;

type ResourcesKeys = keyof typeof enResources;

const frResources: Record<ResourcesKeys, string> = {
    status: "Statut",
    not_started: "Non commencé",
    in_progress: "En cours",
    validated: "Validaté",
    not_material: "Non matériel",
} as const;

// hack to use have some sort of typesafty outside this file
// needs further digging into i18next typing
export const keys = Object.fromEntries(
    Object.keys(enResources).map((k) => [k, k]),
) as Record<ResourcesKeys, ResourcesKeys>;

export const RESOURCE_NAME = "CsrdDatapointStatusMultiSelect";

i18next.addResources("en", RESOURCE_NAME, enResources);
i18next.addResources("fr", RESOURCE_NAME, frResources);
