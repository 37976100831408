import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { useTranslation } from "react-i18next";
import { DocumentExpirationStatus } from "../../../../../generated/client/graphql";
import FilterTrigger from "../../components/triggers/Filter";
import ExpirationStatusDropdown from "./dropdown";
import "./i18n";

const DocumentExpirationStatusFilter = ({
    status,
    setStatus,
}: {
    status: DocumentExpirationStatus[] | undefined;
    setStatus: (status: DocumentExpirationStatus[] | undefined) => void;
}) => {
    const { t } = useTranslation("ExpirationStatusDropdown");

    return (
        <DropdownMenu>
            <FilterTrigger
                unsetCallback={() => setStatus(undefined)}
                active={status !== undefined}
            >
                {status ? t(status) : t("placeholder")}
            </FilterTrigger>
            <ExpirationStatusDropdown
                status={status}
                onStatusChange={setStatus}
            />
        </DropdownMenu>
    );
};

export default DocumentExpirationStatusFilter;
