import i18next from "../../../i18n";

i18next.addResourceBundle("en", "PermissionTable", {
    name: "Name",
    uname: "Uname",
    beavr_user: "Beavr User",
    type: "Type",
    edit: "Edit",
    configure: "Configure",
});

i18next.addResourceBundle("fr", "PermissionTable", {
    name: "Nom",
    uname: "Uname",
    beavr_user: "Beavr Utilisateur",
    type: "Type",
    edit: "Modifier",
    configure: "Configurer",
});
