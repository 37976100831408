import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
    GetSupplierQuestionnnaireForContributorPageDocument,
    SupplierQuestionnairePatch,
    UpdateSupplierQuestionnaireDocument,
} from "../../../../../generated/client/graphql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../../QueryClientWithHeaders";
import { useSetToast } from "../../../../components/Toast";

export const useUpdateSupplierQuestionnaire = (questionnaireId?: string) => {
    const updateQuestionnaireMutation = useGraphQLMutation(
        UpdateSupplierQuestionnaireDocument,
        undefined,
        true
    );

    const client = useQueryClient();
    const { t } = useTranslation("SupplierContribution");
    const { setToastSuccess } = useSetToast();

    const updateQuestionnaire = async (patch: SupplierQuestionnairePatch) => {
        if (!questionnaireId) {
            return;
        }
        await updateQuestionnaireMutation.mutate(
            {
                id: questionnaireId,
                patch,
            },
            {
                onSuccess: () => {
                    setToastSuccess(t("questionnaire_updated_success"));
                    client.invalidateQueries({
                        queryKey: useGraphQL.getKey(
                            GetSupplierQuestionnnaireForContributorPageDocument,
                            {
                                id: questionnaireId!,
                            }
                        ),
                    });
                },
            }
        );
    };

    return {
        updateQuestionnaire,
        isPending: updateQuestionnaireMutation.isPending,
    };
};
