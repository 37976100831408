import SideBarBody from "./SideBarBody";
import SideBarHeader from "./SideBarHeader";
import "./i18n";

const SideBar = ({}: {}) => {
    return (
        <div className="overflow-hidden h-full">
            <SideBarHeader />
            <SideBarBody />
        </div>
    );
};

export default SideBar;
