import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { atom, useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import {
    GetAllSupplierQuestionnaireTemplatesDocument,
    SupplierQuestionnaireTemplate,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import FilterTrigger from "../../components/triggers/Filter";
import SupplierQuestionnaireTemplatesDropdownMultiSelect from "./dropdown_multi_select";

export const filterSupplierQuestionnaireTemplateIdsAtom = atom<
    string[] | undefined
>(undefined);

const SupplierQuestionnaireTemplateFilter = () => {
    const { t } = useTranslation("SupplierQuestionnaireTemplatesDropdown");

    const [templateIds, setTemplateIds] = useAtom(
        filterSupplierQuestionnaireTemplateIdsAtom
    );
    const getTemplates = useGraphQL(
        GetAllSupplierQuestionnaireTemplatesDocument,
        undefined,
        {},
        true
    );

    return (
        <DropdownMenu>
            <FilterTrigger
                unsetCallback={() => setTemplateIds(undefined)}
                active={templateIds !== undefined}
            >
                {templateIds
                    ? t("setter_text", { count: templateIds.length })
                    : t("placeholder")}
            </FilterTrigger>
            <SupplierQuestionnaireTemplatesDropdownMultiSelect
                templateIds={templateIds}
                onTemplateIdsChange={(ids) =>
                    setTemplateIds(ids.length > 0 ? ids : undefined)
                }
                templates={
                    getTemplates.data?.supplierQuestionnaireTemplates as
                        | SupplierQuestionnaireTemplate[]
                        | undefined
                }
                hidePlus
            />
        </DropdownMenu>
    );
};

export default SupplierQuestionnaireTemplateFilter;
