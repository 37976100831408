import { useTranslation } from "react-i18next";
import { InputSelect, InputSelectProps } from "..";

import { SelectItem } from "@radix-ui/react-select";
import { Check } from "iconoir-react";
import { GetAllAvatarsDocument } from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import i18next from "../../../../i18n";
import Avatar from "../../../User/Avatar";

i18next.addResourceBundle("en", "SelectInput", {
    select_avatar: "Avatar",
});

i18next.addResourceBundle("fr", "SelectInput", {
    select_avatar: "Avatar",
});

export default function InputSelectAvatar(props: InputSelectProps) {
    const { t } = useTranslation("SelectInput");
    const { data } = useGraphQL(GetAllAvatarsDocument, undefined, {}, true);

    return (
        <InputSelect
            {...props}
            placeholder={t("select_avatar")}
            values={data?.avatars.map((avatar) => ({
                id: avatar.url,
                name: avatar.url.split("/").pop(),
                node: (
                    <SelectItem
                        value={avatar.url}
                        className="group py-1 px-2 focus:outline-none focus:bg-green-50 flex space-x-2 items-center"
                    >
                        <Check className="w-4 h-4 text-green group-data-[state=unchecked]:invisible" />
                        <Avatar
                            avatarUrl={avatar.signedUrl}
                            initials=""
                            size="large"
                        />
                    </SelectItem>
                ),
            }))}
        />
    );
}
