import { useNavigate, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { currentUserOrgsAtom } from "@app/store/userStore";
import { Avatar } from "@design-system/DataDisplay/Avatar";
import { Icon } from "@design-system/Icons";
import { Button } from "@design-system/Inputs/Button";
import Select from "@design-system/Inputs/Select";
import { Box } from "@design-system/Layout/Box";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { useQueryClient } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import "../i18n";

export default function OrganizationSelect() {
    const { t } = useTranslation("Navbar");

    const currentUserOrgs = useAtomValue(currentUserOrgsAtom);
    const orgs = currentUserOrgs?.data?.orgs?.data;
    const { org_uname } = useParams();
    const navigate = useNavigate();

    const currentOrg = orgs?.find((org) => org.tenant?.uname === org_uname);
    const client = useQueryClient();
    const onValueChange = (value: string) => {
        if (value !== org_uname) {
            navigate(`/o/${value}/admin`);
            client.invalidateQueries();
        }
    };

    return (
        <>
            {!!orgs?.length && orgs.length > 1 && (
                    <Select onValueChange={onValueChange}>
                        <Select.Trigger asChild className="w-8 h-8 ">
                            <Button
                                variant="plain"
                                className="w-[42px] h-[42px] p-2 flex align-middle rounded-lg border border-tertiary"
                            >
                                <Avatar
                                    className="w-6 h-6 rounded-lg"
                                    imageUrl={
                                        currentOrg?.customization?.logo
                                            ?.signedUrl || undefined
                                    }
                                    hexColor={
                                        currentOrg?.customization?.hexColor ||
                                        undefined
                                    }
                                    letter={currentOrg?.tenant?.name[0]}
                                />
                            </Button>
                            <Box
                                w="4"
                                h="4"
                                alignItems="center"
                                justifyContent="center"
                                className="bg-neutral-100 relative left-7 bottom-2.5 rounded-lg border border-tertiary flex"
                            >
                                <Icon name="angleDown" className="w-3" />
                            </Box>
                        </Select.Trigger>
                        <Select.Content className="max-h-[300px] overflow-auto">
                            <Box px="2" py="2">
                                <Text variant="body-sm-bold" color="secondary">
                                    {t("organizations")}
                                </Text>
                            </Box>
                            {orgs?.map((org) => (
                                <Select.Item
                                    key={org.tenant?.uname}
                                    value={org.tenant?.uname || ""}
                                    className="h-10"
                                >
                                    <FlexRow
                                        gap="2"
                                        w="full"
                                        h="full"
                                        px="2"
                                        py="2.5"
                                        alignItems="center"
                                    >
                                        {org_uname === org.tenant?.uname ? (
                                            <Icon
                                                name="check"
                                                className="text-beavrGreen"
                                            />
                                        ) : (
                                            <Box w="5" />
                                        )}
                                        <Avatar
                                            variant="rounded"
                                            imageUrl={
                                                org?.customization?.logo
                                                    ?.signedUrl || undefined
                                            }
                                            hexColor={
                                                org?.customization?.hexColor ||
                                                undefined
                                            }
                                            letter={org?.tenant?.name[0]}
                                        />
                                        <FlexCol>
                                            <Text variant="body-sm-bold">
                                                {org.tenant?.name}
                                            </Text>
                                            <Text
                                                variant="body-xs"
                                                color="secondary"
                                            >
                                                {t("count_members", {
                                                    count: org?.userPermissions
                                                        ?.length,
                                                })}
                                            </Text>
                                        </FlexCol>
                                    </FlexRow>
                                </Select.Item>
                            ))}
                        </Select.Content>
                    </Select>
            )}
        </>
    );
}
