import {
    ColumnFiltersState,
    SortingState,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import { useEffect, useState } from "react";
import { useColumns } from "./columns";

import { SigmaFunction } from "iconoir-react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";

import { Indicator, IndicatorType } from "../../../generated/client/graphql";
import { newIndicatorModalOpenAtom } from "../../screens/reporting/Indicators/components/CreateIndicatorModal";
import { documentModeFilterAtom } from "../Dropdown/dropdowns/DocumentMode/filter";
import { filterSupplierQuestionnaireTemplateIdsAtom } from "../Dropdown/dropdowns/SupplierQuestionnaireTemplate/filter";
import { filterTagIdsAtom } from "../Dropdown/dropdowns/Tags/filter";
import { filterThemeIdsAtom } from "../Dropdown/dropdowns/Themes/filter";
import { filterSubthemeIdsAtom } from "../Filters/MultiFilter/Subtheme";
import { searchFilterStringAtom } from "../Filters/Search";
import DataTable from "../Table/DataTable";
import EmptyTable from "../Table/EmptyTable";
import LoadingTable from "../Table/LoadingTable";

import SavebarSupplier from "./components/Savebar/suppliers";
import UpdateIndicatorModal from "./components/Update";
import "./i18n";

function IndicatorTableInner({
    indicators,
    refetch,
    type,
}: {
    indicators: Indicator[];
    refetch: () => void;
    type: IndicatorType;
}) {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [rowSelection, setRowSelection] = useState({});
    const [searchFilterString, setSearchFilterString] = useAtom(
        searchFilterStringAtom,
    );

    const columns = useColumns(type);
    const table = useReactTable<Indicator>({
        data: indicators,
        columns,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onRowSelectionChange: setRowSelection,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setSearchFilterString,
        state: {
            sorting,
            columnFilters,
            rowSelection,
            globalFilter: searchFilterString,
        },
        autoResetPageIndex: false,
    });
    const filterSubThemeIds = useAtomValue(filterSubthemeIdsAtom);
    useEffect(() => {
        table.getColumn("subtheme")?.setFilterValue(filterSubThemeIds);
    }, [filterSubThemeIds]);

    const filterThemeIds = useAtomValue(filterThemeIdsAtom);
    useEffect(() => {
        table.getColumn("theme")?.setFilterValue(filterThemeIds);
    }, [filterThemeIds]);

    const [documentAcceptanceFilter, setDocumentAcceptanceFilter] = useAtom(
        documentModeFilterAtom,
    );
    useEffect(() => {
        table
            .getColumn("documentAcceptance")
            ?.setFilterValue(documentAcceptanceFilter);
    }, [documentAcceptanceFilter]);

    const filterTagIds = useAtomValue(filterTagIdsAtom);
    useEffect(() => {
        table.getColumn("tags")?.setFilterValue(filterTagIds);
    }, [filterTagIds]);

    const filterTemplateIds = useAtomValue(
        filterSupplierQuestionnaireTemplateIdsAtom,
    );
    useEffect(() => {
        table.getColumn("templates")?.setFilterValue(filterTemplateIds);
    }, [filterTemplateIds]);

    useEffect(() => {
        setDocumentAcceptanceFilter(undefined);
    }, []);
    return (
        <>
            <DataTable table={table} />
            <UpdateIndicatorModal refetch={refetch} />
            {type === IndicatorType.Supplier && (
                <SavebarSupplier
                    indicators={table
                        .getSelectedRowModel()
                        .flatRows.map((r) => r.original)}
                    unset={table.resetRowSelection}
                />
            )}
        </>
    );
}

const IndicatorTable = ({
    indicators,
    isLoading,
    refetch,
    type,
}: {
    indicators: Indicator[];
    isLoading: boolean;
    refetch: () => void;
    type: IndicatorType;
}) => {
    const setCreateModalOpen = useSetAtom(newIndicatorModalOpenAtom);
    const { t } = useTranslation("IndicatorTable");

    return !indicators && isLoading ? (
        <LoadingTable />
    ) : indicators.length === 0 ? (
        <EmptyTable
            Icon={SigmaFunction}
            smallText={t("empty_table_small_text")}
            buttonText={
                type === IndicatorType.Reporting
                    ? t("empty_table_button_text")
                    : undefined
            }
            onClick={() => setCreateModalOpen(true)}
        />
    ) : indicators ? (
        <IndicatorTableInner
            indicators={indicators}
            refetch={refetch}
            type={type}
        />
    ) : (
        <div>Error, refresh ...</div>
    );
};

export default IndicatorTable;
