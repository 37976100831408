import { useAuth0 } from "@auth0/auth0-react";
import { type TypedDocumentNode } from "@graphql-typed-document-node/core";
import {
    MutationFunction,
    QueryClient,
    QueryClientProvider,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
    useMutation,
    useQuery,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import request from "graphql-request";
import { useAtomValue, useSetAtom } from "jotai";
import { ReactNode, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AccessHeadersHolder } from "./repositories/AccessHeadersHolder";
import {
    graphqlClient,
    graphqlClientKoyeb,
    restClient,
} from "./repositories/clients";
import { JotaiProvider } from "./store/StoreConfig";
import { bearerAtomReadonly, setBearerAtom } from "./store/authStore";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus:
                import.meta.env.VITE_REACT_APP_AUTH_MODE === "development"
                    ? false
                    : true,
        },
    },
});

export function useRest(path: string) {
    const fetchData = async () =>
        restClient.get(path).then((response) => response.data);

    const { data, isLoading } = useQuery({
        queryKey: ["rest", ...path.split("/")],
        queryFn: fetchData,
    });
    return { data, isLoading };
}

export function useGraphQL<TResult, TVariables, TError>(
    document: TypedDocumentNode<TResult, TVariables>,
    variables?: TVariables extends Record<string, never> ? {} : TVariables,
    options?: Omit<
        UseQueryOptions<TResult, TError, TResult, any>,
        "queryKey" | "queryFn" | "initialData"
    >,
    useKoyeb?: boolean,
): UseQueryResult<TResult, TError> {
    const { org_uname } = useParams();
    const bearer = useAtomValue(bearerAtomReadonly);
    const { pathname } = useLocation();
    const [localBearer, setLocalBearer] = useState<string | undefined>(bearer);
    useEffect(() => {
        setLocalBearer(bearer);
    }, [bearer]);

    return useQuery({
        queryKey: [(document.definitions[0] as any).name.value, variables],
        queryFn: async ({ queryKey }) => {
            var headers: any = options?.meta?.headers || {};
            headers = { ...headers, pathname };
            if (bearer) {
                headers = { ...headers, authorization: `Bearer ${bearer}` };
            }
            if (org_uname) {
                headers = { ...headers, "x-org-uname": org_uname };
            }
            if (useKoyeb) {
                return graphqlClientKoyeb.request(
                    document,
                    queryKey[1] ? queryKey[1] : undefined,
                    headers,
                );
            } else {
                return graphqlClient.request(
                    document,
                    queryKey[1] ? queryKey[1] : undefined,
                    headers,
                );
            }
        },
        ...(options !== undefined ? options : null),
        enabled:
            (!!localBearer ||
                options?.meta?.noLogIn === true ||
                import.meta.env.VITE_REACT_APP_AUTH_MODE === "development") &&
            options?.enabled !== false,
    });
}

useRest.getKey = (path: string) => ["rest", ...path.split("/")];

useGraphQL.getKey = <TResult, TVariables>(
    document: TypedDocumentNode<TResult, TVariables>,
    ...[variables]: TVariables extends Record<string, never> ? [] : [TVariables]
) => [(document.definitions[0] as any).name.value, variables];

useGraphQL.getRootKey = <TResult, TVariables>(
    document: TypedDocumentNode<TResult, TVariables>,
) => [(document.definitions[0] as any).name.value];

export function useGraphQLMutation<TResult, TVariables>(
    document: TypedDocumentNode<TResult, TVariables>,
    options?: Omit<
        UseMutationOptions<TResult, unknown, TVariables, unknown>,
        "mutationKey" | "mutationFn"
    >,
    useKoyeb?: boolean,
): UseMutationResult<TResult, unknown, TVariables, unknown> {
    const bearer = useAtomValue(bearerAtomReadonly);
    const { pathname } = useLocation();
    const { org_uname } = useParams();
    const mutationFunction: MutationFunction<TResult, TVariables> = async (
        variables: TVariables,
    ) => {
        const headers: any = {
            pathname: pathname,
        };
        if (bearer) {
            headers.authorization = `Bearer ${bearer}`;
        }
        if (org_uname) {
            headers["x-org-uname"] = org_uname;
        }
        if (useKoyeb) {
            return request(
                import.meta.env.VITE_REACT_APP_GRAPHQL_URL_KOYEB,
                document,
                variables ? variables : {},
                options?.meta?.headers
                    ? { ...headers, ...options.meta.headers }
                    : headers,
            );
        } else {
            return request(
                import.meta.env.VITE_REACT_APP_GRAPHQL_URL,
                document,
                variables ? variables : {},
                options?.meta?.headers
                    ? { ...headers, ...options.meta.headers }
                    : headers,
            );
        }
    };
    return useMutation<TResult, unknown, TVariables, unknown>({
        mutationFn: mutationFunction,
        ...options,
    });
}

export const QueryClientWithHeadersWrapper = ({
    children,
    noDevTools,
}: {
    children: ReactNode;
    noDevTools?: boolean;
}) => {
    return (
        <QueryClientProvider client={queryClient}>
            <JotaiProvider>
                <QueryClientWithHeaders>{children}</QueryClientWithHeaders>
            </JotaiProvider>
            {!noDevTools && <ReactQueryDevtools initialIsOpen={false} />}
        </QueryClientProvider>
    );
};

const QueryClientWithHeaders = ({ children }: { children: ReactNode }) => {
    const { org_uname } = useParams();
    const setBearer = useSetAtom(setBearerAtom);

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        AccessHeadersHolder.setOrgUname(org_uname);
    }, [org_uname]);

    useEffect(() => {
        const getToken = async () => {
            const token = isAuthenticated ? await getAccessTokenSilently() : "";
            return token;
        };
        getToken().then((token) => setBearer(token));
    }, [isAuthenticated, getAccessTokenSilently]);

    return <>{children}</>;
};

export default QueryClientWithHeaders;
