import { useTranslation } from "react-i18next";

import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import {
    CreateDocumentV2Document,
    DocumentActivationStatus,
    DocumentType,
    GetDocumentsV2Document,
} from "../../../../../generated/client/graphql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../../QueryClientWithHeaders";
import ButtonLarge from "../../../../components/Button/ButtonLarge";
import { ButtonType } from "../../../../components/Button/ButtonShared";
import InputSimple from "../../../../components/InputSimple";
import {
    CenterModal,
    CenterModalContent,
} from "../../../../components/Modal/CenterModal";
import { useSetToast } from "../../../../components/Toast";
import SelectInputOwner from "../../../../components/inputs/InputSelect/Owner";
import "./i18n";

export default function AddDocumentModal({
    type,
    open,
    setOpen,
}: {
    type: DocumentType | undefined;
    open: boolean;
    setOpen: (open: boolean) => void;
}) {
    const { t } = useTranslation("UpdateDocumentModal");

    const [name, setName] = useState<string | undefined>();
    const [description, setDescription] = useState<string | undefined>();
    const [ownerId, setOwnerId] = useState<string | undefined>();

    const client = useQueryClient();
    const { mutate, isPending } = useGraphQLMutation(
        CreateDocumentV2Document,
        {},
        true,
    );
    const { setToastSuccess, setToastError } = useSetToast();

    const canSave = name !== undefined && name !== "";
    const handleSave = () => {
        canSave &&
            mutate(
                {
                    input: {
                        name: name,
                        decription: description,
                        ownerId: ownerId,
                    },
                },
                {
                    onSuccess() {
                        client.invalidateQueries({
                            queryKey: useGraphQL.getKey(
                                GetDocumentsV2Document,
                                {
                                    type: type,
                                    activationStatus:
                                        DocumentActivationStatus.Relevant,
                                },
                            ),
                        });
                        setToastSuccess(t("document_update_success"));
                        setOpen(false);
                    },
                    onError() {
                        client.invalidateQueries({
                            queryKey: useGraphQL.getKey(
                                GetDocumentsV2Document,
                                {
                                    type: undefined,
                                    activationStatus:
                                        DocumentActivationStatus.Relevant,
                                },
                            ),
                        });
                        setToastError(t("document_update_error"));
                    },
                },
            );
    };

    return (
        <CenterModal open={open} onOpenChange={setOpen} modal={false}>
            <CenterModalContent
                title={t("title")}
                subtitle={t("subtitle")}
                actions={
                    <>
                        <ButtonLarge
                            text={t("save")}
                            variant={ButtonType.ELEVATED}
                            disabled={!canSave}
                            onClick={() => {
                                handleSave();
                            }}
                            loading={isPending}
                        />
                    </>
                }
            >
                <div className="w-full space-y-3">
                    <InputSimple
                        title={t("document_name")}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <InputSimple
                        title={t("document_description")}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <SelectInputOwner
                        value={ownerId}
                        onValueChange={(id) => setOwnerId(id)}
                    />
                </div>
            </CenterModalContent>
        </CenterModal>
    );
}
