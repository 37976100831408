import * as TabPrimitive from "@radix-ui/react-tabs";

import React from "react";
import { cn } from "../../lib/utils";

export const TabRoot = TabPrimitive.Root;
export const TabList = TabPrimitive.List;
export const TabContent = TabPrimitive.Content;

export const TabTrigger = React.forwardRef(
    (
        {
            text,
            stat,
            className,
            ...props
        }: {
            text: string;
            stat?: string | number;
        } & TabPrimitive.TabsTriggerProps,
        ref: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <TabPrimitive.Trigger
                ref={ref}
                className={cn(
                    "px-4 py-5 border-b-2 border-secondary text-base font-bold text-secondary",
                    "data-[state=active]:border-green data-[state=active]:text-primary",
                    className
                )}
                {...props}
            >
                <div className="flex items-center space-x-1.5">
                    <div>{text}</div>
                    <div className="text-sm text-green">{stat}</div>
                </div>
            </TabPrimitive.Trigger>
        );
    }
);
