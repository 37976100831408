import { CsrdDatapointStatus } from "@generated/client/graphql";
import { atom } from "jotai";
import { datapointSearchStrAtom } from "../../ctx";

export const disclosureRequirementIdFilterAtom = atom<string | undefined>(
    undefined,
);
export const esrsIdFilterAtom = atom<string | undefined>(undefined);
export const datapointStatusFilterAtom = atom<CsrdDatapointStatus | undefined>(
    undefined,
);
export const ownerIdsFilterAtom = atom<(string | null)[] | undefined>(
    undefined,
);

export const filterAtom = atom((get) => {
    return {
        search: get(datapointSearchStrAtom),
        statuses: !!get(datapointStatusFilterAtom)
            ? ([get(datapointStatusFilterAtom)] as CsrdDatapointStatus[])
            : undefined,
        esrsId: get(esrsIdFilterAtom),
        disclosureRequirementId: get(disclosureRequirementIdFilterAtom),
        ownerIds: !!get(ownerIdsFilterAtom)?.length
            ? get(ownerIdsFilterAtom)
            : undefined,
    };
});
