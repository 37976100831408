import { ColumnDef } from "@tanstack/react-table";
import { useHover } from "@uidotdev/usehooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { Indicator, Tag } from "../../../generated/client/graphql";
import SupplierQuestionnaireTemplateFilter from "../Dropdown/dropdowns/SupplierQuestionnaireTemplate/filter";
import TemplatesSetter from "../Dropdown/dropdowns/SupplierQuestionnaireTemplate/setter";
import TagList from "../Tags/TagList";
import { useUpdateIndicator } from "./hooks";

export const useQuestionnairesCol = (
    getIndicatorsKey: any[]
): ColumnDef<Indicator> =>
    useMemo(
        () => ({
            id: "templates",
            accessorFn: (row) =>
                row.supplierQuestionnaireTemplates?.map((t) => t.id),
            filterFn: "arrIncludesSome",
            meta: {
                headerClassName: "w-[20%]",
            },
            header: () => {
                return <SupplierQuestionnaireTemplateFilter />;
            },
            cell: ({ row }) => {
                const [open, setOpen] = useState(false);
                const [divRef, hovering] = useHover<HTMLDivElement>();
                const setterRef = useRef<HTMLDivElement>(null);
                const { updateIndicator } = useUpdateIndicator(
                    row.original.id,
                    getIndicatorsKey
                );

                useEffect(() => {
                    if (!hovering && !open) {
                        setterRef.current?.style.setProperty("display", "none");
                    } else {
                        setterRef.current?.style.setProperty(
                            "display",
                            "block"
                        );
                    }
                }, [hovering, open]);
                return (
                    <>
                        <div ref={divRef}>
                            <TagList
                                tags={
                                    row.original.supplierQuestionnaireTemplates?.map(
                                        (q) =>
                                            ({
                                                id: q.id,
                                                name: q.name,
                                                color: "",
                                            } as Tag)
                                    ) || []
                                }
                                extra={
                                    <div ref={setterRef}>
                                        <TemplatesSetter
                                            templateIds={row.original.supplierQuestionnaireTemplates?.map(
                                                (t) => t.id
                                            )}
                                            onTemplateIdsChange={(ids) =>
                                                updateIndicator({
                                                    questionnaireIds: ids,
                                                })
                                            }
                                            open={open}
                                            setOpen={setOpen}
                                        />
                                    </div>
                                }
                            />
                        </div>
                    </>
                );
            },
        }),
        [getIndicatorsKey]
    );
