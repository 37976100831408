import { ChevronDown } from "lucide-react";
import React, { FC } from "react";
import { cn } from "../../../../../lib/utils";

const EditorButton = React.forwardRef(
    (
        {
            Icon,
            active,
            arrow,
            ...props
        }: {
            Icon: FC<Partial<{ className: string }>>;
            active?: boolean;
            arrow?: boolean;
        } & React.ButtonHTMLAttributes<HTMLButtonElement> &
            React.RefAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <button
                ref={forwardedRef}
                className={cn(
                    "p-1 space-x-0.5 hover:bg-tertiary active:bg-quarternary rounded-lg h-[28px] box-border flex justify-center items-center ",
                    arrow ? "w-[42px]" : "w-[28px]",
                    active && "border border-green "
                )}
                {...props}
            >
                <Icon className="w-4 h-4" />
                {arrow && <ChevronDown className="w-4 h-4" />}
            </button>
        );
    }
);
export default EditorButton;
