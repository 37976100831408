import "./i18n";

import {
    ColumnFiltersState,
    SortingState,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { Requirement } from "../../../../generated/client/graphql";

import { useAtom, useAtomValue } from "jotai";
import {
    nonRelevantRequirementsColumns,
    useColumns,
} from "../components/table/columns";
import {
    ownerIdsFilterAtom,
    paginationAtom,
    searchFilterAtom,
    subthemeIdsFilterAtom,
    themeIdFilterAtom,
    unrelevantModalSearchFilterAtom,
} from "../context";
import {
    useAllNotRelevantRequirements,
    useFilteredRelevantdRequirements,
} from "./data";

export const useRequirementsTable = () => {
    const subthemeIdsFilter = useAtomValue(subthemeIdsFilterAtom);
    const themeIdFilter = useAtomValue(themeIdFilterAtom);
    const ownerIdsFilter = useAtomValue(ownerIdsFilterAtom);
    const [searchFilter, setSearchFilter] = useAtom(searchFilterAtom);
    const [pagination, setPagination] = useAtom(paginationAtom);

    const { data, count } = useFilteredRelevantdRequirements();

    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [rowSelection, setRowSelection] = useState({});

    useEffect(() => {
        table.getColumn("subtheme")!.setFilterValue(subthemeIdsFilter);
    }, [subthemeIdsFilter]);

    useEffect(() => {
        table.getColumn("theme")!.setFilterValue(themeIdFilter);
    }, [themeIdFilter]);

    useEffect(() => {
        table.getColumn("owner")!.setFilterValue(ownerIdsFilter);
    }, [ownerIdsFilter]);

    const columns = useColumns();
    const table = useReactTable<Requirement>({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getFilteredRowModel: getFilteredRowModel(),
        onColumnFiltersChange: setColumnFilters,
        getSortedRowModel: getSortedRowModel(),
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setSearchFilter,
        onPaginationChange: setPagination,
        manualPagination: true,
        rowCount: count,
        initialState: {
            sorting,
            columnFilters,
            rowSelection,
            globalFilter: searchFilter,
            columnVisibility: { theme: false },
        },
        state: {
            rowSelection,
            sorting,
            columnFilters,
            pagination,
        },
        autoResetPageIndex: false,
    });

    // With backend pagination, count would come from backend
    return { table: table, count };
};

export const useNonRelevantRequirementsTable = () => {
    const [searchFilter, setSearchFilter] = useAtom(
        unrelevantModalSearchFilterAtom,
    );

    const { data } = useAllNotRelevantRequirements();

    const [sorting, setSorting] = useState<SortingState>([]);
    const [rowSelection, setRowSelection] = useState({});

    const table = useReactTable<Requirement>({
        data: data || [],
        columns: nonRelevantRequirementsColumns(),
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onRowSelectionChange: setRowSelection,
        onGlobalFilterChange: setSearchFilter,
        state: {
            sorting,
            rowSelection,
            columnVisibility: { theme: false },
            globalFilter: searchFilter,
        },
        autoResetPageIndex: false,
    });

    return { table: table, count: data?.length };
};
