import { cn } from "@design-system/Utilities";
import { cva, type VariantProps } from "class-variance-authority";
import React, {
    forwardRef,
    HTMLAttributes,
    PropsWithChildren,
    Ref,
    useImperativeHandle,
    useRef,
} from "react";
import { ReactComponent as UploadIllus } from "./upload.svg";

import { Text } from "@design-system/Typography/Text";
import { Button } from "../Button";

const containerClasses = cva(
    [
        "flex justify-center items-center",
        "border border-secondary rounded-lg border-dashed hover:border-green",
        "bg-secondary hover:bg-green-50",
        "cursor-pointer",
        "text-secondary hover:text-green",
        "gap-5",
    ],
    {
        variants: {
            size: {
                md: "p-5 pr-4 flex-row",
                lg: "p-6 flex-col",
            },
        },
        defaultVariants: {
            size: "md",
        },
    },
);

const iconClasses = cva([], {
    variants: {
        size: {
            md: "h-[48px]",
            lg: "h-[96px]",
        },
    },
});

const textClasses = cva([], {
    variants: {
        size: {
            md: "text-base",
            lg: "text-lg",
        },
    },
});

export type DropZoneProps = PropsWithChildren<
    | {
          buttonText?: string;
          onChange?: (file: File) => void;
          multiple?: false;
      }
    | {
          buttonText?: string;
          onChange?: (files: File[]) => void;
          multiple: true;
      }
> &
    VariantProps<typeof containerClasses> &
    Omit<HTMLAttributes<HTMLInputElement>, "type" | "onChange">;

const DropZone = forwardRef<HTMLInputElement, DropZoneProps>(
    (
        { buttonText, children, className, size, ...props },
        ref: Ref<HTMLInputElement | null>,
    ) => {
        const inputRef = useRef<HTMLInputElement>(null);
        useImperativeHandle(ref, () => inputRef.current, []);

        const onButtonClick = () => {
            inputRef.current?.click();
        };

        const handleDrop = (event: React.DragEvent<HTMLLabelElement>) => {
            event.preventDefault();
            const files = event.dataTransfer.files;
            if (files) {
                if (props.multiple) {
                    props.onChange && props.onChange([...files]);
                } else {
                    props.onChange && props.onChange(files[0]);
                }
            }
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const files = e.target.files;
            if (files) {
                if (props.multiple) {
                    props.onChange && props.onChange([...files]);
                } else {
                    props.onChange && props.onChange(files[0]);
                }
            }
        };
        const childrenWithProps = React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
                return (
                    <>
                        {React.cloneElement(
                            child as React.ReactElement<{ className: string }>,
                            {
                                className: textClasses({ size }),
                            },
                        )}
                    </>
                );
            }
            return child;
        });

        return (
            <label
                className={cn(containerClasses({ size }), className)}
                onDrop={handleDrop}
                onDragOver={(event) => event.preventDefault()}
            >
                <UploadIllus className={iconClasses({ size })} />

                <Text
                    className={cn(
                        "flex-grow text-inherit justify-center space-y-1.5",
                        size === "md" ? "text-left" : "text-center",
                    )}
                    variant={size === "md" ? "body-sm" : "body-lg"}
                >
                    {childrenWithProps}
                </Text>

                <input
                    hidden
                    ref={inputRef}
                    type="file"
                    {...props}
                    onChange={handleChange}
                />
                <Button variant="outlined" onClick={onButtonClick}>
                    {buttonText}
                </Button>
            </label>
        );
    },
);

export default DropZone;
