import i18next from "../../../../../i18n";

i18next.addResourceBundle("en", "UpdateSiteModal", {
    title: "Modify site",
    subtitle: "Modify name, email, role or department",
    name: "Name",
    shortName: "Short name",
    type: "Type",
    site_update_success: "Site updated successfully",
    site_update_error: "Error updating entity",
});

i18next.addResourceBundle("fr", "UpdateSiteModal", {
    title: "Modifier l'siteé",
    subtitle: "Modifier le nom, l'email, le rôle ou le département",
    name: "Nom",
    shortName: "Nom court",
    type: "Type",
    site_update_success: "Siteé mise à jour avec succès",
    site_update_error: "Erreur lors de la mise à jour de l'entité",
});
