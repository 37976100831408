import i18next from "../../../i18n";

i18next.addResourceBundle("en", "SupplierCampaignReminder", {
    title: "Send reminder",
    subtitle:
        "An email reminder will be sent to each contributor that has at least one outstanding data request.",
    subject: "Subject",
    default_subject: "New CSR data collection campaign",
    sender_name: "Sender name (if empty, your name will be used)",
    email_ccs: "Emails to cc (separate by comma)",
    alert_title_one: "One supplier will be notified",
    alert_title_other: "{{count}} suppliers will be notified",
    alert_subtitle:
        "Double check the recipients, and test your email by inserting your email below before confirming.",
    send_test: "Send test",
    send_test_success: "Test email sent successfully",
    send_test_error: "Error sending test email",
    send_reminder: "Send reminder",
    send_reminder_success: "Email sent successfully to {{count}} contributors",
    send_reminder_error: "Error sending emails",
    send_test_tooltip: "Send a test email to yourself",
});

i18next.addResourceBundle("fr", "SupplierCampaignReminder", {
    title: "Envoyer un rappel",
    subtitle:
        "Un rappel sera envoyé par email à chaque contributeur qui a au moins une demande de données en attente.",
    subject: "Sujet",
    default_subject: "Nouvelle campagne de collecte de données RSE",
    sender_name:
        "Nom de l'expéditeur (si le champ est vide, votre nom sera utilisé)",
    email_ccs: "Emails en copie (séparés par une virgule)",
    alert_title_one: "Un fournisseur sera notifié",
    alert_title_other: "{{count}} fournisseurs seront notifiées",
    alert_subtitle:
        "Vérifiez les destinataires et testez votre e-mail en insérant votre e-mail ci-dessous avant de confirmer.",
    send_test: "Envoyer le test",
    send_test_success: "E-mail de test envoyé avec succès",
    send_test_error: "Erreur lors de l'envoi de l'e-mail de test",
    send_reminder: "Envoyer un rappel",
    send_reminder_success: "Email envoyé avec succès à {{count}} contributeurs",
    send_reminder_error: "Erreur lors de l'envoi des emails",
    send_test_tooltip: "Envoyer un e-mail de test à vous-même",
});
