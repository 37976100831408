import { useState } from "react";

import { useTranslation } from "react-i18next";

import { EyeOff } from "lucide-react";
import {
    Relevance,
    Requirement,
} from "../../../../../generated/client/graphql";
import { ButtonType } from "../../../../components/Button/ButtonShared";
import ButtonSmall from "../../../../components/Button/ButtonSmall";
import UserSelect from "../../../../components/Dropdown/dropdowns/User";
import Floatbar from "../../../../components/Floatbar";

import { useUpdateRequirements } from "../../hooks/data";
import "./i18n";

enum Actions {
    SET_OWNER = "SET_OWNER",
}

const RequirementSavebar = ({
    requirements,
    unset,
}: {
    requirements: Requirement[];
    unset: () => void;
}) => {
    const { t } = useTranslation("RequirementTable");

    const [ownerId, setOwnerId] = useState<string | null>();
    const [activeAction, setActiveAction] = useState<Actions>();

    const { mutate, isPending } = useUpdateRequirements();

    const onOwnerApply = () => {
        mutate({
            ids: requirements.map((d) => d.id),
            patch: { ownerId: ownerId },
        });
        unset();
    };

    const onRelevanceApply = () => {
        mutate({
            ids: requirements.map((d) => d.id),
            patch: { relevance: Relevance.NotRelevant },
        });
        unset();
    };

    return (
        <Floatbar
            unset={unset}
            open={requirements.length > 0}
            text={t("selection", { count: requirements.length })}
            primary={
                <ButtonSmall onClick={() => setActiveAction(Actions.SET_OWNER)}>
                    {t("set_owner")}
                </ButtonSmall>
            }
            secondary={
                <ButtonSmall
                    variant={ButtonType.OUTLINED}
                    onClick={() => onRelevanceApply()}
                    IconRight={EyeOff}
                >
                    {t("set_unrelevant_text")}
                </ButtonSmall>
            }
            actions={[
                {
                    id: Actions.SET_OWNER,
                    text: t("set_owner_text", { count: requirements.length }),
                    selector: (
                        <div>
                            <UserSelect
                                selectedUserId={ownerId || undefined}
                                onChange={(id: string | undefined) =>
                                    setOwnerId(id || null)
                                }
                            />
                        </div>
                    ),
                    apply: (
                        <ButtonSmall
                            loading={isPending}
                            onClick={() => onOwnerApply()}
                        >
                            {t("apply")}
                        </ButtonSmall>
                    ),
                },
            ]}
            activeAction={activeAction}
            setActiveAction={setActiveAction}
        ></Floatbar>
    );
};

export default RequirementSavebar;
