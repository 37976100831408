import { useTranslation } from "react-i18next";
import { InputSelect, InputSelectProps } from "..";

import i18next from "../../../../i18n";
import { UserRole } from "../../../../../generated/client/graphql";

i18next.addResourceBundle("en", "SelectInput", {
    select_user_role: "Role",
    CONTRIBUTOR: "Contributor",
    ADMIN: "Admin",
});

i18next.addResourceBundle("fr", "SelectInput", {
    select_user_role: "Rôle",
    CONTRIBUTOR: "Contributeur",
    ADMIN: "Admin",
});

export default function InputSelectUserRole(props: InputSelectProps) {
    const { t } = useTranslation("SelectInput");

    return (
        <InputSelect
            {...props}
            placeholder={t("select_user_role")}
            values={[
                {
                    id: UserRole.Contributor,
                    name: t(UserRole.Contributor),
                },
                {
                    id: UserRole.Admin,
                    name: t(UserRole.Admin),
                },
            ]}
        />
    );
}
