import { useAtomValue } from "jotai";
import { ArrowUpRightSquareIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { currentUserAtom } from "../TopBarProfil";
import "../i18n";

export default function TopBarHelp() {
    const { t } = useTranslation("TopBar");
    const currentUser = useAtomValue(currentUserAtom);

    return (
        <a
            className="flex gap-1 items-center hover:no-underline"
            href={new URL(
                currentUser?.locale === "fr" ? "fr/knowledge/" : "knowledge",
                import.meta.env.VITE_REACT_APP_HELP_CENTER_URL,
            ).toString()}
            target="_blank"
        >
            <div className="text-secondary text-base">{t("help_center")}</div>
            <ArrowUpRightSquareIcon className="w-5 text-secondary" />
        </a>
    );
}
