import { EditPencil } from "iconoir-react";
import { MailIcon } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SustainabilityCenter } from "../../../../../generated/client/graphql";
import { ButtonType } from "../../../../components/Button/ButtonShared";
import ButtonSmall from "../../../../components/Button/ButtonSmall";
import "../i18n";
import EditOverviewModal from "./modals/EditOverviewModal";

export default function SustainabilityCenterHeader({
    sustainabilityCenter,
    editMode,
}: {
    sustainabilityCenter: SustainabilityCenter | undefined;
    editMode: boolean;
}) {
    const { t } = useTranslation("SustainabilityCenter", {
        lng: sustainabilityCenter?.locale || "fr",
    });
    const [openUpdatePPModal, setOpenUpdatePPModal] = useState(false);

    return (
        <div className="flex w-[1120px] flex-col items-end">
            <div className="flex p-6 items-start gap-8 self-stretch border border-secondary bg-primary rounded-xl">
                <div className="w-[120px] flex justify-center items-center">
                    {sustainabilityCenter?.logo?.signedUrl ? (
                        <img src={sustainabilityCenter?.logo?.signedUrl} />
                    ) : (
                        <img></img>
                    )}
                </div>
                <div className="gap-2 flex flex-col items-start flex-1">
                    <div className="text-3xl capitalize font-bold">
                        {sustainabilityCenter?.name}
                    </div>
                    <div className="text-lg text-secondary justify-start max-w-[844px] overflow-hidden text-ellipsis">
                        {sustainabilityCenter?.description ||
                            t("add_description")}
                    </div>
                    {sustainabilityCenter?.contact && (
                        <div className="flex items-center">
                            <MailIcon className="mr-2 h-5 w-5" />
                            <div
                                className="font-bold"
                                style={{
                                    color: sustainabilityCenter?.hexColor
                                        ? sustainabilityCenter?.hexColor
                                        : "var(--color-green-DEFAULT)",
                                }}
                            >
                                {" "}
                                {sustainabilityCenter?.contact}
                            </div>
                        </div>
                    )}
                </div>
                {editMode && (
                    <ButtonSmall
                        variant={ButtonType.OUTLINED}
                        IconLeft={EditPencil}
                        onClick={() => setOpenUpdatePPModal(true)}
                    ></ButtonSmall>
                )}
            </div>
            <EditOverviewModal
                sustainabilityCenter={
                    sustainabilityCenter as SustainabilityCenter
                }
                open={openUpdatePPModal}
                setOpen={setOpenUpdatePPModal}
            ></EditOverviewModal>
        </div>
    );
}
