import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { useState } from "react";
import DropdownItemSearch from "./items/Search";
import SimpleItem from "./items/SimpleItem";
import DropdownContentLayout from "./layout";

const SingleSelectDropdown = <T,>({
    className,
    disableSearch,
    values,
    value,
    onValueChange,
    ...props
}: {
    value: T | undefined;
    onValueChange: (v: T | undefined) => void;
    values: {
        value: T;
        node: React.ReactNode;
    }[];
    disableSearch?: boolean;
    portalContainer?: HTMLElement;
} & DropdownPrimitive.DropdownMenuContentProps) => {
    const [searchFilterString, setSearchFilterString] = useState("");

    return (
        <DropdownContentLayout align="start" sideOffset={4} {...props}>
            {!disableSearch && (
                <DropdownItemSearch
                    searchFilterString={searchFilterString}
                    setSearchFilterString={setSearchFilterString}
                />
            )}
            {values
                ?.filter(
                    ({ value }) =>
                        !searchFilterString ||
                        `${value}`
                            .toLowerCase()
                            .includes(searchFilterString.toLowerCase())
                )
                .map(({ value: v, node }, idx) => (
                    <SimpleItem
                        key={idx}
                        checked={value === v}
                        onCheckedChange={() => {
                            onValueChange(v);
                        }}
                    >
                        {node}
                    </SimpleItem>
                ))}
        </DropdownContentLayout>
    );
};

export default SingleSelectDropdown;
