import axios from "axios";
import { GraphQLClient } from "graphql-request";

export const graphqlClient = new GraphQLClient(
    import.meta.env.VITE_REACT_APP_GRAPHQL_URL,
);
export const graphqlClientKoyeb = new GraphQLClient(
    import.meta.env.VITE_REACT_APP_GRAPHQL_URL_KOYEB,
);

export const restClient = axios.create({
    baseURL: import.meta.env.VITE_REACT_APP_REST_URL,
});
