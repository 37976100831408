import * as Popover from "@radix-ui/react-popover";
import { cn } from "../../../lib/utils";

interface FloatbarLayoutProps {
    children: React.ReactNode;
    open: boolean;
}
const FloatbarLayout = ({ children, open }: FloatbarLayoutProps) => {
    return (
        <Popover.Root open={open} modal={false}>
            <div className="fixed bottom-0 left-0 w-screen">
                <Popover.Anchor />
            </div>
            <Popover.Portal>
                <Popover.Content
                    className={cn(
                        "rounded-lg bg-black p-3 pl-6 mb-5 flex items-center space-x-6"
                    )}
                >
                    {children}
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};

export default FloatbarLayout;
