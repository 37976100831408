import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "OrgTypeDropdown", {
    placeholder: "Type",
    ARCHIVED: "Archived",
    DEMO: "Demo",
    TRIAL: "Trial",
    CUSTOMER: "Customer",
    CUSTOMER_INACTIVE: "Customer (Inactive)",
});

i18next.addResourceBundle("fr", "OrgTypeDropdown", {
    placeholder: "Type",
    ARCHIVED: "Archivé",
    DEMO: "Démo",
    TRIAL: "Essai",
    CUSTOMER: "Client",
    CUSTOMER_INACTIVE: "Client (Inactif)",
});
