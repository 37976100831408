import { atom, useAtom } from "jotai";
import { useTranslation } from "react-i18next";

import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
    Entity,
    GetAllEntitiesDocument,
    UpdateEntityDocument,
} from "../../../../../../generated/client/graphql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../../../QueryClientWithHeaders";
import ButtonLarge from "../../../../../components/Button/ButtonLarge";
import { ButtonType } from "../../../../../components/Button/ButtonShared";
import InputSimple from "../../../../../components/InputSimple";
import {
    CenterModal,
    CenterModalContent,
} from "../../../../../components/Modal/CenterModal";
import { useSetToast } from "../../../../../components/Toast";
import "./i18n";

export const toUpdateSiteAtom = atom<Entity | undefined>(undefined);

export default function UpdateSiteModal() {
    const { t } = useTranslation("UpdateSiteModal");

    const [toUpdateSite, setToUpdateSite] = useAtom(toUpdateSiteAtom);

    const [name, setName] = useState<string | undefined>(
        toUpdateSite?.name || undefined
    );

    const { mutate, isPending } = useGraphQLMutation(
        UpdateEntityDocument,
        {},
        true
    );
    const { setToastSuccess, setToastError } = useSetToast();
    const client = useQueryClient();

    const canSave = true;
    const handleSave = () => {
        canSave &&
            toUpdateSite &&
            mutate(
                {
                    id: toUpdateSite.id,
                    name: name,
                },
                {
                    onSuccess() {
                        client.invalidateQueries({
                            queryKey: useGraphQL.getKey(GetAllEntitiesDocument),
                        });
                        setToUpdateSite(undefined);
                        setToastSuccess(t("site_update_success"));
                    },
                    onError() {
                        client.invalidateQueries({
                            queryKey: useGraphQL.getKey(GetAllEntitiesDocument),
                        });
                        setToastError(t("site_update_error"));
                    },
                }
            );
    };

    useEffect(() => {
        setName(toUpdateSite?.name || undefined);
    }, [toUpdateSite]);
    return (
        <CenterModal
            open={toUpdateSite !== undefined}
            onOpenChange={(open) => {
                !open && setToUpdateSite(undefined);
            }}
            modal={false}
        >
            <CenterModalContent
                title={t("title")}
                subtitle={t("subtitle")}
                actions={
                    <>
                        <ButtonLarge
                            text={t("save")}
                            variant={ButtonType.ELEVATED}
                            disabled={!canSave}
                            onClick={() => {
                                handleSave();
                            }}
                            loading={isPending}
                        />
                    </>
                }
            >
                <div className="w-full space-y-3">
                    <InputSimple
                        title={t("name")}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
            </CenterModalContent>
        </CenterModal>
    );
}
