import i18next from "../../i18n";

i18next.addResourceBundle("en", "Requirements", {
    title: "Requirements",
    subtitle:
        "Requirements are the overall proof elements needed to align with the frameworks you’re working towards.",
    see_not_relevant: "See {{count}} not relevant items",
});

i18next.addResourceBundle("fr", "Requirements", {
    title: "Contrôles",
    subtitle:
        "Les Contrôles sont l’ensemble des éléments de preuves requis pour se mettre en conformité avec les frameworks choisis.",
    see_not_relevant: "Voir {{count}} éléments non pertinent",
});
