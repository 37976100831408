import i18next from "../../../i18n";

i18next.addResourceBundle("en", "General", {
    title: "General informations",
    subtitle:
        "Personalize your org's color and logo. Those settings will be used to customize design of your pages",
    organization_name: "Organization name",
    color: "Color",
    upload_success: "Logo uploaded successfully",
    save: "Save",
});

i18next.addResourceBundle("fr", "General", {
    title: "Informations générales",
    subtitle:
        "Personnalisez la couleur et le logo de votre organisation. Ces paramètres seront utilisés pour personnaliser la conception de vos pages",
    organization_name: "Nom de l'organisation",
    color: "Couleur",
    upload_success: "Logo téléchargé avec succès",
    save: "Enregistrer",
});
