import { CreateIndicatorInput, IndicatorType } from "@generated/client/graphql";
import Papa from "papaparse";
import { useMemo } from "react";
import { useThemesAndSubtheme, useUnits } from "./data";

export type CSVRow = {
    name: string;
    uname: string;
    description: string;
    cmsUnitId: string;
    cmsThemeId: string;
    cmsSubthemeId: string;
};

export const useValidateCSVRows = (csvRows: CSVRow[]) => {
    const { themes, subthemes } = useThemesAndSubtheme();
    const units = useUnits();

    const validateRow = (csvRow: CSVRow) => {
        const theme = themes?.find(
            (theme) => theme.cmsThemeId === csvRow.cmsThemeId,
        );
        const subtheme = subthemes?.find(
            (subtheme) => subtheme.cmsSubThemeId === csvRow.cmsSubthemeId,
        );
        const unit = units?.find((unit) => unit.bvId === csvRow.cmsUnitId);

        const isValid =
            !!csvRow.name && !!csvRow.uname && !!unit && !!theme && !!subtheme;
        return {
            csvRow,
            theme,
            subtheme,
            unit,
            isValid,
            input: isValid
                ? ({
                      name: csvRow.name,
                      description: csvRow.description,
                      cmsUnitId: csvRow.cmsUnitId,
                      uname: csvRow.uname,
                      themeId: theme?.id,
                      subthemeId: subtheme?.id,
                      type: IndicatorType.Reporting,
                  } as CreateIndicatorInput)
                : undefined,
        };
    };

    const validatedRows = useMemo(
        () => csvRows.map(validateRow),
        [csvRows, themes, subthemes],
    );

    return validatedRows;
};

export const useCsvImport = (onAddIndicators: (inputs: CSVRow[]) => void) => {
    const onCsvImport = (file: File) => {
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: function (results: any) {
                const csvInputs = results.data.map((row: any) => {
                    const csvRow: CSVRow = {
                        name: row.name || "",
                        uname: row.uname || "",
                        cmsUnitId: row["unit-bv-id"] || "",
                        cmsThemeId: row["theme-bv-id"] || "",
                        cmsSubthemeId: row["subtheme-bv-id"] || "",
                        description: row.description || "",
                    };
                    return csvRow;
                });
                onAddIndicators(csvInputs);
            },
        });
    };

    return onCsvImport;
};
