import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
    DocumentPatch,
    GetDocumentsV2Document,
    UpdateDocumentsV2Document,
} from "../../../../generated/client/graphql";
import {
    useGraphQL,
    useGraphQLMutation,
} from "../../../QueryClientWithHeaders";
import { useSetToast } from "../../../components/Toast";
import "./i18n";

export const useUpdateDocuments = () => {
    const UpdateDocumentsMutation = useGraphQLMutation(
        UpdateDocumentsV2Document,
        {},
        true
    );

    const { t } = useTranslation("UpdateDocuments");

    const client = useQueryClient();
    const { setToastSuccess } = useSetToast();

    const updateDocument = async (
        ids: string[],
        patch: DocumentPatch,
        onSucces?: () => void
    ) => {
        UpdateDocumentsMutation.mutate(
            {
                ids,
                patch,
            },
            {
                onSuccess: () => {
                    setToastSuccess(t("success_message"));
                    client.invalidateQueries({
                        queryKey: useGraphQL.getRootKey(GetDocumentsV2Document),
                    });
                    if (onSucces) {
                        onSucces();
                    }
                },
            }
        );
    };

    return {
        mutate: updateDocument,
        isPending: UpdateDocumentsMutation.isPending,
    };
};
