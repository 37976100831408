import { forwardRef } from "react";
import { ReactComponent as Loading } from "../../../assets/loading-small.svg";
import { cn } from "../../../lib/utils";
import { Tooltip } from "../../Tooltip";
import { ButtonProps } from "../ButtonShared";

export default forwardRef(function ButtonSmallBase(
    {
        className,
        textClassName,
        iconClassName,
        onClick,
        IconLeft,
        IconRight,
        onRightClick,
        active,
        tooltipContent,
        loading,
        ...props
    }: ButtonProps &
        React.ButtonHTMLAttributes<HTMLButtonElement> & {
            buttonClassName?: string;
            textClassName?: string;
            iconClassName?: string;
        },
    ref: React.Ref<HTMLButtonElement>,
) {
    return (
        <Tooltip
            triggerAsChild={true}
            tooltipContent={tooltipContent}
            className="max-w-[200px]"
        >
            <button
                ref={ref}
                className={cn(
                    "h-[28px] min-w-[28px] flex items-center justify-center px-1 py-1 cursor-pointer rounded-lg disabled:bg-neutral-150 max-w-full",
                    className,
                )}
                onClick={!loading ? onClick : undefined}
                {...props}
            >
                {loading && (
                    <div className="text-white animate-spin px-1 shrink-0">
                        <Loading className={iconClassName} />
                    </div>
                )}
                {IconLeft && (
                    <div className={`${loading && "invisible"} p-0.5 shrink-0`}>
                        <IconLeft className={`w-4 h-4 ${iconClassName}`} />
                    </div>
                )}
                {(props.text || props.children) && (
                    <div
                        className={`${
                            loading && "invisible"
                        } text-sm font-bold whitespace-nowrap disabled:text-disabled truncate flex flex-nowrap space-x-2
                ${!IconLeft ? "pl-1" : "pl-0.5"}
                ${!IconRight && "pr-1"} ${textClassName}`}
                    >
                        {props.text}
                        {props.children}
                    </div>
                )}

                {IconRight && (
                    <div
                        className={`${
                            loading && "invisible"
                        } p-0.5 cursor-pointer shrink-0`}
                        onPointerDown={(e) => {
                            if (onRightClick) {
                                e.preventDefault();
                                onRightClick(e);
                            }
                        }}
                    >
                        <IconRight className={`w-4 h-4 ${iconClassName} `} />
                    </div>
                )}
            </button>
        </Tooltip>
    );
});
