import {
    ColumnFiltersState,
    SortingState,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import React, { useState } from "react";
import { columns } from "./columns";

import { Bell } from "iconoir-react";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Datapoint } from "../../../../../generated/client/graphql";
import { searchFilterStringAtom } from "../../../../components/Filters/Search";
import DataTable from "../../../../components/Table/DataTable";
import EmptyTable from "../../../../components/Table/EmptyTable";
import LoadingTable from "../../../../components/Table/LoadingTable";
import "./i18n";

function SupplierDataTableInner({ datapoints }: { datapoints: Datapoint[] }) {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [columnFilters, setColumnFilters] =
        React.useState<ColumnFiltersState>([]);
    const [rowSelection, setRowSelection] = React.useState({});

    const [searchFilterString, setSearchFilterString] = useAtom(
        searchFilterStringAtom
    );

    // const filterUserIds = useAtomValue(filterUserIdsAtom);
    // React.useEffect(() => {
    //     table.getColumn("owner")!.setFilterValue(filterUserIds);
    // }, [filterUserIds]);

    // const filterEntityIds = useAtomValue(filterEntityIdsAtom);
    // React.useEffect(() => {
    //     table.getColumn("site")!.setFilterValue(filterEntityIds);
    // }, [filterEntityIds]);

    // const filterTagIds = useAtomValue(filterTagIdsAtom);
    // React.useEffect(() => {
    //     table.getColumn("tags")!.setFilterValue(filterTagIds);
    // }, [filterTagIds]);

    // const filterSubthemeIds = useAtomValue(filterSubthemeIdsAtom);
    // React.useEffect(() => {
    //     table.getColumn("subtheme")!.setFilterValue(filterSubthemeIds);
    // }, [filterSubthemeIds]);

    const table = useReactTable<Datapoint>({
        data: (datapoints || []) as Datapoint[],
        columns,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onGlobalFilterChange: setSearchFilterString,
        onRowSelectionChange: setRowSelection,
        autoResetPageIndex: false,
        state: {
            sorting,
            columnFilters,
            rowSelection,
            globalFilter: searchFilterString,
            columnVisibility: { tags: false },
        },
    });

    return <DataTable table={table} />;
}

export default function SupplierDataTable({
    datapoints,
    isLoading,
}: {
    datapoints: Datapoint[];
    isLoading: boolean;
}) {
    const { t } = useTranslation("SupplierDatapointTable");
    const navigate = useNavigate();
    const params = useParams();

    return !datapoints && isLoading ? (
        <LoadingTable />
    ) : datapoints.length === 0 ? (
        <EmptyTable
            Icon={Bell}
            smallText={t("empty_table_small_text")}
            buttonText={t("empty_table_button_text")}
            onClick={() =>
                navigate(`/o/${params.org_uname}/admin/supplier/suppliers`)
            }
        />
    ) : datapoints ? (
        <SupplierDataTableInner datapoints={datapoints as Datapoint[]} />
    ) : (
        <div>Error, refresh ...</div>
    );
}
