import i18next from "i18next";

i18next.addResourceBundle("en", "ReportingPage", {
    page_title: "Reporting",
    page_subtitle: "Manage your reporting campaigns",
    add_period_button_title: "Add new period",
    periods_title: "Periods:",
    no_period_warning: "Select a period to see datapoints",
    no_period: "Add a new period to see datapoints",
    add_period_modal_title: "Add new periods",
    add_period_modal_confirm: "Confirm",
    add_period_modal_no_more_periods: "No more periods to add",
    indicator_table_indicator_column_title: "Indicator",
    indicator_table_requirements_column_title: "Requirements",
    indicator_table_frameworks_column_title: "Frameworks",
    indicator_table_tags_column_title: "Tags",
    indicator_table_subtheme_column_title: "Subtheme",
    indicator_table_assigned_to_column_title: "Owner",
    indicator_table_action_send_reminder_action: "Send reminder",
    indicator_table_action_send_reminder: "Send a reminder to {{count}} users",
    indicator_table_action_assign_owner_action: "Assign to",
    indicator_table_action_assign_owner: "Assign {{count}} indicators",
    indicator_table_action_mark_not_relevant_action: "Not relevant",
    indicator_table_action_mark_not_relevant:
        "Mark {{count}} indicators as not relevant",
    indicator_table_action_selection: "Selection ({{count}})",
    indicator_table_action_select_action: "Select action",
    indicator_table_action_select_act: "Select action",
    apply: "Apply",
    indicator_table_action_update_indicator_success:
        "Successfully updated indicators",
    see_not_relevant: "See {{count}} not relevant",
    not_relevant_modal_title: "Indicators marked as not relevant",
    set_relevant: "Mark {{count}} as relevant",
    no_data_available: "No data",
    no_data_available_checkbox: "No data available",
    comment_trigger: "Comment",
    update_success: "Data updated successfully",
    update_error: "Error updating data",
    export_reporting: "Export reporting",
    export_reporting_files: "Export reporting files",
    period_type: "Type",
    year: "Year",
    Year: "Year",
    month: "Month",
    Month: "Month",
    quarter: "Quarter",
    add_period_success: "Period added successfully",
    add_period_error:
        "Error adding period. Make sure the period does not already exist",
    select_periods: "Select periods",
    cancel: "Cancel",
    select: "Select",
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
    add_custom_indicators: "Add custom indicators",
    create_indicators_success: "Success creating {{count}} indicators",
    create_indicators_error: "Error creating indicators",
});

i18next.addResourceBundle("fr", "ReportingPage", {
    page_title: "Reporting",
    page_subtitle: "Gérez vos campagnes de reporting",
    add_period_button_title: "Ajouter une période",
    periods_title: "Périodes:",
    no_period_warning: "Sélectionnez une période pour voir les données",
    no_period: "Ajouter une nouvelle période pour voir les points de données",
    add_period_modal_title: "Ajouter des nouvelles périodes",
    add_period_modal_confirm: "Confirmer",
    add_period_modal_no_more_periods: "Plus de périodes à ajouter",
    indicator_table_indicator_column_title: "Indicateur",
    indicator_table_requirements_column_title: "Contrôles",
    indicator_table_frameworks_column_title: "Référentiels",
    indicator_table_tags_column_title: "Tags",
    indicator_table_subtheme_column_title: "Sous thème",
    indicator_table_assigned_to_column_title: "Propriétaire",
    indicator_table_action_send_reminder_action: "Envoyer un rappel",
    indicator_table_action_send_reminder:
        "Envoyer un rappel à {{count}} utilisateurs",
    indicator_table_action_assign_owner_action: "Assigner à",
    indicator_table_action_assign_owner: "Assigner {{count}} indicateurs",
    indicator_table_action_update_indicator_success:
        "Indicateurs mis à jour avec succès",
    indicator_table_action_mark_not_relevant_action: "Non pertinent",
    indicator_table_action_mark_not_relevant:
        "Marquer {{count}} indicateurs comme non pertinents",
    indicator_table_action_selection: "Sélection ({{count}})",
    indicator_table_action_select_action: "Choisir une action",
    apply: "Enregistrer",
    see_not_relevant: "Voir {{count}} non pertinents",
    not_relevant_modal_title: "Indicateurs marqués comme non pertinents",
    set_relevant: "Marquer {{count}} comme pertinents",
    no_data_available: "Pas de donnée",
    no_data_available_checkbox: "Donnée non disponible",
    comment_trigger: "Commentaire",
    update_success: "Données mises à jour avec succès",
    update_error: "Erreur lors de la mise à jour des données",
    export_reporting: "Exporter le reporting",
    export_reporting_files: "Exporter les fichiers de reporting",
    period_type: "Type",
    year: "Année",
    Year: "Année",
    month: "Mois",
    Month: "Mois",
    quarter: "Quarter",
    add_period_success: "Periode ajoutée avec succès",
    add_period_error:
        "Erreur lors de l'ajout de la période. Assurez vous qu'elle n'éxiste pas déjà",
    select_periods: "Sélectionner des periodes",
    cancel: "Annuler",
    select: "Sélectionner",
    January: "Janvier",
    February: "Février",
    March: "Mars",
    April: "Avril",
    May: "Mai",
    June: "Juin",
    July: "Juillet",
    August: "Août",
    September: "Septembre",
    October: "Octobre",
    November: "Novembre",
    December: "Décembre",
    add_custom_indicators: "Ajouter des indicateurs personnalisés",
    create_indicators_success: "Success creating {{count}} indicators",
    create_indicators_error: "Error creating indicators",
});
