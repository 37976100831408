import {
    defaultProgress,
    type OnboardingProgress,
    type OnboardingProgressRepository,
} from "../OnboardingProgressRepository";

export class LocalStorageOnboardingProgressRepository
    implements OnboardingProgressRepository
{
    private readonly onboardingProgressKey = "onboardingProgress";
    private readonly sectionCollapsedKey = "sectionCollapsed";

    getOnboardingProgress = (): OnboardingProgress => {
        const data = localStorage.getItem(this.onboardingProgressKey);
        try {
            if (data) {
                return JSON.parse(data) as OnboardingProgress;
            }
            return defaultProgress;
        } catch (e) {
            console.error(e);
            return defaultProgress;
        }
    };

    setOnboardingProgress = (
        progress: OnboardingProgress = defaultProgress
    ) => {
        localStorage.setItem(
            this.onboardingProgressKey,
            JSON.stringify(progress)
        );
    };

    getIsOnboardingSectionCollapsed = () => {
        const data = localStorage.getItem(this.sectionCollapsedKey);
        try {
            if (data) {
                return JSON.parse(data) as boolean;
            }
            return false;
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    setIsOnboardingSectionCollapsed = (isCollapsed: boolean) => {
        localStorage.setItem(
            this.sectionCollapsedKey,
            JSON.stringify(isCollapsed)
        );
    };
}
