import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DocumentAcceptance } from "../../../../../generated/client/graphql";
import FilterTrigger from "../../components/triggers/Filter";
import DocumentModeDropdown from "./dropdown";
import "./i18n";

export const documentModeFilterAtom = atom<DocumentAcceptance | undefined>(
    undefined
);
const DocumentModeFilter = () => {
    const [mode, setMode] = useAtom(documentModeFilterAtom);
    const { t } = useTranslation("DocumentModeDropdown");

    useEffect(() => {
        setMode(undefined);
    }, []);
    return (
        <DropdownMenu>
            <FilterTrigger
                unsetCallback={mode ? () => setMode(undefined) : undefined}
                active={mode !== undefined}
            >
                {mode ? t(mode) : t("placeholder")}
            </FilterTrigger>
            <DocumentModeDropdown mode={mode} onModeChange={setMode} />
        </DropdownMenu>
    );
};

export default DocumentModeFilter;
