import { forwardRef } from "react";
import { ReactComponent as Loading } from "../../../assets/loading.svg";
import { cn } from "../../../lib/utils";
import { ButtonProps } from "../ButtonShared";

export default forwardRef(function ButtonLargeBase(
    {
        className,
        textClassName,
        iconClassName,
        onClick,
        IconLeft,
        IconRight,
        onRightClick,
        ...props
    }: ButtonProps &
        React.ButtonHTMLAttributes<HTMLButtonElement> & {
            buttonClassName?: string;
            textClassName?: string;
            iconClassName?: string;
        },
    ref: React.Ref<HTMLButtonElement>,
) {
    return (
        <div>
            <button
                ref={ref}
                className={cn(
                    `h-[44px] flex items-center justify-center p-3 cursor-pointer rounded-lg disabled:bg-neutral-150 space-x-2`,
                    className,
                )}
                onClick={!props.loading ? onClick : undefined}
                {...props}
            >
                {props.loading && (
                    <div className="absolute text-white animate-spin shrink-0">
                        <Loading className={cn("h-5 w-5", iconClassName)} />
                    </div>
                )}
                {IconLeft && (
                    <div className={`${props.loading && "invisible"} shrink-0`}>
                        <IconLeft className={`w-5 h-5 ${iconClassName}`} />
                    </div>
                )}
                {(props.text || props.children) && (
                    <div
                        className={cn(
                            props.loading && "invisible",
                            "text-regular font-bold whitespace-nowrap disabled:text-disabled",
                            !IconLeft && "pl-1",
                            !IconRight && "pr-1",
                            textClassName,
                        )}
                    >
                        {props.text}
                        {props.children}
                    </div>
                )}

                {IconRight && (
                    <div
                        className={cn(
                            props.loading && "invisible",
                            props.text && "pr-1",
                            "cursor-pointer shrink-0",
                        )}
                        onPointerDown={(e) => {
                            if (onRightClick) {
                                e.preventDefault();
                                onRightClick(e);
                            }
                        }}
                    >
                        <IconRight className={cn("w-5 h-5", iconClassName)} />
                    </div>
                )}
            </button>
        </div>
    );
});
