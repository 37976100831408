interface GaugeCircularProps {
    progress: number;
    size: number;
    children?: React.ReactNode;
    width?: number;
}

export const GaugeCircular = ({
    size,
    progress,
    width,
    children,
}: GaugeCircularProps) => {
    const trackColor = `#E6E6E6`;
    const indicatorColor = `#03A365`;
    const indicatorCap = `round`;
    const center = size / 2;

    const indicatorWidth = width || 2;
    const trackWidth = width || 2;
    const radius =
        center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth);
    const dashArray = 2 * Math.PI * radius;
    const dashOffset = dashArray * ((100 - progress) / 100);

    return (
        <>
            <div className="relative " style={{ width: size, height: size }}>
                <svg
                    className="rotate-[-90deg] svg-pi"
                    style={{ width: size, height: size }}
                >
                    <circle
                        className="svg-pi-track"
                        cx={center}
                        cy={center}
                        fill="transparent"
                        r={radius}
                        stroke={trackColor}
                        strokeWidth={trackWidth}
                    />
                    <circle
                        cx={center}
                        cy={center}
                        fill="transparent"
                        r={radius}
                        stroke={indicatorColor}
                        strokeWidth={indicatorWidth}
                        strokeDasharray={dashArray}
                        strokeDashoffset={dashOffset}
                        strokeLinecap={indicatorCap}
                    />
                </svg>
                <span className="absolute font-bold inset-0 flex items-center justify-center">
                    {children}
                </span>
            </div>
        </>
    );
};
