import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { UserMinus } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { User } from "../../../../../generated/client/graphql";
import { cn } from "../../../../lib/utils";
import Avatar from "../../../User/Avatar";
import DropdownItemSearch from "../../components/dropdowns/items/Search";
import DropdownContentLayout from "../../components/dropdowns/layout";

const UserDropdown = ({
    selectedUserIds,
    onUserIdChange,
    users,
    preventDefault,
    ...props
}: {
    selectedUserIds?: (string | null)[];
    onUserIdChange: (userId: string | undefined) => void;
    users: User[];
    preventDefault?: boolean;
} & DropdownPrimitive.DropdownMenuContentProps) => {
    const [searchFilterString, setSearchFilterString] = useState("");

    const { t } = useTranslation("UserDropdown");
    return (
        <DropdownContentLayout {...props}>
            <DropdownItemSearch
                searchFilterString={searchFilterString}
                setSearchFilterString={setSearchFilterString}
            />
            <DropdownPrimitive.Item
                className="h-[32px] cursor-pointer flex px-3 space-x-3 items-center text-sm text-secondary focus:outline-none focus:bg-green-50"
                onClick={(e) => {
                    onUserIdChange(undefined);
                    e.stopPropagation();
                }}
            >
                <UserMinus className="w-4 h-4" />
                <span>{t("unset")}</span>
            </DropdownPrimitive.Item>
            {users
                .filter(
                    ({ firstName, lastName, email }) =>
                        !searchFilterString ||
                        [firstName, lastName, email].some(
                            (s) =>
                                s &&
                                s
                                    .toLowerCase()
                                    .includes(searchFilterString.toLowerCase())
                        )
                )
                .map((user, idx) => (
                    <DropdownPrimitive.Item
                        key={idx}
                        onClick={(e) => {
                            onUserIdChange(user.id);
                            e.stopPropagation();
                            preventDefault && e.preventDefault();
                        }}
                        className={cn(
                            "flex items-center px-2 py-1.5 space-x-2.5 rounded-lg focus:outline-none focus:bg-green-50 cursor-pointer",
                            selectedUserIds?.includes(user.id) && "bg-green-50"
                        )}
                    >
                        <Avatar
                            avatarUrl={user.avatar?.signedUrl}
                            initials={user.initials}
                            size="small"
                        />
                        <div className="text-secondary text-sm font-bold truncate">
                            {user.firstName} {user.lastName}
                        </div>
                    </DropdownPrimitive.Item>
                ))}
        </DropdownContentLayout>
    );
};

export default UserDropdown;
