import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { useTranslation } from "react-i18next";
import { RequirementStatus } from "../../../../../generated/client/graphql";
import FilterTrigger from "../../components/triggers/Filter";
import RequirementStatusDropdown from "./dropdown";
import "./i18n";

const RequirementStatusFilter = ({
    status,
    onStatusChange,
}: {
    status: RequirementStatus[] | undefined;
    onStatusChange: (status: RequirementStatus[] | undefined) => void;
}) => {
    const { t } = useTranslation("RequirementStatusDropdown");

    return (
        <DropdownMenu>
            <FilterTrigger
                unsetCallback={() => onStatusChange(undefined)}
                active={status !== undefined}
            >
                {status ? t(status) : t("placeholder")}
            </FilterTrigger>
            <RequirementStatusDropdown
                status={status}
                onStatusChange={onStatusChange}
            />
        </DropdownMenu>
    );
};

export default RequirementStatusFilter;
