import DataTable from "@app/components/Table/DataTable";
import LoadingTable from "@app/components/Table/LoadingTable";
import { useNavigation } from "@app/lib/navigation";
import {
    filteredReportingContributorsAtom,
    selectedUsersAtom,
} from "@app/store/reportingStore";
import {
    Row,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { User } from "generated/client/graphql";
import { useAtom } from "jotai";
import { type FC } from "react";
import { useColumns } from "./columns";

const ReportingProgressTable: FC = () => {
    const [{ data, isPending }] = useAtom(filteredReportingContributorsAtom);
    const columns = useColumns();

    const table = useReactTable<User>({
        data: (data as User[]) || [],
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        autoResetPageIndex: false,
    });
    const { navigateFromHome } = useNavigation();
    const [_, setSelectedUsers] = useAtom(selectedUsersAtom);
    const redirectToFilteredOverview = (row: Row<User>) => {
        setSelectedUsers([row.original?.id]);
        navigateFromHome("reporting/overview");
    };

    return (
        <>
            {isPending ? (
                <LoadingTable />
            ) : (
                <DataTable
                    onRowClick={(row) => {
                        redirectToFilteredOverview(row);
                    }}
                    table={table}
                    rowClassName={() =>
                        "hover:bg-secondary h-[64px] cursor-pointer"
                    }
                    type="no-v-padding"
                />
            )}
        </>
    );
};

export default ReportingProgressTable;
