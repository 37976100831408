import { calculatePercentage, roundNumber } from "@app/shared/utils/math";
import {
    CsrdPillar,
    GetCsrdPillarDataQuery,
    GetCsrdPillarsQuery,
    ProgressStats,
    type GetCsrdStatsQuery,
} from "@generated/client/graphql";
import {
    CsrdDatapoint,
    CsrdDatapointInfo,
    EnrichedCsrdDatapoint,
    type CmsCsdrDisclosureRequirement,
    type CmsCsdrEsrs,
    type CompletionStat,
    type Pillar,
} from "./types";

export const CSRD_ID = "csrd";

export const completionsStatsKeys: CompletionStat[] = [
    "validated",
    "inProgress",
    "notStarted",
    "notMaterial",
];

export const statsColors: Record<CompletionStat, string> = {
    validated: "#03A365",
    inProgress: "#5E89AE",
    notStarted: "#89B3D0",
    notMaterial: "#E6E6E6",
};

export function makeCompletionStatOption(
    stats: GetCsrdStatsQuery["csrdStats"] | Pillar["stats"],
) {
    const categories = completionsStatsKeys.map((key) => {
        return {
            value: stats[key],
            color: statsColors[key],
            label: key,
        };
    });

    const completed = stats.validated + stats.notMaterial;

    return {
        percentage: roundNumber(calculatePercentage(completed, stats.total)),
        total: stats.total,
        completed,
        categories,
    };
}

export function makeDrCompletionStats(
    esrss: GetCsrdPillarDataQuery["pillar"]["esrss"],
) {
    return esrss
        .map((esrs) => {
            return esrs.disclosureRequirements.map((dr) => {
                return {
                    drCmsId: dr.cmsId,
                    stats: makeCompletionStatOption(dr.stats),
                };
            });
        })
        .flat();
}

export function makePillars(pillars?: GetCsrdPillarsQuery["pillars"]) {
    const byId: Record<string, { cmsId: string; name: string }> = pillars
        ? pillars.reduce(
              (acc, pillar) => {
                  acc[pillar.cmsId] = pillar;
                  return acc;
              },
              {} as Record<string, { cmsId: string; name: string }>,
          )
        : {};
    return {
        byId,
        list: pillars,
    };
}

export function makeCmsDisclosureRequirements(esrss?: CmsCsdrEsrs[]) {
    const list = esrss?.map((esrs) => esrs.disclosureRequirements).flat() ?? [];

    const { byEsrsId, byId } = esrss?.reduce(
        (acc, esrs) => {
            if (!esrs.disclosureRequirements || !esrs.cmsId) return acc;

            acc.byEsrsId[esrs.cmsId] = esrs.disclosureRequirements;
            esrs.disclosureRequirements.forEach((dr) => {
                if (!dr.cmsId) return;
                acc.byId[dr.cmsId] = dr;
            });
            return acc;
        },
        { byId: {}, byEsrsId: {} } as {
            byId: Record<string, CmsCsdrDisclosureRequirement>;
            byEsrsId: Record<string, CmsCsdrDisclosureRequirement[]>;
        },
    ) ?? { byEsrsId: {}, byId: {} };

    return esrss ? { byId, list, byEsrsId } : undefined;
}

export function getCsrdAssignmentStats(stats: ProgressStats | undefined) {
    return {
        total: (stats?.total ?? 0) - (stats?.notMaterial ?? 0),
        assigned: stats?.assigned ?? 0,
        unassigned:
            (stats?.total ?? 0) -
            (stats?.notMaterial ?? 0) -
            (stats?.assigned ?? 0),
    };
}

export function getPillarsValidationStats(pillars: CsrdPillar[] | undefined) {
    return pillars?.map((pillar) => {
        return Math.round(
            ((pillar.stats.validated + pillar.stats.notMaterial) /
                pillar.stats.total) *
                100,
        );
    });
}

export function getEsrsDisplayStats(pillars: CsrdPillar[] | undefined) {
    let esrsDisplayStats: Record<string, Record<string, string>> = {};
    pillars?.forEach((pillar) => {
        pillar.esrss.forEach((esrs) => {
            esrsDisplayStats = {
                ...esrsDisplayStats,
                [esrs.name]: {
                    validRatio: `${esrs.stats.validated + esrs.stats.notMaterial} / ${esrs.stats.total}`,
                    validPercentage:
                        esrs.stats.total === 0
                            ? "0%"
                            : `${Math.round(((esrs.stats.validated + esrs.stats.notMaterial) / esrs.stats.total) * 100)}%`,
                    validLabel: `${esrs.stats.validated} validated (${Math.round(
                        (esrs.stats.validated / esrs.stats.total) * 100,
                    )}%)`,
                    inProgressLabel: `${esrs.stats.inProgress} in progress (${Math.round(
                        (esrs.stats.inProgress / esrs.stats.total) * 100,
                    )}%)`,
                    notStartedLabel: `${esrs.stats.notStarted} not started (${Math.round(
                        (esrs.stats.notStarted / esrs.stats.total) * 100,
                    )}%)`,
                    notMaterialLabel: `${esrs.stats.notMaterial} not material (${Math.round(
                        (esrs.stats.notMaterial / esrs.stats.total) * 100,
                    )}%)`,
                },
            };
        });
    });
    return esrsDisplayStats;
}

export function filterPillarData(
    data: GetCsrdPillarDataQuery["pillar"],
): GetCsrdPillarDataQuery["pillar"] {
    return {
        ...data,
        esrss: data.esrss
            .map((esrs) => ({
                ...esrs,
                disclosureRequirements: esrs.disclosureRequirements.filter(
                    (dr) => !!dr.datapoints.length,
                ),
            }))
            .filter((esrs) => !!esrs.disclosureRequirements.length),
    };
}

export function cleanEsrsId(esrsId: string) {
    return esrsId.replace("bv-csrd-", "")?.toLocaleUpperCase();
}

export function cleanDrId(drId: string) {
    return drId.replace("bv-csrd-dr-", "")?.toLocaleUpperCase();
}

export function enrichDatapoints(
    datapoints: CsrdDatapoint[] | undefined,
): EnrichedCsrdDatapoint[] | undefined {
    return datapoints?.map((datapoint) => {
        const isNotMaterial = datapoint?.status === "NOT_MATERIAL";
        const hasNoData = datapoint?.value?.noData === true;
        const isNarrative = datapoint.type === "NARRATIVE";
        const isSeminarNarrative = datapoint.type === "SEMINARRATIVE";
        const hasNumberValue =
            datapoint?.value?.number !== undefined &&
            datapoint?.value?.number !== null;
        const hasBooleanValue =
            datapoint?.value?.boolean !== undefined &&
            datapoint?.value?.boolean !== null;
        const datapointInfo: CsrdDatapointInfo = {
            isNotMaterial,
            hasNoData,
            isNarrative,
            isSeminarNarrative,
            hasNumberValue,
            hasBooleanValue,
        };
        return {
            ...datapoint,
            info: datapointInfo,
        };
    });
}
