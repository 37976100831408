import i18next from "../../../../i18n";

i18next.addResources("en", "CreateDocumentVersionBlock", {
    start_button: "Start",

    MASTER_POLICY_start_new_version_title_first: "Create my policy",
    MASTER_POLICY_start_new_version_title: "Start a new version of my policy",
    MASTER_POLICY_start_new_version_desc_first:
        "Welcome to your policy page. Build the initial version of your policy with our tools specially optimized for you. ",
    MASTER_POLICY_start_new_version_desc:
        "Effortlessly update the latest version of your policy thanks to our optimized tools.",

    POLICY_start_new_version_title_first: "Set objectives",
    POLICY_start_new_version_title: "Renew objectives",
    POLICY_start_new_version_desc_first:
        "Define your commitments and set the objectives you want to achieve.",
    POLICY_start_new_version_desc:
        "Update the objectives and commitments to adapt them to your new challenges.",

    PROCEDURE_start_new_version_title_first: "Create my procedure",
    PROCEDURE_start_new_version_title: "Start a new version of my procedure",
    PROCEDURE_start_new_version_desc_first:
        "Welcome to your procedure page. Build the initial version of your procedure with our tools specially optimized for you. ",
    PROCEDURE_start_new_version_desc:
        "Effortlessly update the latest version of your procedure.",

    MISC_start_new_version_title_first: "Create my document",
    MISC_start_new_version_title: "Start a new version of my document",
    MISC_start_new_version_desc_first:
        "Welcome to your document page. Build the initial version of your document with our tools specially optimized for you. ",
    MISC_start_new_version_desc:
        "Effortlessly update the latest version of your document.",

    CUSTOM_DOCUMENT_start_new_version_title_first: "Create my document",
    CUSTOM_DOCUMENT_start_new_version_title:
        "Start a new version of my document",
    CUSTOM_DOCUMENT_start_new_version_desc_first:
        "Welcome to your document page. Build the initial version of your document with our tools specially optimized for you. ",
    CUSTOM_DOCUMENT_start_new_version_desc:
        "Effortlessly update the latest version of your document.",

    get_started: "Let's get started",
});
i18next.addResources("fr", "CreateDocumentVersionBlock", {
    start_button: "Commencer",

    MASTER_POLICY_start_new_version_title_first: "Créer ma politique",
    MASTER_POLICY_start_new_version_title:
        "Créer une nouvelle version de ma politique",
    MASTER_POLICY_start_new_version_desc_first:
        "Construisez la première version de votre politique.",
    MASTER_POLICY_start_new_version_desc:
        "Mettez à jour la dernière version de votre politique.",

    POLICY_start_new_version_title_first: "Définir des objectifs",
    POLICY_start_new_version_title: "Mettre à jour les objectifs",
    POLICY_start_new_version_desc_first:
        "Définissez vos engagements et fixez les objectifs que vous souahitez atteindre.",
    POLICY_start_new_version_desc:
        "Mettez à jour les objectifs et engagements pour les adapter à vos nouveaux enjeux.",

    PROCEDURE_start_new_version_title_first: "Créer ma procédure",
    PROCEDURE_start_new_version_title:
        "Créer une nouvelle version de ma procédure",
    PROCEDURE_start_new_version_desc_first:
        "Construisez la première version de votre procédure.",
    PROCEDURE_start_new_version_desc:
        "Mettez à jour la dernière version de votre procédure en toute simplicité.",

    MISC_start_new_version_title_first: "Créer mon document",
    MISC_start_new_version_title: "Créer une nouvelle version de mon document",
    MISC_start_new_version_desc_first:
        "Construisez la première version de votre document.",
    MISC_start_new_version_desc:
        "Mettez à jour la dernière version de votre document.",

    CUSTOM_DOCUMENT_start_new_version_title_first: "Créer mon document",
    CUSTOM_DOCUMENT_start_new_version_title:
        "Créer une nouvelle version de mon document",
    CUSTOM_DOCUMENT_start_new_version_desc_first:
        "Construisez la première version de votre document.",
    CUSTOM_DOCUMENT_start_new_version_desc:
        "Mettez à jour la dernière version de votre document.",

    get_started: "Attention, c'est parti",
});
