
import { type FrameworkSection } from "@generated/client/graphql";
import RequirementRow from "./Requirement";

const SectionBlock = ({
    groupName,
    subgroupName,
    section,
    anchor,
}: {
    groupName: string;
    subgroupName: string;
    section: FrameworkSection;
    anchor: string;
}) => {
    return (
        <div id={anchor} data-anchorid={anchor} className="space-y-3 ">
            <div className="px-2">
                <div className="text-sm">
                    {groupName} / {subgroupName}
                </div>
                <div className="font-bold">{section.name}</div>
            </div>
            {!!section.requirements.length && (
                <div className="rounded-lg overflow-hidden border border-secondary">
                    {section.requirements?.map((requirement) => (
                        <RequirementRow requirement={requirement} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default SectionBlock;
