import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { atom, useAtomValue } from "jotai";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "../../../../i18n";
import { cn } from "../../../../lib/utils";
dayjs.extend(relativeTime);

i18next.addResourceBundle("en", "LastSavedBanner", {
    message: "Your data was automatically saved",
    error: "Error saving your last datapoint. Please try again.",
});

i18next.addResourceBundle("fr", "LastSavedBanner", {
    message: "Vos données ont été enregistrées automatiquement",
    error: "Erreur lors de l'enregistrement de votre dernière donnée. Veuillez réessayer.",
});

interface LastSavedBannerState {
    lastSaveTime?: Date;
    error?: boolean;
}
export const saveBannerStateAtom = atom<LastSavedBannerState>({
    lastSaveTime: undefined,
    error: false,
});

const LastSavedBanner = (props: { color?: chroma.Color }) => {
    const { t } = useTranslation("LastSavedBanner");

    const { lastSaveTime, error } = useAtomValue(saveBannerStateAtom);
    useEffect(() => {
        const interval = setInterval(() => {}, 10000);
        return () => clearInterval(interval);
    }, []);
    return lastSaveTime || error ? (
        <div
            className={cn(
                "h-[40px] w-full flex items-center justify-center text-sm font-bold text-white ",
                error ? "bg-red" : "bg-green"
            )}
            style={{
                backgroundColor: props.color?.css(),
            }}
        >
            {lastSaveTime && `${t("message")} ${dayjs(lastSaveTime).fromNow()}`}
            {error && t("error")}
        </div>
    ) : (
        <></>
    );
};
export default LastSavedBanner;
