import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { Plus } from "iconoir-react";
import {
    GetAllSupplierQuestionnaireTemplatesDocument,
    SupplierQuestionnaireTemplate,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import { ButtonType } from "../../../Button/ButtonShared";
import ButtonXSmall from "../../../Button/ButtonXSmall";
import { DropdownTrigger } from "../../components";
import SupplierQuestionnaireTemplatesDropdownMultiSelect from "./dropdown_multi_select";

const TemplatesSetter = ({
    templateIds,
    onTemplateIdsChange,
    open,
    setOpen,
}: {
    templateIds?: string[] | undefined;
    onTemplateIdsChange: (templateIds: string[]) => void;
    open: boolean;
    setOpen: (open: boolean) => void;
}) => {
    const getTemplates = useGraphQL(
        GetAllSupplierQuestionnaireTemplatesDocument,
        undefined,
        {},
        true
    );

    return (
        <DropdownMenu open={open} onOpenChange={setOpen}>
            <DropdownTrigger asChild>
                <ButtonXSmall
                    IconLeft={Plus}
                    variant={ButtonType.FLAT_PRIMARY}
                />
            </DropdownTrigger>
            <SupplierQuestionnaireTemplatesDropdownMultiSelect
                templateIds={templateIds}
                onTemplateIdsChange={onTemplateIdsChange}
                templates={
                    getTemplates.data?.supplierQuestionnaireTemplates as
                        | SupplierQuestionnaireTemplate[]
                        | undefined
                }
            />
        </DropdownMenu>
    );
};

export default TemplatesSetter;
