import { useAtom } from "jotai";
import { atomWithHash } from "jotai-location";
import { useTranslation } from "react-i18next";
import MultiFilter from "..";
import { GetAllUsersDocument } from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import Avatar from "../../../User/Avatar";
import "./i18n";

interface AssignedToFilterProps {
    placeholder?: string;
}

export const filterUserIdsAtom = atomWithHash<(string | null)[] | undefined>(
    "id",
    undefined
);
export default function AssignedToFilter({
    placeholder,
}: AssignedToFilterProps) {
    const { t } = useTranslation("AssignedToFilter");
    const { data } = useGraphQL(GetAllUsersDocument, undefined, {}, true);
    const [userIds, setUserIds] = useAtom(filterUserIdsAtom);

    return (
        <MultiFilter
            activeValueIds={userIds}
            setActiveValueIds={setUserIds}
            placeholder={placeholder || t("assign_to")}
            values={data?.users.map((user) => ({
                id: user.id,
                name: `${user.firstName} ${user.lastName}`,
                node: (
                    <>
                        <div className="w-full items-center flex justify-between">
                            <div className="text-sm font-regular group-data-[state=checked]:font-bold group-focus:!text-green text-primary truncate">
                                {user.firstName} {user.lastName}
                            </div>
                            <Avatar
                                avatarUrl={user.avatar?.signedUrl}
                                initials={user.initials}
                                size="small"
                            />
                        </div>
                    </>
                ),
            }))}
        />
    );
}
