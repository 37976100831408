import { Combobox } from "@headlessui/react";
import { ChangeEvent, Dispatch, useEffect, useState } from "react";
import { cn } from "../../../lib/utils";

interface IProps {
    label: string;
    input: string | undefined;
    setInput: Dispatch<string | undefined>;
    onBlurCallback?: () => void;
    hint?: string;
    disabled?: boolean;
    error?: string | undefined;
    pressed?: boolean;
    useCombobox?: boolean;
    displayValue?: (input: any) => string;
    type?: string;
}
const InputSimple = ({
    input,
    setInput,
    label,
    onBlurCallback,
    hint,
    disabled,
    error,
    pressed,
    useCombobox,
    displayValue,
    type,
}: IProps) => {
    const [inputString, setInputString] = useState(input || "");

    const marginWithoutInput = error || pressed ? "my-[12px]" : "my-[13px]";
    const paddingLeft = error || pressed ? "pl-[11px]" : "pl-3";

    const inputProps = {
        type: type || "text",
        className: cn(
            "flex-grow w-full h-5 p-0 border-0 focus:ring-0 font-default text-sm text-primary",
            input ? "py-1" : marginWithoutInput,
            disabled && "bg-neutral-50"
        ),
        value: inputString,
        disabled: disabled,
        placeholder: input ? "" : label,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
            setInputString(e.target.value);
            const val = e.target.value.trimStart();
            setInput(val !== "" ? val : undefined);
        },
        ...(onBlurCallback && { onBlur: onBlurCallback }),
        ...(displayValue && { displayValue: displayValue }),
    };
    const inputEl = useCombobox ? (
        <Combobox.Input {...inputProps} />
    ) : (
        <input {...inputProps} />
    );

    useEffect(() => {
        setInputString(input || "");
    }, [input]);
    return (
        <div className="w-full bg-white">
            <div
                className={`group w-full h-[52px] py-1.5 px-3 ${paddingLeft} rounded border ${
                    !pressed &&
                    !error &&
                    !disabled &&
                    "border-neutral-200 focus-within:border-neutral-700"
                }
        ${pressed && "border-green-400 border-[2px]"}
        ${error && "border-red-500 border-[2px]"}
        ${disabled && "bg-neutral-50 border-neutral-150"}`}
            >
                {input && (
                    <p
                        className={`h-4 ${
                            error
                                ? "text-red-500"
                                : "text-neutral-500 group-focus-within:text-primary"
                        }`}
                    >
                        {label}
                    </p>
                )}
                {inputEl}
            </div>
            {hint && !error && (
                <div className="mt-1 h-4">
                    <p className={`text-neutral-500`}>{hint}</p>
                </div>
            )}
            {error && (
                <div className="mt-1 h-4">
                    <p className={`text-error-500`}>{error}</p>
                </div>
            )}
        </div>
    );
};

export default InputSimple;
