import { atom } from "jotai";
import { AccessHeadersHolder } from "@app/repositories/AccessHeadersHolder";

/**
 * Read Atoms
 */
const bearerAtom = atom<string | undefined>(undefined);
export const bearerAtomReadonly = atom((get) => get(bearerAtom));
export const bearerTokenIsSetAtom = atom(
    (get) => get(bearerAtomReadonly) !== undefined,
);
export const setBearerAtom = atom(
    null,
    (_get, set, arg: string | undefined) => {
        set(bearerAtom, arg);
        AccessHeadersHolder.setBearer(arg);
    },
);
