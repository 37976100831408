import AssignementCard from "@app/components/BodyAnalytics/AssignementCard";
import {
    Header,
    HeaderFullSkeleton,
} from "@app/screens/Frameworks/framework/components/Header";
import {
    useFramework,
    useFrameworkBreadcrumb,
} from "@app/screens/Frameworks/framework/hooks/framework";
import { Button } from "@design-system/Inputs/Button";
import SearchBar from "@design-system/Inputs/SearchBar";
import { FlexCol, FlexRow } from "@design-system/Layout/Flex";
import { PageContainer } from "@design-system/Layout/PageContainer";
import { Tabs } from "@design-system/Tabs";
import { useAtom } from "jotai";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DataTab } from "./components/DataTab";
import { DatapointsCompletionCard } from "./components/DatapointsCompletionCard";
import { OverviewTab } from "./components/OverviewTab";
import { Tab, activeTabAtom, datapointSearchStrAtom } from "./ctx";
import { useCsrdStats } from "./data";
import "./i18n";
import { RESOURCE_NAME, keys } from "./i18n";
import { CSRD_ID } from "./services";

export const CsrdGeneral = () => {
    const { t } = useTranslation(RESOURCE_NAME);
    const [activeTab, setActiveTab] = useAtom(activeTabAtom);
    const [searchString, setSearchString] = useAtom(datapointSearchStrAtom);
    const { framework } = useFramework(CSRD_ID);

    const { completionStats, assignmentStats, stats, isPending } =
        useCsrdStats();
    const datapointCompletionOptions = useMemo(() => {
        if (!completionStats) return [];
        return completionStats.categories.map((cat) => ({
            ...cat,
            label: t(`completion_card_${cat.label}`, { count: cat.value }),
        }));
    }, [completionStats]);

    useFrameworkBreadcrumb(CSRD_ID);

    return (
        <PageContainer>
            <FlexCol p="4" gap="4">
                {framework ? (
                    <Header bgColor="#0051AF" className="rounded-lg">
                        <Header.LeftColumn>
                            <Header.Title>{framework.name}</Header.Title>
                            <Header.Description>
                                {framework.description}
                            </Header.Description>
                        </Header.LeftColumn>
                        <Header.RightColumn cover="csrd" />
                    </Header>
                ) : (
                    <HeaderFullSkeleton />
                )}
                <FlexRow gap="4" w="full" alignItems="center">
                    <AssignementCard
                        isLoading={isPending || !stats}
                        total={assignmentStats.total}
                        assigned={assignmentStats.assigned}
                        unassigned={assignmentStats.unassigned}
                    />
                    <DatapointsCompletionCard
                        completed={completionStats?.completed}
                        isLoading={isPending || !completionStats}
                        label={`${completionStats?.percentage}%`}
                        options={datapointCompletionOptions}
                        total={completionStats?.total}
                    />
                </FlexRow>
                <FlexRow gap="4" w="full" alignItems="center">
                    <Tabs
                        activeTab={activeTab}
                        onActiveTabChange={setActiveTab}
                        className="flex-grow"
                        size="md"
                    >
                        <Tabs.Trigger
                            value={Tab.overview}
                            label={t(keys.tab_overview)}
                        />
                        <Tabs.Trigger
                            value={Tab.datapoints}
                            label={t(keys.tab_datapoints)}
                        />
                    </Tabs>
                    {activeTab === Tab.datapoints && (
                        <>
                            <SearchBar
                                placeholder={t(keys.search_datapoint)}
                                // this below is for hurry purposes only, the search bar should changed and take a size param too
                                className="h-[38px] w-[236px] box-border [&_input]:placeholder:text-base [&_input]:text-base [&_svg]:h-[14px] [&_svg]:w-[14px]"
                                searchString={searchString}
                                setSearchString={setSearchString}
                            />
                            <Button>{t(keys.btn_export_documents)}</Button>
                        </>
                    )}
                </FlexRow>
                {activeTab === Tab.overview && <OverviewTab />}
                {activeTab === Tab.datapoints && <DataTab />}
            </FlexCol>
        </PageContainer>
    );
};
