import * as Select from "@radix-ui/react-select";
import { Ref, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { GetThemesAndSubthemesDocument } from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import SelectItem from "../../../Dropdown/SelectItem";
import SelectTrigger from "../../SelectTrigger";
import "./i18n";

const ThemeAndSubthemeInput = forwardRef(
    (
        {
            themeId,
            onThemeIdChange,
            subthemeId,
            onSubthemeIdChange,
        }: {
            themeId: string | undefined;
            onThemeIdChange: (value: string | undefined) => void;
            subthemeId: string | undefined;
            onSubthemeIdChange: (value: string | undefined) => void;
        },
        ref: Ref<HTMLButtonElement>,
    ) => {
        const { data } = useGraphQL(
            GetThemesAndSubthemesDocument,
            {},
            undefined,
            true,
        );
        const { t } = useTranslation("ThemeAndSubthemeInput");

        const activeTheme = data?.themes.find((t) => t.id === themeId);
        const activeSubTheme = activeTheme?.subthemes.find(
            (st) => st.id === subthemeId,
        );

        return (
            <>
                <Select.Root
                    value={themeId || ""}
                    onValueChange={(val) => {
                        onThemeIdChange(val || undefined);
                    }}
                >
                    <SelectTrigger
                        stringValue={activeTheme?.name}
                        placeholder={t("theme_placeholder")}
                        value={themeId || ""}
                        ref={ref}
                        className="w-full"
                    />

                    <Select.Portal className="w-full">
                        <Select.Content
                            position="popper"
                            sideOffset={4}
                            className="border bg-white shadow-dropdown rounded-xl p-1 max-h-[300px] overflow-y-auto"
                            style={{
                                width: "var(--radix-select-trigger-width)",
                            }}
                        >
                            <Select.Viewport>
                                {data?.themes.map(
                                    (t) =>
                                        t.id && (
                                            <SelectItem value={t.id}>
                                                {t.name}
                                            </SelectItem>
                                        ),
                                )}
                            </Select.Viewport>
                        </Select.Content>
                    </Select.Portal>
                </Select.Root>
                {themeId && (
                    <Select.Root
                        value={themeId || ""}
                        onValueChange={(val) => {
                            onSubthemeIdChange(val || undefined);
                        }}
                    >
                        <SelectTrigger
                            stringValue={activeSubTheme?.name}
                            placeholder={t("subtheme_placeholder")}
                            value={themeId || ""}
                            ref={ref}
                            className="w-full"
                        />

                        <Select.Portal className="w-full">
                            <Select.Content
                                position="popper"
                                sideOffset={4}
                                className="border bg-white shadow-dropdown rounded-xl p-1 max-h-[300px] overflow-y-auto"
                                style={{
                                    width: "var(--radix-select-trigger-width)",
                                }}
                            >
                                <Select.Viewport>
                                    {activeTheme?.subthemes?.map((st) => (
                                        <SelectItem value={st.id}>
                                            {st.name}
                                        </SelectItem>
                                    ))}
                                </Select.Viewport>
                            </Select.Content>
                        </Select.Portal>
                    </Select.Root>
                )}
            </>
        );
    },
);

export default ThemeAndSubthemeInput;
