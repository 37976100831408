import i18next from "../../../i18n";

i18next.addResourceBundle("en", "SupplierQuestionnaires", {
    title: "Supplier questionnaires",
    subtitle:
        "Manage your questionnaires. Multi select indicators then assign questionnaires to them.",
    create_indicator_button: "Create Indicator",
});

i18next.addResourceBundle("fr", "SupplierQuestionnaires", {
    title: "Questionnaires fournisseurs",
    subtitle:
        "Gérez vos indicateurs. Sélectionnez plusieurs indicateurs puis attribuez-leur des questionnaires.",
    create_indicator_button: "Créer un indicateur",
});
