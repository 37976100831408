import { ComponentType } from "react";
import { cn } from "../../../lib/utils";
import { ButtonType } from "../../Button/ButtonShared";
import ButtonSmall from "../../Button/ButtonSmall";

interface EmptyTableProps {
    Icon: ComponentType<Partial<{ className: string }>>;
    smallText: string;
    buttonText?: string;
    onClick?: () => void;
}
const EmptyTable = ({
    Icon,
    smallText,
    buttonText,
    onClick,
}: EmptyTableProps) => {
    return (
        <div className={cn("border rounded-lg border-tertiary", "relative")}>
            <div
                className={cn(
                    "absolute w-full h-full bg-gradient-to-b from-white to-[#FFFFFF66]",
                    "flex flex-col items-center justify-center space-y-3"
                )}
            >
                <div className="space-y-2 flex flex-col items-center">
                    <Icon className="h-4 w-4" />
                    <div className="text-xs font-regular text-secondary">
                        {smallText}
                    </div>
                </div>
                {buttonText && (
                    <ButtonSmall
                        variant={ButtonType.OUTLINED}
                        text={buttonText}
                        onClick={onClick}
                    />
                )}
            </div>
            <table className="w-full">
                {Array.from({ length: 10 }).map(() => (
                    <tr className=" border-b border-tertiary last:border-none">
                        {Array.from({ length: 4 }).map(() => (
                            <td className="px-4 py-3">
                                <div className="rounded-full w-full h-2 bg-gradient-to-r from-neutral-150 to-white"></div>
                            </td>
                        ))}
                    </tr>
                ))}
            </table>
        </div>
    );
};

export default EmptyTable;
