import { Button } from "@design-system/Inputs/Button";
import { type FC, type PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { FlexRow } from "@design-system/Layout/Flex";
import { Box } from "@design-system/Layout/Box";
import "../i18n";

type LaunchTutorialButtonProps = {
    /** This informatio is added to the data-chameleon
     * atrribute of the button; it'll automatically be
     * prefixed with "launch-tutorial-"
     *
     * @example
     * // If you pass "my-page" as chameleonData prop:
     * // The button will have the following attribute:
     * // <button data-chameleon="launch-tutorial-my-page" />
     */
    chameleonData: string;
};

export const LaunchTutorialButton: FC<LaunchTutorialButtonProps> = ({
    chameleonData,
}) => {
    const { t } = useTranslation("Chameleon");
    return (
        <Button
            data-chameleon={`launch-tutorial-${chameleonData}`}
            variant="tonal"
            size="md"
        >
            {t("launchTutorialButton")}
            <Button.Icon name="info" />
        </Button>
    );
};

export const WrapperWithLaunchTutorialButton: FC<
    PropsWithChildren<LaunchTutorialButtonProps>
> = ({ children, chameleonData }) => {
    return (
        <FlexRow alignItems="center" gap="3">
            <LaunchTutorialButton chameleonData={chameleonData} />
            {children && (
                <>
                    <Box className="border-l border-secondary" h="5" mx="1" />
                    <Box>{children}</Box>
                </>
            )}
        </FlexRow>
    );
};
