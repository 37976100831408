import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "ThemeSharedSubthemes", {
    title: "Topics ",
    subtitle: "Select the topics that will be configured for your account.",
    add_subtheme: "Add topic",
});

i18next.addResourceBundle("fr", "ThemeSharedSubthemes", {
    title: "Enjeux",
    subtitle:
        "Sélectionnez les enjeux qui seront configurés pour votre compte.",
    add_subtheme: "Ajouter un enjeu",
});
