import { ColumnDef } from "@tanstack/react-table";
import { MoreHoriz } from "iconoir-react";
import { useTranslation } from "react-i18next";

import { Unit } from "../../../../../../generated/client/graphql";
import {
    ActionDropdownMenu,
    ActionDropdownMenuTrigger,
} from "../../../../../components/ActionDropdown";
import { ButtonType } from "../../../../../components/Button/ButtonShared";
import ButtonSmall from "../../../../../components/Button/ButtonSmall";
import Header from "../../../../../components/Table/Header";
import "./i18n";

export const columns: ColumnDef<Unit>[] = [
    {
        accessorKey: "name",
        header: ({ column }) => {
            const { t } = useTranslation("UnitsTable");
            return <Header title={t("unit")} column={column} />;
        },

        meta: {
            colWidth: "30%",
        },
    },
    {
        accessorKey: "shortName",
        header: ({ column }) => {
            const { t } = useTranslation("UnitsTable");
            return <Header title={t("shortName")} column={column} />;
        },
    },
    {
        accessorKey: "type",
        header: ({ column }) => {
            const { t } = useTranslation("UnitsTable");
            return <Header title={t("type")} column={column} />;
        },
    },
    {
        id: "actions",
        cell: ({}) => {
            return (
                <ActionDropdownMenu modal={false}>
                    <ActionDropdownMenuTrigger asChild>
                        <ButtonSmall
                            variant={ButtonType.OUTLINED}
                            IconLeft={MoreHoriz}
                        />
                    </ActionDropdownMenuTrigger>
                </ActionDropdownMenu>
            );
        },
        meta: {
            colWidth: "60px",
        },
    },
];
