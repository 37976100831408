import {
    CreateCustomDocumentDocument,
    DocumentPatch,
    GetDocumentsForSearchDocument,
    GetDocumentsForSearchQuery,
    UpdateDocumentsV2Document,
    UpdateDocumentsV2Mutation,
} from "@generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../clients";
import { DocumentRepository } from "../DocumentRepository";

export class GraphQLDocumentRepository implements DocumentRepository {
    createCustomDocument = (input: { name: string }) =>
        graphqlClientKoyeb.request(
            CreateCustomDocumentDocument,
            { input },
            AccessHeadersHolder.getHeaders(),
        );
    getDocumentsForSearch = (): Promise<GetDocumentsForSearchQuery> => {
        return graphqlClientKoyeb.request(
            GetDocumentsForSearchDocument,
            undefined,
            AccessHeadersHolder.getHeaders(),
        );
    };
    updateMany = (input: {
        ids: string[];
        patch: DocumentPatch;
    }): Promise<UpdateDocumentsV2Mutation> => {
        return graphqlClientKoyeb.request(
            UpdateDocumentsV2Document,
            input,
            AccessHeadersHolder.getHeaders(),
        );
    };
}
