import { GraphQLFrameworkRepository } from "@app/repositories/GraphQLRepositories/framework/FrameworkRepository";
import { FrameworkActivation } from "@generated/client/graphql";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getFrameworks } from "./services";

const frameworkRepository = new GraphQLFrameworkRepository();

export const frameworkKeys = {
    all: ["frameworks"] as const,
};

export function useFrameworks() {
    const { data, isPending, error } = useQuery({
        queryKey: frameworkKeys.all,
        queryFn: frameworkRepository.getMany,
    });

    return {
        frameworks: getFrameworks(data?.frameworks),
        isPending,
        error,
    };
}

export const useFrameworkInitialize = () => {
    const client = useQueryClient();
    return useMutation({
        mutationFn: (frameworkId: string) =>
            frameworkRepository.initialize(frameworkId),
        onSettled: () => {
            client.invalidateQueries({ queryKey: frameworkKeys.all });
        },
    });
};

export const useSetFrameworkActivation = () => {
    const client = useQueryClient();
    return useMutation({
        mutationFn: ({
            frameworkId,
            isActive,
        }: {
            frameworkId: string;
            isActive: boolean;
        }) =>
            frameworkRepository.update(frameworkId, {
                activation: isActive
                    ? FrameworkActivation.Active
                    : FrameworkActivation.Inactive,
            }),
        onSettled: () => {
            client.invalidateQueries({ queryKey: frameworkKeys.all });
        },
    });
};
