import { TCollabThread } from "@hocuspocus/provider";
import { useAtomValue } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import { currentUserAtom } from "../../../../components/TopBar/TopBarProfil";
import { useEditorContext } from "./useEditor";

export const useThreads = () => {
    const [threads, setThreads] = useState<TCollabThread[]>([]);
    const { editor, provider } = useEditorContext();
    const currentUser = useAtomValue(currentUserAtom);

    const resolvedThreads = useMemo(
        () => threads.filter((thread) => thread.resolvedAt),
        [threads],
    );
    const activeThreads = useMemo(
        () => threads.filter((thread) => !thread.resolvedAt),
        [threads],
    );

    useEffect(() => {
        if (!provider) {
            return () => null;
        }

        const getThreads = () => {
            setThreads(provider.getThreads());
        };

        getThreads();

        provider.watchThreads(getThreads);

        return () => {
            provider.unwatchThreads(getThreads);
        };
    }, [provider]);

    const createThread = useCallback(
        (comment: string | undefined) => {
            if (!comment) {
                return;
            }

            if (!editor) {
                return;
            }

            editor
                .chain()
                .focus()
                .setThread({
                    content: comment,
                    data: {
                        user: currentUser,
                    },
                    commentData: {
                        user: currentUser,
                    },
                })
                .run();
        },
        [editor, currentUser],
    );

    const removeThread = useCallback(
        (thread: TCollabThread) => {
            editor?.commands.removeThread({
                id: thread?.id,
                deleteThread: true,
            });
        },
        [editor],
    );

    const resolveThread = useCallback(
        (thread: TCollabThread) => {
            editor?.commands.resolveThread({
                id: thread?.id,
            });
        },
        [editor],
    );
    const unresolveThread = useCallback(
        (thread: TCollabThread) => {
            editor?.commands.unresolveThread({
                id: thread?.id,
            });
        },
        [editor],
    );
    return {
        threads,
        createThread,
        removeThread,
        resolveThread,
        unresolveThread,
        resolvedThreads,
        activeThreads,
    };
};
