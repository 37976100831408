interface AvatarProps {
    avatarUrl?: string;
    initials: string;
    size: "small" | "medium" | "large";
}

export default function Avatar({ avatarUrl, initials, size }: AvatarProps) {
    if (size == "small") {
        return avatarUrl ? (
            <img
                className="w-5 h-5 rounded-full border border-px shrink-0"
                src={avatarUrl}
            />
        ) : (
            <div className="shrink-0 flex justify-center items-center rounded-full text-[8px] text-white w-5 h-5 bg-green  shrink-0">
                {initials}
            </div>
        );
    } else if (size == "medium") {
        return avatarUrl ? (
            <img
                className="w-6 h-6 rounded-full border border-px  shrink-0"
                src={avatarUrl}
            />
        ) : (
            initials && (
                <div className="shrink-0 flex justify-center items-center rounded-full text-sm font-bold text-white w-6 h-6 bg-green">
                    {initials}
                </div>
            )
        );
    } else if (size == "large") {
        return avatarUrl ? (
            <img
                className="w-[36px] h-[36px] rounded-full border border-px  shrink-0"
                src={avatarUrl}
            />
        ) : (
            initials && (
                <div className="shrink-0 flex justify-center items-center rounded-full text-white w-[36px] h-[36px] bg-green">
                    {initials}
                </div>
            )
        );
    } else {
        return <></>;
    }
}
