import * as Popover from "@radix-ui/react-popover";
import { type FC, type PropsWithChildren } from "react";
import { Badge, type BadgeProps } from "./Badge";

export type FloatingBadgeProps = Omit<BadgeProps, "content"> & {
    show?: boolean;
    align?: Popover.PopoverContentProps["align"];
    alignOffset?: Popover.PopoverContentProps["alignOffset"];
    content?: string;
    side?: Popover.PopoverContentProps["side"];
    sideOffset?: Popover.PopoverContentProps["sideOffset"];
};

export const FloatingBadge: FC<PropsWithChildren<FloatingBadgeProps>> = ({
    align = "end",
    alignOffset = 7,
    children,
    content = "·",
    show,
    side = "top",
    sideOffset = -7,
    ...props
}) => {
    return (
        <Popover.Root open={!!show}>
            <Popover.Anchor asChild>{children}</Popover.Anchor>
            <Popover.Portal>
                <Popover.Content
                    align={align}
                    alignOffset={alignOffset}
                    asChild
                    side={side}
                    sideOffset={sideOffset}
                >
                    <Badge content={content} {...props} />
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};
