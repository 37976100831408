import { useRest } from "../../../../QueryClientWithHeaders";

export const useApprovedRequirements = (orgUname: string | undefined) => {
    const { data, isLoading } = useRest(`sc/${orgUname}/themes`);

    return {
        themes: data,
        isLoading,
    };
};
