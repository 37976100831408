import { Table } from "@tanstack/react-table";
import { useAtom } from "jotai";
import { useTranslation } from "react-i18next";
import {
    Relevance,
    Requirement,
} from "../../../../../generated/client/graphql";
import ButtonRegular from "../../../../components/Button/ButtonRegular";
import SearchFilter from "../../../../components/Filters/Search";
import {
    CenterModal,
    CenterModalContent,
} from "../../../../components/Modal/CenterModal";
import { unrelevantModalSearchFilterAtom } from "../../context";
import { useUpdateRequirements } from "../../hooks/data";
import { NonRelevantRequirementTable } from "../table/table";
import "./i18n";

export default function UnrelevantRequirementsModal({
    table,
    open,
    setOpen,
}: {
    table: Table<Requirement>;
    open: boolean;
    setOpen: (open: boolean) => void;
}) {
    const [searchFilter, setSearchFilter] = useAtom(
        unrelevantModalSearchFilterAtom,
    );
    const { t } = useTranslation("UnrelevantRequirements");
    const { mutate, isPending } = useUpdateRequirements();

    const onSetRelevant = () => {
        mutate({
            ids: requirements.map((d) => d.id),
            patch: { relevance: Relevance.Relevant },
        });
        table.resetRowSelection();
    };

    const requirements = table
        .getSelectedRowModel()
        .flatRows.map((row) => row.original);

    return (
        <CenterModal open={open} onOpenChange={setOpen} modal={false}>
            <CenterModalContent
                className="w-[820px]"
                title={t("title")}
                actions={
                    <ButtonRegular
                        text={t("set_relevant", { count: requirements.length })}
                        onClick={onSetRelevant}
                        loading={isPending}
                        disabled={requirements.length === 0}
                    />
                }
            >
                <div className="w-[820px] flex flex-col gap-4">
                    <SearchFilter
                        searchString={searchFilter}
                        setSearchString={setSearchFilter}
                        className="w-full box-border"
                    />
                    <NonRelevantRequirementTable table={table} />
                </div>
            </CenterModalContent>
        </CenterModal>
    );
}
