import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { useTranslation } from "react-i18next";
import { RequirementStatus } from "../../../../../generated/client/graphql";
import MultiSelectLayout from "../../components/dropdowns/MultiSelectLayout";
import "./i18n";

const RequirementStatusDropdown = ({
    status,
    onStatusChange,
    ...props
}: {
    status?: RequirementStatus[];
    onStatusChange: (status: RequirementStatus[] | undefined) => void;
} & DropdownPrimitive.DropdownMenuContentProps) => {
    const { t } = useTranslation("RequirementStatusDropdown");
    return (
        <MultiSelectLayout
            disableSearch
            activeValueIds={status}
            onActiveValueIdsChange={(s: (string | null)[] | undefined) =>
                s
                    ? onStatusChange(s as RequirementStatus[])
                    : onStatusChange(undefined)
            }
            values={[
                {
                    id: RequirementStatus.NotOk,
                    node: t(RequirementStatus.NotOk),
                },
                {
                    id: RequirementStatus.Ok,
                    node: t(RequirementStatus.Ok),
                },
            ]}
            {...props}
        />
    );
};

export default RequirementStatusDropdown;
