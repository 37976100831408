import i18next from "../../../i18n";

i18next.addResourceBundle("en", "SupplierDatapoints", {
    title: "Supplier Datapoints",
    subtitle: "Inspect what you suppliers answered to your questions.",
});

i18next.addResourceBundle("fr", "SupplierDatapoints", {
    title: "Données fournisseurs",
    subtitle:
        "Consultez les réponses de vos fournisseurs à vos questionnaires.",
});
