export enum Variant {
    Done,
    Validated,
    InProgress,
    PendingReview,
    Answered,
    Suggestion,
    Base,
    InProgressVariant,
    NotStarted,
    Transfered,
    ArrowDown,
    Nothing,
}

export interface StatusTagProps {
    variant: Variant;
    disabled?: boolean;
    text?: string;
}
