import {
    RegularTabs,
    useEditorContext,
} from "@app/screens/Document/Edit/hooks/useEditor";
import {
    approveAtom,
    editValidationTypeAtom,
    rejectDocumentVersionAtom,
    sendForReviewAtom,
    versionChecksRatioAtom,
} from "@app/store/versionStore";
import { Icon } from "@design-system/Icons";
import { DropdownMenu } from "@design-system/Inputs/DropdownMenu";
import { FlexRow } from "@design-system/Layout/Flex";
import { useAtomValue } from "jotai/react";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { ButtonType } from "../../../components/Button/ButtonShared";
import ButtonSmall from "../../../components/Button/ButtonSmall";
import { keys } from "./i18n";

import { currentUserRoleAtom } from "@app/store/userStore";
import { EditValidationType } from "@app/usecases/VersionUseCases";
import { UserRole } from "@generated/client/graphql";
import "./i18n";

export const ValidationButton: FC = () => {
    const { t } = useTranslation("VersionValidation");
    const { setRegularModeActiveTab } = useEditorContext();
    const {
        data: { ratio },
        isPending: isRatioPending,
    } = useAtomValue(versionChecksRatioAtom);

    const validationType = useAtomValue(editValidationTypeAtom);

    const { role } = useAtomValue(currentUserRoleAtom);

    const {
        mutate: sendForReviewMutation,
        isPending: isRequestForApprovalPending,
    } = useAtomValue(sendForReviewAtom);
    const sendForReview = () => sendForReviewMutation();

    const { mutate: rejectMutation, isPending: isRejectRequestPending } =
        useAtomValue(rejectDocumentVersionAtom);
    const reject = () => rejectMutation();
    const { mutate: approveMutation, isPending: isApproveMutationPending } =
        useAtomValue(approveAtom);
    const approve = () => approveMutation();

    const openChecklist = () => setRegularModeActiveTab(RegularTabs.CHECKS);

    const isLoading =
        isRatioPending ||
        isRequestForApprovalPending ||
        isRejectRequestPending ||
        isApproveMutationPending;

    return validationType === EditValidationType.SendForReview ? (
        ratio.num === 1 ? (
            <ButtonSmall
                loading={isLoading}
                className="w-full"
                onClick={sendForReview}
            >
                {t(keys.send_for_review, {
                    count: ratio.total,
                    ratio: ratio.str,
                })}
            </ButtonSmall>
        ) : (
            <FlexRow
                w="full"
                br="lg"
                className="overflow-hidden border border-secondary"
            >
                <ButtonSmall
                    className="border-none rounded-none w-full"
                    loading={isLoading}
                    tooltipContent={t(keys.send_for_review_tooltip)}
                    onClick={openChecklist}
                    variant={ButtonType.PLAIN}
                >
                    {t(keys.send_for_review, {
                        count: ratio.total,
                        ratio: ratio.str,
                    })}
                </ButtonSmall>

                <DropdownMenu>
                    <DropdownMenu.Trigger asChild>
                        <ButtonSmall
                            className="[&>div]:p-0 border-l border-l-secondary rounded-none"
                            loading={isLoading}
                            variant={ButtonType.TEXT}
                        >
                            <Icon name="angleDown" />
                        </ButtonSmall>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content
                        align="end"
                        side="bottom"
                        sideOffset={10}
                    >
                        <DropdownMenu.Item onSelect={approve}>
                            <DropdownMenu.ItemIcon
                                name="warning"
                                className="text-warning-500"
                            />
                            {t(keys.force_approve)}
                        </DropdownMenu.Item>
                        {role === UserRole.BeavrAdmin && (
                            <DropdownMenu.Item onSelect={reject}>
                                <DropdownMenu.ItemIcon
                                    name="close"
                                    className="text-danger"
                                />
                                {t(keys.reject)}
                            </DropdownMenu.Item>
                        )}
                    </DropdownMenu.Content>
                </DropdownMenu>
            </FlexRow>
        )
    ) : validationType === EditValidationType.PendingCsReview ? (
        <ButtonSmall
            disabled
            className="w-full"
            loading={isLoading}
            variant={ButtonType.TEXT}
            tooltipContent={t(keys.under_review_tooltip)}
        >
            {t(keys.under_review)}
        </ButtonSmall>
    ) : validationType === EditValidationType.Approved ? (
        <ButtonSmall loading={isLoading} className="w-full " disabled>
            <span>{t(keys.approved)}</span>
            <Icon name="doubleCheck" className="text-success-500" />
        </ButtonSmall>
    ) : validationType === EditValidationType.CsReview ? (
        <FlexRow
            w="full"
            br="lg"
            className="overflow-hidden border border-secondary"
        >
            <ButtonSmall
                className="border-none rounded-none w-full"
                disabled={ratio.num != 1}
                loading={isLoading}
                onClick={approve}
                variant={ButtonType.PLAIN}
            >
                {t(keys.approve)}
            </ButtonSmall>
            <DropdownMenu>
                <DropdownMenu.Trigger asChild>
                    <ButtonSmall
                        className="[&>div]:p-0 border-l border-l-secondary rounded-none"
                        loading={isLoading}
                        variant={ButtonType.TEXT}
                    >
                        <Icon name="angleDown" />
                    </ButtonSmall>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content align="end" side="bottom" sideOffset={10}>
                    {ratio.num < 1 && (
                        <DropdownMenu.Item onSelect={approve}>
                            <DropdownMenu.ItemIcon
                                name="cloudWithValidate"
                                className="text-warning-500"
                            />
                            {t(keys.force_approve)}
                        </DropdownMenu.Item>
                    )}
                    {role === UserRole.BeavrAdmin && (
                        <DropdownMenu.Item onSelect={reject}>
                            <DropdownMenu.ItemIcon
                                name="close"
                                className="text-danger"
                            />
                            {t(keys.reject)}
                        </DropdownMenu.Item>
                    )}
                </DropdownMenu.Content>
            </DropdownMenu>
        </FlexRow>
    ) : validationType === EditValidationType.ApproveDirectly ? (
        <ButtonSmall loading={isLoading} className="w-full" onClick={approve}>
            {t(keys.approve)}
        </ButtonSmall>
    ) : (
        <></>
    );
};
