import i18next from "../../i18n";

i18next.addResourceBundle("en", "TopBar", {
    help_center: "Help center",
    referral: "Learn more about our referral program",
});

i18next.addResourceBundle("fr", "TopBar", {
    help_center: "Centre d'aide",
    referral: "En savoir plus sur notre programme de parrainage",
});
