import Checkbox from "@app/components/Checkbox";
import {
    activeDatapointGroupsIdsAtom,
    datapointGroupsOfEntityAtom,
} from "@app/store/reportingStore";
import { orderPeriods, orderedYears } from "@app/usecases/ReportingUseCases";
import { Icon } from "@design-system/Icons";
import { Button } from "@design-system/Inputs/Button";
import { MultiSelect } from "@design-system/Inputs/MultiSelect";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { ReportingPeriod } from "@generated/client/graphql";
import { useAtom } from "jotai";
import { FC, PropsWithChildren, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import "../../i18n";

const PeriodChipsSelect: FC<PropsWithChildren> = () => {
    const { t } = useTranslation("ReportingPage");
    const [activeDatapointGroupsIds, setActiveDatapointGroupsIds] = useAtom(
        activeDatapointGroupsIdsAtom,
    );
    const [datapointGroupsOfEntity] = useAtom(datapointGroupsOfEntityAtom);

    const orderedGroups = useMemo(() => {
        return datapointGroupsOfEntity.sort((a, b) =>
            orderPeriods(a.period, b.period),
        );
    }, [datapointGroupsOfEntity]);

    const yearsOfPeriods = useMemo(() => {
        return [
            ...new Set(
                datapointGroupsOfEntity.map((group) => group.period.year),
            ),
        ].sort((a, b) => orderedYears.indexOf(a) - orderedYears.indexOf(b));
    }, [datapointGroupsOfEntity]);

    useEffect(() => {
        if (!!orderedGroups?.length && !activeDatapointGroupsIds.length) {
            setActiveDatapointGroupsIds([
                orderedGroups[orderedGroups.length - 1].id,
            ]);
        }
    }, [datapointGroupsOfEntity]);

    const onActiveGroupIdsChange = (values: (string | null)[]) => {
        setActiveDatapointGroupsIds(values as string[]);
    };

    const filteredGroups = (year: number) => {
        return orderedGroups?.filter((group) => group.period.year === year);
    };

    const displayedPeriod = (period: ReportingPeriod) => {
        return !period.quarter && !period.month
            ? t("year")
            : period.month
              ? t(period.month)
              : period.quarter;
    };
    return (
        <FlexRow alignItems="center" gap="2" w="full" className="h-8">
            <Text variant="body">
                {!!datapointGroupsOfEntity?.length
                    ? t("periods_title")
                    : t("no_period")}
            </Text>
            {!!datapointGroupsOfEntity?.length && (
                <MultiSelect
                    values={activeDatapointGroupsIds}
                    onValuesChange={onActiveGroupIdsChange}
                >
                    <MultiSelect.Trigger>
                        <Button
                            variant="tonal"
                            size="sm"
                            className="shadow-sm cursor-pointer focus:outline-none bg-white"
                        >
                            <Text variant="body-sm">{t("select_periods")}</Text>
                            <Icon name="angleDown" className="h-4" />
                        </Button>
                    </MultiSelect.Trigger>
                    <MultiSelect.Content hasPortal={false}>
                        {yearsOfPeriods.map((year) => (
                            <MultiSelect.Group key={year}>
                                <FlexRow
                                    h="7"
                                    px="1"
                                    py="2"
                                    alignItems="center"
                                    className="cursor-default"
                                >
                                    <Text variant="body-bold">{year}</Text>
                                </FlexRow>
                                {filteredGroups(year).map((group) => (
                                    <MultiSelect.Item
                                        value={group.id}
                                        key={group.id}
                                    >
                                        {({ isSelected }) => (
                                            <FlexRow
                                                h="7"
                                                alignItems="center"
                                                pl="6"
                                                py="2"
                                                gap="2"
                                                className="hover:bg-secondary hover:text-green"
                                            >
                                                <Checkbox
                                                    checked={isSelected}
                                                ></Checkbox>
                                                <Text
                                                    variant="body"
                                                    key={group.id}
                                                >
                                                    {displayedPeriod(
                                                        group.period,
                                                    )}
                                                </Text>
                                            </FlexRow>
                                        )}
                                    </MultiSelect.Item>
                                ))}
                            </MultiSelect.Group>
                        ))}
                    </MultiSelect.Content>
                </MultiSelect>
            )}
            {!!orderedGroups.length &&
                activeDatapointGroupsIds?.length === 0 && (
                    <Text variant="body-sm-bold" color="warning">
                        {t("no_period_warning")}
                    </Text>
                )}
        </FlexRow>
    );
};

export default PeriodChipsSelect;
