import {
    GetDashboardPlanStepsDocument,
    GetDashboardPlanStepsQuery,
    UpsertDashboardPlanStepDocument,
    UpsertDashboardPlanStepMutationVariables,
} from "../../../generated/client/graphql";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { DashboardRepository } from "../DashboardRepository";
import { graphqlClientKoyeb } from "../clients";

export class GraphQLDashboardRepository implements DashboardRepository {
    getPlanSteps = (): Promise<GetDashboardPlanStepsQuery> => {
        return graphqlClientKoyeb.request(
            GetDashboardPlanStepsDocument,
            undefined,
            AccessHeadersHolder.getHeaders(),
        );
    };

    upsertPlanStep = (payload: UpsertDashboardPlanStepMutationVariables) => {
        return graphqlClientKoyeb.request(
            UpsertDashboardPlanStepDocument,
            payload,
            AccessHeadersHolder.getHeaders(),
        );
    };
}
