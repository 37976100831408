import { atom, useAtom } from "jotai";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Theme } from "../../../../../generated/client/graphql";
import SearchFilter from "../../../../components/Filters/Search";
import DataTable from "../../../../components/Table/DataTable";
import { cn, orderSort } from "../../../../lib/utils";
import { useApprovedRequirementsTable } from "../hooks/useApprovedRequirementsTables";
import "../i18n";

export const scControlsTabSearchStringAtom = atom<string | undefined>(
    undefined,
);

export default function SustainabilityCenterControlsTab({
    themes,
    locale,
}: {
    themes: Theme[];
    locale: string;
}) {
    const [searchString, setSearchString] = useAtom(
        scControlsTabSearchStringAtom,
    );
    const [selectInd, setSelectInd] = useState<number>(0);
    const { tables: requirementTablesByTheme } =
        useApprovedRequirementsTable(themes);
    const { t } = useTranslation("SustainabilityCenter", { lng: locale });
    return (
        <div className="mt-7 flex items-start gap-4 w-[1120px]">
            <div className="flex w-[232px] flex-col items-start gap-2 shrink-0 self-stretch">
                <div className="text-primary font-bold text-xl">
                    {t("controls_tab_title")}
                </div>
                <div className="flex flex-col self-stretch items-start text-ellipsis overflow-hidden">
                    {orderSort(themes) &&
                        themes.map((theme, idx) => (
                            <div className="flex items-start self-stretch whitespace-nowrap overflow-hidden text-ellipsis">
                                <div
                                    className={cn(
                                        "flex py-2 px-1.5 items-center flex-1 text-base cursor-pointer",
                                        idx === selectInd
                                            ? "bg-secondary rounded-lg text-green"
                                            : "",
                                    )}
                                    onClick={() => {
                                        setSelectInd(idx);
                                        document
                                            .getElementById(theme.name)
                                            ?.scrollIntoView({
                                                behavior: "smooth",
                                            });
                                    }}
                                >
                                    {theme.name}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
            <div className="flex flex-col items-start gap-6 flex-1">
                <div className="flex items-end flex-col self-stretch pr-5">
                    <SearchFilter
                        searchString={searchString}
                        setSearchString={setSearchString}
                        placeholder={t("search_controls")}
                        className="w-[236px]"
                    />
                </div>
                {requirementTablesByTheme
                    .filter(
                        (theme) =>
                            theme.subthemes
                                .map(
                                    (subtheme) =>
                                        subtheme.table.getFilteredRowModel()
                                            .flatRows.length,
                                )
                                .reduce((a, b) => a + b, 0) > 0,
                    )
                    .map((theme, _) => (
                        <div className="pr-5 gap-4 flex flex-col">
                            <div
                                className="text-primary font-bold text-xl"
                                id={theme.name}
                            >
                                {theme.name}
                            </div>
                            <div className="flex flex-col gap-4">
                                {theme.subthemes.map((subtheme) => (
                                    <div>
                                        {subtheme.table
                                            .getFilteredRowModel()
                                            .flatRows.map((r) => r.original)
                                            .length > 0 && (
                                            <DataTable table={subtheme.table} />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
}
