import { queryClient } from "@app/QueryClientWithHeaders";
import { GraphQLDocumentRepository } from "@app/repositories/GraphQLRepositories/DocumentRepository";
import { DocumentPatch } from "@generated/client/graphql";
import { atom } from "jotai";
import { atomWithMutation, atomWithQuery } from "jotai-tanstack-query";
import { documentKeys } from "./queryKeys";

export const documentURLAtom = atom<string | null>(null);

// init
const documentRepository = new GraphQLDocumentRepository();

export const documentsForSearchAtom = atomWithQuery(() => {
    return {
        queryKey: [documentKeys.documentsForSearch],
        queryFn: documentRepository.getDocumentsForSearch,
    };
});

export const createCustomDocumentMutationAtom = atomWithMutation(() => ({
    mutationKey: [documentKeys.createCustomDocument],
    mutationFn: documentRepository.createCustomDocument,
    onSettled: () => {
        queryClient.invalidateQueries({
            queryKey: [documentKeys.documentsForSearch],
        });
    },
}));

export const updateDocumentMutation = atomWithMutation(() => ({
    mutationKey: [documentKeys.updateManyDocuments],
    mutationFn: async ({
        ids,
        patch,
    }: {
        ids: string[];
        patch: DocumentPatch;
    }) => {
        await documentRepository.updateMany({
            ids,
            patch,
        });
    },
    onSettled() {
        queryClient.invalidateQueries({
            queryKey: [documentKeys.GetDocumentsV2],
        });
    },
}));

export const selectedSubthemesAtom = atom<(string | null)[]>([]);
export const searchThemesFilterAtom = atom<string | undefined>(undefined);
export const selectedTagsAtom = atom<(string | null)[]>([]);
