import { useSetAtom } from "jotai";
import { useCallback } from "react";
import { breadcrumbAtom } from "./ctx";

/**
 * A hook to dynamically set breadcrumb data for your application pages.
 * 
 * Use this hook when the breadcrumb labels need to be set dynamically based on the current route
 * parameters, and when they cannot be predefined in the i18n configuration of the Navigation component.
 * 
 * **Important:** The routes where this hook is used must have a `handle` property defined in the router configuration.
 * 
 * @returns {Function} A memoized callback function that updates the breadcrumb state.
 * 
 * @example
 * // In your routing file (e.g., routing/admin.tsx):
 * {
 *   path: adminPaths.frameworks,
 *   handle: {
 *       crumb: Link,
 *       page: adminPaths.frameworks,
 *   },
 *   children: [
 *       { index: true, element: <Frameworks /> },
 *       {
 *           path: adminPaths.framework_id_param,
 *           element: <Framework />,
 *           handle: {
 *               crumb: Link,
 *               page: adminPaths.framework_id_param,
 *           },
 *       },
 *   ],
 * }
 * 
 * // In the component using the hook:
 * const setBreadcrumb = useBreadcrumb();
 * setBreadcrumb(":framework_id", "Ecovadis", [
 *     { name: "Alternative Framework 1", param: "framework_1" },
 *     { name: "Alternative Framework 2", param: "framework_2" },
 * ]);
 * 
 * @function
 * @name useBreadcrumb
 * 
 * @param {string} key - The key identifying the breadcrumb path. This is usually a route parameter
 * (e.g., `":framework_id"`). It should match the `page` value specified in the route's `handle`.
 * 
 * @param {string} name - The label to display in the breadcrumb for the current route. For example, 
 * if the breadcrumb is for a specific framework, you might set this to the framework's name (e.g., `"Ecovadis"`).
 * 
 * @param {Object[]} [list] - An optional array of objects, each representing an alternative route or option
 * to display in a dropdown within the breadcrumb.
 * @param {string} list[].name - The name to display for each alternative route in the dropdown.
 * @param {string} list[].param - The route parameter that corresponds to each alternative option.
 * 
 * @description
 * This hook allows you to dynamically set breadcrumb data, which is particularly useful when the breadcrumb
 * labels depend on dynamic route parameters or context. The returned function updates the breadcrumb state with the
 * provided `key`, `name`, and optional list of alternatives.
 * 
 * The `key` parameter should correspond to a route parameter slug (like `":framework_id"`) and must be matched
 * with the `page` value in the `handle` of the route configuration.
 * 
 * The `name` parameter is the text that will appear in the breadcrumb for that route.
 * 
 * The optional `list` parameter allows you to provide alternative routes or options that can be shown in a dropdown within the breadcrumb.
 * 
 * Ensure that the routes where you intend to use this hook have a `handle` property defined, otherwise the breadcrumb won't be displayed.
 */
export function useBreadcrumb() {
    const setBreadcrumb = useSetAtom(breadcrumbAtom);

    return useCallback(
        (
            key: string,
            name: string,
            list?: { name: string; param: string }[],
        ) => {
            setBreadcrumb((breadcrumb) => ({
                ...breadcrumb,
                [key]: { name, list },
            }));
        },
        [setBreadcrumb],
    );
}
