import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "TagsDropdown", {
    placeholder: "Tags",
    setter_text: "{{count}} tags selected",
});

i18next.addResourceBundle("fr", "TagsDropdown", {
    placeholder: "Tags",
    setter_text: "{{count}} tags sélectionnés",
});
