import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "AddDocumentModal", {
    title: "Documents",
    subtitle:
        "Upload, store and share all the documents associated with your CSR or impact strategy.",
    document_name: "Document name",
    document_description: "Document description",
    owner: "Owner",
    document_update_success: "Document updated successfully",
    document_update_error: "Document update error",
});

i18next.addResourceBundle("fr", "AddDocumentModal", {
    title: "Documents",
    subtitle:
        "Téléchargez, stockez et partagez tous les documents associés à votre stratégie RSE ou d'impact.",
    document_name: "Nom du document",
    document_description: "Description du document",
    owner: "Propriétaire",
    document_update_success: "Document mis à jour avec succès",
    document_update_error: "Erreur de mise à jour du document",
});
