import i18next from "../../../i18n";

i18next.addResourceBundle("en", "SustainabilityCenterControlsBlock", {
    title: "Controls",
    see_more: "See {{count}} more controls",
});

i18next.addResourceBundle("fr", "SustainabilityCenterControlsBlock", {
    title: "Contrôles",
    see_more: "Voir {{count}} autres contrôles",
});
