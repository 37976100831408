import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "UserDropdown", {
    assign_to: "Assign to",
    unset: "Unset",
    replace: "Replace",
    replace_confirm:
        "Are you sure you want to replace {{count}} users with {{user}}?",
});

i18next.addResourceBundle("fr", "UserDropdown", {
    assign_to: "Assigner à",
    unset: "Retirer",
    replace: "Remplacer",
    replace_confirm:
        "Êtes-vous sûr de vouloir remplacer {{count}} contributeurs par {{user}}?",
});
