import * as Select from "@radix-ui/react-select";
import { Ref, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import SelectItem from "../../../Dropdown/SelectItem";
import SelectTrigger from "../../SelectTrigger";
import "./i18n";

const BooleanInput = forwardRef(
    (
        {
            value,
            onValueChange,
            success,
        }: {
            value: boolean | undefined;
            onValueChange: (value: boolean | null) => void;
            success?: boolean;
        },
        ref: Ref<HTMLButtonElement>
    ) => {
        const { t } = useTranslation("InputBoolean");
        const stringValue =
            value === true ? "true" : value === false ? "false" : "";

        return (
            <Select.Root
                value={stringValue}
                onValueChange={(val) => {
                    if (val === "") {
                        onValueChange(null);
                    }
                    if (val === "true") {
                        onValueChange(true);
                    }
                    if (val === "false") {
                        onValueChange(false);
                    }
                }}
            >
                <SelectTrigger
                    stringValue={stringValue && t(stringValue)}
                    placeholder={t("placeholder")}
                    value={stringValue}
                    ref={ref}
                    className="w-full"
                    success={success}
                />

                <Select.Portal className="w-full">
                    <Select.Content
                        position="popper"
                        sideOffset={4}
                        className="border bg-white shadow-dropdown rounded-xl p-1"
                        style={{ width: "var(--radix-select-trigger-width)" }}
                    >
                        <Select.Viewport>
                            <SelectItem value={""}>{t("unset")}</SelectItem>
                            <SelectItem value={"true"}>{t("true")}</SelectItem>
                            <SelectItem value={"false"}>
                                {t("false")}
                            </SelectItem>
                        </Select.Viewport>
                        <Select.Arrow />
                    </Select.Content>
                </Select.Portal>
            </Select.Root>
        );
    }
);

export default BooleanInput;
