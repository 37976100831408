import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "UsersTable", {
    last_login: "Last Login",
    user: "User",
    status: "Status",
    verified: "Verified",
    pending: "Pending...",
    delete: "Delete",
    role: "Role",
    ADMIN: "Admin",
    CONTRIBUTOR: "Contributor",
    SUPPLIER: "Supplier",
    department: "Department",
    edit_contributor: "Edit contributor",

    delete_user: "Delete user",
    delete_title: "Delete user",
    delete_confirm_button: "Confirm",
    delete_text_1:
        "Are you sure you want to delete this user ? This action is irreversible.",

    empty_table_small_text: "No users yet",
    empty_table_button_text: "Add a user",
});

i18next.addResourceBundle("fr", "UsersTable", {
    last_login: "Dernière connexion",
    user: "Utilisateur",
    status: "Statut",
    verified: "Vérifié",
    pending: "En attente...",
    delete: "Supprimer",
    role: "Rôle",
    ADMIN: "Admin",
    CONTRIBUTOR: "Contributeur",
    SUPPLIER: "Fournisseur",
    department: "Département",
    edit_contributor: "Modifier le contributeur",
    delete_contributor: "Supprimer le contributeur",

    empty_table_small_text: "Vous n'avez pas encore d'utilisateur",
    empty_table_button_text: "Ajouter un utilisateur",
    delete_user: "Supprimer l'utilisateur",
    delete_title: "Supprimer l'utilisateur",
    delete_text_1:
        "Etes vous sur de vouloir supprimer cet utilisateur ? Cette action est irréversible.",
    delete_confirm_button: "Confirmer",
});
