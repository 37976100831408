import BackgroundBars from "../../../../components/BackgroundBars";

const GroupBlockLoading = () => {
    return (
        <div className="w-[880px]">
            <div className="-z-10 relative">
                <div className="absolute -left-4 -top-4">
                    <BackgroundBars />
                </div>
            </div>
            <div className="h-[300px] rounded-lg border border-tertiary p-6 bg-primary space-y-4">
                {Array.from({ length: 3 }).map(() => (
                    <div className="px-4 py-3 animate-pulse ">
                        <div className="rounded-lg w-full h-10 bg-gradient-to-r from-neutral-150 to-white"></div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const GroupsLoading = () => {
    return (
        <>
            {Array.from({ length: 3 }).map(() => (
                <GroupBlockLoading />
            ))}
        </>
    );
};
export default GroupsLoading;
