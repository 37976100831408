import { validateEmail } from "@app/utils/emailRegex";
import { Text } from "@design-system/Typography/Text";
import { Trash } from "iconoir-react";
import { useTranslation } from "react-i18next";
import { CreateSupplierInput } from "../../../../../../generated/client/graphql";
import { ButtonType } from "../../../../../components/Button/ButtonShared";
import ButtonXLarge from "../../../../../components/Button/ButtonXLarge";
import TextInput from "../../../../../components/inputs_v2/Text";

const NewSupplierRow = ({
    input,
    onInputChange,
    onDelete,
}: {
    input: CreateSupplierInput;
    onInputChange: (input: Partial<CreateSupplierInput>) => void;
    onDelete: () => void;
}) => {
    const { t } = useTranslation("NewSupplierModal");

    return (
        <div className="w-full flex space-x-3 items-center">
            <div className="w-1/4">
                <TextInput
                    placeholder={t("supplier_name")}
                    value={input.name}
                    setValue={(v) => onInputChange({ name: v || "" })}
                />
            </div>
            <div className="w-1/4">
                <TextInput
                    placeholder={t("email")}
                    value={input.pointOfContact.email}
                    error={
                        input.pointOfContact.email &&
                        !validateEmail(input.pointOfContact.email)
                            ? "Bad email"
                            : undefined
                    }
                    setValue={(v) =>
                        onInputChange({
                            pointOfContact: {
                                ...input.pointOfContact,
                                email: v || "",
                            },
                        })
                    }
                />
            </div>
            <TextInput
                placeholder={t("firstname")}
                value={input.pointOfContact.firstName}
                setValue={(v) =>
                    onInputChange({
                        pointOfContact: {
                            ...input.pointOfContact,
                            firstName: v || "",
                        },
                    })
                }
            />
            <TextInput
                placeholder={t("lastname")}
                value={input.pointOfContact.lastName}
                setValue={(v) =>
                    onInputChange({
                        pointOfContact: {
                            ...input.pointOfContact,
                            lastName: v || "",
                        },
                    })
                }
            />
            {!!input.tagIds?.length && <Text>{input.tagIds.length} tags</Text>}
            <ButtonXLarge
                IconLeft={Trash}
                onClick={onDelete}
                variant={ButtonType.TEXT}
            />
        </div>
    );
};

export default NewSupplierRow;
