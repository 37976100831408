import { OpenSelectHandGesture } from "iconoir-react";
import { useTranslation } from "react-i18next";
import {
    GetSuppliersDocument,
    Supplier,
} from "../../../../generated/client/graphql";
import { useGraphQL } from "../../../QueryClientWithHeaders";
import Container from "../../../components/Container";
import SearchFilter, {
    searchFilterStringAtom,
} from "../../../components/Filters/Search";
import BodyHeader from "../../../shared/components/BodyHeader";

import { useAtom } from "jotai";
import { useCapturePageView } from "../../../lib/tracking/utils";
import NewSupplierModal from "./components/NewSupplierModal";
import SupplierTable from "./components/SupplierTable";
import "./i18n";

export default function Suppliers() {
    useCapturePageView("supplier");

    const { t } = useTranslation("Suppliers");
    const getSuppliers = useGraphQL(GetSuppliersDocument, undefined, {}, true);
    const [searchString, setSearchString] = useAtom(searchFilterStringAtom);
    return (
        <>
            <Container>
                <BodyHeader
                    icon={OpenSelectHandGesture}
                    title={t("title")}
                    subTitle={t("subtitle")}
                    action={<NewSupplierModal refetch={getSuppliers.refetch} />}
                />
                <div className="p-4 space-y-4">
                    <SearchFilter
                        searchString={searchString}
                        setSearchString={setSearchString}
                    />
                    <SupplierTable
                        suppliers={getSuppliers.data?.suppliers as Supplier[]}
                        isLoading={getSuppliers.isLoading}
                    />
                </div>
            </Container>
        </>
    );
}
