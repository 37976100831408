import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { Unit } from "../../../../../generated/client/graphql";
import SingleSelectDropdown from "../../components/dropdowns/SingleSelect";

const UnitDropdown = ({
    units,
    unitId,
    onUnitIdChange,
    ...props
}: {
    units?: Unit[];
    unitId: string | undefined;
    onUnitIdChange: (unitId: string | undefined) => void;
    portalContainer?: HTMLElement;
} & DropdownPrimitive.DropdownMenuContentProps) => {
    return (
        <SingleSelectDropdown
            value={unitId}
            onValueChange={(id) => id && onUnitIdChange(id)}
            values={
                units?.map((unit) => ({
                    value: unit.id,
                    node: unit.name,
                })) || []
            }
            {...props}
        />
    );
};

export default UnitDropdown;
