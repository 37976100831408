import {
    borderRadius,
    elevation,
    height,
    layout,
    mapPropsToClasses,
    margin,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    padding,
    size,
    width,
} from "@design-system/Theme";
import { cn } from "@design-system/Utilities";
import {
    PropsWithChildren,
    forwardRef,
    useMemo,
    type HTMLAttributes,
} from "react";

//using interface instead of type because there are more than 200 properties in HTMLAttributes<HTMLDivElement>
export interface BoxProps extends HTMLAttributes<HTMLDivElement> {
    as?: "article" | "div" | "section" | "header" | "footer" | "main";
    /**border-radius */
    br?: keyof typeof borderRadius;
    /** box-shadow */
    elevation?: keyof typeof elevation;
    display?: keyof (typeof layout)["display"];
    direction?: keyof (typeof layout)["direction"];
    gap?: keyof (typeof layout)["gap"];
    justifyContent?: keyof (typeof layout)["justifyContent"];
    alignItems?: keyof (typeof layout)["alignItems"];
    alignSelf?: keyof (typeof layout)["alignSelf"];
    p?: keyof (typeof padding)["p"];
    px?: keyof (typeof padding)["px"];
    py?: keyof (typeof padding)["py"];
    pt?: keyof (typeof padding)["pt"];
    pr?: keyof (typeof padding)["pr"];
    pb?: keyof (typeof padding)["pb"];
    pl?: keyof (typeof padding)["pl"];
    m?: keyof (typeof margin)["m"];
    mx?: keyof (typeof margin)["mx"];
    my?: keyof (typeof margin)["my"];
    mt?: keyof (typeof margin)["mt"];
    mr?: keyof (typeof margin)["mr"];
    mb?: keyof (typeof margin)["mb"];
    ml?: keyof (typeof margin)["ml"];
    h?: keyof typeof height;
    maxH?: keyof typeof maxHeight;
    minH?: keyof typeof minHeight;
    w?: keyof typeof width;
    minW?: keyof typeof minWidth;
    maxW?: keyof typeof maxWidth;
    /** height and width */
    size?: keyof typeof size;
    wrap?: keyof (typeof layout)["wrap"];
    className?: string;
}

const boxPropNames = [
    "as",
    "br",
    "elevation",
    "display",
    "direction",
    "gap",
    "justifyContent",
    "alignItems",
    "alignSelf",
    "p",
    "px",
    "py",
    "pt",
    "pr",
    "pb",
    "pl",
    "m",
    "mx",
    "my",
    "mt",
    "mr",
    "mb",
    "ml",
    "h",
    "maxH",
    "minH",
    "w",
    "minW",
    "maxW",
    "size",
    "className",
    "wrap",
];

export const Box = forwardRef<HTMLDivElement, PropsWithChildren<BoxProps>>(
    (props, ref) => {
        const { as = "div", className, children, ...rest } = props;
        const Comp = as;
        const classes = useMemo(() => mapPropsToClasses(rest), [rest]);
        const attrs = useMemo(
            () =>
                Object.entries(rest).reduce((acc, [key, value]) => {
                    if (!boxPropNames.includes(key)) {
                        const k = key as keyof HTMLAttributes<HTMLDivElement>;
                        const v =
                            value as HTMLAttributes<HTMLDivElement>[typeof k];
                        acc[k] = v;
                    }
                    return acc;
                }, {} as HTMLAttributes<HTMLDivElement>),
            [rest],
        );

        return (
            <Comp ref={ref} className={cn(...classes, className)} {...attrs}>
                {children}
            </Comp>
        );
    },
);
