import ButtonLarge from "@app/components/Button/ButtonLarge";
import { ButtonType } from "@app/components/Button/ButtonShared";
import ButtonSmall from "@app/components/Button/ButtonSmall";
import Checkbox from "@app/components/Checkbox";
import {
    AlertModal,
    AlertModalContent,
    AlertModalTrigger,
} from "@app/components/Modal/AlertModal";
import { updateDashboardPlanStepAtom } from "@app/store/dashboardStore";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { DashboardPlanStepType } from "@generated/client/graphql";
import { Edit } from "iconoir-react";
import { useAtomValue } from "jotai";
import { useState, type ChangeEvent, type FC } from "react";

type EditStepModalProps = {
    dueDate: Date | null;
    uid: string | null;
    isChecked: boolean;
    stepType: DashboardPlanStepType;
};

const EditStepModalContent: FC<
    EditStepModalProps & { closeModal: () => void }
> = ({ closeModal, dueDate, uid: id, isChecked, stepType }) => {
    const [date, setDate] = useState(
        dueDate ? new Date(dueDate).toISOString().substring(0, 10) : undefined
    );
    const [checked, setChecked] = useState(isChecked);

    const { mutateAsync, isPending } = useAtomValue(
        updateDashboardPlanStepAtom
    );

    const save = async () => {
        await mutateAsync({
            id,
            stepType,
            dueDate: date ? new Date(date).toISOString() : undefined,
            checked,
        });
        closeModal();
    };
    const handleDateChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setDate(evt.target.value);
    };
    const handleCheckboxChange = () => {
        setChecked((c) => !c);
    };

    return (
        <AlertModalContent title="Change due date and check/uncheck">
            <Text>This is for our dear CS only</Text>
            <FlexRow alignItems="center" gap="2">
                <label htmlFor="dueDate">Due date: </label>
                <input
                    id="dueDate"
                    onChange={handleDateChange}
                    type="date"
                    value={date}
                />
            </FlexRow>

            <FlexRow
                alignItems="center"
                onClick={handleCheckboxChange}
                w="fit"
                className="cursor-pointer"
            >
                <Checkbox checked={checked} />
                <Text as="span" ml="2">
                    is this step completed?
                </Text>
            </FlexRow>

            <FlexRow justifyContent="end" gap="2">
                <ButtonLarge
                    loading={isPending}
                    onClick={save}
                    type="submit"
                    variant={ButtonType.DEFAULT}
                >
                    Save
                </ButtonLarge>
            </FlexRow>
        </AlertModalContent>
    );
};

export const EditStepModal: FC<EditStepModalProps> = (props) => {
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    return (
        <AlertModal open={open} onOpenChange={setOpen}>
            <AlertModalTrigger asChild>
                <ButtonSmall variant={ButtonType.TONAL}>
                    <Edit />
                </ButtonSmall>
            </AlertModalTrigger>
            <EditStepModalContent {...{ ...props, closeModal }} />
        </AlertModal>
    );
};
