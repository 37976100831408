import { useEffect, useState } from "react";
import { ImportType } from "..";
import {
    GetCmsUnitsDocument,
    GetIndicatorsDocument,
    GetTagsDocument,
    GetThemesAndSubthemesWithCmsIdDocument,
    TagType,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import { IndicatorImport } from "./columns";

export const useRichedIndicatorImports = (
    indicatorImport?: IndicatorImport[],
    importType?: ImportType,
) => {
    const [richIndicators, setRichIndicators] = useState<IndicatorImport[]>();
    const { data } = useGraphQL(
        GetThemesAndSubthemesWithCmsIdDocument,
        undefined,
        {},
        true,
    );
    const { data: unitData } = useGraphQL(
        GetCmsUnitsDocument,
        undefined,
        {},
        true,
    );
    const { data: indicatorData } = useGraphQL(
        GetIndicatorsDocument,
        undefined,
        {},
        true,
    );
    const getTags = useGraphQL(
        GetTagsDocument,
        { type: TagType.Indicator },
        {},
        true,
    );

    const matchThemeColumn = (indicator: IndicatorImport) => {
        const theme = data?.themes.find(
            (theme) =>
                theme.cmsThemeId === indicator.csv.theme ||
                theme.name === indicator.csv.theme,
        );
        indicator.theme_id = theme?.id || undefined;
        indicator.theme = theme?.name;
    };
    const matchSubTthemeColumn = (indicator: IndicatorImport) => {
        const subtheme = data?.themes
            .flatMap((t) => t.subthemes)
            .find(
                (subtheme) =>
                    subtheme.cmsSubThemeId === indicator.csv.subtheme ||
                    subtheme.name === indicator.csv.subtheme,
            );
        console.log("indicator.csv.subtheme", indicator.csv.subtheme, subtheme);
        indicator.subtheme_id = subtheme?.id;
        indicator.subtheme = subtheme?.name;
    };
    const matchUnitColumn = (indicator: IndicatorImport) => {
        const unit = unitData?.units?.find(
            (unit) => unit.id === indicator.csv.unit,
        );
        indicator.cms_unit_id = unit?.id || undefined;
        indicator.unit = unit?.name || undefined;
    };

    const matchExistingIndicators = (indicator: IndicatorImport) => {
        const existing = indicatorData?.indicators?.find(
            (i) => i.uname === indicator.csv.uname,
        );
        if (existing) {
            indicator.indicator_id = existing?.id;
            indicator.already_exists = true;
        } else {
            indicator.already_exists = false;
        }
    };

    const matchTags = (indicator: IndicatorImport) => {
        indicator.tagsIds = [];
        indicator.tags = [];
        indicator.csv.tags
            ?.split(",")
            .map((t) => t.trim())
            .filter((t) => t)
            .forEach((tagStg) => {
                const tag = getTags?.data?.tags.find(
                    (t) => t.name.toLowerCase() === tagStg.trim().toLowerCase(),
                );
                if (tag) {
                    indicator.tagsIds?.push(tag.id);
                    indicator.tags?.push(tag.name);
                }
            });
    };

    const compileCanBeCreate = (
        indicator: IndicatorImport,
        type?: ImportType,
    ) => {
        if (type === ImportType.Reporting) {
            indicator.can_be_created =
                indicator.theme_id &&
                indicator.subtheme_id &&
                indicator.cms_unit_id &&
                indicator.uname &&
                indicator.name &&
                (indicator.order === undefined || !isNaN(indicator.order))
                    ? true
                    : false;
        }
        if (type === ImportType.Supplier) {
            indicator.can_be_created =
                indicator.theme_id &&
                indicator.uname &&
                indicator.name &&
                indicator.cms_unit_id &&
                (indicator.order === undefined || !isNaN(indicator.order))
                    ? true
                    : false;
        }

        if (indicator.can_be_created) {
            indicator.status = "Can be created";
        } else {
            indicator.status = "Cannot be created";
        }
    };
    useEffect(() => {
        const inds =
            indicatorImport &&
            [...indicatorImport]?.map((indicator) => {
                matchThemeColumn(indicator);
                matchSubTthemeColumn(indicator);
                matchExistingIndicators(indicator);
                matchUnitColumn(indicator);
                matchTags(indicator);
                indicator = {
                    ...indicator,
                    order: indicator.csv.order
                        ? parseFloat(indicator.csv.order)
                        : undefined,
                    uname: indicator.csv.uname,
                    name: indicator.csv.name,
                    description: indicator.csv.description,
                    options: indicator.csv.options?.split(";"),
                    document_acceptance: indicator.csv.document_acceptance,
                };
                compileCanBeCreate(indicator, importType);
                return indicator;
            });
        setRichIndicators(inds);
    }, [indicatorImport, importType]);

    return { richIndicators };
};
