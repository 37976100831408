import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";

import { ComponentType, ReactNode } from "react";
import { cn } from "../../../../../lib/utils";

export default function BaseItem({
    children,
    IconLeft,
    IconRight,
    className,
    ...props
}: {
    children: ReactNode;
    IconLeft?: ComponentType<Partial<{ className: string }>>;
    IconRight?: ComponentType<Partial<{ className: string }>>;
} & DropdownPrimitive.MenuCheckboxItemProps) {
    return (
        <DropdownPrimitive.CheckboxItem
            {...props}
            className={cn(
                `cursor-pointer h-[32px] flex items-center px-2 py-1.5 space-x-2.5 rounded-lg focus:outline-none focus:bg-green-50`,
                className,
            )}
        >
            <div className="w-4 h-4">
                {IconLeft && <IconLeft className={cn("text-green w-4 h-4")} />}
            </div>
            <div className="font-small truncate flex-grow hover:text-green">
                {children}
            </div>
            {IconRight && <IconRight className="text-primary w-4 h-4" />}
        </DropdownPrimitive.CheckboxItem>
    );
}
