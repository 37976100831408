import { type S3FileFromBulkUpload } from "@app/usecases/VersionUseCases";
import {
    Dispatch,
    SetStateAction,
    createContext,
    useContext,
    type FC,
    type PropsWithChildren,
} from "react";

export type UploadedFilesContextType = {
    uploadedFiles: {
        list: S3FileFromBulkUpload[];
        byId: Record<string, S3FileFromBulkUpload>;
    };
    setUploadedFiles: Dispatch<
        SetStateAction<{
            list: S3FileFromBulkUpload[];
            byId: Record<string, S3FileFromBulkUpload>;
        }>
    >;
};

const ctx = createContext<UploadedFilesContextType | null>(null);

export const UploadedFilesProvider: FC<
    PropsWithChildren<UploadedFilesContextType>
> = ({ children, setUploadedFiles, uploadedFiles }) => {
    return (
        <ctx.Provider
            value={{
                uploadedFiles,
                setUploadedFiles,
            }}
        >
            {children}
        </ctx.Provider>
    );
};

export function useUploadedFiles() {
    const ctxVal = useContext(ctx);
    if (!ctxVal) {
        throw new Error(
            "useUploadedFiles must be used within a UploadedFilesProvider",
        );
    }
    return ctxVal;
}
