import i18next from "../../../i18n";

i18next.addResourceBundle("en", "SupplierContribution", {
    campaign: "Loading questionnaire...",
    instructions:
        "Welcome ! Use the fields below to provide the requested information. Information and data will be automatically saved as you provide it. Please contact us using the chat if you have any question.",
    nothing_to_complete:
        "You have no pending requests to complete for this campaign.",
    hide_answered: "Hide answered",
    show_answered: "Show answered",
    closed_questionnaire_message:
        "Your questionnaire is closed. If you want to re-open it, contact the owner of the questionnaire.",
    close_questionnaire_button: "Close questionnaire",
    close_questionnaire_confirm: "Close questionnaire",
    close_questionnaire_title:
        "Confirm that ypou want to close the questionnaire",
    close_questionnaire_content:
        "Are you sure you want to close the questionnaire? You will not be able to edit it anymore.",
});
i18next.addResourceBundle("fr", "SupplierContribution", {
    campaign: "Chargement du questionnaire...",
    instructions:
        "Bienvenue ! Utilisez les champs ci-dessous pour fournir les informations demandées. Les informations et les données seront automatiquement sauvegardées au fur et à mesure que vous les fournissez. Veuillez nous contacter via le chat si vous avez des questions.",
    nothing_to_complete:
        "Vous n'avez aucune demande en attente à compléter pour cette campagne.",
    hide_answered: "Masquer les questions répondues",
    show_answered: "Afficher tous",
    closed_questionnaire_message:
        "Votre questionnaire est fermée. Si vous souhaitez le réouvirir, contactez le propriétaire du questionnaire.",
    close_questionnaire_button: "Fermer le questionnaire",
    close_questionnaire_confirm: "Fermer le questionnaire",
    close_questionnaire_title:
        "Confirmez que vous voulez fermer le questionnaire",
    close_questionnaire_content:
        "Êtes-vous sûr de vouloir fermer le questionnaire ? Vous ne pourrez plus le modifier.",
});
