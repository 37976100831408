import DataTable from "@app/components/Table/DataTable";
import LoadingTable from "@app/components/Table/LoadingTable";
import {
    activeIndicatorsAtom,
    selectedActiveFrameworkIdsAtom,
    selectedSubthemesAtom,
    selectedTagsAtom,
    selectedUsersAtom,
} from "@app/store/reportingStore";
import {
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { Indicator, User } from "generated/client/graphql";
import { useAtom } from "jotai";
import { useEffect, useMemo, useState, type FC } from "react";
import ReportingTableActionBar from "./actionBar";
import { useColumns } from "./columns";
import SendReminderModal from "./sendReminderModal";

const IndicatorsTable: FC = () => {
    const columns = useColumns();
    const [{ data, isPending }] = useAtom(activeIndicatorsAtom);
    const [selectedUserIds] = useAtom(selectedUsersAtom);
    const [selecteSubthemesIds] = useAtom(selectedSubthemesAtom);
    const [selecteTagIds] = useAtom(selectedTagsAtom);
    const [selectedFrameworkIds] = useAtom(selectedActiveFrameworkIdsAtom);
    const [rowSelection, setRowSelection] = useState({});

    const memoData = useMemo(
        () =>
            (data?.sort((i1, i2) => i1?.order - i2?.order) ||
                []) as Indicator[],
        [data],
    );
    const table = useReactTable<Indicator>({
        data: memoData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onRowSelectionChange: setRowSelection,
        state: {
            rowSelection,
        },
        autoResetPageIndex: false,
    });

    useEffect(() => {
        table.getColumn("subtheme") &&
            table
                .getColumn("subtheme")!
                .setFilterValue(
                    selecteSubthemesIds.length
                        ? selecteSubthemesIds
                        : undefined,
                );
    }, [selecteSubthemesIds]);

    useEffect(() => {
        table.getColumn("frameworks") &&
            table
                .getColumn("frameworks")!
                .setFilterValue(
                    selectedFrameworkIds.length
                        ? selectedFrameworkIds
                        : undefined,
                );
    }, [selectedFrameworkIds]);

    useEffect(() => {
        table.getColumn("assignedTo") &&
            table
                .getColumn("assignedTo")!
                .setFilterValue(
                    selectedUserIds.length ? selectedUserIds : undefined,
                );
    }, [selectedUserIds]);

    useEffect(() => {
        table.getColumn("tags") &&
            table
                .getColumn("tags")!
                .setFilterValue(
                    selecteTagIds.length ? selecteTagIds : undefined,
                );
    }, [selecteTagIds]);

    useEffect(table.resetPageIndex, [
        selecteSubthemesIds,
        selectedFrameworkIds,
        selectedUserIds,
        selecteTagIds,
    ]);

    const selectedIndicators = table
        .getSelectedRowModel()
        .flatRows.map((r) => r.original);
    const selectedUsers = selectedIndicators
        .map((elt) => elt.entityConfig?.assignedTo)
        .filter((elt) => elt?.id) as User[];
    const uniqueSelectedUsers = [
        ...new Map(selectedUsers.map((item) => [item.id, item])).values(),
    ];
    return (
        <>
            {isPending ? (
                <LoadingTable />
            ) : (
                <DataTable
                    table={table}
                    rowClassName={() => "hover:bg-primary h-[84px]"}
                    type="no-v-padding"
                />
            )}
            <ReportingTableActionBar
                indicators={selectedIndicators}
                unset={table.resetRowSelection}
            />
            <SendReminderModal users={uniqueSelectedUsers}></SendReminderModal>
        </>
    );
};

export default IndicatorsTable;
