import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "RequirementStatusDropdown", {
    placeholder: "Status",
    OK: "OK",
    NOT_OK: "Not OK",
});

i18next.addResourceBundle("fr", "RequirementStatusDropdown", {
    placeholder: "Statut",
    OK: "OK",
    NOT_OK: "Pas OK",
});
