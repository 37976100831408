export const textType = {
    errorCode: {
        Comp: "h1",
        style: "hero-3 text-center mb-2",
    },
    errorMessage: {
        Comp: "h2",
        style: "hero-5 text-center mb-4",
    },
    errorDescription: {
        Comp: "p",
        style: "text-base text-secondary text-center mb-7",
    },
} as const;

export const gridTemplateAreas = `
    "navbar"
    "body"
`;
