import i18next from "@app/i18n";

i18next.addResourceBundle("en", "ErrorPage", {
    go_to_homepage: "Go to homepage",
    access_denied: {
        status_code: "403",
        status_message: "Access denied",
        description:
            "The page your were looking for might have been removed, had it’s name changed or is temporary unavailable.",
    },
    maintenance: {
        status_message: "Sorry! We’re under maintenance",
        description:
            "The page your’re looking for is currently under maintenance and will be back soon.",
    },
    not_found: {
        status_code: "404",
        status_message: "Oops! Page not found",
        description:
            "The page your were looking for might have been removed, had it’s name changed or is temporary unavailable.",
    },
    copy_error_message: "Copy error message",
});

i18next.addResourceBundle("fr", "ErrorPage", {
    access_denied: {
        status_code: "403",
        status_message: "Accès refusé",
        description:
            "La page que vous recherchiez a peut-être été supprimée, son nom a été modifié ou elle est temporairement indisponible.",
    },
    maintenance: {
        status_message: "Désolé ! Nous sommes en maintenance",
        description:
            "La page que vous recherchez est actuellement en maintenance et sera bientôt de retour.",
    },
    not_found: {
        status_code: "404",
        status_message: "Oups ! Page introuvable",
        description:
            "La page que vous recherchiez a peut-être été supprimée, son nom a été modifié ou elle est temporairement indisponible.", // Translated description
    },
    copy_error_message: "Copier le message d'erreur",
});
