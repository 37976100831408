import { ColumnDef } from "@tanstack/react-table";
import { EditPencil, MoreHoriz, Trash } from "iconoir-react";
import { useSetAtom } from "jotai";
import { useTranslation } from "react-i18next";

import { Entity } from "../../../../../../generated/client/graphql";
import {
    ActionDropdownMenu,
    ActionDropdownMenuContent,
    ActionDropdownMenuItem,
    ActionDropdownMenuItemDanger,
    ActionDropdownMenuTrigger,
} from "../../../../../components/ActionDropdown";
import { ButtonType } from "../../../../../components/Button/ButtonShared";
import ButtonSmall from "../../../../../components/Button/ButtonSmall";
import Header from "../../../../../components/Table/Header";
import { toUpdateSiteAtom } from "../UpdateSiteModal";
import "./i18n";

export const columns: ColumnDef<Entity>[] = [
    {
        accessorKey: "name",
        header: ({ column }) => {
            const { t } = useTranslation("SitesTable");
            return <Header title={t("site")} column={column} />;
        },
        cell: ({ getValue }) => (
            <span className=" font-bold">{getValue() as string}</span>
        ),
        meta: {
            colWidth: "60%",
        },
    },
    {
        accessorKey: "type",
        header: ({ column }) => {
            const { t } = useTranslation("SitesTable");
            return <Header title={t("type")} column={column} />;
        },
    },
    {
        id: "actions",
        cell: ({ row }) => {
            const { t } = useTranslation("SitesTable");
            const setToUpdateSite = useSetAtom(toUpdateSiteAtom);
            return (
                <ActionDropdownMenu modal={false}>
                    <ActionDropdownMenuTrigger asChild>
                        <ButtonSmall
                            variant={ButtonType.OUTLINED}
                            IconLeft={MoreHoriz}
                        />
                    </ActionDropdownMenuTrigger>
                    <ActionDropdownMenuContent>
                        <ActionDropdownMenuItem
                            onSelect={() => {
                                setToUpdateSite(row.original);
                            }}
                            Icon={({ className }) => (
                                <EditPencil className={className} />
                            )}
                            text={t("edit_site")}
                        />
                        <ActionDropdownMenuItemDanger
                            disabled={true}
                            Icon={({ className }) => (
                                <Trash className={className} />
                            )}
                            text={t("delete_site")}
                        />
                    </ActionDropdownMenuContent>
                </ActionDropdownMenu>
            );
        },
        meta: {
            colWidth: "60px",
        },
    },
];
