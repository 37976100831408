import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import { useTranslation } from "react-i18next";
import {
    GetSubthemesDocument,
    SubTheme,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import FilterTrigger from "../../components/triggers/Filter";
import ThemeDropdown from "./dropdown";
import "./i18n";

const SubthemeFilter = ({
    themeId,
    subthemeIds,
    setSubthemeIds,
    className,
}: {
    themeId: string | undefined;
    subthemeIds: string[] | undefined;
    setSubthemeIds: (subthemeIds: string[] | undefined) => void;
    className?: string;
}) => {
    const { t } = useTranslation("SubthemeDropdown");

    const getSubthemes = useGraphQL(GetSubthemesDocument, undefined, {}, true);

    return (
        <DropdownMenu>
            <FilterTrigger
                unsetCallback={
                    subthemeIds ? () => setSubthemeIds(undefined) : undefined
                }
                active={subthemeIds !== undefined}
                className={className}
            >
                {t("placeholder")}
            </FilterTrigger>
            <ThemeDropdown
                subthemeIds={subthemeIds}
                onSubthemeIdsChange={(ids: string[]) =>
                    setSubthemeIds(ids.length > 0 ? ids : undefined)
                }
                subthemes={
                    getSubthemes?.data?.subthemes.filter(
                        (sb) =>
                            (themeId === undefined || sb.themeId === themeId) &&
                            sb.active
                    ) as SubTheme[] | undefined
                }
            />
        </DropdownMenu>
    );
};

export default SubthemeFilter;
