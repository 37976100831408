import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ImportType } from "..";
import {
    CreateIndicatorsDocument,
    IndicatorType,
} from "../../../../../generated/client/graphql";
import { useGraphQLMutation } from "../../../../QueryClientWithHeaders";
import ButtonRegular from "../../../../components/Button/ButtonRegular";
import Floatbar from "../../../../components/Floatbar";
import { IndicatorImport } from "./columns";

const SaveIndicators = ({
    toCreateIndicators,
    importType,
}: {
    toCreateIndicators: IndicatorImport[];
    importType?: ImportType;
}) => {
    const { t } = useTranslation("LoadIndicatorTable");
    const navigate = useNavigate();

    const { mutate, isPending } = useGraphQLMutation(
        CreateIndicatorsDocument,
        {},
        true
    );

    const onSave = () => {
        if (importType === undefined) return;
        mutate(
            {
                inputs: toCreateIndicators
                    .filter((i) => i.can_be_created)
                    .map((i) => ({
                        indicatorId: i.indicator_id,
                        name: i.name!,
                        uname: i.uname!,
                        description: i.description || "",
                        cmsUnitId: i.cms_unit_id!,
                        themeId: i.theme_id!,
                        subthemeId: i.subtheme_id!,
                        type:
                            importType === ImportType.Supplier
                                ? IndicatorType.Supplier
                                : IndicatorType.Reporting,
                        order: i.order,
                        options: i.options,
                        documentAcceptance: i.document_acceptance
                            ? i.document_acceptance
                            : undefined,
                        tagIds: i.tagsIds,
                    })),
            },
            {
                onSuccess: () => {
                    navigate(0);
                },
            }
        );
    };

    return (
        <Floatbar
            open={importType && toCreateIndicators.length > 0 ? true : false}
            text={t("ready")}
            primary={
                <ButtonRegular
                    text={t("create", { count: toCreateIndicators.length })}
                    onClick={onSave}
                    loading={isPending}
                />
            }
            secondary={<></>}
        />
    );
};

export default SaveIndicators;
