import { LocalStorageOnboardingProgressRepository } from "@app/repositories/LocalStorageRepositories/OnboardingProgressRepository";
import { type OnboardingProgress } from "@app/repositories/OnboardingProgressRepository";
import {
    getOnboardingProgress,
    setOnboardingProgress,
    toggleIsOnboardingSectionCollapsed,
    type OnboardingProgressEntity,
} from "@app/usecases/OnboardingUseCases";
import { atom } from "jotai";
import { atomWithDefault } from "jotai/utils";
/**
 * Init repositories and use cases
 */
const onboardingProgressRepository =
    new LocalStorageOnboardingProgressRepository();

/**
 * read
 */
export const onboardingProgressAtom = atomWithDefault(() =>
    getOnboardingProgress({ onboardingProgressRepository }),
);

export const isOnboardingSectionCollapsedAtom = atomWithDefault(() =>
    onboardingProgressRepository.getIsOnboardingSectionCollapsed(),
);

/**
 * write
 */
export const setOnboardingProgressAtom = atom<
    null,
    [OnboardingProgress],
    OnboardingProgressEntity
>(null, (_get, set, update) => {
    const onboardingProgress = setOnboardingProgress(
        { onboardingProgressRepository },
        update,
    );
    set(onboardingProgressAtom, onboardingProgress);
    return onboardingProgress;
});

export const toggleIsOnboardingSectionCollapsedAtom = atom<null, [], boolean>(
    null,
    (_get, set) => {
        const isCollapsed = toggleIsOnboardingSectionCollapsed({
            onboardingProgressRepository,
        });
        set(isOnboardingSectionCollapsedAtom, isCollapsed);
        return isCollapsed;
    },
);
