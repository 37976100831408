import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "SupplierDropdown", {
    select_all: "Select all",
    placeholder: "Suppliers",
});

i18next.addResourceBundle("fr", "SupplierDropdown", {
    select_all: "Tout sélectionner",
    placeholder: "Suppliers",
});
