import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { NavArrowDown } from "iconoir-react";
import React from "react";
import { ButtonType } from "../../../Button/ButtonShared";
import ButtonSmall from "../../../Button/ButtonSmall";

const BaseTrigger = React.forwardRef(
    (
        {
            children,
            variant,
            ...props
        }: {
            variant?: ButtonType;
            loading?: boolean;
        } & React.ButtonHTMLAttributes<HTMLButtonElement>,
        ref: React.Ref<HTMLButtonElement>
    ) => {
        return (
            <DropdownPrimitive.Trigger asChild>
                <ButtonSmall
                    ref={ref}
                    variant={variant || ButtonType.OUTLINED}
                    IconRight={NavArrowDown}
                    {...props}
                >
                    {children}
                </ButtonSmall>
            </DropdownPrimitive.Trigger>
        );
    }
);

export default BaseTrigger;
