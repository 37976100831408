import i18next from "../../../i18n";

i18next.addResourceBundle("en", "SustainabilityCenterFaqsBlock", {
    title: "FAQ",
    empty_text: "Show frequently asked questions",
    add_faq_text: "Add FAQs",
});

i18next.addResourceBundle("fr", "SustainabilityCenterFaqsBlock", {
    title: "FAQ",
    empty_text: "Montrer les questions fréquentes",
    add_faq_text: "Ajouter FAQ",
});
