import {
    GetAllEntitiesDocument,
    GetAllEntitiesQuery,
} from "../../../generated/client/graphql";
import { EntityRepository } from "../EntityRepository";
import { AccessHeadersHolder } from "../AccessHeadersHolder";
import { graphqlClientKoyeb } from "../clients";

export class GraphQLEntityRepository implements EntityRepository {
    get = (): Promise<GetAllEntitiesQuery> => {
        return graphqlClientKoyeb.request(
            GetAllEntitiesDocument,
            undefined,
            AccessHeadersHolder.getHeaders()
        );
    };
}
