import {
    inactiveIndicatorsAtom,
    selectedInactiveFrameworkIdsAtom,
    selectedInactiveSubthemesAtom,
    updateManyIndicatorEntityConfigAtom,
} from "@app/store/reportingStore";
import SearchBar from "@design-system/Inputs/SearchBar";
import {
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Indicator } from "../../../../../generated/client/graphql";
import ButtonRegular from "../../../../components/Button/ButtonRegular";
import {
    CenterModal,
    CenterModalContent,
} from "../../../../components/Modal/CenterModal";
import DataTable from "../../../../components/Table/DataTable";
import { inactiveIndicatorsModalIsOpenAtom } from "../../context";
import "../../i18n";
import { inactiveIndicatorsTableColumns } from "../IndicatorsTable/columns";

export default function InactiveIndicatorsModal() {
    const { t } = useTranslation("ReportingPage");
    const [open, setOpen] = useAtom(inactiveIndicatorsModalIsOpenAtom);

    const [data] = useAtom(inactiveIndicatorsAtom);
    const [{ mutate, isPending }] = useAtom(
        updateManyIndicatorEntityConfigAtom,
    );
    const selecteSubthemesIds = useAtomValue(selectedInactiveSubthemesAtom);
    const selectedFrameworkIds = useAtomValue(selectedInactiveFrameworkIdsAtom);

    const [rowSelection, setRowSelection] = useState({});
    const [searchFilter, setSearchFilter] = useState<string>();
    const table = useReactTable<Indicator>({
        data: (data?.sort((i1, i2) => i1?.order - i2?.order) ||
            []) as Indicator[],
        columns: inactiveIndicatorsTableColumns(),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onRowSelectionChange: setRowSelection,

        state: {
            rowSelection,
            globalFilter: searchFilter,
        },
        autoResetPageIndex: false,
    });

    useEffect(() => {
        table
            .getColumn("subtheme")!
            .setFilterValue(
                selecteSubthemesIds.length ? selecteSubthemesIds : undefined,
            );
    }, [selecteSubthemesIds]);

    useEffect(() => {
        table
            .getColumn("frameworks")!
            .setFilterValue(
                selectedFrameworkIds.length ? selectedFrameworkIds : undefined,
            );
    }, [, selectedFrameworkIds]);

    const selectedIndicators = table
        .getSelectedRowModel()
        .flatRows.map((row) => row.original);

    const onSetRelevant = () => {
        const ids = selectedIndicators.map(
            (d) => d.entityConfig?.id,
        ) as string[];
        if (!ids) {
            return;
        }
        mutate(
            {
                indicatorEntityConfigIds: ids,
                set: {
                    active: true,
                    userId: undefined,
                },
            },
            {
                onSuccess: () => {
                    table.resetRowSelection();
                    setOpen(false);
                },
            },
        );
        table.resetRowSelection();
    };

    return (
        <CenterModal open={open} onOpenChange={setOpen} modal={true}>
            <CenterModalContent
                title={t("not_relevant_modal_title")}
                actions={
                    <ButtonRegular
                        text={t("set_relevant", {
                            count: selectedIndicators.length,
                        })}
                        onClick={onSetRelevant}
                        loading={isPending}
                        disabled={selectedIndicators.length === 0}
                    />
                }
            >
                <div className="w-[920px]">
                    {data ? (
                        <div className="flex flex-col gap-4">
                            <SearchBar
                                searchString={searchFilter}
                                setSearchString={setSearchFilter}
                                className="w-full"
                            />
                            <DataTable
                                table={table}
                                rowClassName={() => "cursor-pointer"}
                            />
                        </div>
                    ) : (
                        <div>Error, refresh ...</div>
                    )}
                </div>
            </CenterModalContent>
        </CenterModal>
    );
}
