import * as DropdownPrimitive from "@radix-ui/react-dropdown-menu";
import { SupplierQuestionnaireTemplate } from "../../../../../generated/client/graphql";
import SingleSelectDropdown from "../../components/dropdowns/SingleSelect";
import "./i18n";

const SupplierQuestionnaireTemplatesDropdownSingleSelect = ({
    templates,
    templateId,
    onTemplateIdChange,
    ...props
}: {
    templates?: SupplierQuestionnaireTemplate[];
    templateId?: string;
    onTemplateIdChange: (id: string) => void;
} & DropdownPrimitive.DropdownMenuContentProps) => {
    // const { t } = useTranslation("SupplierQuestionnaireTemplatesDropdown");

    return (
        <SingleSelectDropdown
            disableSearch
            value={templateId}
            onValueChange={(id) => id && onTemplateIdChange(id)}
            values={
                templates?.map((template) => ({
                    value: template.id,
                    node: template.name,
                })) || []
            }
            {...props}
        />
    );
};

export default SupplierQuestionnaireTemplatesDropdownSingleSelect;
