import { ReactComponent as PDFDocumentIllustration } from "@app/assets/illustrations/document-pdf.svg";
import { ReactComponent as PPTDocumentIllustration } from "@app/assets/illustrations/document-ppt.svg";
import { ReactComponent as UnknownDocumentIllustration } from "@app/assets/illustrations/document-unknown-format.svg";
import { ReactComponent as XLSDocumentIllustration } from "@app/assets/illustrations/document-xls.svg";
import { hasUnapprovedLastVersion } from "@app/usecases/DocumentUseCases";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useModalActions } from "./useModalActions";
import { DocumentVersionStatus } from "@generated/client/graphql";

export function useDocumentUploadModalItem(id: string) {
    const { t } = useTranslation("DocumentsUploadModal");
    const statusRef = useRef<DocumentVersionStatus>(
        DocumentVersionStatus.Draft,
    );

    const {
        associateFileToDocuments,
        documentsForSearch,
        deleteFile,
        dissociateFileFromDocuments,
        uploadedFiles,
    } = useModalActions();

    const currentFile = uploadedFiles.byId[id];

    const Illustration = useMemo(() => {
        switch (currentFile?.format) {
            case "PDF":
                return PDFDocumentIllustration;
            case "PPT":
                return PPTDocumentIllustration;
            case "XLS":
                return XLSDocumentIllustration;
            default:
                return UnknownDocumentIllustration;
        }
    }, [currentFile.format]);

    const errorMsg = currentFile.associatedDocuments?.some(
        (doc) => doc.hasAlreadyEntry,
    )
        ? t("error_already_an_entry")
        : undefined;

    const handleStatusChange = (status: DocumentVersionStatus) => {
        statusRef.current = status;
        const associatedDocs = currentFile?.associatedDocuments?.map((doc) => ({
            ...doc,
            status,
        }));
        if (!associatedDocs) return;

        dissociateFileFromDocuments(id);
        associateFileToDocuments(id, associatedDocs);
    };

    const handleButtonClick = () => {
        return currentFile?.associatedDocuments?.length
            ? dissociateFileFromDocuments(id)
            : deleteFile(id);
    };

    const handleAssociatedDocumentsChange = (docIds: (string | null)[]) => {
        if (!documentsForSearch) return;
        const docs = docIds
            .map((id) => {
                const rawDoc = documentsForSearch.find((doc) => doc.id === id);
                if (!rawDoc) throw new Error("Document not found");
                return {
                    lastVersionId: rawDoc.lastVersion?.id,
                    id: rawDoc.id,
                    hasAlreadyEntry: hasUnapprovedLastVersion(rawDoc),
                    name: rawDoc.name,
                    status: statusRef.current,
                    theme: rawDoc.theme
                        ? {
                              color: rawDoc.theme?.color ?? "",
                              name: rawDoc.theme?.name,
                          }
                        : undefined,
                };
            })
            .filter((doc) => doc);

        dissociateFileFromDocuments(id);
        associateFileToDocuments(id, docs);
    };

    const buttonIconName: "close" | "trash" = currentFile?.associatedDocuments
        ?.length
        ? "close"
        : "trash";

    return {
        buttonIconName,
        currentFile,
        documentsForSearch,
        errorMsg,
        handleButtonClick,
        handleAssociatedDocumentsChange,
        handleStatusChange,
        Illustration,
        uploadedFiles,
    };
}
