import { useTranslation } from "react-i18next";
import {
    Document,
    DocumentActivationStatus,
    DocumentType,
} from "../../../../../generated/client/graphql";
import ButtonRegular from "../../../../components/Button/ButtonRegular";
import SearchFilter from "../../../../components/Filters/Search";
import {
    CenterModal,
    CenterModalContent,
} from "../../../../components/Modal/CenterModal";
import DataTable from "../../../../components/Table/DataTable";
import { useUpdateDocuments } from "../../hooks";
import { useDocumentPageState } from "../../hooks/pageState";
import { useUnrelevantDocumentsTable } from "../DocumentsTable";
import "./i18n";
export default function UnrelevantDocumentsModal({
    documents,
    type,
    open,
    setOpen,
}: {
    documents: Document[];
    type: DocumentType;
    open: boolean;
    setOpen: (open: boolean) => void;
}) {
    const { t } = useTranslation("UnrelevantDocumentsModal");

    const { mutate, isPending } = useUpdateDocuments();
    const { table } = useUnrelevantDocumentsTable(documents, type);
    const selectedDocuments = table
        .getSelectedRowModel()
        .flatRows.map((row) => row.original);

    const onSetRelevant = () => {
        mutate(
            selectedDocuments.map((d) => d.id),
            { activationStatus: DocumentActivationStatus.Relevant },
        );
        table.resetRowSelection();
    };
    const { state, setState } = useDocumentPageState();

    return (
        <CenterModal open={open} onOpenChange={setOpen} modal={true}>
            <CenterModalContent
                className="w-[820px] h-[500px]"
                title={t("title")}
                actions={
                    <ButtonRegular
                        text={t("set_relevant", {
                            count: selectedDocuments.length,
                        })}
                        onClick={onSetRelevant}
                        loading={isPending}
                        disabled={selectedDocuments.length === 0}
                    />
                }
            >
                <div className="w-[820px]">
                    {documents ? (
                        <div className="flex flex-col gap-4 h-[500px]">
                            <SearchFilter
                                searchString={state.unrelevantSearchFilter}
                                setSearchString={(unrelevantSearchFilter) =>
                                    setState({ unrelevantSearchFilter })
                                }
                                className="w-full box-border"
                            />
                            <div className="h-full overflow-auto">
                                <DataTable
                                    table={table}
                                    rowClassName={() => "cursor-pointer"}
                                />
                            </div>
                        </div>
                    ) : (
                        <div>Error, refresh ...</div>
                    )}
                </div>
            </CenterModalContent>
        </CenterModal>
    );
}
