import { Table } from "@tanstack/react-table";
import { NavArrowLeft, NavArrowRight } from "iconoir-react";
import { forwardRef } from "react";
import { cn } from "../../../lib/utils";
import ButtonSmallBase from "../../Button/ButtonSmall/ButtonSmallBase";
import { DropdownMenu } from "../../Dropdown/components";
import SimpleItem from "../../Dropdown/components/dropdowns/items/SimpleItem";
import DropdownContentLayout from "../../Dropdown/components/dropdowns/layout";
import BaseTrigger from "../../Dropdown/components/triggers/Base";

interface ButtonPaginationProps {
    text?: string;
    icon?:
        | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
        | React.ForwardRefExoticComponent<any>;
    disabled?: boolean;
    active?: boolean;
    onClick?: (event: React.MouseEvent) => void;
}
const ButtonPagination = forwardRef(
    (
        { text, icon, onClick, disabled, active }: ButtonPaginationProps,
        ref: React.Ref<HTMLButtonElement>,
    ) => {
        return (
            <ButtonSmallBase
                ref={ref}
                text={text}
                IconLeft={icon}
                onClick={onClick}
                disabled={disabled}
                active={active}
                className={cn(
                    "hover:bg-secondary active:bg-tertiary disabled:bg-white",
                    active && "border border-green",
                )}
                textClassName={
                    disabled && !active ? "text-neutral-300" : "text-primary"
                }
                iconClassName={
                    disabled && !active ? "text-neutral-300" : "text-primary"
                }
            />
        );
    },
);
interface PaginationProps {
    table: Table<any>;
}

const InnerPaginationMoreThanSeven = ({ table }: PaginationProps) => {
    const pageCount = table.getPageCount();

    const activePage = table.getState().pagination.pageIndex;
    const isInMiddle = activePage > 2 && activePage < pageCount - 3;
    return (
        <>
            <ButtonPagination
                text={`1`}
                active={activePage === 0}
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
            />
            <ButtonPagination
                text={`2`}
                active={activePage === 1}
                onClick={() => table.setPageIndex(1)}
                disabled={false}
            />
            {isInMiddle ? (
                <>
                    <ButtonPagination
                        text={`...`}
                        active={false}
                        disabled={true}
                    />
                    <ButtonPagination
                        text={`${activePage + 1}`}
                        active={true}
                        disabled={true}
                    />
                    <ButtonPagination
                        text={`...`}
                        active={false}
                        disabled={true}
                    />
                </>
            ) : (
                <>
                    <ButtonPagination
                        text={`3`}
                        active={activePage === 2}
                        onClick={() => table.setPageIndex(2)}
                        disabled={false}
                    />
                    <ButtonPagination
                        text={`...`}
                        active={false}
                        disabled={true}
                    />
                    <ButtonPagination
                        text={`${pageCount - 2}`}
                        active={activePage === pageCount - 3}
                        onClick={() => table.setPageIndex(pageCount - 3)}
                        disabled={false}
                    />
                </>
            )}

            <ButtonPagination
                text={`${pageCount - 1}`}
                active={activePage === pageCount - 2}
                onClick={() => table.setPageIndex(pageCount - 2)}
                disabled={false}
            />
            <ButtonPagination
                text={`${pageCount}`}
                active={activePage === pageCount - 1}
                onClick={() => table.setPageIndex(pageCount - 1)}
                disabled={!table.getCanNextPage()}
            />
        </>
    );
};

const InnerPaginationLessThanSeven = ({ table }: PaginationProps) => {
    const pageCount = table.getPageCount();

    const activePage = table.getState().pagination.pageIndex;
    return Array.from({ length: pageCount }, (_, i) => i).map((i) => (
        <ButtonPagination
            key={i}
            text={`${i + 1}`}
            active={activePage === i}
            onClick={() => table.setPageIndex(i)}
            disabled={false}
        />
    ));
};
export default function Pagination({ table }: PaginationProps) {
    const pageCount = table.getPageCount();
    if (table.getPageCount() < 2) return <></>;
    return (
        <div className="flex justify-between">
            <div className="flex">
                <ButtonPagination
                    icon={NavArrowLeft}
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                />
                {pageCount <= 7 ? (
                    <InnerPaginationLessThanSeven table={table} />
                ) : (
                    <InnerPaginationMoreThanSeven table={table} />
                )}
                <ButtonPagination
                    icon={NavArrowRight}
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                />
            </div>
            <div>
                <DropdownMenu>
                    <BaseTrigger>
                        {table.getState().pagination.pageSize}
                    </BaseTrigger>
                    <DropdownContentLayout
                        className="w-fit"
                        sideOffset={5}
                        align="end"
                        alignOffset={0}
                    >
                        {[10, 25, 50, 100].map((pageSize) => (
                            <SimpleItem
                                key={pageSize}
                                className="min-w-0"
                                checked={
                                    table.getState().pagination.pageSize ===
                                    pageSize
                                }
                                onClick={() =>
                                    table.setPageSize(Number(pageSize))
                                }
                            >
                                {pageSize}
                            </SimpleItem>
                        ))}
                    </DropdownContentLayout>
                </DropdownMenu>
            </div>
        </div>
    );
}
