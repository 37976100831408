import { datadogRum } from "@datadog/browser-rum";
import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { currentUserAtom } from "./store/userStore";

datadogRum.init({
    applicationId: import.meta.env.VITE_DATADOG_APP_ID,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "datadoghq.eu",
    service: "beavr-cloud",
    env: "prod",
    // Specify a version number to identify the deployed version of your application in Datadog
    version: "1.0.0",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
});

export const useSetDatadogRumUser = () => {
    const { data } = useAtomValue(currentUserAtom);

    useEffect(() => {
        const user = data?.currentUser;

        if (user)
            datadogRum.setUser({
                id: user.id,
                name: `${user.firstName} ${user.lastName}`,
                email: user.email,
                tenant: user.permission?.tenant?.uname,
                role: user.permission?.role,
            });
    }, [data?.currentUser]);
};
