import {
    Document,
    DocumentType,
} from "../../../../../generated/client/graphql";
import { useEditorContext } from "../../../../screens/Document/Edit/hooks/useEditor";
import InformationBlock from "./InformationBlock";
import RelatedDocumentList from "./RelatedDocumentsList";
import "./i18n";

export const Relations = {
    CHILDREN: "CHILDREN",
    PARENTS: "PARENTS",
} as const;
export type DocumentRelationType = (typeof Relations)[keyof typeof Relations];

const InfoTab = ({
    documentType,
}: {
    documentType: DocumentType | undefined;
}) => {
    const { editor, version } = useEditorContext();

    const relatedDocuments = version?.document?.children?.length
        ? version?.document?.children
        : version?.document?.parents?.length
        ? version?.document?.parents
        : [];

    const relationType = !!version?.document?.children?.length
        ? Relations.CHILDREN
        : Relations.PARENTS;

    const insertDocument = (relatedDocument: Document) => {
        if (!editor) return;
        editor.commands.insertContent(
            relatedDocument?.lastVersion?.tiptapData || "{}"
        );
    };
    return (
        <div className="flex flex-col items-start gap-4 self-stretch px-4 overflow-scroll scrollbar-hide">
            <InformationBlock documentType={documentType} />
            <RelatedDocumentList
                relatedDocuments={relatedDocuments}
                relationType={relationType}
                insertDocument={insertDocument}
            />
        </div>
    );
};

export default InfoTab;
