import { ReactNode } from "react";

interface ContainerProps {
    children: ReactNode;
}

/**
 * @deprecated
 */
export default function Container({ children }: ContainerProps) {
    return (
        <div className=" flex flex-1 flex-col w-full bg-white border border-neutral-150 rounded-xl shadow-container">
            {children}
        </div>
    );
}
