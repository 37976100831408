import {
    OVERDUE_THREASHOLD_IN_WEEKS,
    getRemainingWeeks,
} from "@app/usecases/DashboardUseCases";
import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "@design-system/DataDisplay/Badge";

import "../../i18n";

export const DeadlineStatusBadge: FC<{
    hideBadge: boolean;
    date: Date;
}> = ({ hideBadge, date }) => {
    const { t } = useTranslation("NewDashboard");
    const weeksToGo = getRemainingWeeks(date);
    return hideBadge ||
        weeksToGo > OVERDUE_THREASHOLD_IN_WEEKS ? null : weeksToGo <= 0 ? (
        <Badge content={t("overdue")} variant="error" />
    ) : (
        <Badge
            content={t("deadline_in", { count: weeksToGo })}
            variant="error"
        />
    );
};
