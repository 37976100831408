export enum ButtonType {
    DEFAULT,
    ELEVATED,
    FLAT_PRIMARY,
    FLAT_DANGER,
    TONAL,
    OUTLINED,
    OUTLINED_DANGER,
    TEXT,
    PLAIN,
}

export interface ButtonProps {
    text?: string;
    IconLeft?:
        | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
        | React.ForwardRefExoticComponent<any>;
    IconRight?:
        | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
        | React.ForwardRefExoticComponent<any>;
    disabled?: boolean;
    loading?: boolean;
    active?: boolean;
    onRightClick?: (event: React.MouseEvent) => void;
    tooltipContent?: string;
}
