import "./i18n";

import { Table } from "@tanstack/react-table";
import { Requirement } from "../../../../../generated/client/graphql";
import SearchFilter from "../../../../components/Filters/Search";
import ThemeTabFilter from "../../../../components/Filters/ThemeTabFilter";

import { useAtom } from "jotai";
import {
    searchFilterAtom,
    subthemeIdsFilterAtom,
    themeIdFilterAtom,
} from "../../context";
import RequirementTable from "./table";
export default function RequirementTableSection({
    table,
}: {
    table: Table<Requirement>;
}) {
    const [themeIdFilter, setThemeIdFilter] = useAtom(themeIdFilterAtom);
    const [subthemeIdsFilter, setSubthemeIdsFilter] = useAtom(
        subthemeIdsFilterAtom,
    );
    const [searchFilter, setSearchFilter] = useAtom(searchFilterAtom);

    return (
        <div className="space-y-4">
            <div className="flex justify-between">
                <ThemeTabFilter
                    themeId={themeIdFilter}
                    setThemeId={setThemeIdFilter}
                    subthemeIds={subthemeIdsFilter}
                    setSubthemeIds={setSubthemeIdsFilter}
                />
                <SearchFilter
                    searchString={searchFilter}
                    setSearchString={setSearchFilter}
                />
            </div>

            <RequirementTable table={table} />
        </div>
    );
}
