import React, { FunctionComponent } from "react";
import { ButtonProps } from "./ButtonShared";

const ButtonElevated = React.forwardRef(
    (
        {
            Base,
            className,
            ...props
        }: {
            Base: FunctionComponent<
                ButtonProps & {
                    buttonClassName?: string;
                    textClassName?: string;
                    iconClassName?: string;
                } & React.ButtonHTMLAttributes<HTMLButtonElement>
            >;
        } & ButtonProps &
            React.ButtonHTMLAttributes<HTMLButtonElement> &
            React.RefAttributes<HTMLButtonElement>,
        forwardedRef: React.Ref<HTMLButtonElement>,
    ) => {
        return (
            <Base
                ref={forwardedRef}
                className={`shadow-button_elevated disabled:shadow-none active:shadow-none ${
                    (props.loading || props.active) && "shadow-none"
                } 
             bg-green hover:bg-green-300 active:bg-green-650 ${className}`}
                textClassName={props.disabled ? "text-disabled" : "text-white"}
                iconClassName="text-white"
                {...props}
            />
        );
    },
);

export default ButtonElevated;
