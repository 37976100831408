import i18next from "../../../../i18n";

i18next.addResourceBundle("en", "RequirementModal", {
    owner: "Owner",
    mark_not_relevant: "Mark as not revelant",
    mark_relevant: "Mark as revelant",
});

i18next.addResourceBundle("fr", "RequirementModal", {
    owner: "Propriétaire",
    mark_not_relevant: "Définir comme non pertinent",
    mark_relevant: "Définir comme pertinent",
});
