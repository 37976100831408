import { GaugeCircular } from "@app/components/Progress/ProgressCircular/GaugeCircular";
import FrameworkSection from "@app/screens/Frameworks/framework/components/Section";
import { FlexRow } from "@design-system/Layout/Flex";
import { Text } from "@design-system/Typography/Text";
import { DatapointForm } from "../../../DatapointForm";
import { DisclosureRequirement as DisclosureRequirementType } from "../../data";

const DisclosureRequirement = ({
    disclosureRequirement,
}: {
    disclosureRequirement: DisclosureRequirementType;
}) => {
    const stats = disclosureRequirement.stats;
    const done = stats.validated + stats.notMaterial;
    const total = stats.total;
    return (
        <FrameworkSection.Block className="w-full">
            <FrameworkSection.BlockHeader id={disclosureRequirement.cmsId}>
                <FlexRow
                    gap="7"
                    py="2"
                    pl="2"
                    justifyContent="between"
                    alignItems="center"
                >
                    <Text variant="header2">{disclosureRequirement.name}</Text>
                    <FlexRow
                        gap="8"
                        justifyContent="between"
                        alignItems="center"
                    >
                        <FlexRow
                            gap="2.5"
                            justifyContent="between"
                            alignItems="center"
                        >
                            <Text variant="body">
                                {done} / {total}
                            </Text>
                            <GaugeCircular
                                size={20}
                                progress={(done / total) * 100}
                            />
                        </FlexRow>
                    </FlexRow>
                </FlexRow>
            </FrameworkSection.BlockHeader>
            <FrameworkSection.BlockBody className="w-full">
                {disclosureRequirement.datapoints.map((datapoint, idx) => (
                    <DatapointForm
                        datapoint={datapoint}
                        key={idx}
                    ></DatapointForm>
                ))}
            </FrameworkSection.BlockBody>
        </FrameworkSection.Block>
    );
};

export default DisclosureRequirement;
