import { Cancel, NavArrowDown } from "iconoir-react";
import { ButtonType } from "../../Button/ButtonShared";
import ButtonSmall from "../../Button/ButtonSmall";
import MultiSelect from "../../Dropdown/MultiSelect";
import "../../Dropdown/MultiSelect/i18n";

interface MultiFilterProps {
    activeValueIds: (string | null)[] | undefined;
    setActiveValueIds: (v: (string | null)[] | undefined) => void;
    values:
        | {
              id: string | null;
              name?: string;
              node?: React.ReactNode;
          }[]
        | undefined;
    placeholder: string;
}
const MultiFilter = ({
    activeValueIds,
    setActiveValueIds,
    values,
    placeholder,
}: MultiFilterProps) => {
    const activeValueName =
        activeValueIds &&
        activeValueIds.length === 1 &&
        values?.find(({ id }) => id === activeValueIds[0])?.name;

    return (
        <MultiSelect
            activeValueIds={activeValueIds}
            onActiveValueIdsChange={(ids) =>
                setActiveValueIds(ids && ids.length > 0 ? ids : undefined)
            }
            values={values}
            triggerButton={
                <ButtonSmall
                    variant={ButtonType.OUTLINED}
                    active={activeValueIds !== undefined}
                    text={
                        activeValueName
                            ? activeValueName
                            : activeValueIds && activeValueIds?.length > 1
                            ? `${placeholder} (${activeValueIds?.length})`
                            : `${placeholder}`
                    }
                    IconRight={
                        activeValueIds !== undefined ? Cancel : NavArrowDown
                    }
                    onRightClick={
                        activeValueIds !== undefined
                            ? () => setActiveValueIds(undefined)
                            : undefined
                    }
                    className="h-[28px] maw-w-full"
                />
            }
        />
    );
};

export default MultiFilter;
