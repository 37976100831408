import { type SyntheticEvent } from "react";

/**
 * Allows to nest clickable elements and without the event bubbling up.
 * Prevents the default behavior and stops the event propagation.
 * Optionally, calls a callback function.
 *
 * @param cb - Callback function to call when the event is stopped
 * @returns A function that stops the event and calls the callback function
 * @category Utils
 * @example
 *
 * const handleClick = allowNestedClickWithCallback(() => console.log("Clicked"));
 *
 * <button onClick={handleClick}>Click me</button>
 */
export function allowNestedClickWithCallback<T extends SyntheticEvent | Event>(
    cb?: (evt: T) => unknown,
): (evt: T) => unknown {
    return (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        return cb?.(evt);
    };
}
