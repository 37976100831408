import {
    KnockFeedProvider,
    KnockProvider,
    NotificationCell,
    NotificationFeedPopover,
    NotificationIconButton,
} from "@knocklabs/react";
import { useRef, useState } from "react";

// Required CSS import, unless you're overriding the styling
import "@knocklabs/react/dist/index.css";
import { useAtomValue } from "jotai";
import { currentUserAtom } from "../TopBar/TopBarProfil";

const NotificationCenter = () => {
    const [isVisible, setIsVisible] = useState(false);
    const notifButtonRef = useRef(null);
    const currentUser = useAtomValue(currentUserAtom);
    if (
        !import.meta.env.VITE_REACT_APP_KNOCK_PUBLIC_API_KEY ||
        !currentUser?.id ||
        !import.meta.env.VITE_REACT_APP_KNOCK_FEED_CHANNEL_ID
    ) {
        return <></>;
    } else {
        return (
            <KnockProvider
                apiKey={import.meta.env.VITE_REACT_APP_KNOCK_PUBLIC_API_KEY}
                userId={currentUser?.id}
            >
                <KnockFeedProvider
                    feedId={
                        import.meta.env.VITE_REACT_APP_KNOCK_FEED_CHANNEL_ID
                    }
                >
                    <>
                        <NotificationIconButton
                            ref={notifButtonRef}
                            onClick={() => setIsVisible(!isVisible)}
                        />
                        <NotificationFeedPopover
                            buttonRef={notifButtonRef}
                            isVisible={isVisible}
                            onClose={() => setIsVisible(false)}
                            renderItem={({ item, ...props }) => (
                                <NotificationCell
                                    {...props}
                                    key={item.id}
                                    item={item}
                                    avatar={null}
                                />
                            )}
                        />
                    </>
                </KnockFeedProvider>
            </KnockProvider>
        );
    }
};

export default NotificationCenter;
