import * as Popover from "@radix-ui/react-popover";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "react";
import "./i18n";

interface SaveBarState {
    open: boolean;
    warning: boolean;
    content: React.ReactNode;
    mainButton: React.ReactNode;
    loading: boolean;
    isResetButtonVisible: boolean;
    onReset: (() => void) | undefined;
}

const saveBarStateAtom = atom<SaveBarState>({
    open: false,
    warning: false,
    content: undefined,
    mainButton: undefined,
    loading: false,
    isResetButtonVisible: false,
    onReset: undefined,
});

export const setSaveBarStateAtom = atom(
    null,
    (get, set, newVal: Partial<SaveBarState>) => {
        set(saveBarStateAtom, {
            ...get(saveBarStateAtom),
            ...newVal,
        });
    }
);

export const isSaveBarOpenAtom = atom((get) => get(saveBarStateAtom).open);
export const setSaveBarWarningAtom = atom(
    null,
    (get, set, warning: boolean) => {
        set(saveBarStateAtom, {
            ...get(saveBarStateAtom),
            warning: warning,
        });
    }
);

export default function SaveBar() {
    const state = useAtomValue(saveBarStateAtom);
    const setState = useSetAtom(setSaveBarStateAtom);
    const open = useAtomValue(isSaveBarOpenAtom);

    useEffect(() => {
        let timer = setTimeout(() => setState({ warning: false }), 2 * 1000);
        return () => {
            clearTimeout(timer);
        };
    }, [state.warning]);

    return (
        <Popover.Root open={open} modal={false}>
            <div className="fixed bottom-0 left-0 w-screen">
                <Popover.Anchor />
            </div>
            <Popover.Portal>
                <Popover.Content
                    className={` mb-4 p-2 pl-5 flex items-center justify-between space-x-8 rounded-lg
            ${state.warning ? "bg-error-600" : "bg-neutral-900"}
            `}
                >
                    <div className="text-white flex items-center space-x-2">
                        {state.content}
                    </div>
                    <div className="flex space-x-3">
                        {state.isResetButtonVisible && (
                            <button
                                className="px-3 text-white text-regular"
                                onClick={state.onReset}
                                disabled={state.loading}
                            >
                                Reset
                            </button>
                        )}
                        {state.mainButton}
                    </div>
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
}
