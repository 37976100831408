import { useTranslation } from "react-i18next";
import "./i18n";

const GetStartedChip = () => {
    const { t } = useTranslation("CreateDocumentVersionBlock");
    return (
        <div className="flex flex-col items-center">
            <div className="py-1 px-3 rounded-full bg-white border border-tertiary text-sm font-bold space-x-2 w-fit">
                <span>🚀</span>
                <span>{t("get_started")}</span>
            </div>
            <div className="h-4 border-l border-tertiary" />
        </div>
    );
};

export default GetStartedChip;
