import { FileText } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    SustainabilityCenter,
    Theme,
} from "../../../../../generated/client/graphql";
import ControlsBlock from "../../../../components/SustainabilityCenter/ControlsBlock";
import FaqBlock from "../../../../components/SustainabilityCenter/FaqBlock";
import SideBarBlock from "../../../../components/SustainabilityCenter/SideBarBlock";
import "../i18n";
import EditDocumentsModal from "./modals/EditDocumentsModal";
import EditFaqsModal from "./modals/EditFaqsModal";
import EditFrameworksModal from "./modals/EditFrameworksModal";
import EditStrategyModal from "./modals/EditStrategyModal";

export default function SustainabilityCenterOverviewTab({
    sustainabilityCenter,
    themes,
    editMode,
    setTabValue,
}: {
    sustainabilityCenter: SustainabilityCenter;
    themes: Theme[];
    editMode: boolean;
    setTabValue: (val: string) => void;
}) {
    const { t } = useTranslation("SustainabilityCenter", {
        lng: sustainabilityCenter?.locale || "fr",
    });

    const [openStrategyModal, setOpenStrategyModal] = useState(false);
    const [openFrameworksModal, setOpenFrameworksModal] = useState(false);
    const [openDocumentsModal, setOpenDocumentsModal] = useState(false);
    const [openFAQsModal, setOpenFAQsModal] = useState(false);

    const firstBlockProps = {
        editMode: editMode,
        title: t("impact_strategy"),
        emptyText: t("show_strategy"),
        buttonText: t("add_strategy"),
        subtitle: sustainabilityCenter?.strategyTitle || "",
        description: sustainabilityCenter?.strategyDescription || "",
        setOpenModal: setOpenStrategyModal,
    };
    const secondBlockProps = {
        editMode: editMode,
        title: t("compliance"),
        emptyText: t("show_frameworks"),
        buttonText: t("add_frameworks"),
        setOpenModal: setOpenFrameworksModal,
        items:
            sustainabilityCenter?.scFrameworks?.map((elt) => {
                return {
                    name: elt.name || "",
                    imageUrl: elt.badge?.signedUrl || undefined,
                    order: elt.order || 0,
                };
            }) || [],
    };

    const thirdBlockProps = {
        editMode: editMode,
        title: t("documents"),
        emptyText: t("show_documents"),
        buttonText: t("add_documents"),
        setOpenModal: setOpenDocumentsModal,
        items:
            sustainabilityCenter?.scDocuments?.map((elt) => {
                return {
                    ...elt,
                    icon: FileText,
                    link:
                        elt.document?.lastVersion?.finalFile?.signedUrl ||
                        undefined,
                };
            }) || [],
    };

    const showStrategy =
        sustainabilityCenter?.strategyTitle ||
        sustainabilityCenter?.strategyDescription;
    const showFrameworks =
        sustainabilityCenter?.scFrameworks &&
        sustainabilityCenter?.scFrameworks?.length > 0;
    const showDocuments =
        sustainabilityCenter?.scDocuments &&
        sustainabilityCenter?.scDocuments.length > 0;
    const showFaqs =
        sustainabilityCenter?.scFaqs && sustainabilityCenter?.scFaqs.length > 0;
    return (
        <div className="flex items-start w-[1120px] mt-7 gap-7">
            <div className="w-[300px] gap-7 flex flex-col">
                {(editMode || showStrategy) && (
                    <SideBarBlock {...firstBlockProps} />
                )}
                {(editMode || showFrameworks) && (
                    <SideBarBlock {...secondBlockProps} />
                )}
                {(editMode || showDocuments) && (
                    <SideBarBlock {...thirdBlockProps} />
                )}
            </div>
            <div className="flex flex-col items-start gap-7 flex-1">
                <ControlsBlock
                    themes={themes}
                    locale={sustainabilityCenter?.locale || "fr"}
                    setTabValue={setTabValue}
                ></ControlsBlock>
                {(editMode || showFaqs) && (
                    <FaqBlock
                        sustainabilityCenter={sustainabilityCenter}
                        setOpenModal={setOpenFAQsModal}
                        editMode={editMode}
                    ></FaqBlock>
                )}
            </div>
            <EditStrategyModal
                sustainabilityCenter={sustainabilityCenter}
                open={openStrategyModal}
                setOpen={setOpenStrategyModal}
            />
            <EditFrameworksModal
                sustainabilityCenter={sustainabilityCenter}
                open={openFrameworksModal}
                setOpen={setOpenFrameworksModal}
            />
            <EditDocumentsModal
                sustainabilityCenter={sustainabilityCenter}
                open={openDocumentsModal}
                setOpen={setOpenDocumentsModal}
            />
            <EditFaqsModal
                sustainabilityCenter={sustainabilityCenter}
                open={openFAQsModal}
                setOpen={setOpenFAQsModal}
            ></EditFaqsModal>
        </div>
    );
}
