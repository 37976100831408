import chroma from "chroma-js";
import "./i18n";

interface ColorTagProps {
    color: string;
    text: string;
}

/**
 * @deprecated
 */
export default function ColorTag({ color, text }: ColorTagProps) {
    return (
        <div
            className="flex items-center border rounded px-2 h-[20px] box-border max-w-fit "
            style={{
                backgroundColor: color && chroma(color).alpha(0.1).hex(),
                borderColor: color && chroma(color).alpha(0.3).hex(),
            }}
        >
            <div
                className=" text-xs font-bold truncate "
                style={{
                    color: color,
                }}
            >
                {text}
            </div>
        </div>
    );
}
