import { useTranslation } from "react-i18next";
import {
    GetAllSupplierQuestionnaireTemplatesDocument,
    SupplierQuestionnaireTemplate,
} from "../../../../../generated/client/graphql";
import { useGraphQL } from "../../../../QueryClientWithHeaders";
import { DropdownMenu } from "../../components";
import BaseTrigger from "../../components/triggers/Base";
import SupplierQuestionnaireTemplatesDropdownSingleSelect from "./dropdown_single_select";

const TemplateSetterFloatbar = ({
    templateId,
    onTemplateIdChange,
}: {
    templateId?: string;
    onTemplateIdChange: (id: string) => void;
}) => {
    const { t } = useTranslation("SupplierQuestionnaireTemplatesDropdown");
    const getTemplates = useGraphQL(
        GetAllSupplierQuestionnaireTemplatesDocument,
        undefined,
        {},
        true
    );

    const selectedTemplate =
        getTemplates.data?.supplierQuestionnaireTemplates?.find(
            (template) => template.id === templateId
        );
    return (
        <DropdownMenu>
            <BaseTrigger>
                {selectedTemplate
                    ? selectedTemplate.name
                    : t("choose_template")}
            </BaseTrigger>
            <SupplierQuestionnaireTemplatesDropdownSingleSelect
                templateId={templateId}
                onTemplateIdChange={onTemplateIdChange}
                templates={
                    getTemplates.data?.supplierQuestionnaireTemplates as
                        | SupplierQuestionnaireTemplate[]
                        | undefined
                }
            />
        </DropdownMenu>
    );
};

export default TemplateSetterFloatbar;
