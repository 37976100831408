import { useNavigateToOpenRequirement } from "@app/pages/Requirements/components/modal/modal-hooks";
import {
    ColumnDef,
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
    FrameworkSection,
    Requirement,
} from "../../../../../generated/client/graphql";
import FullFrameBlock from "../../../../components/Layouts/FullFrame/Block";
import DataTable from "../../../../components/Table/DataTable";
import Header from "../../../../components/Table/Header";
import { Tooltip } from "../../../../components/Tooltip";
import ChooseRequirementModal from "./components/ChooseRequirementModal";
import "./i18n";

const columns: ColumnDef<Requirement & { section: FrameworkSection }>[] = [
    {
        id: "framework",
        meta: {
            headerClassName: "w-1/4",
        },
        header: ({ column }) => {
            const { t } = useTranslation("DocumentRequirementTable");
            return <Header title={t("framework")} column={column} />;
        },
        cell: ({ row }) => {
            return <div>{row.original.section.framework.name}</div>;
        },
    },
    {
        id: "section",
        meta: {
            headerClassName: "w-1/4",
        },
        header: ({ column }) => {
            const { t } = useTranslation("DocumentRequirementTable");
            return <Header title={t("section")} column={column} />;
        },
        cell: ({ row }) => {
            return (
                <Tooltip tooltipContent={row.original.section.name}>
                    {row.original.section.tagName}
                </Tooltip>
            );
        },
    },
    {
        id: "name",
        meta: {
            headerClassName: "w-1/2",
        },
        header: ({ column }) => {
            const { t } = useTranslation("DocumentRequirementTable");
            return <Header title={t("name")} column={column} />;
        },
        cell: ({ row }) => {
            return <div className=" line-clamp-2">{row.original.name}</div>;
        },
    },
];
const RequirementTable = ({
    documentId,
    showUpdateReqsModal,
    requirements,
}: {
    documentId: string;
    showUpdateReqsModal: boolean;
    requirements: Requirement[];
}) => {
    const data = useMemo(() => {
        return requirements
            .filter((r) => r.frameworkSections)
            .flatMap((r) =>
                r.frameworkSections?.map((fs) => ({ ...r, section: fs })),
            ) as (Requirement & { section: FrameworkSection })[];
    }, [requirements]);

    const table = useReactTable<Requirement & { section: FrameworkSection }>({
        data,
        columns,

        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    const navigate = useNavigateToOpenRequirement();

    return (
        <FullFrameBlock>
            <DataTable
                table={table}
                rowClassName={() => "cursor-pointer"}
                onRowClick={(row) => {
                    navigate(row.original.id);
                }}
                noBorder
            />
            {showUpdateReqsModal && (
                <div className="w-full flex justify-end px-4 h-[44px] items-center">
                    <ChooseRequirementModal
                        documentId={documentId}
                        requirements={requirements}
                    />
                </div>
            )}
        </FullFrameBlock>
    );
};

export default RequirementTable;
